import React from "react";
import "./CustomizableChatWindow.scss";

function CustomizableChatWindow(props) {
  return (
    <div className="CustomizableWindow">
      <div className="jetsense-chatbot body-proactive active">
        <div
          className="company-header"
          style={{ backgroundColor: props.chatbotWidgetColor }}
        >
          <div className=" header-proactive">
            <div className="logo  header-proactive-agents">
              <div className="agent-image-container">
                <img
                  className="agent-header agent-1"
                  src="https://assets.jetsense.ai/TextChat/v2assets/agent%201.png"
                  title="Ian"
                  alt="Ian"
                />
                <img
                  className="agent-header agent-2"
                  src="https://assets.jetsense.ai/TextChat/v2assets/agent%203.png"
                  title="Cindy"
                  alt="Cindy"
                />
                <span className="status-oval" />
              </div>
              <div className="jetsense-chatbot-companyTitle">
                <div>{props.header}</div>
                <div className="slogan">{props.slogan}</div>
              </div>
              <div className="window-action btns">
                <div className="jetsense-chatbot-minimize">
                  <i
                    className="textchat-fa textchat-fa-times"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </div>

            <div className="header-greating">
              {props.greeting
                ? props.greeting.replace(/[\n\r]/g, "</br>")
                : " "}
            </div>
          </div>
          {/* <div className="gradient-transfer-animation hidden" /> */}
        </div>
        <div className="jetsense-chatbot-body" style={{ top: "220px" }}>
          {/* <iframe src="http://localhost:8080/root?clientToken=d7c26a28-4f49-4490-b852-07030b85dbad&amp;isAfterHours=false" id="js-chatbot" className="glass-design-iframe"></iframe> */}
          <div className="theme-new-design uhctheme--shopify keep-msg-bottom js-chatbot-top">
            <aside className="hide">
              <div className="transfer-loader lds-ellipsis">
                <div />
                <div />
                <div />
                <div />
              </div>
              <div className="row">
                <div className="agent">
                  <div className="meta">
                    {/* <img src="https://www.getmonero.org/press-kit/symbols/monero-symbol-800.png" width="50" /> */}
                    <img
                      src="assets/img/Chatbot agent.png"
                      className="icon-agent hide"
                      width={50}
                    />
                    <img
                      src="https://assets.jetsense.ai/textchat/your%20icon%20here.png"
                      className="icon-bot"
                      width={50}
                    />
                  </div>
                  <div className="bubble-container">
                    <div className="bubble">
                      <div className="text">
                        <div>Hello there! Welcome to the site!</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="time">15:32</div>
              </div>
              <div className="row">
                <div className="visitor">
                  <div className="bubble-container">
                    <div className="bubble">
                      <div className="text">
                        <div>Hello there! Welcome to the site!</div>
                      </div>
                    </div>
                  </div>
                  <div className="meta">
                    <img
                      src="https://pngimage.net/wp-content/uploads/2018/05/avatar-profile-png-8.png"
                      width={50}
                    />
                    <div className="time">15:32</div>
                  </div>
                </div>
              </div>
              <div className="loading">
                <div className="dot one" />
                <div className="dot two" />
                <div className="dot three" />
              </div>
            </aside>
            <section className="page">
              <section className="chat-status-bar ">
                <div className="status-bar-frame">
                  <i className="active fa fa-circle" aria-hidden="true" />
                  <span className="status-bar-text">Active</span>
                </div>
              </section>
              <div className="chat-frame">
                <div className="chat-body">
                  <div className="row chatbot-msg type-agent" data-nodeid={1}>
                    <div className="agent">
                      <div className="meta">
                        {/* <img src="https://www.getmonero.org/press-kit/symbols/monero-symbol-800.png" width="50" /> */}
                        <img
                          src="C:\Users\asus\Desktop\MCN\JetSense\JetSense.TextChat.Shopify\textchat.shopify.client\src\img\Chatbot agent icon.png"
                          className="icon-agent hide"
                          width={50}
                        />
                        {/* <img
                        src="https://assets.jetsense.ai/TextChat/v2assets/your%20logo%20here.png"
                        className="icon-bot"
                        width={50}
                      /> */}
                      </div>
                      <div className="bubble-container">
                        <div
                          className="bubble initial"
                          style={{ backgroundColor: props.AgentBubbleColor }}
                        >
                          <div
                            className="text"
                            style={{ color: props.AgenttextColor }}
                          >
                            <div>
                              This is what a message from your chatbot looks
                              like.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="time" style={{ display: "block" }}>
                      23 h
                    </div>
                  </div>
                  <div className="row type-visitor first">
                    <div className="visitor">
                      <div className="bubble-container">
                        <div
                          className="bubble initial"
                          style={{ backgroundColor: props.clientBubbleColor }}
                        >
                          <div
                            className="text"
                            style={{ color: props.clientTextColor }}
                          >
                            <div className="nowrap">
                              This is what a message from a visitor looks like.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="meta">
                        <img
                          src="https://pngimage.net/wp-content/uploads/2018/05/avatar-profile-png-8.png"
                          width={50}
                        />
                        <div className="time" style={{ display: "none" }}>
                          Now
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <section className="msg-container">
                <div className="err">Test</div>
                {/*
      <input type="text" class="msg" />
       <a href="#" class="logo-btn">
          <img src="client/shopify/assets/img/arrow.png" style="border:0;" />
          <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>
      </a> */}
                <img
                  src="https://assets.jetsense.ai/textchat/smiley.png"
                  alt="smiley"
                  className="emoticons no-pointer"
                />
                <textarea
                  type="text"
                  className="msg input-enter-message"
                  placeholder="Type message here..."
                  data-emojiable="true"
                  disabled
                  defaultValue={"            "}
                />
                <div className="icon-send">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={21}
                    height={18}
                    viewBox="0 0 21 18"
                  >
                    <g fill="none" fillRule="evenodd">
                      <path
                        d="M0 0L24 0 24 24 0 24z"
                        transform="translate(-2 -3)"
                      />
                      <path
                        fill="#4285F4"
                        d="M2.01 21L23 12 2.01 3 2 10 17 12 2 14z"
                        transform="translate(-2 -3)"
                      />
                    </g>
                  </svg>
                </div>
              </section>
              <div className="chat-footer">
                <span>
                  Powered by{" "}
                  <a target="_blank" href="https://www.textchat.com">
                    TextChat
                  </a>
                </span>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomizableChatWindow;
