import React, { Component } from "react";
import PDF from "../../components/Shop/Billing/Invoice/PDF/PDF";
import { PDFViewer } from "@react-pdf/renderer";
import SearchParams from "../../components/General/SearchParams";
import axios from "axios";
import ErrorRequest from "../../components/General/ErrorRequest";
import en from "@shopify/polaris/locales/en.json";
import { AppProvider } from "@shopify/polaris";

class GetInvoice extends Component{

    constructor(){
        super();
        this.state = {
            pdfContent: null,
            shopInfo: null,
            error: false,
            shop: null,
            setupInfo: null
        };
    }

    componentDidMount(){
        const params = SearchParams();
        axios
            .get(this.props.serverAPI + "/app/get/invoice", {
                params: {
                    shop: params.shop || this.props.shop,
                    kbill: params.kbill || this.props.kbill,
                },
            })
            .then((resp) => {
                const {bill, shopInfo} = resp.data;
                if (bill && shopInfo){
                    this.setState({
                        pdfContent: bill,
                        shopInfo: shopInfo,
                    });
                }
            })
            .catch((error) => {
                console.log("error")
                this.setState({
                    shop: params.shop || this.props.shop,
                    error: error.response,
                });
            });

        axios.get(`${this.props.serverAPI}/app/get/info`, {
                params: {
                    shop: params.shop || this.props.shop
                }
            }).then(resp => {
                const infoshop = resp.data[0] || {};
                let allAppFields = {}
                for (var i = 0; i<5; i++) {
                    let stepInfo = infoshop['step' + String(i)]
                    if (stepInfo)
                        allAppFields = {...allAppFields, ...stepInfo}
                }
                this.setState({setupInfo: allAppFields})
            })
            .catch(error=>{
                console.log("error")
                this.setState({
                    shop: params.shop || this.props.shop,
                    error: error.response,
                });
            });
    }

    render(){
        if (this.state.error)
            return (
                <AppProvider i18n={en}>
                    <ErrorRequest shop={this.state.shop} error={this.state.error} serverAPI={this.props.serverAPI}/>
                </AppProvider>
            );
        if (!this.state.pdfContent || !this.state.shopInfo || !this.state.setupInfo) return null;
        
        const shopInfoToRender = this.state.shopInfo
        if(this.state.setupInfo['contactName'])
            shopInfoToRender['Name'] = this.state.setupInfo['contactName']
        if(this.state.setupInfo['businessName'])
            shopInfoToRender['BusinessName'] = this.state.setupInfo['businessName']
        if(shopInfoToRender['Address1'] = "2 Radnor Corporate Center") {
            shopInfoToRender['Address1'] = null
            shopInfoToRender['Address2'] = null
        }
                
        return (
            <PDFViewer className="full-page-invoice pdf-viewer">
                <PDF
                    pdfContent={this.state.pdfContent}
                    shopInfo={shopInfoToRender}
                />
            </PDFViewer>
        );
    }

}

export default GetInvoice;