import { Card } from "@shopify/polaris";
import React from "react";
import "./CustomizablePopUp1.scss";
import { CloseIcon } from "../../../SVG/CustomIcons";
import AgentOliver from "../../../../assets/img/Chatbot-agent-oliver.jpg";
import AgentIan from "../../../../assets/img/Chatbot-agent-Ian1.jpg";

function CustomizablePopUp(props) {
  return (
    <Card>
      <Card.Section>
        <div className="customizablePopUp">
          <div className="classic-main-container">
            <div className="chat-popup-text-container">
              <div className="classic-chatbot-bubble">
                <p>{"Ask us anything, we're online now!"}</p>
              </div>

              <div className="btn-container">
                <div
                  className="classic-chat-btn"
                  style={{ backgroundColor: props.buttonColor }}
                >
                  <div className="tc-agent-container js-agent-box ">
                    <div className="tc-agent">
                      <div className="tc-agent-img-container">
                        <img className="tc-agent-img" src={AgentIan} />
                        <span className="badge-online">
                          <span></span>
                        </span>
                      </div>
                    </div>
                    <div className="tc-agent">
                      <div className="tc-agent-img-container">
                        <img
                          className="tc-agent-img"
                          src="https://d2fzjtk430nuyq.cloudfront.net/f82d51e5-abe0-4cd0-a015-5362b40ee74a/5000cb05-cf57-4405-b8b3-56e40e583248.jpeg"
                        />
                        <span className="badge-online">
                          <span></span>
                        </span>
                      </div>
                    </div>
                    <div className="tc-agent">
                      <div className="tc-agent-img-container">
                        <img className="tc-agent-img" src={AgentOliver} />
                        <span className="badge-online">
                          <span></span>
                        </span>
                      </div>
                    </div>{" "}
                  </div>
                  <span
                    className="chat-text"
                    style={{ color: props.textColor }}
                  >
                    Chat Now
                  </span>
                </div>
              </div>
            </div>
            <div className="message-cross-container">
              <a href={null}>
                <CloseIcon
                  height="12px"
                  width="14px"
                  color={props.buttonColor}
                />
              </a>
            </div>
          </div>
        </div>
      </Card.Section>
      {/* <Card.Section>
        <h2 className="popupWindowHeading">Customizable Popup</h2>
        <p style={{ textAlign: "center" }}>
          This popup is customizable for users that like to have a hand in their
          branding and design. Simplicity and control comibe to make this the
          ideal popup for a user with moderate experience.
        </p>
      </Card.Section> */}
    </Card>
  );
}

export default CustomizablePopUp;
