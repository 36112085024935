import React, { useState, useCallback } from "react";
import { TextField, Popover, Button, Icon } from "@shopify/polaris";
import { MobileHamburgerMajor } from "@shopify/polaris-icons";
import DownArrowIcon from "../../../assets/img/down-arrow.svg";

import "./style.css";

function FilterList(props) {
  const [popoverActive, setPopoverActive] = useState(false);
  const [searchText, setSearchText] = useState("");

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    []
  );

  const activator = (
    <div
      onClick={togglePopoverActive}
      style={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        height: "44px",
        border: "1px solid #EBEAED",
        borderRadius: "10px",
        width: "296px",
        padding: "0 20px 0 16px",
        cursor: "pointer",
      }}
    >
      <span
        style={{
          fontSize: "16px",
          fontWeight: 400,
          lineHeight: "125%",
          letterSpacing: "0.5px",
          color: "#262626",
          textTransform: "capitalize",
        }}
      >
        {props.title}
      </span>
      <img src={DownArrowIcon} alt="" width="12px" height="12px" />
    </div>
  );

  function filteredList() {
    let filterList = props.filterList || [];

    if (searchText.trim() === "") return filterList;
    else {
      // filter list and set new list
      let newList = filterList.filter((item) => {
        return (
          item.name.toLowerCase().indexOf(searchText.trim().toLowerCase()) !== -1
        );
      });

      return newList;
    }
  }
  function handleTextFieldChange(value) {
    setSearchText(value);
  }

  return (
    <div className="padded-filterList">
      <Popover
        active={popoverActive}
        activator={activator}
        onClose={togglePopoverActive}
        preferredAlignment="left"
        fullHeight={true}
      >
        <div className="tc-filterList">
          <Popover.Section>
            <TextField
              value={searchText}
              onChange={handleTextFieldChange}
              placeholder="Search Agent"
              autoComplete={false}
            />
          </Popover.Section>
          <Popover.Section>
            <div
              style={{
                width: "248px",
                minHeight: "226px",
                maxHeight: "226px",
                overflowY: "auto",
              }}
            >
              {filteredList().map((item, index) => {
                return props.renderItem(item, index);
              })}
            </div>
            {/* display filter List here */}
          </Popover.Section>
          <Popover.Section>
            <Button plain monochrome onClick={props.onClear}>
              Clear Filter
            </Button>
          </Popover.Section>
        </div>
      </Popover>
    </div>
  );
}

export default FilterList;
