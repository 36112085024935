import React from "react";
import Img11 from "../../images/img-11.png";
import Img12 from "../../images/img-12.png";
import Img13 from "../../images/img-13.png";
import Img14 from "../../images/img-14-02.png";
import Img15 from "../../images/img-15.png";
import Img18 from "../../images/img-18.png";
import Img17 from "../../images/img-17.png";
import Dots from "../../images/dots.png";
import Bg1 from "../../images/bg-1.png";
import Bg2 from "../../images/bg-2.png";
import Bg3 from "../../images/bg-3.png";
import Img21 from "../../images/img-21.png";
import Img22 from "../../images/img-22.png";
import Img23 from "../../images/img-23.png";
import AnswerNet_logo from "../../images/answerNet_logo_02.png";

import { useParams } from "react-router-dom";

const EFLeftSideWrapper = (props) => {
  let params = useParams();

  const isFinalStep = JSON.parse(localStorage.getItem("final-step"));

  return (
    <div className="col-6 leftside">
      <div
        className="inner-bg"
        style={{
          backgroundImage: [
            "signup-textable",
            "signup-nontextable",
            "select-new-number",
          ].includes(params.form)
            ? `url(${Bg2})`
            : params.form === "agent-transfer" ||
              params.form === "answernet-signup"
            ? `url(${Bg3})`
            : `url(${Bg1})`,
        }}
      >
        <div className="inner-wrapper">
          {/* {params.form === "answernet-signup" && (
            <div className="logo-wrpper">
              <img className="logo" src={AnswerNet_logo} alt="" />
            </div>
          )} */}
          <div className="img-holder">
            {/* {displayWrapperImage()} */}
            {params.form == "answernet-signup" && (
              <img src={Img21} alt="img1" />
            )}
            {params.form == "select-new-number" && (
              <img src={Img14} className=" " alt="img1" />
            )}
            {params.form == "signup-textable" && !isFinalStep && (
              <img src={Img12} className=" " alt="img1" />
            )}
            {params.form == "signup-textable" && isFinalStep && (
              <img src={Img23} className=" " alt="img1" />
            )}
            {params.form == "signup-nontextable" && (
              <img src={Img14} className=" " alt="img1" />
            )}
            {params.form == "existing-with-new-copy" && (
              <img src={Img18} className="  " alt="img1" />
            )}
            {params.form == "signup-loa" && (
              <img src={Img18} className=" " alt="img1" />
            )}
            {(params.form == "complete" ||
              params.form == "completeOne" ||
              params.form == "no-sms-hosting" ||
              params.form == "website-url") && (
              <img src={Img13} className=" " alt="img1" />
            )}
            {params.form == "agent-transfer" && (
              <img src={Img22} className=" " alt="img1" />
            )}
            {params.form == "pricing" && (
              <img src={Img15} className="  " alt="img1" />
            )}
            {params.form == "pricing-no-live-agents" && (
              <img src={Img15} className="  " alt="img1" />
            )}
            {params.form == "plan-selection" && (
              <img src={Img17} className=" " alt="img1" />
            )}
            {/* {params.form == "complete" && (
              <img src={Img13} className="  " alt="img1" />
            )} */}
            {params.form == "account-setup" && (
              <img src={Img13} className="  " alt="img1" />
            )}
            {/* {params.form == "complete-one" && (
              <img src={Img13} className="  " alt="img1" />
            )} */}
          </div>
          {params.form === "answernet-signup" && (
            <div className="">
              <div className="slide-title signup-text text-dark">
                A completely new way to <br />
                communicate with your customers.
              </div>
              {/* <div className="slide-sub-title signup-text text-dark font-14">
                Enhance your communication with customers - by allowing
                <br />
                them to text your business phone and offer live chat on your
                website!
              </div> */}
            </div>
          )}
          {params.form === "existing-with-new-copy" ||
          params.form === "signup-loa" ? (
            <div className="slide-title existing-with-new-copy-text">
              You’re a few steps away from full textability!
            </div>
          ) : null}
          {params.form === "signup-textable" && (
            <div className="slide-title signup-textable-text">
              Give your customers the ease of communication they’re looking for.
            </div>
          )}
          {params.form === "agent-transfer" && (
            <div className="slide-title agent-transfer-text">
              Show your customers constant availability with the support of our
              live agents.
            </div>
          )}
          {["pricing", "pricing-no-live-agents"].includes(params.form) && (
            <div className="slide-title pricing-text">
              Get ready for happier customers!
            </div>
          )}
          {["signup-nontextable", "select-new-number"].includes(
            params.form
          ) && (
            <div className="slide-title signup-nontextable-text">
              Ready for a new textable number?
            </div>
          )}
          {["complete", "complete-one", "account-setup"].includes(
            params.form
          ) && (
            <div className="slide-title-wrapper">
              <div className="slide-title complete-text">
                A completely new way to communicate with your customers.
              </div>
              {/* <div className="slide-sub-title complete-text">
                Enhance your communication with customers - by allowing them to
                text your business phone and offer live chat on your website!
              </div> */}
            </div>
          )}
          {["completeOne"].includes(params.form) && (
            <div className="slide-title-wrapper">
              <div className="slide-title complete-text">
                Welcome to AnswerNet TextChat... let's get texting!
              </div>
              {/* <div className="slide-sub-title complete-text">
                Enhance your communication with customers - by allowing them to
                text your business phone and offer live chat on your website!
              </div> */}
            </div>
          )}
        </div>
        {params.form !== "agent-transfer" &&
          params.form !== "answernet-signup" && (
            <div className="div-dots">
              <img src={Dots} alt="" />
            </div>
          )}
      </div>
    </div>
  );
};

export default EFLeftSideWrapper;
