import React from 'react';
import './StepHead.css';

const StepHead = ({step}) => {
    if (!step) return null;
    return (
        <div className="m-portlet__head steps-head">
            <div className="nav w-100 flex-nowrap align-items-center">
                <a
                    className={
                            `head-step-main font-heading w-100 h-100 align-items-center justify-content-left
                            ${step === 1 ? 'js-active' : ''}`
                    }>
                    <span>1</span>Admin Info
                </a>
                <a
                    className={
                        `head-step-main font-heading w-100 h-100 align-items-center justify-content-left
                            ${step === 2 ? 'js-active' : ''}`
                    }>
                    <span>2</span>User Info
                </a>
                <a
                    className={
                        `head-step-main font-heading w-100 h-100 align-items-center justify-content-left
                            ${step === 3 ? 'js-active' : ''}`
                    }>
                    <span>3</span>Chatbot Editor
                </a>
                <a
                    className={
                        `head-step-main font-heading w-100 h-100 align-items-center justify-content-left
                            ${step === 4 ? 'js-active' : ''}`
                    }>
                    <span>4</span>Billing
                </a>
            </div>
        </div>
    );
}
export default StepHead;
