import React from "react";
import "./ProactiveWidgetPreview.css";

export default function ProactiveWidgetPreview({
  previewText = "",
  color = "#0B24FB",
}) {
  return (
    <div className="preview-mode">
      <div className="proactive">
        <div className="bar" style={{ backgroundColor: color }}>
          <div className="agents">
            <img
              style={{ border: "3px solid " + color }}
              className="agent-widget"
              src="https://assets.jetsense.ai/TextChat/stock_1.png"
              title="John Smith"
              alt="John Smith"
            />
            <img
              style={{ border: "3px solid " + color }}
              className="agent-widget"
              src="https://assets.jetsense.ai/TextChat/stock_2.png"
              title="John Doe"
              alt="John Doe"
            />
            <span className="agent-online-dot"></span>
          </div>
          <div className="controls">
            <a className="proactive-jetsense-chatbot-excerpt-close">
              <i className="fa fa-times" aria-hidden="true"></i>
            </a>
          </div>
        </div>
        <div className="content">
          <div className="popup-text">{previewText}</div>
          <div
            style={{ color: "#ffffff", backgroundColor: color }}
            className="popup-btn"
          >
            Chat Now
          </div>
        </div>
      </div>
    </div>
  );
}
