import React from "react";
import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    Image,
    Link
} from "@react-pdf/renderer";
import moment from "moment";
import utility from "../../../../General/utility";
import reverbot from "../../../../../assets/img/robot.png";
import logo from "../../../../../assets/img/logo.png";
// Create styles
const styles = StyleSheet.create({
    page: {
        paddingTop: 15,
        paddingBottom: 15,
        paddingHorizontal: 20,
    },
    container: {
        marginBottom: 100,
        flexDirection: "row",
    },
    section: {
        flexGrow: 1,
    },
    sectionHeader: {
        paddingTop: 31,
        flexGrow: 1,
        color: "#fff",
    },
    sectionLarge: {
        flexGrow: 9,
        textAlign: "right",
    },
    header: {
        textAlign: "left",
        width: "100%",
        flexDirection: "row",
        backgroundColor: "#90b3c6",
        borderRadius: 10,
    },
    text: {
        fontSize: 12,
        textAlign: "justify",
        fontFamily: "Times-Roman",
        paddingRight: 30,
        paddingBottom: 4,
    },
    textHeader: {
        fontSize: 8,
        textAlign: "justify",
        fontFamily: "Times-Roman",
        paddingTop: 5,
        color: "#fff",
    },
    title: {
        fontSize: 14,
        paddingBottom: 7,
        paddingTop: 5,
        textAlign: "justify",
        fontFamily: "Times-Roman",
    },
    titleHeader: {
        fontSize: 16,
        paddingBottom: 7,
        paddingTop: 13,
        textAlign: "justify",
        fontFamily: "Times-Roman",
    },
    titleRight: {
        fontSize: 14,
        paddingBottom: 5,
        paddingTop: 5,
        paddingLeft: 200,
        textAlign: "justify",
        fontFamily: "Times-Roman",
    },
    textRight: {
        fontSize: 12,
        paddingLeft: 200,
        textAlign: "justify",
        fontFamily: "Times-Roman",
    },
    totalTitle: {
        fontSize: 14,
        paddingBottom: 5,
        paddingTop: 5,
        paddingRight: 85,
        textAlign: "right",
        fontFamily: "Times-Roman",
    },
    totalText: {
        fontSize: 18,
        paddingTop: 20,
        paddingRight: 65,
        textAlign: "right",
        fontFamily: "Times-Roman",
    },
    border: {
        borderBottomWidth: 1,
        borderBottomColor: "#112131",
        borderBottomStyle: "solid",
        alignItems: "stretch",
    },
    detailColumn: {
        flexDirection: "column",
    },
    textDescription: {
        fontSize: 7,
        paddingLeft: 15,
    },
    image: {
        width: "20%",
    },
    imageRight: {
        width: "20%",
        paddingTop: 35,
        paddingRight:15
    },
    link: {
        width: 80,
        textDecoration: "none",
        textAlign: "justify",
        color: "#fff",
    },
});

// Create Document Component
const PDF = ({ pdfContent, shopInfo }) => (
    <Document>
        <Page size="A4" style={styles.page}>
            <View style={styles.header}>
                <View style={styles.image}>
                    <Image src={reverbot} />
                </View>
                <View style={styles.sectionHeader}>
                    <Text style={styles.titleHeader}>RECEIPT</Text>
                </View>
                <View style={styles.sectionHeader}>
                    <Text style={styles.textHeader}>
                        <Link href="tel:8775780008" style={styles.link}>
                            877-578-0008
                        </Link>
                    </Text>
                    <Text style={styles.textHeader}>
                        <Link
                            href="mailto:info@jetsense.ai"
                            style={styles.link}
                        >
                            info@jetsense.ai
                        </Link>
                    </Text>
                    <Text style={styles.textHeader}>
                        <Link
                            href="https://www.jetsense.ai"
                            style={styles.link}
                        >
                            https://www.jetsense.ai
                        </Link>
                    </Text>
                </View>
                <View style={styles.sectionHeader}>
                    <Text style={styles.textHeader}>
                        2 Radnor Corporate Center
                    </Text>
                    <Text style={styles.textHeader}>
                        100 Matsonford Road, Suite 101
                    </Text>
                    <Text style={styles.textHeader}>Radnor, PA 19087</Text>
                </View>
                <View style={styles.imageRight}>
                    <Image src={logo} />
                </View>
            </View>
            <View style={styles.container}>
                <View style={styles.section}>
                    <Text style={styles.title}>BILLED TO</Text>
                    <Text style={styles.text}>{shopInfo.Name}</Text>
                    <Text style={styles.text}>
                        {shopInfo.Address1 ? shopInfo.Address1 + " " : shopInfo['BusinessName']}
                        {shopInfo.Address1 && shopInfo.Address2 ? +", " + shopInfo.Address2 : ""}
                    </Text>
                    <Text style={styles.text}>{shopInfo.Address1 ? shopInfo.Zip : ""}</Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.title}>RECEIPT NUMBER</Text>
                    <Text style={styles.text}>{pdfContent.id}</Text>
                    <Text style={styles.title}>DATE OF ISSUE</Text>
                    <Text style={styles.text}>
                        {pdfContent.updatedAt
                            ? moment(pdfContent.updatedAt).format("MM/DD/YYYY")
                            : ""}
                    </Text>
                </View>
            </View>
            <View style={styles.border}>
                <View style={styles.detailColumn}></View>
            </View>
            <View style={styles.container}>
                <View style={styles.section}>
                    <Text style={styles.title}>DESCRIPTION</Text>
                    <Text style={styles.text}>Flat rate per 30 days</Text>
                    <Text style={styles.text}>Prorated:</Text>
                    <Text style={styles.textDescription}>
                        Days using this app after
                    </Text>
                    <Text style={styles.textDescription}>
                        your trial expired
                    </Text>
                    <Text style={styles.text}>Overages:</Text>
                    <Text style={styles.textDescription}>
                        Charges for exceeding your
                    </Text>
                    <Text style={styles.textDescription}>
                        plan's available chat sessions
                    </Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.title}>ITEM COST</Text>
                    <Text style={styles.text}>
                        ${utility.formatMoney(pdfContent.planDescription.costPerAgent)}
                    </Text>
                    <Text style={styles.text}>
                        ${utility.formatMoney(pdfContent.prorated)}
                    </Text>
                    <Text style={styles.text}></Text>
                    <Text style={styles.text}></Text>
                    <Text style={styles.text}>
                        ${utility.formatMoney(pdfContent.overages)}
                    </Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.titleRight}>AMOUNT DUE</Text>
                    <Text style={styles.textRight}>
                        ${utility.formatMoney(pdfContent.totalCharge - (pdfContent.prorated + pdfContent.overages))}
                    </Text>
                    <Text style={styles.text}></Text>
                    <Text style={styles.textRight}>
                        ${utility.formatMoney(pdfContent.prorated)}
                    </Text>
                    <Text style={styles.text}></Text>
                    <Text style={styles.text}></Text>
                    <Text style={styles.text}></Text>
                    <Text style={styles.textRight}>
                        ${utility.formatMoney(pdfContent.overages)}
                    </Text>
                </View>
            </View>
            <View style={styles.container}>
                <View style={styles.sectionLarge}>
                    <Text style={styles.totalTitle}>RECEIPT TOTAL</Text>
                    <Text style={styles.totalText}>
                        ${pdfContent.totalCharge}
                    </Text>
                </View>
            </View>
        </Page>
    </Document>
);

export default PDF;
