import React, { FC, useRef } from "react";
import { InputBase, InputBaseProps, Box, useTheme, alpha } from "@mui/material";

interface InputWithIconProps {
  IconComponent?: React.ReactElement;
  inputProps?: InputBaseProps;
}

const InputWithIcon: FC<InputWithIconProps> = ({
  IconComponent,
  inputProps,
}) => {
  const theme = useTheme();
  const [inputFocused, setInputFocused] = React.useState(false);

  console.log(inputProps?.error);

  const propsOnFocused = inputFocused
    ? {
        boxShadow: `${alpha(
          inputProps?.error
            ? theme.palette.error.main
            : theme.palette.primary.main,
          0.25
        )} 0 0 0 0.2rem`,
        borderColor: inputProps?.error
          ? theme.palette.error.main
          : theme.palette.primary.main,
      }
    : {};

  return (
    <Box
      sx={{
        backgroundColor: "#fcfcfb",
        borderRadius: 2,
        border: `1px solid  ${inputProps?.error ? "red" : "#EBEAED"}`,
        padding: "4px 12px",
        ...propsOnFocused,
      }}
      display="flex"
      alignItems="center"
    >
      <InputBase
        sx={{
          flexGrow: 1,
          fontSize: theme.typography.fontSize,
        }}
        {...inputProps}
        onBlur={() => setInputFocused(false)}
        onFocus={() => setInputFocused(true)}
      />
      {IconComponent && IconComponent}
    </Box>
  );
};

export default InputWithIcon;
