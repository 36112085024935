import React from 'react';

const Nodes = (props) => {
    const {
        nodeDom,
        nodeObj,
        deleteNode,
        selectNode,
        editing
    } = props;

    let extraNodes = 0;
    return nodeDom.map((element, index)=>{
        if(element.isNode){
            extraNodes++;
        }
        let btns = extraNodes;
        return (

            <div
                key={index}
                id={element.idContainer}
                className={'window ' + (element.idNode === editing ? "active" : "") + (element.indexBtn !== undefined ? " btn" : "")}
                data-nodeid={element.idNode}
                data-objectid={element.id}
                style={{top: element.top, left: element.left}}

            >
                {element.deletable ?
                    <div className='delete' onClick={(e) => deleteNode(e)}>
                        x
                    </div>
                    : null
                }
                <div className='nodeBody' onClick={(e) => selectNode(e, element.idNode, index, btns)}>
                    <p className='msg'>
                        {element.isNode
                            ?
                            (!element.msgInput
                                ? nodeObj[element.parentNode-1].btns[element.indexBtn].msg
                                : nodeObj[element.parentNode-1].input.placeholder)
                            :
                            nodeObj[element.id - extraNodes].msg
                        }
                    </p>
                </div>
            </div>
        )
    });
}
export default Nodes;