import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Avatar,
  Typography,
  Grid,
  FormControl,
  useTheme,
  styled,
  InputLabel,
  Button,
} from "@mui/material";
import Resizer from "react-image-file-resizer";
import * as yup from "yup";
import { BootstrapInput } from "../../../components/Input/BootstrapInput";
import SaveIcon from "@mui/icons-material/Save";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useForm, Controller } from "react-hook-form";

const StyledInputLabel = styled(InputLabel)({
  fontSize: "18px",
  fontWeight: 500,
});

const userFormValidationSchema = yup.object({
  FullName: yup.string().required("Full Name is required"),
  EmailAddress: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  MobilePhoneNumber: yup.string().required("Mobile Phone Number is required"),
});

// User Form
const AddUserForm = ({
  onCloseModal,
  onAddAgent,
  serverAPI,
  agents,
  token,
  setFileImage,
  setIsCropDialogShown,
  fileImage,
  loading,
}) => {
  const { control, formState, handleSubmit } = useForm({
    mode: "all",
    resolver: yupResolver(userFormValidationSchema),
  });

  const onDrop = useCallback(async (acceptedFiles) => {
    const resizedFile = await resizeFile(acceptedFiles[0]);
    setFileImage(
      Object.assign(resizedFile, {
        preview: URL.createObjectURL(acceptedFiles[0]),
      })
    );
    setIsCropDialogShown(true);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const onAgentAdded = (data) => {
    onAddAgent(data);
  };

  const theme = useTheme();
  return (
    <Box width="100%" display="flex" flexDirection="column">
      <Box marginBottom={theme.spacing(1.5)} alignItems="center" display="flex">
        <Avatar src={fileImage?.preview} />
        <Box
          display="flex"
          flexDirection="column"
          marginLeft={theme.spacing(2)}
        >
          <Typography variant="h6" fontSize="13px" fontWeight="500">
            Profile Picture
          </Typography>
          <Box {...getRootProps()}>
            <input {...getInputProps()} />
            <Button
              sx={{
                marginTop: theme.spacing(1),
                borderRadius: theme.spacing(1.4),
                backgroundColor: theme.palette.action.hover,
              }}
            >
              Upload
            </Button>
          </Box>
        </Box>
      </Box>
      <form onSubmit={handleSubmit(onAgentAdded)}>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <Controller
              name="FullName"
              control={control}
              render={({ field, fieldState }) => (
                <FormControl fullWidth variant="standard">
                  <StyledInputLabel shrink htmlFor="FullName">
                    Full Name
                  </StyledInputLabel>
                  <BootstrapInput
                    {...field}
                    {...fieldState}
                    error={!!formState.errors.FullName}
                    fullWidth
                    id="FullName"
                  />
                  {formState.errors.FullName && (
                    <Typography color="error" fontSize="12px" marginTop="4px">
                      {formState.errors.FullName.message}
                    </Typography>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item md={12}>
            <Controller
              name="EmailAddress"
              control={control}
              render={({ field, fieldState }) => (
                <FormControl fullWidth variant="standard">
                  <StyledInputLabel shrink htmlFor="EmailAddress">
                    Email Address
                  </StyledInputLabel>
                  <BootstrapInput
                    {...field}
                    {...fieldState}
                    fullWidth
                    error={!!formState.errors.EmailAddress}
                    id="EmailAddress"
                  />
                  {formState.errors.EmailAddress && (
                    <Typography color="error" fontSize="12px" marginTop="4px">
                      {formState.errors.EmailAddress.message}
                    </Typography>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item md={12}>
            <Controller
              name="MobilePhoneNumber"
              control={control}
              render={({ field, fieldState }) => (
                <FormControl fullWidth variant="standard">
                  <StyledInputLabel shrink htmlFor="MobilePhoneNumber">
                    Phone Number
                  </StyledInputLabel>
                  <BootstrapInput
                    {...field}
                    {...fieldState}
                    error={!!formState.errors.MobilePhoneNumber}
                    fullWidth
                    id="MobilePhoneNumber"
                  />
                  {formState.errors.MobilePhoneNumber && (
                    <Typography color="error" fontSize="12px" marginTop="4px">
                      {formState.errors.MobilePhoneNumber.message}
                    </Typography>
                  )}
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
        <Box
          width="100%"
          display="flex"
          justifyContent="space-between"
          marginTop={theme.spacing(3)}
        >
          <Button
            onClick={() => {
              onCloseModal();
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={loading}
            type="submit"
            disabled={!formState.isValid}
            startIcon={<SaveIcon />}
            variant="contained"
          >
            Save
          </LoadingButton>
        </Box>
      </form>
    </Box>
  );
};

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      150,
      150,
      "PNG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "file",
      50,
      50
    );
  });

export default AddUserForm;
