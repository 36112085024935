import { useState, useEffect } from "react";
import {
  CircleTickOutlineMinor,
  CircleDisableMinor,
} from "@shopify/polaris-icons";
import axios from "axios";
import backendUtility from "../../General/backendUtility";

import "./ToggleWidget.css";

export default function ToggleWidget(serverAPI, shop) {
  let [widgetActive, setWidget] = useState(null);
  let [token, setToken] = useState(null);

  useEffect(() => {
    let asyncEffect = async () => {
      let tempToken = await backendUtility.getToken(shop.shop, serverAPI);
      setToken(tempToken);
      axios
        .get(serverAPI + "/app/Widget/enabled?token=" + tempToken)
        .then((resp) => {
          setWidget(resp.data);
        })
        .catch((error) => {
          console.warn(error.response);
        });
    };
    asyncEffect();
  });

  return [
    {
      label: widgetActive ? "Deactivate chat widget" : "Activate chat widget",
      icon: widgetActive ? CircleDisableMinor : CircleTickOutlineMinor,
      onClick: () => {
        if (widgetActive !== null) {
          let toSet = !widgetActive;
          axios
            .post(serverAPI + "/app/Widget/setEnabled", {
              clientToken: token,
              enabled: toSet,
            })
            .then((resp) => {
              if (resp.data === true) setWidget(toSet);
            })
            .catch((error) => {
              console.warn(error.response);
            });
        }
      },
      escapeState: widgetActive,
    },
  ];
}
