import React, { Component } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  //   Input,
  Alert,
  ListGroup,
  ListGroupItem,
  Spinner,
} from "reactstrap";
import cookie from "js-cookie";
import axios from "axios";
import { Redirect, Link } from "react-router-dom";
import "./Signup.css";
import { Fragment } from "react";
import logoBlack from "../../../assets/img/logo_black.png";
import mainLogo from "../../../assets/img/TextChat_LogoFull.svg";
import blueBlob from "../../../assets/img/blue_blob.svg";
import orangeBlob from "../../../assets/img/orange_blob.svg";
import en from "@shopify/polaris/locales/en.json";
import { AppProvider } from "@shopify/polaris";
import ErrorRequest from "../../General/ErrorRequest";
import utility from "../../General/utility";

class Signup extends Component {
  constructor() {
    super();

    this.state = {
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      phoneNumber: "",
      clientName: "",
      websiteUrl: "",
      agreeTerms: false,
      error: 0,
      isComplete: false,
      embedCode: "",
      adminDash: "",
      agentDash: "",
    };
  }

  componentDidMount() {
    //console.log("componentDidMount");
  }

  /*
   * changeHandler event to get target name and value
   *
   * @param {event}
   *
   */
  changeHandler(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  checkboxHandler(e) {
    if (e.target.checked) {
      this.setState({ [e.target.name]: true });
    } else {
      this.setState({ [e.target.name]: false });
    }
  }

  passwordValidator = (password = "") => {
    var passwordvalid =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
    if (password.match(passwordvalid)) return true;
    else return false;
  };

  validateInputs() {
    let { password, confirmPassword } = this.state;

    this.setState({ invalidAction: false, errorText: false });

    // validate email
    utility.verifyInput("email", this.state.email, () => {
      this.setState({
        invalidAction: true,
        errorText: "Email Address is not valid.",
      });
      return false;
    });

    // validate phone
    utility.verifyInput("phone", this.state.phoneNumber, () => {
      this.setState({
        invalidAction: true,
        errorText: "Phone Number is not valid.",
      });
      return false;
    });

    // validate that passwords match and meet complexity requirements
    if (password !== confirmPassword) {
      this.setState({
        invalidAction: true,
        errorText: "Confirm password should match password",
      });
      return false;
    }

    if (!this.passwordValidator(password)) {
      this.setState({
        invalidAction: true,
        errorText: "Password should match the complexity as mentioned below.",
      });
      return false;
    }

    // validate all other fields
    if (
      !this.state.name ||
      !this.state.clientName ||
      !this.state.websiteUrl ||
      !this.state.agreeTerms
    ) {
      this.setState({
        invalidAction: true,
        errorText: "All fields are required.",
      });
      return false;
    }

    return true;
  }

  submitSignup = async () => {
    let isValid = this.validateInputs();
    if (!isValid) {
      return;
    }

    let { name, email, password, phoneNumber, clientName, websiteUrl } =
      this.state;

    axios
      .post(this.props.serverAPI + "/app/signup/submit", {
        name,
        email,
        password,
        phoneNumber,
        clientName,
        websiteUrl,
      })
      .then(async (resp) => {
        this.setState({
          isComplete: true,
          embedCode: resp.data.embedScript,
          adminDash: resp.data.adminDash,
          agentDash: resp.data.agentDash,
        });
      })
      .catch((error) => {
        // error.response.data contains the custom error message
        if (error.response && error.response.data) {
          this.setState({
            invalidAction: true,
            errorText: error.response.data,
          });
        } else {
          this.setState({
            invalidAction: true,
            errorText:
              "We're sorry, there was an error processing your request",
          });
        }
      });
  };

  render() {
    if (this.state.isComplete) {
      return (
        <Fragment>
          <div className="signup-container sign-up-wrapper form-wrapper-2">
            <div className="mx-auto d-block width50">
              {this.state.invalidAction ? (
                <Alert color="danger">{this.state.errorText}</Alert>
              ) : null}
              <div className="sign-up-inner-wrap">
                <img
                  src={mainLogo}
                  loading="lazy"
                  width="203"
                  alt=""
                  className="logo-login"
                ></img>
                <div className="alternate-login-text">
                  Already have an account?{" "}
                  <a href="/Login" className="sign-up-link">
                    Log in
                  </a>
                </div>
                <div className="sign-in-form-block">
                  <Form className="sign-in-form">
                    {/* <FormGroup> */}
                    <Label
                      className="contact-form-field-label text2"
                      for="InputName"
                    >
                      Here is your website code:
                    </Label>
                    <textarea
                      className="contact-form-text-field"
                      value={this.state.embedCode}
                      type="text"
                      name="name"
                      id="InputName"
                      placeholder=""
                      readOnly
                      onChange={(e) => this.changeHandler(e)}
                    ></textarea>
                    <Label
                      className="contact-form-field-label"
                      for="InputEmail"
                    >
                      To add code to your site, please follow the below steps:
                    </Label>
                    <ol className="custom-listing">
                      <li>Copy the above snippet of code</li>
                      <li>Access your website's development page</li>
                      <li>
                        Insert the snippet of code at the bottom of your
                        website's body tag
                      </li>
                    </ol>

                    <Label className="contact-form-field-label">
                      Having issues?
                    </Label>
                    <p className="contact-link">
                      Please contact us{" "}
                      <a
                        href="https://www.textchat.com/contact-new"
                        target="_blank"
                        className="btn-hyperlink"
                      >
                        here
                      </a>{" "}
                      for help.
                    </p>

                    <Label className="contact-form-field-label">
                      Continue To:
                    </Label>
                    <div className="other-links">
                      <p className="admin-div">
                        <a
                          href={this.state.adminDash}
                          className="submit-button"
                          target="_blank"
                        >
                          Admin Dashboard
                        </a>
                      </p>
                      <p>
                        <a
                          href={this.state.agentDash}
                          className="submit-button"
                          target="_blank"
                        >
                          Agent Dashboard
                        </a>
                      </p>
                    </div>
                    {/* </FormGroup> */}
                  </Form>
                  <img
                    src={blueBlob}
                    loading="lazy"
                    alt=""
                    className="form-vector"
                  ></img>
                  <img
                    src={orangeBlob}
                    loading="lazy"
                    alt=""
                    className="form-vector-2"
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <div className="signup-container sign-up-wrapper">
            <div className="mx-auto d-block width50">
              {this.state.invalidAction ? (
                <Alert color="danger">{this.state.errorText}</Alert>
              ) : null}
              <div className="sign-up-inner-wrap">
                <img
                  src={mainLogo}
                  loading="lazy"
                  width="203"
                  alt=""
                  className="logo-login"
                ></img>

                <div className="alternate-login-text">
                  Already have an account?{" "}
                  <a href="/Login" className="sign-up-link">
                    Log in
                  </a>
                </div>
                <div className="sign-in-form-block">
                  <Form className="sign-in-form">
                    {/* <FormGroup> */}
                    <Label
                      className="contact-form-field-label text2"
                      for="InputName"
                    >
                      Name
                    </Label>
                    <input
                      className="contact-form-text-field"
                      value={this.state.name}
                      type="text"
                      name="name"
                      id="InputName"
                      placeholder="Stefan Paul"
                      required
                      onChange={(e) => this.changeHandler(e)}
                    />
                    <Label
                      className="contact-form-field-label"
                      for="InputEmail"
                    >
                      Email Address
                    </Label>
                    <input
                      className="contact-form-text-field"
                      value={this.state.email}
                      type="email"
                      name="email"
                      id="InputEmail"
                      placeholder="franklyfrank@example.com"
                      required
                      onChange={(e) => this.changeHandler(e)}
                    />

                    <Label
                      className="contact-form-field-label"
                      for="InputPassword"
                    >
                      Password
                    </Label>
                    <input
                      className="contact-form-text-field"
                      value={this.state.password}
                      type="password"
                      name="password"
                      id="InputPassword"
                      placeholder="********"
                      required
                      onChange={(e) => this.changeHandler(e)}
                    />
                    <p className="pwd-info">
                      Password should be minimum 8 characters, at least 1
                      uppercase letter, 1 lowercase letter, 1 number, and 1
                      symbol
                    </p>

                    <Label
                      className="contact-form-field-label"
                      for="InputConfirmPassword"
                    >
                      Confirm Password
                    </Label>
                    <input
                      className="contact-form-text-field"
                      value={this.state.confirmPassword}
                      type="password"
                      name="confirmPassword"
                      id="InputConfirmPassword"
                      placeholder="********"
                      required
                      onChange={(e) => this.changeHandler(e)}
                    />
                    <Label
                      className="contact-form-field-label"
                      for="InputPhone"
                    >
                      Phone Number
                    </Label>
                    <input
                      className="contact-form-text-field"
                      value={this.state.phoneNumber}
                      type="tel"
                      name="phoneNumber"
                      id="InputPhone"
                      placeholder="+13920010346"
                      required
                      onChange={(e) => this.changeHandler(e)}
                    />
                    <Label
                      className="contact-form-field-label"
                      for="InputClientName"
                    >
                      Business Name
                    </Label>
                    <input
                      className="contact-form-text-field"
                      value={this.state.clientName}
                      type="text"
                      name="clientName"
                      id="InputClientName"
                      placeholder="My Business"
                      required
                      onChange={(e) => this.changeHandler(e)}
                    />
                    <Label
                      className="contact-form-field-label"
                      for="InputWebsiteUrl"
                    >
                      Business Website URL
                    </Label>
                    <input
                      className="contact-form-text-field"
                      value={this.state.websiteUrl}
                      type="text"
                      name="websiteUrl"
                      id="InputWebsiteUrl"
                      placeholder="www.example.com"
                      required
                      onChange={(e) => this.changeHandler(e)}
                    />
                    <label className="w-checkbox">
                      <input
                        className="checkbox"
                        value={this.state.agreeTerms}
                        type="checkbox"
                        name="agreeTerms"
                        id="InputAgreeTerms"
                        onChange={(e) => this.checkboxHandler(e)}
                      />
                      <span
                        className="checkbox-label"
                        htmlFor="InputAgreeTerms"
                      >
                        Agree with{" "}
                      </span>
                      <a
                        className="text-terms"
                        href="https://www.textchat.com/terms-and-conditions-non-shopify"
                      >
                        Terms and Conditions
                      </a>
                    </label>
                    {/* </FormGroup> */}
                    <div className="d-flex justify-content-center">
                      <input
                        type="button"
                        className="submit-button"
                        onClick={this.submitSignup}
                        value="Submit"
                      ></input>
                    </div>
                  </Form>
                  <img
                    src={blueBlob}
                    loading="lazy"
                    alt=""
                    className="form-vector"
                  ></img>
                  <img
                    src={orangeBlob}
                    loading="lazy"
                    alt=""
                    className="form-vector-2"
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    }
  }
}
export default Signup;
