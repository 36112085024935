import React, { Component } from "react";
import axios from "axios";
import {
  Page,
  Card,
  ResourceList,
  Stack,
  Banner,
  Icon,
  Spinner,
  Toast,
} from "@shopify/polaris";

import {
  CircleTickMajor,
  CircleAlertMajor,
} from "@shopify/polaris-icons";

import moment from "moment";
import utility from "../../General/utility";
import Invoice from "./Invoice/Invoice";

import "./style.css";

class Billing extends Component {
  constructor() {
    super();
    this.state = {
      infoBilling: {},
      disableBtn: false,
      showModalInvoice: false,
      pdfContent: {},
      loadingMail: false,
      sendSuccess: false,
      emailsent: [],
      kbill: null,
      infoGot: false,
    };
    //this.getShopifyCharge = this.getShopifyCharge.bind(this);
    this.toggleActive = this.toggleActive.bind(this);
  }
  componentDidMount() {
    /*Frontend example data test:
        this.setState({infoBilling: {allBills: [
            {
                "shopifyId": 1430225035,
                "shop": "jetsense-dev.myshopify.com",
                "billingPackage": "bronze",
                "confirmationUrl": "https://jetsense-dev.myshopify.com/admin/charges/1430225035/confirm_application_charge?signature=BAh7BzoHaWRsKweLgD9VOhJhdXRvX2FjdGl2YXRlRg%3D%3D--423aaa94db1d67d47dd3143967281a6908954169",
                "overages": 0,
                "isActive": 1,
                "createdAt": "2020-08-07T17:23:59-04:00",
                "totalCharge": "76.00",
                "totalChats": 8,
                "lastDayPeriod": "Mon Sep 07 2020 17:23:58 GMT-0400 (Eastern Daylight Time)",
                "firstDayPeriod": "Fri Aug 07 2020 17:23:58 GMT-0400 (Eastern Daylight Time)",
                "apiClientId": 3414363,
                "status": "pending",
                "updatedAt": "2020-08-07T17:23:59-04:00",
                "prorated": 0
              }
        ]},
        toPay: {
            "shopifyId": 1430225035,
            "shop": "jetsense-dev.myshopify.com",
            "billingPackage": "bronze",
            "confirmationUrl": "https://jetsense-dev.myshopify.com/admin/charges/1430225035/confirm_application_charge?signature=BAh7BzoHaWRsKweLgD9VOhJhdXRvX2FjdGl2YXRlRg%3D%3D--423aaa94db1d67d47dd3143967281a6908954169",
            "overages": 0,
            "isActive": 1,
            "createdAt": "2020-08-07T17:23:59-04:00",
            "totalCharge": "76.00",
            "totalChats": 8,
            "lastDayPeriod": "Mon Sep 07 2020 17:23:58 GMT-0400 (Eastern Daylight Time)",
            "firstDayPeriod": "Fri Aug 07 2020 17:23:58 GMT-0400 (Eastern Daylight Time)",
            "apiClientId": 3414363,
            "status": "pending",
            "updatedAt": "2020-08-07T17:23:59-04:00",
            "prorated": 0
          }})*/

    const url_string = window.location.href;
    const url = new URL(url_string);
    const charge_id = url.searchParams.get("charge_id");
    if (charge_id) {
      axios
        .post(
          this.props.serverAPI + "/app/billing/callback?charge_id=" + charge_id
        )
        .then((resp) => console.log("callback: " + JSON.stringify(resp)));
    }

    this.getInfoActivation();
  }
  getInfoActivation = async () => {
    axios
      .post(this.props.serverAPI + "/app/bills?shopUrl=" + this.props.shop.shop)
      .then((resp) => {
        console.log(JSON.stringify(resp));
        if (typeof resp.data !== "object" || resp.data.length === undefined)
          //Add something for no bills
          return;
        let toPay = {};
        const pendingBills = resp.data.filter((b) => b.status === "pending");
        //Note: assumes bills come back in order of most recent first
        if (pendingBills.length > 0) toPay["toPay"] = pendingBills[0];
        this.setState({
          infoBilling: { allBills: resp.data },
          infoGot: true,
          ...toPay,
        });
      })
      .catch((error) => {
        this.props.setError(error.response);
      });
  };

  toggleActive = async (item) => {
    this.setState({
      showModalInvoice: !this.state.showModalInvoice,
      pdfContent: item,
      id: item.shopifyId,
    });
  };

  toggleSuccessMail = () => {
    this.setState({
      sendSuccess: false,
    });
  };

  sendMailPDF = async (billingInfo, index) => {
    const info = this.props.getInfoBusiness;
    let allAppFields = {};
    for (var i = 0; i < 5; i++) {
      let stepInfo = info["step" + String(i)];
      if (stepInfo) allAppFields = { ...allAppFields, ...stepInfo };
    }

    //Grab name and email
    let Name = allAppFields["contactName"],
      Email = allAppFields["personalEmail"];
    this.setState({
      loadingMail: true,
    });
    let newBilling = billingInfo;
    newBilling["id"] = newBilling["shopifyId"];

    await axios
      .post(this.props.serverAPI + "/app/mailing/invoice", {
        shop: this.props.shop.shop,
        billing: newBilling,
        name: Name,
        email: Email,
      })
      .then((resp) => {
        const emailsent = this.state.emailsent;
        emailsent[index] = resp.data.success;
        this.setState({
          sendSuccess: resp.data.success,
          emailsent: emailsent,
        });
        setTimeout(() => {
          this.setState({
            loadingMail: false,
          });
        }, 1000);
      })
      .catch((error) => {
        this.setState({
          sendSuccess: false,
        });
        setTimeout(() => {
          this.setState({
            loadingMail: false,
          });
        }, 1000);
      });
  };
  nextPayment() {
    if (!this.state.toPay) {
      return null;
    }
    const dateCharge = moment(this.state.toPay.lastDayPeriod).format(
      "MMM Do YYYY"
    );
    const dateChargeMStart = moment(this.state.toPay.firstDayPeriod).format(
      "MMM"
    );
    const dateChargeMEnd = moment(this.state.toPay.lastDayPeriod).format("MMM");

    const firstDay = moment(this.state.toPay.firstDayPeriod).format("DD");

    const lastDay = moment(this.state.toPay.lastDayPeriod).format("DD");

    const total = this.state.toPay.totalCharge;
    const overages = this.state.toPay.overages;
    const prorated = this.state.toPay.prorated;

    return (
      <Card
        title={
          dateChargeMStart === dateChargeMEnd
            ? "Amount Due " +
              dateChargeMStart +
              " " +
              firstDay +
              " - " +
              lastDay
            : "Amount Due " +
              dateChargeMStart +
              " " +
              firstDay +
              " - " +
              dateChargeMEnd +
              " " +
              lastDay
        }
        secondaryFooterActions={[
          {
            content: "Download Invoice",
            onAction: (e) => this.toggleActive(this.state.toPay),
          },
        ]}
        primaryFooterAction={{
          content: "Pay Now",
          url: this.state.toPay.confirmationUrl,
          disabled: this.state.disableBtn,
        }}
      >
        <Card.Section title={"Invoice date: " + dateCharge}>
          <ResourceList
            resourceName={{ singular: "price", plural: "prices" }}
            items={[
              {
                price: "Flat rate per 30 days",
                amount:
                  "USD$" + utility.formatMoney(total - overages - prorated),
              },
              {
                price: "Prorated: Days using this app after your trial expired",
                amount: "USD$" + utility.formatMoney(prorated),
              },
              {
                price:
                  "Overages: Charges for exceeding your plan's available chat sessions",
                amount: "USD$" + utility.formatMoney(overages),
              },
              {
                price: <b>Total</b>,
                amount: <b>{"USD$" + utility.formatMoney(total)}</b>,
              },
            ]}
            renderItem={(item) => {
              const { price, amount } = item;
              return (
                <ResourceList.Item
                  accessibilityLabel={`View Sales for ${price}`}
                >
                  <Stack>
                    <Stack.Item fill>{price}</Stack.Item>
                    <Stack.Item>{amount}</Stack.Item>
                  </Stack>
                </ResourceList.Item>
              );
            }}
          />
        </Card.Section>
      </Card>
    );
  }
  toastMailing = () => {
    if (!this.state.loadingMail) return null;
    return (
      <Toast
        content={
          this.state.sendSuccess
            ? "Mail Sent"
            : "Error sending mail, please try later"
        }
        onDismiss={(e) => this.toggleSuccessMail()}
        duration={1000}
        error={!this.state.sendSuccess}
      />
    );
  };
  allBills() {
    const { allBills } = this.state.infoBilling;
    if (!allBills || allBills.length === 0) return null;
    if (this.state.loadingMail)
      return (
        <div style={{ textAlign: "center" }}>
          <Spinner
            accessibilityLabel="Spinner mailing"
            size="large"
            color="teal"
          />
        </div>
      );
    const allBillsCpy = allBills.map((item, index) => {
      const dateChargeM = moment(item.firstDayPeriod).format("MMM");
      const dateChargeMTwo = moment(item.lastDayPeriod).format("MMM");
      const firstDay = moment(item.lastDayPeriod).format("Do");
      const lastDay = moment(item.firstDayPeriod).format("Do");
      const billPaid = item.status !== "pending";
      return (
        <Card.Section
          key={index}
          title={
            dateChargeM === dateChargeMTwo
              ? dateChargeM + " " + firstDay + " - " + lastDay
              : dateChargeM +
                " " +
                firstDay +
                " - " +
                dateChargeMTwo +
                " " +
                lastDay
          }
          actions={[
            {
              content: "Send Mail",
              onAction: (e) => this.sendMailPDF(item, index),
              disabled: this.state.emailsent[index],
            },
            {
              content: "Download Invoice",
              onAction: (e) => this.toggleActive(item),
            },
          ]}
        >
          <ul className="list-check-bill">
            <li>
              {billPaid ? (
                <Icon source={CircleTickMajor} color="green" />
              ) : (
                <Icon source={CircleAlertMajor} color="yellow" />
              )}
            </li>
            <li>${item.totalCharge}</li>
          </ul>
        </Card.Section>
      );
    });

    return <Card title="Payments">{allBillsCpy}</Card>;
  }

  currentPayment() {
    //CB how to get this information
    //const { nextBilling } = this.state.infoBilling;
    //<p>{"Your next payment will be before " + nextCharge}</p>
    const nextBilling =
      "Mon Sep 07 2020 17:23:58 GMT-0400 (Eastern Daylight Time)";
    const nextCharge = moment(nextBilling).format("MMM Do YYYY");
    return (
      <Banner
        title="You're up to date with your payments!"
        status="success"
      ></Banner>
    );
  }

  render() {
    let needToPay = undefined;
    if (this.state.infoGot) needToPay = !!this.state.toPay;

    const title = "Invoices";
    return (
      <Page title={title}>
        {needToPay === undefined
          ? null
          : needToPay
          ? this.nextPayment()
          : this.currentPayment()}

        {this.allBills()}
        <Invoice
          toggleActive={this.toggleActive}
          showModalInvoice={this.state.showModalInvoice}
          pdfContent={this.state.pdfContent}
          shop={this.props.shop.shop}
          id={this.state.id}
          serverAPI={this.props.serverAPI}
        />
      </Page>
    );
  }
}

export default Billing;
