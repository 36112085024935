import React, { Component } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./style.scss";

const localizer = momentLocalizer(moment);

function Event(event, agents) {
  if (Array.isArray(event.title)) {
    return event.title.map((name, index) => {
      const targetAgent = agents.find((agent) => agent.name === name);
      const bgColor = targetAgent ? targetAgent.color : "none";
      return (
        <p key={index} style={{ background: bgColor }}>
          {name}
        </p>
      );
    });
  }

  const targetAgent = agents.find((agent) => agent.name === event.title);
  const bgColor = targetAgent ? targetAgent.color : "none";
  return <p style={{ background: bgColor }}>{event.title}</p>;
}

class Scheduler extends Component {
  eventPropGetter = (event, start, end, isSelected, isAgent) => {
    let newStyle = {
      backgroundColor: "lightgrey",
      color: "black",
      borderRadius: "0px",
      border: "none",
    };
    const startDate = moment(start);
    const endDate = moment(end);
    const diff = endDate.diff(startDate, "minutes");
    let className = "";

    if (event.isAgent) {
      if (diff <= 45) {
        className = "hide-text ";
      } else if (diff > 45 && diff <= 60) {
        className = "small-text ";
      }
      let length = event.agentTokens ? event.agentTokens.size : 1;
      className += `${length > 1 ? "hide-text " : ""}agent agent-` + length;
      return {
        className,
        style: newStyle,
      };
    }
    if (isAgent) {
      className += "hide-text on-agent ";
    } else {
      if (diff <= 45) {
        className = "hide-text ";
      } else if (diff > 45 && diff <= 60 && !isAgent) {
        className = "small-text ";
      }
    }

    if (event.afterHours) {
      newStyle.backgroundColor = "#F2F2F2";
      className += "company after-hours";
      return {
        className,
        style: newStyle,
      };
    } else if (event.available) {
      newStyle.backgroundColor = "#DBE8F0";
      className += "company available";
      return {
        className,
        style: newStyle,
      };
    } else if (event.busy) {
      newStyle.backgroundColor = "#7EA1BF";
      className += "company busy";
      return {
        className,
        style: newStyle,
      };
    }

    return {
      className: "",
      style: newStyle,
    };
  };

  render() {
    let {
      onSelectEvent,
      onSelectSlot,
      events = [],
      agents,
      isAgent,
    } = this.props;

    return (
      <div className="Scheduler">
        <Calendar
          localizer={localizer}
          defaultDate={new moment("01-01-2021", "DD-MM-YYYY").toDate()}
          defaultView="week"
          events={events}
          style={{ height: "600px" }}
          scrollToTime={new Date(1970, 1, 1, 8)}
          showMultiDayTimes={false}
          onSelectEvent={onSelectEvent}
          selectable={true}
          onSelectSlot={onSelectSlot}
          step={30}
          showAllEvents={true}
          views={{
            week: true,
          }}
          toolbar={false}
          drilldownView={null}
          components={{
            week: {
              header: ({ date, localizer }) => localizer.format(date, "ddd"),
            },
            event: (e) => Event(e, agents),
          }}
          eventPropGetter={(event, start, end, isSelected) =>
            this.eventPropGetter(event, start, end, isSelected, isAgent)
          }
        />
      </div>
    );
  }
}

export default Scheduler;
