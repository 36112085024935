import React, { useCallback, useMemo, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  FormControl,
  Grid,
  Typography,
  useTheme,
  styled,
  InputLabel,
  Dialog,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { BootstrapInput } from "../../../components/Input/BootstrapInput";
import { useForm, Controller } from "react-hook-form";
import resizeFile from "../../../utils/resizeFile";
import { useDropzone } from "react-dropzone";
import CustomImageCrop from "../../Crop";
import getBase64Image from "../../../utils/base64Image";
import utility from "../../General/utility";

const StyledInputLabel = styled(InputLabel)({
  fontSize: "18px",
  fontWeight: 500,
  color: "black",
});

const ExpandedUserProfile = ({ row, onSaveAgent, loading }) => {
  const [isCropDialogShown, setIsCropDialogShown] = useState(false);
  const [fileBase64, setFileBase64] = useState();
  const [fileImage, setFileImage] = useState(null);
  const userData = row?.original;

  const defaultValues = useMemo(
    () => ({
      fullName: userData?.fullName,
      emailAddress: userData?.emailAddress,
      mobilePhoneNumber: userData?.mobilePhoneNumber,
    }),
    [userData]
  );

  const { control, handleSubmit } = useForm({ defaultValues });

  const onDrop = useCallback(async (acceptedFiles) => {
    const resizedFile = await resizeFile(acceptedFiles[0]);
    setFileImage(
      Object.assign(resizedFile, {
        preview: URL.createObjectURL(acceptedFiles[0]),
      })
    );
    setIsCropDialogShown(true);
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const onSubmitForm = (data) => {
    let agent = data;
    if (fileBase64) {
      const random = utility.string("a", 10);
      agent["fileMetaData"] = {
        fileName: random + ".png",
        recordType: "AGENT_IMAGE",
        s3FileName: "",
        base64Data: fileBase64,
        tempUrl: URL.createObjectURL(fileImage),
      };
    }
    onSaveAgent(
      {
        ...row.original,
        ...data,
      },
      row.original.token
    );
  };

  const theme = useTheme();
  return (
    <Box width="100%" paddingY={theme.spacing(1)}>
      <Box display="flex" flexDirection="column">
        <Typography
          marginBottom={theme.spacing(1)}
          fontWeight="medium"
          fontSize="14px"
        >
          Edit User Data
        </Typography>
        <Box marginBottom={theme.spacing(1.5)} display="flex">
          <Avatar
            src={
              fileImage?.preview
                ? fileImage?.preview
                : row?.original?.fileMetaData?.url
            }
          />
          <Box
            display="flex"
            flexDirection="column"
            marginLeft={theme.spacing(2)}
          >
            <Typography variant="h6" fontSize="13px" fontWeight="400">
              Change Picture
            </Typography>
            <Box {...getRootProps()}>
              <input {...getInputProps()} />
              <Button
                sx={{
                  marginTop: theme.spacing(1),
                  borderRadius: theme.spacing(1.4),
                  backgroundColor: theme.palette.action.hover,
                }}
              >
                Upload
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Grid container spacing={3}>
          <Grid item md={4}>
            <Controller
              name="fullName"
              control={control}
              render={({ field, fieldState }) => (
                <FormControl fullWidth variant="standard">
                  <StyledInputLabel shrink htmlFor="fullName">
                    Name
                  </StyledInputLabel>
                  <BootstrapInput
                    {...field}
                    {...fieldState}
                    fullWidth
                    id="fullName"
                  />
                </FormControl>
              )}
            />
          </Grid>
          <Grid item md={4}>
            <Controller
              name="mobilePhoneNumber"
              control={control}
              render={({ field, fieldState }) => (
                <FormControl fullWidth variant="standard">
                  <StyledInputLabel shrink htmlFor="mobilePhoneNumber">
                    Phone Number
                  </StyledInputLabel>
                  <BootstrapInput
                    {...field}
                    {...fieldState}
                    fullWidth
                    id="mobilePhoneNumber"
                  />
                </FormControl>
              )}
            />
          </Grid>
          <Grid item md={4}>
            <Controller
              name="emailAddress"
              control={control}
              render={({ field, fieldState }) => (
                <FormControl fullWidth variant="standard">
                  <StyledInputLabel shrink htmlFor="emailAddress">
                    Email
                  </StyledInputLabel>
                  <BootstrapInput
                    {...field}
                    {...fieldState}
                    fullWidth
                    id="emailAddress"
                  />
                </FormControl>
              )}
            />
          </Grid>
        </Grid>

        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          marginTop={theme.spacing(2)}
        >
          <Button onClick={() => row.toggleRowExpanded()} variant="outlined">
            Cancel
          </Button>
          <LoadingButton loading={loading} type="submit" variant="contained">
            Save
          </LoadingButton>
        </Box>
      </form>
      <Dialog fullWidth open={isCropDialogShown}>
        <CustomImageCrop
          src={fileImage?.preview}
          onCropComplete={async (image) => {
            const bs64 = await getBase64Image(image);
            setFileBase64(bs64);
            setIsCropDialogShown(false);
          }}
          onCropAborted={() => {
            setFileImage(null);
            setIsCropDialogShown(false);
          }}
        />
      </Dialog>
    </Box>
  );
};

export default ExpandedUserProfile;
