export const step1 = [
    {
        title: 'Business Name',
        //description: 'We will use your business name in our reports and messaging applications.',
        labelForm: 'Enter Business Name',
        name: 'businessName',
        mandatory: true
    },
    {
        title: "Administrator Name",
        //description: "We will need your contact information in order to set up some of these features.",
        labelForm: "Enter Administrator Name",
        name: "contactName",
        mandatory: true,
    },
    {
        title: "Phone Number",
        //description: "Please enter the administrator phone number",
        labelForm: "(000) 000 - 0000",
        name: "mobileNumbers",
        multiline: true,
        mandatory: true,
        type: "phone"
    },
    {
        title: "Email Address",
        //description: "We will need your Email in order to set up some of these features.",
        labelForm: "Enter Administrator Email Address",
        name: "personalEmail",
        mandatory: true,
        type: "email",
    }
];
export const step1Description = [
    {
        text: "Please add the account administrator information here. This is the person we will reach out to with important updates and changes.",
        bold: true
    }
];

export const EULA = {
        text: 'By checking "I agree", I provide my signature expressly consenting to receive automated text messages from TextChat.com or its subsidiaries, affiliates, or agents at the number I provided to answer live chats from my website or receive other information about the use of TextChat.com. I understand that message and data rates may apply, and I am not required to enter into this agreement as a condition of purchasing services. I understand that I can revoke this consent at any time.'
};

export const step1video = {
    url: "https://www.w3schools.com/html/mov_bbb.mp4",
    poster: null
}