import React, { useEffect, useState } from 'react';
import { CalloutCard, Page } from '@shopify/polaris';
import clock from './../../../assets/img/clock_minor.svg';
import axios from "axios";
import utility from "../../General/utility";
import backendUtility from "../../General/backendUtility";
import cookie from "js-cookie";

const WaitingActivation = (props) => {
  const [completeCalled, setCompleteCalled] = useState(false);

  useEffect(() => {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const chargeId = url.searchParams.get("charge_id");
    if (!completeCalled) {
      setCompleteCalled(true);
      checkCharge(chargeId, () => completeInfo(props), props);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Page>
      <CalloutCard
        title="Your information is being reviewed"
        illustration={clock}
        primaryAction={{
          content: "Please stand by",
        }}
      >
        <p>Please stand by on this screen while we create your account.</p>
        <p>
          If you are waiting or haven't accepted a billing plan, please contact
          our support team at support@textchat.com.
        </p>
      </CalloutCard>
    </Page>
  );
};

async function checkCharge(chargeId, onsuccessful, props) {
  const chargeInfo = await axios
    .post(props.serverAPI + "/app/billing/recurringActivate", {
      shop: props.shop.shop,
      chargeId: chargeId,
    })
    .then((resp) => {
      if (resp.data) return resp.data;
    })
    .catch((error) => {
      props.setError(error.response);
    });
  if (!chargeInfo) {
    console.log("No charge found");
  } else {
    onsuccessful();
  }
}

async function completeInfo(props) {
  console.log("calling complete info");
  let appUserInfo;
  const infoBusiness = await axios
    .get(props.serverAPI + "/app/get/completed/info", {
      params: {
        shop: props.shop.shop,
      },
    })
    .then((resp) => {
      if (resp.data) return resp.data;
    })
    .catch((error) => {
      props.setError(error.response);
    });
  if (!props.getInfoBusiness) {
    appUserInfo = await axios
      .get(`${props.serverAPI}/app/get/info`, {
        params: {
          shop: props.shop.shop,
        },
      })
      .then((resp) => {
        return resp.data[0] || {};
      })
      .catch((error) => {
        props.setError(error.response);
      });
  } else {
    appUserInfo = props.getInfoBusiness;
  }

  let allAppFields = {};
  let steps = Object.keys(appUserInfo).filter(
    (s) => typeof appUserInfo[s] === "object" && s.substring(0, 4) === "step"
  );
  steps.forEach((stepName) => {
    let step = appUserInfo[stepName];
    let stepFields = Object.keys(step);
    stepFields.forEach((stepField) => {
      allAppFields[stepField] = step[stepField];
    });
  });

  if (!infoBusiness.token && Object.keys(appUserInfo).length) {
    if (!infoBusiness.infoBusiness)
      infoBusiness.infoBusiness = {
        businessName: allAppFields.businessName,
        websiteAddres: props.shop.shop,
      };

    if (
      !infoBusiness.infoBusiness.businessName ||
      !allAppFields.contactName ||
      !allAppFields.personalEmail ||
      !allAppFields.mobileNumbers ||
      !allAppFields.mobileNumbers ||
      !allAppFields.Agents ||
      !allAppFields.salesEmail ||
      !allAppFields.supportEmail ||
      !infoBusiness.infoBusiness.websiteAddres
    ) {
      return false;
    }

    const numAgents = allAppFields.Agents.length;
    for (let index = 0; index < numAgents; index++) {
      allAppFields.Agents[index] = {
        FullName: allAppFields.Agents[index].FullName,
        EmailAddress: allAppFields.Agents[index].EmailAddress,
        MobilePhoneNumber: allAppFields.Agents[index].MobilePhoneNumber,
      };
    }
    /*Flattens email arrays for API consumption*/
    if (
      allAppFields.supportEmail[0] &&
      typeof allAppFields.supportEmail[0] !== "string"
    ) {
      allAppFields["supportEmail"] = allAppFields.supportEmail.map(
        (e) => e.EmailAddress
      );
    }
    if (
      allAppFields.salesEmail[0] &&
      typeof allAppFields.salesEmail[0] !== "string"
    ) {
      allAppFields["salesEmail"] = allAppFields.salesEmail.map(
        (e) => e.EmailAddress
      );
    }
    const createAcount = {
      Client: {
        ClientName: appUserInfo.company,
      },
      Agents: allAppFields.Agents,
      Campaign: {
        CampaignName: `${appUserInfo.company} Campaign`,
        IsLive: true,
      },
      ChatbotDetails: {
        WidgetPlacement: "bottom-right",
        WidgetStyles: ":root { --widget-background-color: #0B24FB; }",
        ChatbotStyles: "",
        Button: null,
        ChatbotIcon: null,
        HeaderLogo: null,
        PromptMessage: allAppFields.popupMessage,
        SupportEmails:
          typeof allAppFields.supportEmail === "string"
            ? allAppFields.supportEmail
            : allAppFields.supportEmail.reduce(utility.reduceStringArr),
        SalesEmails:
          typeof allAppFields.salesEmail === "string"
            ? allAppFields.salesEmail
            : allAppFields.salesEmail.reduce(utility.reduceStringArr),
        IsProactivePopup: true,
        ProactiveDelay: 2,
        ProactiveSecondDelay: 30,
        displayName:
          allAppFields.greetingHeader.trim() !== ""
            ? allAppFields.greetingHeader
            : undefined,
        chatbotHeaderSlogan:
          allAppFields.greetingSlogan.trim() !== ""
            ? allAppFields.greetingSlogan
            : undefined,
      },
      Script: {
        ScriptString: JSON.stringify([
          {
            id: 1,
            msg: allAppFields.greetingMessage,
            btns: [
              {
                id: 10001,
                msg: "Sales",
                cssClass: "btn1",
                saveAsKey: "General.Sales",
              },
              {
                id: 10002,
                msg: "Support",
                cssClass: "btn2",
                saveAsKey: "General.Support",
              },
            ],
          },
          {
            id: 35,
            parentId: 10002,
            msg: "I'll do my best to help. Please describe your issue:",
            input: {
              id: 350001,
              placeholder: "Enter text",
              cssClass: "capture-input",
              saveAsKey: "Customer.Issue",
              inputType: "text",
            },
          },
          {
            id: 36,
            parentId: 350001,
            msg: "Thanks! to resolve your issue as quickly as possible, I'm going to need some contact information.",
          },
          {
            id: 37,
            msg: "First, please provide your name:",
            parentId: 36,
            input: {
              id: 370001,
              placeholder: "Enter Name",
              cssClass: "capture-input",
              icon: "fa-id-card-o",
              validation: "name",
              saveAsKey: "Customer.Name",
            },
          },
          {
            id: 38,
            parentId: 370001,
            msg: "Next, please enter your email address.",
            input: {
              id: 380001,
              placeholder: "Enter Email Address",
              cssClass: "capture-input",
              validation: "email",
              saveAsKey: "Customer.EmailAddress",
            },
          },
          {
            id: 39,
            parentId: 380001,
            msg: "Last thing: Please enter your phone number.",
            input: {
              id: 390001,
              placeholder: "Enter Phone Number",
              cssClass: "capture-input phone-number",
              validation: "phone",
              saveAsKey: "Customer.PhoneNumber",
              inputType: "tel",
              masking: "(###) ###-####",
            },
          },
          {
            id: 40,
            parentIds: [390001],
            msg: "Thanks! This information is being submitted to our support team for review. Someone will contact you with a solution as soon as possible. Have a great day!",
            action: "End",
          },
          {
            id: 3,
            parentId: {
              condition: "@Chatbot.HasActiveAgents==true",
              id: 10001,
            },
            msg: "I am going to transfer you to a team member. This should take 30 seconds or less— thanks for your patience!",
            cssClass: "btnLetsChat",
            action: "TRANSFER",
          },
          {
            id: 100,
            parentId: {
              condition: "@Chatbot.HasActiveAgents==false",
              id: 10001,
            },
            msg: "I'm sorry, no team members are available to chat.",
            cssClass: "agentTimedOut",
          },
          {
            id: 100100,
            parentId: {
              condition: "@Chatbot.TransferNotComplete==true",
              id: 3,
            },
            msg: "I'm sorry, no team members are available to chat.",
            cssClass: "agentTimedOut",
          },
          {
            id: 100001,
            parentIds: [100, 100100],
            msg: "But I can take down your contact information, and someone will be in touch with you as soon as possible.",
          },
          {
            id: 100002,
            parentId: 100001,
            msg: "First, please provide your name.",
            input: {
              id: 100002001,
              placeholder: "Enter name",
              cssClass: "capture-input",
              inputType: "text",
              saveAsKey: "Customer.Name",
            },
          },
          {
            id: 100003,
            parentId: 100002001,
            msg: "Next, please enter your email address.",
            input: {
              id: 100003001,
              placeholder: "Enter Email Address",
              cssClass: "capture-input",
              validation: "email",
              saveAsKey: "Customer.EmailAddress",
            },
          },
          {
            id: 100004,
            parentId: 100003001,
            msg: "Last thing: please enter your phone number.",
            input: {
              id: 100004001,
              placeholder: "Enter Phone Number",
              cssClass: "capture-input",
              inputType: "tel",
              validation: "phone",
              saveAsKey: "Customer.PhoneNumber",
              masking: "(###) ###-####",
            },
          },
          {
            id: 103,
            parentIds: [100004001],
            msg: "Thanks! This information is being sent to our sales team for review. Someone will contact you as soon as possible. Have a great day!",
            action: "End",
          },
        ]),
      },
      EmbedInfo: {
        BasePath: "https://chatbot.textchat.ai",
        IFrameURL: infoBusiness.infoBusiness.websiteAddres,
        DemoURL: "https://chatbot.textchat.ai/widget-demo",
      },
      AccountHolder: {
        FullName: allAppFields.contactName,
        EmailAddress: allAppFields.personalEmail,
        PhoneNumber: allAppFields.mobileNumbers,
      },
    };
    const token = await axios
      .post(props.serverAPI + "/app/account/create", {
        account: createAcount,
        shop: props.shop.shop,
      })
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        props.setError(error.response);
        return false;
      });
    if (token && token.clientToken) {
      cookie.set("textchatapi", token.clientToken);
      activateTrial(props, token.clientToken, allAppFields);
    }
  } else if (infoBusiness && infoBusiness.token) {
    let token = await backendUtility.getToken(props.shop.shop, props.serverAPI);
    activateTrial(props, token, allAppFields);
  }
  return true;
}
async function activateTrial(props, token, appFields) {
  await axios
    .put(props.serverAPI + "/app/FunFacts/Upsert", {
      clientToken: token,
      funFactOne: appFields["funFactOne"],
      funFactTwo: appFields["funFactTwo"],
    })
    .then((resp) => {
      console.log("fun fact upsert response: " + JSON.stringify(resp.data));
      return resp.data;
    })
    .then((error) => {
      console.log(JSON.stringify(error));
      props.setError(error.response);
    });
  await axios
    .post(props.serverAPI + "/appUser/tag", {
      shopUrl: props.shop.shop,
    })
    .then((resp) => {
      return resp.data;
    })
    .then((error) => {
      props.setError(error.response);
    });
  await axios
    .post(props.serverAPI + "/app/activate/trial", {
      shop: props.shop.shop,
    })
    .then((resp) => {
      if (resp.data.active) {
        window.location.reload();
      }
    })
    .catch((error) => {
      props.setError(error.response);
    });
}
export default WaitingActivation;
