import { Card } from "@shopify/polaris";
import React from "react";
import "./EffortlessPopup1.scss";

import CloseIcon from "../../../../assets/img/popup-close-icon.svg";
import AgentOliver from "../../../../assets/img/Chatbot-agent-oliver.jpg";
import AgentIan from "../../../../assets/img/Chatbot-agent-Ian1.jpg";

function EffortlessPopup(props) {
  return (
    <Card>
      <Card.Section>
        <div className="effortlessPopup">
          <div className="classic-main-container">
            <div className="chat-popup-text-container">
              <div className="classic-chatbot-bubble">
                <p>{"Ask us anything, we're online now!"}</p>
              </div>

              <div className="btn-container">
                <div className="tc-agent-container ">
                  <div className="tc-agent">
                    <div className="tc-agent-img-container">
                      <img className="tc-agent-img" src={AgentOliver} alt="" />
                      <span className="badge-online">
                        <span></span>
                      </span>
                    </div>
                    <div className="tc-agent-name">Oliver</div>
                  </div>
                  <div className="tc-agent">
                    <div className="tc-agent-img-container">
                      <img
                        className="tc-agent-img"
                        src="https://d2fzjtk430nuyq.cloudfront.net/f82d51e5-abe0-4cd0-a015-5362b40ee74a/5000cb05-cf57-4405-b8b3-56e40e583248.jpeg"
                        alt=""
                      />
                      <span className="badge-online">
                        <span></span>
                      </span>
                    </div>
                    <div className="tc-agent">
                      <div className="tc-agent-img-container">
                        <img className="tc-agent-img" src={AgentIan} alt="" />
                        <span className="badge-online">
                          <span></span>
                        </span>
                      </div>
                      <div className="tc-agent-name">Ian</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="message-cross-container">
              <a href={null}>
                {/* <i
                  className="textchat-fa textchat-fa-times"
                  aria-hidden="true"
                /> */}
                <img src={CloseIcon} alt="close" />
              </a>
            </div>
          </div>
        </div>
      </Card.Section>
      {/* <Card.Section>
      <h2 className="popupWindowHeading">Effortless Popup</h2>
        <p style={{ textAlign: "center" }}>
          This recomended popup is eye catching and ready for your website
          as-is! Trendy glassmorphism and your agents' face make this popup
          apporachable ans stylish.
        </p>
      </Card.Section> */}
    </Card>
  );
}

export default EffortlessPopup;
