import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Input,
} from "reactstrap";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { Icon } from "@shopify/polaris";
import moment from "moment";
import "rc-time-picker/assets/index.css";
import { DeleteMajor } from "@shopify/polaris-icons";
import { Alert } from "reactstrap";

import InfoIcon from "../../assets/img/info.svg";
import { ArrowDropDown } from "@mui/icons-material";
import { Box } from "@mui/material";

function capitalizeFirstLetter(string) {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
}

const SchedulerModal = (props) => {
  const { className, toggleModal, formData, title, isOpen, error, info } =
    props;
  let { busy, timings } = formData;
  if (!Array.isArray(timings)) timings = [{}]; //set empty timings
  const format = "h:mm A";
  let days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Weekdays",
    "Weekends",
  ];
  let selectedDay = days[formData.selectedDay];
  let selectedDayActual = days[formData.selectedDayActual];

  let dropDownMenu =
    [0, 6].indexOf(formData.selectedDayActual) == -1
      ? [selectedDayActual, "Weekdays"]
      : [selectedDayActual, "Weekends"];
  function dayChangeHandler(event) {
    let newValue = days.indexOf(event.target.value);
    props.onValueChange(null, "selectedDay", newValue);
  }

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Modal
          isOpen={isOpen}
          toggle={toggleModal}
          fade={false}
          backdrop={false}
          className={className}
        >
          <ModalHeader toggle={toggleModal} className={busy && "busy"}>
            {title}
          </ModalHeader>
          <ModalBody>
            {error && (
              <Alert color="danger" className="alert-error">
                {error}
              </Alert>
            )}
            {info && (
              <Alert color="warning" className="alert-error">
                {info}
              </Alert>
            )}
            {formData.agentLogoUrl ? (
              <div className="agent-info">
                <div className="agent-info-image">
                  <img src={formData.agentLogoUrl} alt="" />
                </div>
                <div className="agent-info-name">{formData.agentNames}</div>
              </div>
            ) : null}
            <Box width="100%">
              <div className="item-label">
                <div className="item-label-text">Day</div>
              </div>

              {/* <Input
                    type="select"
                    name="select"
                    id="exampleSelect"
                    value={selectedDay}
                    onChange={dayChangeHandler}
                  >
                    {dropDownMenu.map((i, index) => (
                      <option key={`key-${index}`}>
                        {capitalizeFirstLetter(i)}
                      </option>
                    ))}
                  </Input> */}
              <FormControl sx={{ marginTop: "5px" }} fullWidth>
                <InputLabel
                  id="select-day-schedule-label"
                  sx={{
                    fontSize: "16px",
                  }}
                >
                  Select Day
                </InputLabel>
                <Select
                  labelId="select-day-schedule-label"
                  id="select-day-schedule"
                  sx={{
                    label: {
                      fontSize: "14px",
                    },
                    "#select-day-schedule": {
                      fontSize: "16px",
                      marginTop: "8px",
                    },
                    ".MuiInputLabel-root": {
                      fontSize: "16px",
                    },
                  }}
                  MenuProps={{
                    sx: {
                      ".MuiMenuItem-root": {
                        fontSize: "16px",
                      },
                    },
                  }}
                  IconComponent={() => <ArrowDropDown />}
                  value={selectedDay}
                  label="Select Day"
                  onChange={dayChangeHandler}
                >
                  {dropDownMenu.map((i, index) => (
                    <MenuItem value={i}>{capitalizeFirstLetter(i)}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Box marginY={"16px"}>
                <div className="item-label-text">Time</div>
              </Box>

              <Box
                justifyContent="space-between"
                alignItems="center"
                display="flex"
              >
                <Box marginRight="8px">
                  <TimePicker
                    label="Start Time"
                    value={formData.start ? moment(formData.start) : undefined}
                    onChange={(value) => {
                      props.onValueChange(null, "start", moment(value));
                    }}
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          label: {
                            fontSize: "16px",
                          },
                          ".MuiOutlinedInput-input": {
                            fontSize: "16px",
                          },
                        }}
                        {...params}
                      />
                    )}
                  />
                </Box>
                <div className="time-picker-between">-</div>
                <Box marginX="8px">
                  <TimePicker
                    label="End Time"
                    value={formData.end ? moment(formData.end) : undefined}
                    onChange={(value) => {
                      props.onValueChange(null, "end", moment(value));
                    }}
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          label: {
                            fontSize: "16px",
                          },
                          ".MuiOutlinedInput-input": {
                            fontSize: "16px",
                          },
                        }}
                        {...params}
                      />
                    )}
                  />
                </Box>
                <span
                  style={{
                    marginLeft: "20px",
                    display: "inline-flex",
                    verticalAlign: "middle",
                  }}
                  onClick={(e) => {
                    props.onDeleteItem(e);
                  }}
                >
                  <Icon source={DeleteMajor} color={"redDark"} />
                </span>
              </Box>

              {/* <FormGroup>
              <button
                className={`btn-scheduler`}
                onClick={(e) => {
                  props.onValueChange(e, "addTime", true);
                }}
              >
                Add
              </button>
            </FormGroup> */}

              <div className="modal-bottom-container">
                <button
                  className="cancel-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    props.toggleModal();
                  }}
                >
                  Cancel
                </button>
                <button className="save-btn" onClick={props.onSave}>
                  Save
                </button>
              </div>
            </Box>
          </ModalBody>
        </Modal>
      </LocalizationProvider>
    </div>
  );
};

export default SchedulerModal;
