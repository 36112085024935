import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Alert } from "reactstrap";
import axios from "axios";
import Config from "../../config.js";
import utility from "../../components/General/utility";
import Select from "react-select";
import ErrorModal from "./ErrorModal";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const serverAPI = Config.apiGateway.URL;
axios.defaults.headers.common["Authorization"] = Config.backendGUID;

const getPhoneNumbers = (areaCode) => {
  return axios
    .post(serverAPI + "/app/sms/phonenumbers/" + areaCode + "/US")
    .then((res) => {
      return res.data;
    });
};

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "23px",
    color: "#1C1D21",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "23px",
    color: "#1C1D21",
  }),
};

const formatGroupLabel = (data) => (
  <span
    style={{
      color: "#1C1D21",
      fontSize: "14px",
      fontWeight: "700",
      lineHeight: "18px",
    }}
  >
    {data.label}
  </span>
);

const SignUpNonTextable = () => {
  const [isTextable, setIsTextable] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const history = useHistory();
  const dropdownRef = useRef();
  const [isNoSelected, setIsNoSelected] = useState(false);
  const [phoneNumbers, setPhoneNumbers] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isErrorModal, setErrorModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [areaCode, setAreaCode] = useState("");
  const [invalidAction, setInvalidAction] = useState(false);
  const [errorText, setErrorText] = useState(false);
  const [isEditable, setIsEditable] = useState(true);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState();
  const [groupedOptions, setGroupedOptions] = useState([]);

  const [isPhoneNumberError, setPhoneNumberError] = useState(false);
  const [isAreaCodeError, setAreaCodeError] = useState(false);

  const toggleErrorModal = () => {
    setErrorModal(!isErrorModal);
  };

  const onCodeSubmit = async (ev) => {
    setErrorText(false);
    setPhoneNumberError(false);
    setAreaCodeError(false);
    setInvalidAction(false);
    let phoneNumbers;
    ev.preventDefault();
    var isValidCode = validateAreaCode();

    if(!isValidCode) return false;
    if (!isValidCode && parseInt(areaCode)) {
      setInvalidAction(true);
      setAreaCodeError(true);
      setErrorText(
        "Please enter a valid area code. Valid area code ranges between 201 and 999."
      );
      return false;
    }

    try {
      setLoading(true);
      phoneNumbers = await getPhoneNumbers(areaCode);

      phoneNumbers.map((i, v) => {
        i.country = "us";
        i.value = i.phoneNumber;
        i.label = i.phoneNumber;
      });

      phoneNumbers = await utility.groupArrayBy(phoneNumbers, "country");
      setPhoneNumbers(phoneNumbers);
      dropdownRef.current.focus();
      const dropdownWrapper = document.querySelector(
        ".mobile-list > div:first-of-type"
      );
      if (dropdownWrapper) {
        dropdownWrapper.style.border = "3px solid #6CE422";
      }
      setGroupedOptions([]);
      Object.keys(phoneNumbers).map((i, v) => {
        groupedOptions.push({ label: i, options: phoneNumbers[i] });
      });
      setGroupedOptions(groupedOptions);
      setLoading(false);
      return false;
    } catch(ex) {
      setLoading(false);
      toggleErrorModal();
    }
  };

  // const onContinue = () => {
  //   if (isTextable == true && isTextable != undefined) {
  //     history.push("/enrollment-form/select-new-number");
  //   } else {
  //     history.push("/enrollment-form/no-sms-hosting");
  //   }
  // };

  useEffect(() => {
    setPhoneNumbers([""]);
    setErrorText(false);
    setInvalidAction(false);
    setIsEditable(true);
    setSelectedPhoneNumber("");

    const rightSideWrapper = document.querySelector(
      ".rightside .inner-wrapper"
    );
    rightSideWrapper.style.paddingTop = "72px";
  }, []);

  const SaveSelectedPhoneNumber = () => {
    var userId = JSON.parse(localStorage.getItem("UserIdCreated"));
    let tempPhoneNumber = selectedPhoneNumber.replace(/[^\w\s]/gi, "");
    tempPhoneNumber = tempPhoneNumber.replace(/\s+/g, "");
    return axios
      .post(
        serverAPI +
          "/app/sms/SaveSelectedPhoneNumber/" +
          tempPhoneNumber +
          "/" +
          userId
      )
      .then((res) => {
        // console.log("RES: ", res.data);

        // let phoneTypeResult = axios.post(`${serverAPI}/app/sms/setPhoneType/${userId}/PROVISIONED`)
        //   .then(result => result.data)
        //   .catch(err => err);

        return res.data;
      });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    let isValid = validateInputs();

    try {
      let isPhoneNumberSaved = await SaveSelectedPhoneNumber();
      if (
        isValid &&
        selectedPhoneNumber != undefined &&
        selectedPhoneNumber !== "" &&
        isPhoneNumberSaved
      ) {
        setIsLoading(false);

       history.push({
        pathname: "/enrollment-form/agent-transfer",
        state: { from: "signup-nontextable", isNewNumberSelected: true },
      });
      } else {
        throw new Error("Error when attempting to save phone number to database");
      }
    } catch (ex) {
      setInvalidAction(true);
      toggleErrorModal();
      setIsLoading(false);
    }
  };

  const validateInputs = () => {
    setInvalidAction(false);
    setErrorText(false);

    if (parseInt(areaCode) < 201 && parseInt(areaCode) > 999) {
      setInvalidAction(true);
      setErrorText(
        "Please enter a valid area code. Valid area code ranges between 201 and 999."
      );
      return false;
    }

    // validate all other fields
    if (!parseInt(areaCode) || !selectedPhoneNumber) {
      setInvalidAction(true);
      setAreaCodeError(true);
      setPhoneNumberError(true);
      setErrorText("Please enter an area code, click submit and select a number.");
      return false;
    }
    return true;
  };

  const validateAreaCode = () => {
    if(!areaCode || isNaN(parseInt(areaCode))) { 
      setInvalidAction(true);
      setAreaCodeError(true);
      setErrorText("Please enter a valid area code.");
      return false;
    }
    if (parseInt(areaCode) < 201 || parseInt(areaCode) > 999) {
      setInvalidAction(true);
      setAreaCodeError(true);
      setErrorText(
        "Please enter a valid area code. Valid area code ranges between 201 and 999."
      );
      return false;
    } else {
      return true;
    }
  };

  const InfoTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  return (
    <div className="signup-nontextable">
      <div className="top-heading">Choose a new text-enabled number</div>
      <p className="top-desc">
        The number you’ve provided cannot be text-enabled. Not to worry! We can
        get you a new text-enabled number. Enter your 3-digit area code and pick
        from any of the available numbers below.{"  "}
        <InfoTooltip
          title={
            <p style={{ fontStyle: "normal", color: "#718096" }}>
              <em>{"* "}</em>
              {
                "You will still use your existing business landline for voice calls"
              }{" "}
            </p>
          }
        >
          <InfoOutlinedIcon
            sx={{ fontSize: 25 }}
            // className="text-light-color"
            color="#718096"
          />
        </InfoTooltip>
      </p>
      {invalidAction ? <Alert color="danger">{errorText}</Alert> : null}
      <div className="form-wrapper width-427">
        <form>
          <div className="form-group">
            <input
              type="text"
              className={`input-control area-code ${isAreaCodeError ? "input-error" : ""}`}
              placeholder="Area code"
              value={areaCode}
              maxLength={3}
              onChange={(e) => setAreaCode(e.target.value)}
            />
            <input
              type="button"
              value="Submit"
              className="btn-submit"
              // onClick={handleAreaCode}
              onClick={onCodeSubmit}
              style={{
                width: "80px",
                height: "48px",
                marginLeft: "0",
              }}
            />
          </div>

          <div className="form-group">
            <Select
              placeholder={
                groupedOptions.length > 0
                  ? "Click here to pick a textable number"
                  : "Mobile Numbers"
              }
              className={`mobile-list ${isPhoneNumberError ? "input-error" : ""}`}
              styles={customStyles}
              options={groupedOptions}
              isLoading={loading}
              ref={dropdownRef}
              formatGroupLabel={formatGroupLabel}
              onChange={(selectedOption) => {
                const dropdownWrapper = document.querySelector(
                  ".mobile-list > div:first-of-type"
                );
                if (dropdownWrapper) {
                  dropdownWrapper.style.border = "1px solid #e2e8f0";
                }
                setSelectedPhoneNumber(selectedOption.value);
              }}
            />
          </div>
          <div
            className="form-group btn-container"
            style={{
              width: "80px !important",
              height: "48px",
            }}
          >
            <input
              type="button"
              value="Confirm Number"
              className="btn-submit"
              onClick={handleSubmit}
            />
          </div>
        </form>
        <div className="signin-div">
          Already have an account?{" "}
          <a href="#" className="btn-text">
            Sign In
          </a>
        </div>
      </div>
      {!isLoading && (
        <>
          <ErrorModal
            isErrorModal={isErrorModal}
            toggleErrorModal={toggleErrorModal}
          />
        </>
      )}
    </div>
  );
};

export default SignUpNonTextable;
