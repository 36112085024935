import React from "react";

const Copyrights = () => {
  return (
    <>
      <h2>Copyrights</h2>
    </>
  );
};

export default Copyrights;
