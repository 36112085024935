import create from "zustand";
import { persist } from "zustand/middleware";
import arrayToMapOfKey from "../utils/array";

const DISPOSITION_KEY = "_DISPOSITION_KEY_";

const useDispositionStore = create(
  persist(
    (set, get) => ({
      clientChatDepositions: {},
      dispositionResults: {},
      chatSpecificDepositions: [],
      addClientDepositions: (depositions) => {
        const results = depositions.map((dep) => dep.dispositionResults).flat();

        set({
          clientChatDepositions: arrayToMapOfKey(depositions, "id"),
          dispositionResults: arrayToMapOfKey(results, "id"),
        });
      },
      addChatSpecificDepositions: (depositions) =>
        set({
          chatSpecificDepositions: depositions,
        }),
    }),
    {
      name: DISPOSITION_KEY,
    }
  )
);

export default useDispositionStore;
