import React, { useState, useEffect } from "react";
import { CircleAlertMajor } from "@shopify/polaris-icons";
import axios from "axios";

import "./PendingInfo.css";

export default function PendingInfo(serverAPI, shop) {
  let [infoPresent, setTheInfo] = useState(null);

  useEffect(() => {
    let asyncEffect = async () => {
      axios
        .get(serverAPI + "/appUser/isTempInfoPending?shop=" + shop.shop)
        .then((resp) => {
          setTheInfo(resp.data);
        })
        .catch((error) => {
          console.warn(error.response);
        });
    };
    asyncEffect();
  });

  return [
    {
      label: "Save pending information",
      icon: CircleAlertMajor,
      onClick: () => {
        axios
          .post(serverAPI + "/appUser/restoreTempInfo", { shop: shop.shop })
          .then((response) => {
            console.log(JSON.stringify(response.data));
            if (response.data && response.data.billingUrl)
              window.open(response.data.billingUrl, "_self");
          });
      },
      escapeState: infoPresent,
    },
  ];
}
