import React from "react";
import "react-image-crop/dist/ReactCrop.css";
import ReactCrop from "react-image-crop";
import Resizer from "react-image-file-resizer";
import { Box, Button, useTheme } from "@mui/material";
import getCroppedImg from "../General/Crop/cropImage";
import utility from "../General/utility";

const CustomImageCrop = ({ src, onCropComplete, onCropAborted }) => {
  const [parentCrop, setCrop] = React.useState({
    unit: "%",
    width: 70,
    height: 70,
    aspect: 16 / 9,
  });

  const [croppedImage, setCroppedImage] = React.useState();
  const imageRef = React.useRef();

  const makeClientCrop = async (crop) => {
    if (imageRef.current && crop.width && crop.height) {
      const imgPth = await getResizeImage(
        imageRef.current,
        crop,
        "newFile.jpeg"
      );
      const random = utility.string("a", 10);
      // const croppedImage = await getCroppedImg(src, crop);
      // console.log(croppedImage);
      const file = new File([imgPth], `${random}.png`, {
        type: "image/png",
        lastModified: Date.now(),
      });

      const resizedFile = await resizeFile(file);
      setCroppedImage(resizedFile);
    }
  };

  const onFileLoaded = (image) => {
    imageRef.current = image;
  };

  const onImgCropComp = (crop) => {
    console.log(crop);
    makeClientCrop(crop);
  };

  const onImgCropChange = (crop, percentCrop) => {
    setCrop(crop);
  };

  const getResizeImage = (image, crop, fileName) => {
    const canvas = document.createElement("canvas");
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );
    return new Promise((resolve, reject) => {
      canvas.toBlob((file) => {
        resolve(file);
      }, "image/png");
    });
  };

  const theme = useTheme();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
      flexDirection="column"
    >
      <ReactCrop
        src={src}
        ruleOfThirds
        crop={parentCrop}
        onImageLoaded={onFileLoaded}
        onChange={onImgCropChange}
        onComplete={onImgCropComp}
      >
        <img
          ref={imageRef}
          alt="Crop me"
          src={src}
          style={{
            // transform: `scale(${scale}) rotate(${rotate}deg)`,
            height: "auto",
            // maxHeight: "500px",
            width: "auto",
            objectFit: "cover",
          }}
        />
      </ReactCrop>
      <Box
        sx={{
          backgroundColor: "white",
        }}
        marginY={theme.spacing(1)}
        paddingX={theme.spacing(1)}
        display="flex"
        width="100%"
        justifyContent="flex-end"
      >
        <Button
          sx={{
            marginRight: theme.spacing(1),
          }}
          color="secondary"
          variant="outlined"
          onClick={() => {
            onCropAborted();
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={!croppedImage}
          onClick={() => {
            onCropComplete(croppedImage);
          }}
          variant="contained"
        >
          Crop
        </Button>
      </Box>
    </Box>
  );
};

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      70,
      70,
      "PNG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "file",
      70,
      70
    );
  });

export default CustomImageCrop;
