import React, { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  ModalHeader,
  ModalHeaderText,
  ModalHeaderCloseBtn,
  NameLabel,
  NameLabelText,
  NameLabelValue,
  ResultsWrapper,
  ResultInput,
  AddMoreResultBtn,
  ActionWrapper,
} from "./style";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import { Button } from "@mui/material";

function AddResultsModal({
  modalOpen,
  modalClose,
  title,
  editMode = false,
  initialData = {},
  saveDisposition,
}) {
  const [results, setResults] = useState(["", "", ""]);
  const handleChangeResult = (e, index) => {
    let customResults = results.slice();
    customResults[index] = e.target.value;
    setResults(customResults);
  };
  const handleAddResult = () => {
    const customResults = results.slice();
    customResults.push("");
    setResults(customResults);
  };
  const handleSubmit = () => {
    const filteredResults = results.filter((item) => item !== "");
    saveDisposition(
      {
        id: editMode ? initialData.id : null,
        name: editMode ? initialData.name : title,
        results: filteredResults,
      },
      editMode
    );
    setResults(["", "", ""]);
    modalClose();
  };

  useEffect(() => {
    if (editMode) {
      setResults(initialData.results);
    }
  }, [editMode]);

  const submitBtnDisable = useMemo(() => {
    const blankItems = results.filter((item) => item === "");
    return blankItems.length === results.length;
  }, [results]);
  return (
    <Modal open={modalOpen} onClose={modalClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "600px",
          bgcolor: "white",
          borderRadius: "10px",
          padding: "0 24px 24px 24px",
        }}
      >
        <ModalHeader>
          <ModalHeaderText>
            {editMode
              ? "Edit results of disposition"
              : "Add results to disposition"}
          </ModalHeaderText>
          <ModalHeaderCloseBtn onClick={modalClose}>
            <CloseIcon />
          </ModalHeaderCloseBtn>
        </ModalHeader>
        <NameLabel>
          <NameLabelText>Name</NameLabelText>
          <InfoIcon />
        </NameLabel>
        <NameLabelValue>{editMode ? initialData.name : title}</NameLabelValue>
        <NameLabel>
          <NameLabelText>Result</NameLabelText>
          <InfoIcon />
        </NameLabel>
        <ResultsWrapper>
          {results.map((result, index) => (
            <ResultInput
              key={index}
              value={result}
              onChange={(e) => handleChangeResult(e, index)}
            />
          ))}
        </ResultsWrapper>
        <AddMoreResultBtn onClick={() => handleAddResult()} />
        <ActionWrapper>
          <Button
            sx={{
              background: "#F2F2F2",
              borderRadius: "10px",
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "125%",
              letterSpacing: "0.15px",
              color: "#305973",
              height: "42px",
            }}
            onClick={() => modalClose()}
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            sx={{
              background: "#305973",
              borderRadius: "10px",
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "125%",
              letterSpacing: "0.15px",
              color: "white",
              height: "42px",
            }}
            onClick={() => handleSubmit()}
            variant="contained"
            disabled={submitBtnDisable}
          >
            Submit
          </Button>
        </ActionWrapper>
      </Box>
    </Modal>
  );
}

export default AddResultsModal;
