import React from "react";
import { Navigation, Banner } from "@shopify/polaris";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import {
  ArrowLeftMinor,
  CustomersMajor,
  ConfettiMajor,
  QuestionMarkMajor,
  CalendarMajor,
  TroubleshootMajor,
  BillingStatementDollarMajor,
  ChatMajor,
  NoteMajor,
  ConversationMinor,
  HomeMajor,
} from "@shopify/polaris-icons";
import ToggleWidget from "../ToggleWidget/ToggleWidget";
import PendingInfo from "../PendingInfo/PendingInfo";
import { PREVIEW_URL_EXCEPTIONS } from "../../../appConfig";

import SideLogoImage from "../../../assets/img/answernet-1.png";
import cookie from "js-cookie";
import AccountToggle from "src/components/AccountToggle";
import { Box, IconButton, Typography } from "@mui/material";

const NavigationMenu = ({
  toggleIsLoading,
  toggleModalActive,
  admin = false,
  sections = [],
  shop = null,
  path = null,
  serverAPI = null,
  setPendingExists = null,
  pendingExists = null,
}) => {
  let settings = [];
  let billing = [];
  let preview = [];
  let selected = [];
  let pending = [];
  let help = [];
  let product = [];
  let items = sections.default || [];

  if (admin) {
    items.push({
      label: "Users",
      icon: CustomersMajor,
      url: "/admin/users",
    });
  } else {
    if (sections.primary) {
      sections.primary.forEach((item) => {
        items.push(item);
      });
    }
  }

  console.log(path);

  if (sections.settings) {
    let settingsItems = [
      {
        url: "/#home",
        label: "Dashboard",
        icon: HomeMajor,
        selected: path === "/",
      },
      {
        url: "/schedule",
        label: "Schedule",
        icon: CalendarMajor,
        selected: path === "/schedule",
      },
      {
        url: "/contactinfo",
        label: "Users",
        icon: CustomersMajor,
        selected: path === "/contactinfo",
      },
    ];

    pending = PendingInfo(serverAPI, shop);
    setPendingExists(pending && pending[0] && pending[0]["escapeState"]);
    if (pendingExists) settingsItems = [...pending, ...settingsItems];

    settings = [
      <Navigation.Section
        key={2}
        title="Features"
        items={settingsItems}
        action={{
          accessibilityLabel: "Features",
        }}
      />,
    ];

    if (pendingExists)
      settings = [<span className="SettingsSection">{settings[0]}</span>];
  }
  if (sections.billing) {
    billing = [
      <Navigation.Section
        key={3}
        separator
        title="Billing"
        items={[
          //...sections.billing,
          {
            url: "/billingplan",
            label: "Plan Details",
            icon: BillingStatementDollarMajor,
            selected: path === "/billingplan",
          },
        ]}
      />,
    ];
  }
  // if (sections.help) {
  //   help = [
  //     <Navigation.Section
  //       key={2}
  //       separator
  //       title="Help"
  //       items={[
  //         {
  //           url: "/help",
  //           label: "Learning Center",
  //           icon: NoteMajor,
  //           selected: path === "/help",
  //         },
  //       ]}
  //     />,
  //   ];
  // }
  if (sections.preview) {
    selected = ToggleWidget(serverAPI, shop);
    if (
      selected[0] &&
      selected[0]["escapeState"] !== undefined &&
      selected[0]["escapeState"] !== null
    ) {
      let spanName =
        "PreviewSection " +
        (selected[0]["escapeState"] === true ? "ChatActive" : "ChatInactive");
      let items = [...selected];

      if (
        selected[0]["escapeState"] === true &&
        (!shop.IsNonShopify ||
          (shop.IsNonShopify &&
            shop.clientURL &&
            PREVIEW_URL_EXCEPTIONS.indexOf(shop.clientURL) == -1))
      ) {
        items = [
          ...items,
          {
            label: "Preview your chat widget",
            icon: ConfettiMajor,
            selected: false,
            onClick: () => {
              shop && shop.shop && !shop.IsNonShopify
                ? window.open("https://" + shop.shop, "shopWindow")
                : window.open("https://" + shop.clientURL, "shopWindow");
            }, // TODO: Add URL for nonShopify users
          },
        ];
      }
    }
    if (sections.product) {
      product = [
        <Navigation.Section
          key={3}
          separator
          title="Product"
          items={[
            {
              url: "/livechat",
              label: "Live Chat",
              icon: ChatMajor,
              selected: path === "/livechat",
            },
          ]}
        />,
      ];
    }
    if (sections.preview) {
      selected = ToggleWidget(serverAPI, shop);
      if (
        selected[0] &&
        selected[0]["escapeState"] !== undefined &&
        selected[0]["escapeState"] !== null
      ) {
        let spanName =
          "PreviewSection " +
          (selected[0]["escapeState"] === true ? "ChatActive" : "ChatInactive");
        let items = [...selected];

        if (
          selected[0]["escapeState"] === true &&
          (!shop.IsNonShopify ||
            (shop.IsNonShopify &&
              shop.clientURL &&
              PREVIEW_URL_EXCEPTIONS.indexOf(shop.clientURL) == -1))
        )
          items = [
            ...items,
            {
              label: "Preview your chat widget",
              icon: ConfettiMajor,
              selected: false,
              onClick: () => {
                shop && shop.shop && !shop.IsNonShopify
                  ? window.open("https://" + shop.shop, "shopWindow")
                  : window.open("https://" + shop.clientURL, "shopWindow");
              }, // TODO: Add URL for nonShopify users
            },
          ];

        preview = [
          <span className={spanName}>
            <Navigation.Section
              key={3}
              title="Chat widget"
              separator
              items={items}
            />
          </span>,
        ];
      } else {
        preview = [
          <Navigation.Section
            key={3}
            title="Chat widget"
            separator
            items={[]}
          />,
        ];
      }
    }
  }
  let theNavigationSections = [
    <Navigation.Section
      key={4}
      separator
      title=""
      items={items}
      action={{
        icon: ConversationMinor,
        accessibilityLabel: "Contact support",
        onClick: toggleModalActive,
      }}
    />,
    settings,
    help,
    ...(sections.trial
      ? [
          <Banner status="info">
            <p>Days Left of Trial Period: {sections.trial}</p>
          </Banner>,
        ]
      : []),
  ];

  if (!shop.IsNonShopify) {
    theNavigationSections = [
      <Navigation.Section
        key={5}
        items={[
          {
            label: "Back to Shopify",
            icon: ArrowLeftMinor,
            url:
              shop && shop.shop
                ? "https://" + shop.shop + "/admin"
                : "https://www.shopify.com",
          },
        ]}
      />,
      ...theNavigationSections,
    ];
  }

  const logoutUserHandler = () => {
    let cookies = cookie.get();
    Object.keys(cookies).forEach((cookieName) => {
      if (cookieName === "setupDate") return;
      cookie.remove(cookieName);
    });
    window.location.href = "/login";
  };

  return (
    <div className="sidebar-container">
      <div className="sidebar-logo">
        <img src={SideLogoImage} alt="" />
      </div>
      <Box marginTop={"-35px"}>
        <Navigation location="/">
          {pendingExists ? (
            <span className="PendingInfo">{theNavigationSections}</span>
          ) : (
            theNavigationSections
          )}
        </Navigation>
      </Box>
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
        }}
        width="100%"
      >
        <AccountToggle serverAPI={serverAPI} />
        <Box
          display="flex"
          alignItems="center"
          paddingX="16px"
          justifyContent="space-between"
          borderTop="1px solid black"
        >
          {shop.shop}
          <Box
            display="flex"
            alignItems="center"
            onClick={() => logoutUserHandler()}
            marginY="16px"
            justifyContent="center"
          >
            <Typography
              sx={{
                fontSize: "14px",
                cursor: "pointer",
              }}
              color="text.disabled"
              textTransform="uppercase"
            >
              Logout
            </Typography>
          </Box>
        </Box>
      </Box>
    </div>
  );
};
export default NavigationMenu;
