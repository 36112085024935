import React from 'react';
import './SwitchBtn.css';
const SwitchBtn = ({ name, checked, onChange, help, label, disabled, centered=true}) => {
    let labelElement;
    let helpElement;
    if(label && label!==''){
        labelElement=(
            <label className='label-sw' htmlFor={name}><h5>{label}</h5></label>
        );
    }
    if (help && help!==''){
        helpElement = (
            <span className="m-form_help">
                {help}
            </span>
        );
    }
    return (
        <div className={centered ? "text-center" : ""}>
            <span>
                <label className="switch" >
                    <input type="checkbox" disabled={disabled} name={name} value="1" checked={checked} onChange={onChange}/>
                    <span className="slider round"></span>
                </label>
            </span>
            {labelElement}
            {helpElement}
        </div>
    );
}
export default SwitchBtn;
