import React from "react";

const Loader = () => {
  return (
    <>
      <span className="dot1"></span>
      <span className="dot2"></span>
      <span className="dot3"></span>
    </>
  );
};

export default Loader;
