import React from "react";
import Stars2 from "../../images/stars2.png";
import axios from "axios";
import Config from "../../config.js";
import { useHistory } from "react-router-dom";

axios.defaults.headers.common["Authorization"] = Config.backendGUID;

const Complete = () => {
  const history = useHistory();

  const handleGotoDashboard = () => {
    // window.location.href = history.location.state.adminDashUrl;
    window.location.href = "/login";
  };

  return (
    <div className="complete-one-page mt-200 mb-80">
      <div className="top-heading">
        Yay, that’s it!
        <br />
        You’re now a textable business!
      </div>
      <div className="top-desc">
        You’re all set with your new textable <br />
        number! You can click below to continue to <br />
        your administrative dashboard. Let’s get <br />
        texting!
      </div>
      <div className="complete-one-page-action mb-100">
        <button onClick={() => handleGotoDashboard()}>
          Continue To Admin
        </button>
      </div>
    </div>
  );
};

export default Complete;
