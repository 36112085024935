import React, { useEffect, useState } from "react";
import Stars2 from "../../images/stars2.png";
import axios from "axios";
import Config from "../../config.js";
import { useHistory } from "react-router-dom";

axios.defaults.headers.common["Authorization"] = Config.backendGUID;
const AccountSetup = () => {
  const [isFromTextable, setIsFromTextable] = useState();

  const history = useHistory();

  const handleAccountSetup = () => {
    localStorage.setItem("final-step", true);

    isFromTextable
      ? history.push("/enrollment-form/landing-page")
      : history.push("/enrollment-form/signup-textable");
  };

  useEffect(() => {
    if (
      history.location.state &&
      history.location.state.from == "signup-textable"
    ) {
      setIsFromTextable(true);
    } else {
      setIsFromTextable(false);
    }
  }, []);

  return (
    <div className="complete-one-page mt-250">
      <div className="top-heading font-32 mb-30">
        Cool! We’re almost there...
      </div>
      <div className="top-desc font-24">
        Next our carrier requires us to verify <br />
        ownership of your number.
        {/* Now that you’ve signed up, i’m sure you’re curious about next steps....
        <br />
        <br />
        The phone carriers require us to verify ownership of your number, so
        please click below to set up your account.
        <br />
        <br />
        If you prefer assistance, text us! We are happy to walk you through the
        verification process with you (it is quick and easy)!
        <br />
        <br /> */}
      </div>
      <div className="complete-one-page-action mb-10 f-w600">
        <button onClick={() => handleAccountSetup()}>Continue To Verify</button>
      </div>
      <div className="top-desc">Need help? Text us at (123) 456-7891!</div>
    </div>
  );
};

export default AccountSetup;
