export const step3 = [
  [
    {
      title: "Pop-up Message",
      tooltip:
        "Visitors will see this message before they click on the chat widget to open a chat.",
      placeholder: "Need help? Chat now!",
      initialValue: "Need help? Chat now!",
      name: "popupMessage",
      mandatory: true,
      options: {
        maxLength: 135,
        showCharacterCount: true,
      },
    },
    {
      popupMessage: true,
      renderAsIs: true,
      associatedName: "popupMessage",
      placeholder: "Need help? Chat now!",
    },
  ],
  [
    {
      title: "Greeting Message",
      tooltip:
        'This is the first message visitors will see when they open a chat.\nPlease note that after this message visitors will be presented with two buttons: "Sales" or "Support"',
      placeholder: "Thanks for stopping by! How can we help you today?",
      initialValue: "Thanks for stopping by! How can we help you today?",
      name: "greetingMessage",
      mandatory: true,
      options: {
        maxLength: 300,
        showCharacterCount: true,
      },
    },
    {
      title: "Header",
      tooltip:
        "Your company display name visitors will see when they open a chat",
      placeholder: "Company Name",
      initialValue: "",
      name: "greetingHeader",
      mandatory: false,
      options: {
        maxLength: 150,
        showCharacterCount: true,
      },
    },
    {
      title: "Slogan",
      tooltip:
        "Your company slogan visitors will see when they open a chat. If you don't specify, this will be automatically set from your Shopify store name.",
      placeholder: "Brief slogan here",
      initialValue: "",
      name: "greetingSlogan",
      mandatory: false,
      options: {
        maxLength: 150,
        showCharacterCount: true,
      },
    },
    {
      greetingMessage: true,
      renderAsIs: true,
      associatedName: "greetingMessage",
      placeholder: "Thanks for stopping by! How can we help you today?",
    },
  ],
  [
    {
      title: "Sales Notifications",
      name: "salesEmail",
      placeholder: "Enter Sales Email Address",
      tooltip:
        "Whenever you get a sales-related chat request on your website, we'll forward that information to this email address for your records.",
      mandatory: true,
      multipleEmails: true,
    },
    {
      title: "Support Notifications",
      name: "supportEmail",
      placeholder: "Enter Support Email Address",
      tooltip:
        "Whenever you get a support-related chat request on your website, we'll forward that information to this email address for your records",
      mandatory: true,
      multipleEmails: true,
    },
  ],
  [
    {
      title: "Fun Facts",
      name: "funFactOne",
      mandatory: true,
      placeholder: "Did you know that we offer 24/7 live chat services?",
    },
    {
      name: "funFactTwo",
      mandatory: true,
      placeholder: "We're here for you whenever you need us!",
    },
  ],
];
export const step3Description = [
  [
    {
      text: "Now let's customize your live chat widget, starting with the pop-up message that shows up even before a visitor clicks on the button. Let's make them feel welcomed!",
    },
    {
      text: "You can also change the look and feel of your live chat widget any time after the setup process is completed.",
    },
  ],
  [
    {
      text: "Next, let's edit the message your visitors will see upon opening the chat window. Keep it personal to encourage visitors to talk to you!",
    },
  ],
  [
    {
      text: "TextChat logs all customer questions for you while you're away. Where would you like to be notified?",
      bold: true,
    },
  ],
  [
    {
      text: "Last but not least: the fun part!",
    },
    {
      text: "Keep your visitors engaged with fun facts about you while we transfer the live chat conversation from your website to your mobile phone.",
    },
  ],
  [
    {
      text: "Please provide two 'fun facts' that visitors will see while they wait to chat.",
      bold: true,
    },
    {
      text: 'Think of these facts as a live chat version of "on-hold" music. These could be details about your business or industry, or even custom offers or promotions. (Remember, you can always edit or add more of these later.)',
    },
  ],
];

export const step3video = {
  url: "https://www.w3schools.com/html/mov_bbb.mp4",
  poster: null,
};

export const step3Substeps = 4;
