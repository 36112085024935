import React, {useCallback, useState} from "react";
import Cropper from "react-easy-crop";
import { RangeSlider } from "@shopify/polaris";
import "./style.css";
const Crop = (props) =>{

    const [rangeValue, setRangeValue] = useState(1);
    const handleRangeSliderChange = useCallback(
        (value) => setRangeValue(value),
        []
    );

    const [crop, setCropValue] = useState({ x: 0, y: 0 });
    const onCropChange = useCallback((value) => setCropValue(value), []);

    return (
        <div className="body-crop">
            <div className="slidecontainer">
                <Cropper
                    image={props.imageSrc || null}
                    crop={crop}
                    zoom={rangeValue}
                    aspect={props.aspect || 22 / 11}
                    cropShape={props.shapeCrop || "rect"}
                    onCropChange={onCropChange}
                    onCropComplete={props.onCropComplete}
                    onZoomChange={props.handleRangeSliderChange}
                />
            </div>
            <div className="slideFix">
                <RangeSlider
                    value={rangeValue}
                    onChange={handleRangeSliderChange}
                    output
                    max={3}
                    min={0.6}
                    step={0.1}
                />
            </div>
        </div>
    );
}
export default Crop;