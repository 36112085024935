const SearchParams = (search=false) =>{
    let params = {};

    let parts = search
        ? search.substring(1).split('&')
        : window.location.search.substring(1).split('&');

    for (let i = 0; i < parts.length; i++) {
        var nv = parts[i].split('=');
        if (!nv[0]) continue;
        params[nv[0]] = nv[1] || true;
    }
    return params;
};

export default SearchParams;