import React, { Component } from "react";
import { EmptyState } from "@shopify/polaris";
import Axios from "axios";

class ErrorRequest extends Component {
  componentDidMount() {
    if (this.props.error && this.props.error.data) {
      Axios.post(this.props.serverAPI + "/app/log/request/error", {
        error: this.props.error.data,
        client: this.props.shop,
      });
    }
  }
  render() {
    let { error, textError, backgroundURL, title } = this.props;

    if (!title) {
      if (error.status === 404) title = "Page not found";
      else if (error.status === 500)
        title = "Oops, something went wrong, please try again later";
      else title = "Oops, something went wrong, please try again later";
    }
    return (
      <EmptyState
        heading={title}
        action={{
          content: "Reload",
          onAction: (e) => window.location.reload(),
        }}
        secondaryAction={{
          content: "Go Back",
          onAction: (e) => window.history.back(),
        }}
        image={
          backgroundURL ||
          "https://cdn.shopify.com/s/files/1/0757/9955/files/empty-state.svg"
        }
      >
        <p>{textError || null}</p>
      </EmptyState>
    );
  }
}
export default ErrorRequest;
