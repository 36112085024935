import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Input,
} from "reactstrap";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { Icon } from "@shopify/polaris";
// import TimePicker from "rc-time-picker";
import moment from "moment";
import "rc-time-picker/assets/index.css";
import { DeleteMajor } from "@shopify/polaris-icons";
import { Alert } from "reactstrap";
import InfoIcon from "../../assets/img/info.svg";
import AddIcon from "../../assets/img/add.svg";
import DeleteIcon from "../../assets/img/delete.svg";
import { Box } from "@mui/material";

function capitalizeFirstLetter(string) {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
}

const SchedulerModal = (props) => {
  const { className, toggleModal, formData = {}, title, isOpen, error } = props;
  let { busy, timings } = formData;
  if (!Array.isArray(timings)) timings = [{}]; //set empty timings
  let days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Weekdays",
    "Weekends",
  ];
  let selectedDay = days[formData.selectedDay];
  let selectedDayActual = days[formData.selectedDayActual];
  const format = "h:mm A";
  let dropDownMenu =
    [0, 6].indexOf(formData.selectedDayActual) == -1
      ? [selectedDayActual, "Weekdays"]
      : [selectedDayActual, "Weekends"];

  function dayChangeHandler(event) {
    let newValue = days.indexOf(event.target.value);
    props.onValueChange(null, "selectedDay", newValue);
  }
  const handleBusyBtnClick = (e) => {
    let busyBtn = document.querySelector(".busy-btn");
    if (busyBtn.classList.contains("active")) {
      busyBtn.setAttribute("aria-checked", "false");
    } else {
      busyBtn.setAttribute("aria-checked", "true");
    }

    busyBtn.classList.toggle("active");
    props.onValueChange(e, "busy", !busy);
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleModal}
      fade={false}
      backdrop={false}
      className={className}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ModalHeader toggle={toggleModal} className={busy ? "busy" : ""}>
          {title}
        </ModalHeader>
        <ModalBody>
          {error && (
            <Alert color="danger" className="alert-error">
              {error}
            </Alert>
          )}
          <Form>
            <FormGroup>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
                width="100%"
              >
                <div className="item-label-text">Day</div>
                <FormControl sx={{ marginTop: "10px" }} fullWidth>
                  <InputLabel
                    id="select-day-schedule-label"
                    sx={{
                      fontSize: "16px",
                      marginBottom: "5px",
                    }}
                  >
                    Select Day
                  </InputLabel>
                  <Select
                    labelId="select-day-schedule-label"
                    id="select-day-schedule"
                    fullWidth
                    sx={{
                      label: {
                        fontSize: "14px",
                      },
                      "#select-day-schedule": {
                        fontSize: "16px",
                        marginTop: "8px",
                      },
                      ".MuiInputLabel-root": {
                        fontSize: "16px",
                      },
                    }}
                    MenuProps={{
                      sx: {
                        ".MuiMenuItem-root": {
                          fontSize: "16px",
                        },
                      },
                    }}
                    value={selectedDay}
                    label="Select Day"
                    onChange={dayChangeHandler}
                  >
                    {dropDownMenu.map((i, index) => (
                      <MenuItem value={i}>{capitalizeFirstLetter(i)}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              {/* <Input
                type="select"
                name="select"
                id="exampleSelect"
                value={selectedDay}
                onChange={dayChangeHandler}
              >
                {dropDownMenu.map((i, index) => (
                  <option key={`key-${index}`}>
                    {capitalizeFirstLetter(i)}
                  </option>
                ))}
              </Input> */}
            </FormGroup>
            <Box marginBottom={"5px"} className="item-label">
              <div className="item-label-text">Time</div>
            </Box>
            {timings.map((timing, index) => {
              return (
                <FormGroup key={`key-${index}`} className="form-group-item">
                  <TimePicker
                    label="Start Time"
                    value={timing.start ? moment(timing.start) : undefined}
                    onChange={(value) => {
                      props.onValueChange(null, "start", moment(value), index);
                    }}
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          label: {
                            fontSize: "16px",
                          },
                          ".MuiOutlinedInput-input": {
                            fontSize: "16px",
                          },
                        }}
                        {...params}
                      />
                    )}
                  />
                  <div className="time-picker-between">-</div>
                  <TimePicker
                    label="End Time"
                    value={timing.end ? moment(timing.end) : undefined}
                    onChange={(value) => {
                      props.onValueChange(null, "end", moment(value), index);
                    }}
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          label: {
                            fontSize: "16px",
                          },
                          ".MuiOutlinedInput-input": {
                            fontSize: "16px",
                          },
                        }}
                        {...params}
                      />
                    )}
                  />
                  {/* <TimePicker
                  showSecond={false}
                  value={timing.end ? moment(timing.end) : undefined}
                  className="xxx"
                  onChange={(value) => {
                    props.onValueChange(null, "end", value, index);
                  }}
                  format={format}
                  use12Hours
                  minuteStep={15}
                  inputReadOnly
                  placeholder="Choose Time"
                /> */}
                  <span
                    className="remove-event-btn"
                    onClick={(e) => {
                      props.onDeleteItem(e, timing, index);
                    }}
                  >
                    <img src={DeleteIcon} alt="" />
                  </span>
                  <button
                    className="add-event-btn"
                    onClick={(e) => {
                      props.onValueChange(e, "addTime", true);
                    }}
                  >
                    <img src={AddIcon} alt="" />
                  </button>
                </FormGroup>
              );
            })}
            {busy && (
              <FormGroup>
                <Input
                  type="textarea"
                  className="busyTextarea"
                  name="text"
                  id="exampleText"
                  onChange={(e) => {
                    props.setBusyMessage &&
                      props.setBusyMessage(e.target.value);
                  }}
                />
              </FormGroup>
            )}
            {/* <div className="item-label">
              <div className="item-label-text">Busy Time</div>
            </div>
            <div className="modal-button-container">
              <button
                className={`busy-btn ${busy ? "active" : ""}`}
                onClick={(e) => handleBusyBtnClick(e)}
              />
            </div> */}
            <div className="modal-bottom-container">
              <button
                className="cancel-btn"
                onClick={(e) => {
                  e.preventDefault();
                  props.toggleModal();
                }}
              >
                Cancel
              </button>
              <button className="save-btn" onClick={props.onSave}>
                Save
              </button>
            </div>
          </Form>
        </ModalBody>
      </LocalizationProvider>
    </Modal>
  );
};

export default SchedulerModal;
