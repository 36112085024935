import React from "react";

export const CircleTickMajor = ({
  color = "#5C5F62",
  width = "33px",
  height = "33px",
}) => {
  return (
    <svg
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
    >
      <path
        fillRule="evenodd"
        d="M0 10a10 10 0 1020 0 10 10 0 00-20 0zm15.2-1.8a1 1 0 00-1.4-1.4L9 11.6 6.7 9.3a1 1 0 00-1.4 1.4l3 3c.4.4 1 .4 1.4 0l5.5-5.5z"
        fill={color}
      />
    </svg>
  );
};

export const CloseIcon = ({
  color = "#5C5F62",
  width = "20px",
  height = "20px",
}) => {
  return (
    <svg
      viewBox="0 0 36 36"
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M34.3891 1.27023C35.7486 2.63632 35.7486 4.85119 34.3891 6.21728L6.54028 34.202C5.18082 35.5681 2.97671 35.5681 1.61725 34.202C0.257792 32.8359 0.257792 30.621 1.61725 29.2549L29.4661 1.27023C30.8256 -0.0958557 33.0297 -0.0958557 34.3891 1.27023Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.61725 1.26526C2.97671 -0.0941979 5.18082 -0.0941979 6.54028 1.26526L34.3891 29.1141C35.7486 30.4736 35.7486 32.6777 34.3891 34.0372C33.0297 35.3966 30.8256 35.3966 29.4661 34.0372L1.61725 6.18829C0.257792 4.82883 0.257792 2.62472 1.61725 1.26526Z"
        fill={color}
      />
    </svg>
  );
};
