import React from "react";

// import "../../css/stylesheet.scss";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import Config from "../../config.js";
const serverAPI = Config.apiGateway.URL;

const SignUpTextable = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  let isFinalStep = JSON.parse(localStorage.getItem("final-step"));
  // isFinalStep = true;
  const history = useHistory();

  const handleClickButton = () => {
    localStorage.setItem("isHostedPhoneNumber", true);

    var userId = JSON.parse(localStorage.getItem("UserIdCreated"));
    let phoneTypeResult = axios.post(`${serverAPI}/app/sms/setPhoneType/${userId}/HOSTED`)
          .then(result => result.data)
          .catch(err => err);

    history.push({
      pathname: "/enrollment-form/agent-transfer",
      state: { from: "signup-textable" },
    });
    // commented as of now, for new routes
    // history.push({ pathname: "/enrollment-form/pricing-no-live-agents" });
  };

  const handleClickNew = () => {
    var userId = JSON.parse(localStorage.getItem("UserIdCreated"));
    let phoneTypeResult = axios.post(`${serverAPI}/app/sms/setPhoneType/${userId}/PROVISIONED`)
          .then(result => result.data)
          .catch(err => err);

    // need to set phoneType PROVISIONED here 
    localStorage.setItem("isHostedPhoneNumber", false);
    history.push({
      pathname: "/enrollment-form/select-new-number",
      state: { from: "signup-textable" },
    });
  };

  const handleClickPhoneTree = (isPhoneTree = false) => {
    var userId = JSON.parse(localStorage.getItem("UserIdCreated"));
    let phoneTypeResult = axios.post(`${serverAPI}/app/sms/setPhoneType/${userId}/HOSTED`)
          .then(result => result.data)
          .catch(err => err);

    // need to set phoneType HOSTED here
    // or we can pass the phoneType in the final signup step
    localStorage.setItem("phoneTree", isPhoneTree);
    history.push({
      pathname: "/enrollment-form/existing-with-new-copy",
    });
  };

  return (
    <div className="signup-textable">
      {!isFinalStep && (
        <div className="helpline-wrap mt-100">
          <div className="helpline-no">
            {/* +{userInfo.busiLandNumberCode}- */}
            {"("}
            {userInfo.busiLandNumber.substring(0, 3)}
            {") "}
            {userInfo.busiLandNumber.substring(3, 6)}-
            {userInfo.busiLandNumber.substring(6, 10)}
          </div>
        </div>
      )}
      {!isFinalStep ? (
        <div className="top-heading text-black mt-20 mb-30">
          Success! You can use this number to
          <br />
          receive texts from your customers!
        </div>
      ) : (
        <div className="top-sub-heading text-black mt-100 font-26 pt-30">
          Let’s confirm your number is working!
        </div>
      )}
      <div className="bottom-title mb-30">
        {!isFinalStep
          ? "Would you like to activate AnswerNet Text Chat for this line?"
          : "If your business landline phone DOES NOT have a phone tree (sometimes called an IVR or virtual receptionist), click below: "}
        {/* : "If your business landline has a Phone Tree click below (I.e. “Press 1” for Sales, “Press 2” for Support):"} */}
      </div>

      <div className="form-group text-center">
        {!isFinalStep ? (
          <div
            className="new-number-btn no-phone"
            onClick={() => handleClickButton()}
          >
            Yep! Continue Signing Up
            {/* Get me a new number just for texting */}
          </div>
        ) : (
          <div
            className="new-number-btn no-phone"
            onClick={() => handleClickPhoneTree()}
          >
            Direct Line
          </div>
        )}
      </div>
      {/* <Link
        className="select-a-new-number"
        to="/enrollment-form/select-new-number"
      >
        I would rather have a new textable number
      </Link> */}

      {/* {isFinalStep && ( */}
      <div className="or-panel mt-30 mb-0">
        <div className="left-bar" />
        <div className="or-panel-text">OR</div>
        <div className="right-bar" />
      </div>
      {/* )} */}
      <div className="bottom-title mb-30">
        {!isFinalStep
          ? "Want to keep customer SMS communication on its own line?"
          : "If your business landline has a phone tree or virtual receptionist (example: “Press 1 for office hours”, etc.), click below: "}
        {/* : "If your business landline DOES NOT have a phone tree, click below:"} */}
      </div>
      <div className="signin-div">
        {!isFinalStep ? (
          <div
            className="new-number-btn no-phone"
            onClick={() => handleClickNew()}
          >
            Get me a new number just for texting
            {/* Yep! Continue Signing Up */}
          </div>
        ) : (
          <div
            className="new-number-btn no-phone"
            onClick={() => handleClickPhoneTree(true)}
          >
            Phone Tree
          </div>
        )}
      </div>
    </div>
  );
};

export default SignUpTextable;
