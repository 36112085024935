export const chatFlow =
    [
            {
                title: 'PROACTIVE POPUP',
                noContent: true
            },
            {
                title: 'Pop-up Message',
                tooltip: 'Visitors will see this message before they click on the chat widget to open a chat.',
                placeholder: 'Need help? Chat now!',
                initialValue: 'Need help? Chat now!',
                name: 'popupMessage',
                mandatory: true,
                options:
                    {
                        maxLength: 135,
                        showCharacterCount: true
                    }
                },
            {
                popupMessage: true,
                renderAsIs: true,
                associatedName: 'popupMessage',
                placeholder: 'Need help? Chat now!'
            },
            {
                spaceBorder: true
            },
            {
                title: 'CHAT WINDOW',
                noContent: true
            },
            {
                title: 'Header',
                tooltip: "This is the header visitors will see in their chat window. If you don't specify, this will be automatically set from your Shopify store name.",
                placeholder: '',
                initialValue: 'Company Name',
                name: 'greetingHeader',
                mandatory: false,
                options:
                {
                    maxLength: 150,
                    showCharacterCount: true
                }
            },
            {
                title: 'Slogan',
                tooltip: 'You can choose to set a slogan visitors will see in their chat window',
                name: 'greetingSlogan',
                mandatory: false,
                initialValue: 'Brief slogan here',
                options:
                {
                    maxLength: 35,
                    showCharacterCount: true
                }
            },
            {
                title: 'Description',
                tooltip: 'You can choose to add a description your visitors will see when first opening your chat window',
                placeholder: "We're here to help!\n\nWhether you're an existing customer or a curious guest, we're one TextChat away",
                name: 'greetingDescription',
                mandatory: false,
                options: {
                    maxLength: 450,
                    showCharacterCount: true
                }
            },
            {
                title: 'Greeting Message',
                tooltip: 'This is the first message visitors will see when they open a chat.\nPlease note that after this message visitors will be presented with two buttons: \"Sales\" or \"Support\"',
                placeholder: 'Thanks for stopping by! How can we help you today?',
                initialValue: 'Thanks for stopping by! How can we help you today?',
                name: 'greetingMessage',
                mandatory: true,
                options:
                {
                    maxLength: 300,
                    showCharacterCount: true
                }
            },
            {
                greetingMessage: true,
                renderAsIs: true,
                associatedName: 'greetingMessage',
                placeholder: 'Thanks for stopping by! How can we help you today?'
            },
            {
                spaceBorder: true
            },
            {
                title: 'Fun Facts',
                cardTitleCustom: [
                    {
                        p: "Fun facts are like a hold message on the telephone.",
                        class: "custom-p-margin"
                    },
                    {
                        p: "The website visitor sees these messages prior to being connected with you via TextChat"
                    }
                ],
                name: 'funFactOne',
                mandatory: true,
                placeholder: 'Did you know that we offer 24/7 live chat services?',
                // videoDescription: "https://www.w3schools.com/html/mov_bbb.mp4"
            },
            {
                name: 'funFactTwo',
                mandatory: true,
                placeholder: 'We\'re here for you whenever you need us!'
            }

    ]
// export const chatFlowDescription = [

//         {
//             text: "Customize your chatbot.",
//         },

// ];