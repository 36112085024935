import React, { Component } from "react";
import {
  Card,
  Form,
  Popover,
  Layout,
  Stack,
  TextField,
  DropZone,
  RadioButton,
  Select,
  Button,
  ResourceList,
  ResourceItem,
  TextStyle,
  Icon,
  Tooltip,
  EmptyState,
  Subheading,
  Avatar,
} from "@shopify/polaris";
import {
  SearchMajor,
  EditMajor,
  DeleteMajor,
} from "@shopify/polaris-icons";
import cookie from "js-cookie";
import Axios from "axios";
import WidgetPreview from "../../../General/ProactiveWidgetPreview/ProactiveWidgetPreview";
import SwitchBtn from "../../../Layouts/SwitchBtn/SwitchBtn";
import utility from "../../../General/utility";
import "./style.css";
import Config from "../../../../config";
import { faLessThanEqual } from "@fortawesome/free-solid-svg-icons";
import Resizer from "react-image-file-resizer";
import BasicModal from "../../../Frame/Modal/BasicModal";
import Cropper from "../../../General/Crop/Cropper";
import getCroppedImg from "../../../General/Crop/cropImage";

const bucketCDN = Config.bucketCDN.URL;

const normalizeInput = (value) => {
  if(!value || typeof value !== 'string') return "";
  const currentValue = value.replace(/[^\d]/g, "");
  const cvLength = currentValue.length;

  // if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 4) return currentValue;
    if (cvLength < 7)
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
      3,
      6
    )}-${currentValue.slice(6, 10)}`;
  // }
};
export default class AgentManager extends Component {
  constructor() {
    super();
    this.state = {
      imageSrc: null,
      editAgents: true,
      imageAgent: [],
      imageAgents: [],
      errorImage: "",
      agents: [],
      currentAgentName: "",
      currentAgentEmail: "",
      currentAgentPhone: "",
      currentAgentToken: null,
      currentAgentKey: null,
      currentEmailKey: null,
      token: null,
      currentEmail: null,
      labelAgents: null,
      searchText: "",
      updloadedFiles: [],
      activeModal: false,
    };
  }

  togglePopoverActive = () => {
    this.setState({ popoverActive: !this.state.popoverActive });
  };

  handleCurrentAgentPhoneChange = (value) => {
    value =  value.replace(/[^\d]/g, "");
      this.setState({currentAgentPhone: value});
  };
  
  modalAction() {
    this.setState({
      activeModal: !this.state.activeModal,
    });
  }

  showCroppedImage = async () => {
    const agent = this.props.dataToSave["Agents"] || [];
    let { currentAgentKey } = this.state;
    const croppedImage = await getCroppedImg(
      this.state.imageToCrop,
      this.state.croppedAreaPixels
    );
    let croppedImageFile = new File([croppedImage], this.state.nameImputFile + ".png", {
      type: "image/png",
      lastModified: Date.now(),
    });
    croppedImageFile = await this.resizeFile(croppedImageFile);

    let newAgents = [...agent];
    let imgBase64 = await this.getBase64Image(croppedImageFile);
    let dataURL = URL.createObjectURL(croppedImageFile);
    const random = utility.string("a", 10);
    newAgents[currentAgentKey].fileMetaData = {};
    newAgents[currentAgentKey].fileMetaData.fileName = random + ".png";
    newAgents[currentAgentKey].fileMetaData.base64Data = imgBase64;
    newAgents[currentAgentKey].fileMetaData.recordType = "AGENT_IMAGE";
    newAgents[currentAgentKey].fileMetaData.s3FileName = "";
    newAgents[currentAgentKey].fileMetaData.tempUrl = dataURL;
    this.setState({imageToCrop : null});
    this.props.stateDataSave("Agents", newAgents, () => {
      // if (this.props.navSave) this.props.setIsDirty(true);
    });
    this.modalAction();
  };



  componentDidUpdate(prevProps) {
    if (
      Object.keys(prevProps.dataToSave).length == 0 &&
      Object.keys(this.props.dataToSave).keys().length !== 0
    )
      if (
        this.props.dataToSave.Agents &&
        this.props.dataToSave.Agents.length > 0
      )
        this.setState({ editAgents: false });
    if (
      this.props.dataToSave.Agents &&
      prevProps.dataToSave.Agents !== this.props.dataToSave.Agents
    ) {
      this.setState({ agents: this.props.dataToSave.Agents });
    }
  }
  async saveAgents(callback = null) {
    let dataToSave = this.props.dataToSave["Agents"];
    let agentObj = {
      clientToken: this.state.token,
      Agents: dataToSave,
    };
    this.props.setIsDirty(true);
  }

  // getBase64Image(img) {
  //   var canvas = document.createElement("canvas");
  //   canvas.width = img.width;
  //   canvas.height = img.height;
  //   var ctx = canvas.getContext("2d");
  //   ctx.drawImage(img, 0, 0);
  //   var dataURL = canvas.toDataURL("image/png");
  //   return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  // }
  getBase64Image = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
        if (encoded.length % 4 > 0) {
          encoded += "=".repeat(4 - (encoded.length % 4));
        }
        resolve(encoded);
      };
      reader.onerror = (error) => reject(error);
    });
  };

  createImage = (url) => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      //image.setAttribute( 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
      image.src = url;
    });
  };

  getImage = async (file, name) => {
    // const File = await this.resizeFile(file[0]);
    const File = file[0];
    this.setState({
      [name + "Error"]: "",
    });
    let dataURL = URL.createObjectURL(File);
    const imageToValidate = await this.createImage(dataURL);

    // if (imageToValidate.width > 150 || imageToValidate.height > 150) {
    //   this.setState({
    //     [name + "Error"]: "Ensure your file max width is: 150 x 150",
    //   });
    //   return false;
    // }
    if (!utility.imageAcepted(File)) {
      this.setState({
        [name + "Error"]:
          "Invalid format. Ensure your file format is: jpeg, png or svg",
      });
      return false;
    }
    if (!utility.fileSizeMax(File.size)) {
      this.setState({
        [name + "Error"]: "The image size should not exceed 70MB",
      });
      return false;
    }

    // if (name === "agentImage") {
    //   this.setState({
    //     shapeCrop: "round",
    //     aspect: 3 / 3,
    //   });
    // } else {
      this.setState({
        shapeCrop: "rect",
        aspect: 1 / 1,
      });
    // }
    this.setState({
      nameImputFile: name,
    });
    await this.togglePopoverActive();

    // const agent = this.props.dataToSave["Agents"] || [];
    // let { currentAgentKey } = this.state;
    // let newAgents = [...agent];
    // let imgBase64 = await this.getBase64Image(File);
    // const random = utility.string("a", 10);
    // newAgents[currentAgentKey].fileMetaData = {};
    // newAgents[currentAgentKey].fileMetaData.fileName = random + ".png";
    // // newAgents[currentAgentKey].fileMetaData.base64Data = imgBase64;
    // newAgents[currentAgentKey].fileMetaData.recordType = "AGENT_IMAGE";
    // newAgents[currentAgentKey].fileMetaData.s3FileName = "";
    // newAgents[currentAgentKey].fileMetaData.tempUrl = dataURL;
    this.setState({imageToCrop : dataURL});
    this.modalAction();
    // this.props.stateDataSave("Agents", newAgents, () => {
    //   // if (this.props.navSave) this.props.setIsDirty(true);
    // });
  };

  resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        70,
        70,
        "PNG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file",
        70,
        70
      );
    });

  onEditAgentsCancel(e) {
    // let { currentAgentKey, currentAgentToken } = this.state;
    // let currentData = this.props.dataToSave["Agents"][currentAgentKey];
    // if (!currentAgentToken && (!currentData || !currentData.hasAdded)) {
    //   this.deleteAgent(currentAgentKey);
    // }
    this.setState({
      editAgents: false,
      currentAgentEmail: "",
      currentAgentName: "",
      currentAgentPhone: "",
      currentAgentToken: null,
      currentAgentKey: null,
      currentEmailKey: null,
      currentEmail: "",
    });
  }
  onEditAgents(FullName, EmailAddress, MobilePhoneNumber, Token, key) {
    this.setState({
      editAgents: true,
      currentAgentName: FullName,
      currentAgentEmail: EmailAddress,
      currentAgentPhone: MobilePhoneNumber,
      currentAgentToken: Token,
      currentAgentKey: key,
    });
  }

  addAgent = () => {
    const newAgent = {
      FullName: "",
      EmailAddress: "",
      MobilePhoneNumber: "",
    };
    const agent = this.props.dataToSave["Agents"] || [];

    agent.unshift(newAgent);
    this.props.stateDataSave("Agents", agent, () => {
      if (this.props.navSave) this.props.setIsDirty(true);
      // this.onEditAgentsCancel(null);
      this.onEditAgents("", "", null, null, 0);
    });
  };

  addButtonPress = () => {
    if (
      this.state.agents.length >= Config.maxAgents &&
      this.state.currentAgentKey === null
    ) {
      this.props.setIncomplete(
        "No more users can be added",
        "Currently, the maximum number of users is " + Config.maxAgents + "."
      );
      this.onEditAgentsCancel(null);
      return;
    }
    console.log({state: this.state});
    /*Checks whether an agent should be saved.*/
    if (
      this.state.editAgents &&
      this.state.currentAgentName &&
      this.state.currentAgentName.trim() &&
      this.state.currentAgentEmail &&
      this.state.currentAgentEmail.trim() &&
      this.state.currentAgentPhone &&
      this.state.currentAgentPhone.trim()
    ) {
      var unverified = false;
      utility.verifyInput("email", this.state.currentAgentEmail, () => {
        unverified = true;
      });
      utility.verifyInput("phone", this.state.currentAgentPhone, () => {
        unverified = true;
      });
      if (unverified) {
        this.setState({ editAgents: true });
        this.props.setIncomplete(
          "We could not add your user",
          "Please make sure to enter a name, valid email and phone number for your TextChat User."
        );
        return;
      }
      const agent = this.props.dataToSave["Agents"] || [];
      let newAgent = {
        FullName: this.state.currentAgentName.trim(),
        EmailAddress: this.state.currentAgentEmail.trim(),
        MobilePhoneNumber: this.state.currentAgentPhone.trim(),
        hasAdded: true,
      };
      if (this.state.currentAgentToken)
        newAgent["Token"] = this.state.currentAgentToken;

      agent[this.state.currentAgentKey] = {
        ...agent[this.state.currentAgentKey],
        ...newAgent,
      };

      this.props.stateDataSave("Agents", agent, () => {
        if (this.props.navSave) this.props.setIsDirty(true);
        this.onEditAgentsCancel(null);
      });
    } else if (this.state.editAgents) {
      this.props.setIncomplete(
        "We could not add your user",
        "Please make sure to enter a name, valid email and phone number for your TextChat User."
      );
      this.setState({ editAgents: true });
    } else {
      this.addAgent();
      this.setState({ editAgents: true });
    }
  };

  displayNumAgent = (keyElement) => {
    let ordNum = "st";
    if (keyElement === 2) {
      ordNum = "nd";
    } else if (keyElement === 3) {
      ordNum = "rd";
    } else if (keyElement >= 4) {
      ordNum = "th";
    }
    return keyElement + ordNum + " Live Chat User";
  };

  handleSearch = (value) => {
    this.setState({ searchText: value || "" });
  };

  filterAgent = () => {
    let filterList = this.props.dataToSave["Agents"] || [];
    let { searchText } = this.state;
    if (!searchText || searchText.trim() === "") return filterList;
    else {
      // filter list and set new list
      let newList = filterList.filter((item) => {
        return (
          (item.FullName &&
            item.FullName.toLowerCase().indexOf(
              searchText.trim().toLowerCase()
            ) !== -1) ||
          (item.email &&
            item.email.toLowerCase().indexOf(searchText.trim().toLowerCase()) !=
              -1) ||
          (item.MobilePhoneNumber &&
            item.MobilePhoneNumber.toLowerCase().indexOf(
              searchText.trim().toLowerCase()
            ) !== -1)
        );
      });

      return newList;
    }
  };
  deleteAgent(key) {
    const agent = this.props.dataToSave["Agents"];
    agent.splice(key, 1);
    this.props.stateDataSave("Agents", agent);
    this.onEditAgentsCancel(null);
    if (this.props.navSave) this.props.setIsDirty(true);
  }

  renderAgentImageOptions = () => {
    let agentsAvatar = [];
    let { currentAgentKey } = this.state;
    const agents = this.props.dataToSave["Agents"];
    agents.forEach((agent, key) => {
      let profilePicUrl =
        agent.fileMetaData && (agent.fileMetaData.url || agent.tempUrl);
      if (profilePicUrl && agentsAvatar.length < 4)
        agentsAvatar.push(
          <span
            className={`fileUploadPopoverItem ${
              currentAgentKey == key ? "selected" : ""
            }`}
          >
            <Avatar
              size="large"
              initials={agent.FullName[0]}
              name={agent.FullName}
              source={profilePicUrl || null}
            />
          </span>
        );
    });

    return agentsAvatar;
  };

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({ croppedAreaPixels });
  };


  render() {
    const {
      description,
      labelForm,
      name,
    } = this.props;
    let textField;
    let disabled = false;

    // let newTitle = tooltip ? (
    //   title
    // ) : (
    //   <div>
    //     <Tooltip active={false} content={tooltip}>
    //       <Icon source={CircleInformationMajor} />
    //     </Tooltip>
    //     <h3>{title}</h3>
    //   </div>
    // );
    let title = "Agent Manager";
    let agents = this.props.dataToSave["Agents"] || [];
    let notEditing = !this.state.editAgents;
    let noProps = !this.props.dataToSave.Agents;
    let propsButNoLength =
      this.props.dataToSave.Agents && this.props.dataToSave.Agents.length === 0;
    let hideCancel = notEditing || noProps || propsButNoLength;
    let displayAgentList = this.filterAgent();
    let imageToCrop = this.state.imageToCrop;
    let displayNumAgent = agents.length
      ? this.displayNumAgent(agents.length + 1)
      : labelForm;
    const emptyStateMarkup = (
      <EmptyState heading="No agents available"></EmptyState>
    );
    console.log(this.filterAgent());

    return (
      <div className="agentManager">
        <div className="heading">
          {this.props.noContent ? (
            <h3 className="custom-title-onlayout">{title}</h3>
          ) : (
            <span className="SetupCard">
              <Card title={title} sectioned>
                <span className="headerDescription">{description}</span>
              </Card>
            </span>
          )}
        </div>

        <Stack>
          <Stack.Item fill>
            {" "}
            <Button
              primary={null}
              disabled={this.state.editAgents}
              onClick={(e) => {
                this.addButtonPress();
              }}
            >
              <i className="fa fa-floppy-o" />
              {"Add New Agent"}
            </Button>
          </Stack.Item>
          <TextField
            value={this.state.searchText}
            placeholder={"Search Agents"}
            onChange={this.handleSearch}
          />
        </Stack>
        {/* <canvas id="canvasToRotate"></canvas> */}
          <BasicModal
            active={this.state.activeModal}
            handleChange={this.modalAction}
            actionBtnTitle="Crop"
            actionBtn={this.showCroppedImage}
            classBtn="invisible"
            float={true}
            body={
              <Cropper
                onCropComplete={this.onCropComplete}
                imageSrc={this.state.imageToCrop}
                aspect={this.state.aspect}
                shapeCrop={this.state.shapeCrop}
              />
            }
          />

        {this.state.errorImage}

        {!displayAgentList.length ? (
          emptyStateMarkup
        ) : (
          <ResourceList
            resourceName={{
              singular: "customer",
              plural: "customers",
            }}
            items={this.filterAgent()}
            renderItem={(item, key) => {
              const {
                id,
                FullName,
                MobilePhoneNumber,
                EmailAddress,
                Token,
                fileMetaData,
              } = item;
              let profilePicUrl =
                fileMetaData && fileMetaData.tempUrl
                  ? fileMetaData.tempUrl
                  : fileMetaData
                  ? fileMetaData.url
                  : null;
              let content;
              let keyElement = parseInt(key) + 1;
              let displayNumAgent = this.displayNumAgent(keyElement);
              let otherAgentInEditMode =
                this.state.editAgents && this.state.currentAgentToken !== Token;
              let inEditMode =
                this.state.editAgents && key == this.state.currentAgentKey;
              content = (
                <div
                  key={key}
                  className={`List-Element-container ${
                    keyElement % 2 ? "gray-bg" : ""
                  }`}
                >
                  {/* <label className="Polaris-Label__Text User-Number"><b>{displayNumAgent}</b></label> */}
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginRight: "12px",
                      }}
                    >
                      {inEditMode ? (
                        <Popover
                          active={this.state.popoverActive}
                          fluidContent
                          activator={
                            <span
                              className="selectedImageAvatar"
                              onClick={this.togglePopoverActive}
                            >
                              <Avatar
                                size="large"
                                initials={FullName[0]}
                                name={FullName}
                                source={profilePicUrl || null}
                                customer={true}
                              />
                              <div style={{marginTop: "4px"}}><Subheading element="h4">Add Picture</Subheading></div>
                            </span>
                          }
                          onClose={this.togglePopoverActive}
                        >
                          <div className="fileUploadPopover">
                            {this.renderAgentImageOptions()}
                            <div style={{ width: 50, height: 50 }}>
                              <DropZone
                                onDropAccepted={async (file) => {
                                  await this.getImage(file, "agentImage");
                                }}
                              >
                                <DropZone.FileUpload />
                              </DropZone>
                            </div>
                          </div>
                        </Popover>
                      ) : (
                        <Avatar
                          size="large"
                          initials={FullName[0]}
                          name={FullName}
                          source={profilePicUrl || null}
                          customer={true}
                        />
                      )}
                    </div>
                    <div style={{ flex: 1 }}>
                      {inEditMode ? (
                        <>
                          <TextField
                            // label={<b>{displayNumAgent || ""}</b>}
                            value={this.state.currentAgentName}
                            placeholder={"Full Name"}
                            onChange={(e) =>
                              this.setState({ currentAgentName: e })
                            }
                            align={"left"}
                            inputMode="text"
                          />
                          <TextField
                            value={normalizeInput(this.state.currentAgentPhone)}
                            placeholder={"(000) 000 - 0000"}
                            onChange={this.handleCurrentAgentPhoneChange}
                            inputMode="tel"
                          />
                          <TextField
                            value={this.state.currentAgentEmail}
                            placeholder={"Email address"}
                            onChange={(e) =>
                              this.setState({ currentAgentEmail: e })
                            }
                            inputMode="email"
                          />
                        </>
                      ) : (
                        <>
                          <h3>
                            <TextStyle variation="strong">{FullName}</TextStyle>
                          </h3>
                          <div>{normalizeInput(MobilePhoneNumber)}</div>
                          <div>{EmailAddress}</div>
                        </>
                      )}
                    </div>

                    <div className="AgentEditIcons">
                      <div className="flexEnd">
                        <span
                          onClick={
                            otherAgentInEditMode
                              ? null
                              : (e) => {
                                  this.onEditAgents(
                                    FullName,
                                    EmailAddress,
                                    MobilePhoneNumber,
                                    Token,
                                    key
                                  );
                                }
                          }
                        >
                          <Icon
                            source={EditMajor}
                            color={
                              otherAgentInEditMode ? "subdued" : "blueDark"
                            }
                          />
                        </span>
                        <span
                          onClick={
                            otherAgentInEditMode
                              ? null
                              : (e) => this.deleteAgent(key)
                          }
                        >
                          <Icon
                            source={DeleteMajor}
                            color={otherAgentInEditMode ? "subdued" : "redDark"}
                          />
                        </span>
                      </div>

                      {/* action buttons */}

                      {inEditMode && (
                        <div className="editActionButtons">
                          <span>
                            <Button
                              onClick={(e) => {
                                this.addButtonPress();
                              }}
                            >
                              {"Save"}
                            </Button>
                          </span>
                          <span>
                            <Button
                              onClick={this.onEditAgentsCancel.bind(this)}
                              // onClick={!key ?(e) => this.deleteAgent(key) :  this.onEditAgentsCancel.bind(this)}
                            >
                              {"Cancel"}
                            </Button>
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );

              return (
                <ResourceItem
                  id={id}
                  accessibilityLabel={`View details for ${name}`}
                >
                  {content}
                </ResourceItem>
              );
            }}
          />
        )}
      </div>
    );
  }
}
