import React, { useState } from "react";
import AnswerNet_logo from "../../images/answerNet_logo_02.png";
import Config from "../../config.js";
import Loader from "./Loader";
import axios from "axios";

const serverAPI = Config.apiGateway.URL;
axios.defaults.headers.common["Authorization"] = Config.backendGUID;

const AccountMode = () => {
  const [clientToken, setClientToken] = useState();
  const [isClientTokenError, setIsClientTokenError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [modeSelected, setModeSelected] = useState("");
  const [errorText, setErrorText] = useState(false);
  const [isTestMode, setIsTestMode] = useState(false);
  const [clientName, setClientName] = useState();

  const onSubmit = async () => {
    setIsLoading(true);
    let isValid = validateInputs();
    if (!isValid) {
      console.log("Not valid");
      return;

      // let isTextable = await isPhoneNumberTextable(clientToken);
      // setIsNumberTextable(isTextable);
    }
    try {
      console.log("onSubmit: ", clientToken);
      let isTestMode = await checkTestMode(clientToken);
      setIsTestMode(isTestMode);
      setIsSubmitted(true);
      setIsLoading(false);
    } catch (ex) {
      console.log("Some error occurred");
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const validateInputs = () => {
    let hasError = false;
    setErrorText(false);

    let errorField = "";
    if (!clientToken) {
      // setInvalidAction(true);
      hasError = true;
      errorField = "Landline Number";
      setIsClientTokenError(true);
    }
    if (hasError) {
      //   setInvalidAction(true);
      const errorText = `Please include "${errorField}" in order to proceed.`;
      setErrorText(errorText);
      return false;
    }
    return true;
  };
  
  const checkTestMode = async (clientToken) => {
    return await axios
      .get(
        serverAPI + `/app/account/get?token=${clientToken}`
      )
      .then((res) => {
        setClientName(res.data.client.clientName);
        return res.data.client.isTestMode;
      })
  };

  const toggleTestMode = async (clientToken, isTestMode) => {
    return await axios
      .post(
        serverAPI + `/app/setIsTestMode?clientToken=${clientToken}&isTestMode=${isTestMode}`
      )
      .then((res) => {
        return res.data;
      })
  }


  const onModeChange = async (modeSelected) => {
    let isTest = "test" === modeSelected;
    await toggleTestMode(clientToken, isTest)
    setModeSelected(modeSelected);
    setIsTestMode(isTest);
  };

  return (
    <>
      <div className="account-mode ">
        <div className="logo-wrpper">
          <img className="logo" src={AnswerNet_logo} alt="" />
        </div>
        <div className="mt-30 font-18 mb-20 text-bold ">
          {!isSubmitted
            ? "Please input Client Token below to check client status"
            : `Client Name: ${clientName}`}
        </div>
        <div className="text-center mt-10 font-14 mb-20">
          {isSubmitted && `This client is currently ${!isTestMode ? "in Live Mode" : "in Test Mode"}. Click below to change the client to Test/Live Mode:`}
        </div>
        <div className={isSubmitted ? " " : "d-flex"}>
          {!isSubmitted ? (
            <input
              type="text"
              name="clientToken"
              className={`input-control ${
                isClientTokenError ? "input-error" : ""
              }`}
              placeholder="Input Client Token"
              value={clientToken}
              onChange={(e) => setClientToken(e.target.value)}
            />
          ) : (
            <div className="form-group btn-wrapper">
              <input
                type="button"
                value="Test Mode"
                className="btn-submit btn-test"
                onClick={() => onModeChange("test")}
              />
              <input
                type="button"
                value="Live Mode"
                className="btn-submit btn-live"
                onClick={() => onModeChange("live")}
              />
            </div>
          )}
          {/* </div> */}
          {/* <div className="justify-content-center"> */}

          {isLoading ? (
            <Loader />
          ) : (
            <input
              type="button"
              value="Submit"
              className="btn-submit"
              onClick={onSubmit}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default AccountMode;
