import React from "react";
import LinearProgress from "@material/react-linear-progress";

import EFLeftSideWrapper from "./EFLeftSideWrapper";
import EFFooter from "./EFFooter";
import AnswerNetSignUp from "./AnswerNetSignUp";
import { useParams } from "react-router-dom";
import AnswerNet_logo from "../../images/answerNet_logo.png";
import SignUpTextable from "./SignUpTextable";
import SignUpNonTextable from "./SignUpNonTextable";
import LandingPage from "./LandingPage";
import Complete from "./Complete";
import CompleteOne from "./CompleteOne";
import Pricing from "./Pricing";
import AgentTransfer from "./AgentTransfer";
import ExistingWithNewCpy from "./ExistingWithNewCpy";
// import NoSmsHosting from "./NoSmsHosting";
import PricingNoLiveAgents from "./PricingNoLiveAgents";
import SelectNewNumber from "./SelectNewNumber";
import WebsiteURL from "./WebsiteURL";
import { Alert } from "reactstrap";
// import PlanSelection from "./PlanSelection";
import SignUpLOA from "./SignUpLOA";

import "@material/react-linear-progress/dist/linear-progress.css";
import AccountSetup from "./AccountSetup";

const EFContainer = (props) => {
  let params = useParams();

  const rightSideContent = () => {
    switch (params.form) {
      case "answernet-signup":
        return <AnswerNetSignUp />;
      case "signup-textable":
        return <SignUpTextable />;
      case "signup-nontextable":
        return <SignUpNonTextable />;
      case "landingpage":
        return <LandingPage />;
      case "complete":
        return <Complete />;
      case "completeOne":
        return <CompleteOne />;
      case "existing-with-new-copy":
        return <ExistingWithNewCpy />;
      // case "no-sms-hosting":
      //   return <NoSmsHosting />;
      case "pricing":
        return <Pricing serverAPI={props.serverAPI} />;
      case "pricing-no-live-agents":
        return <PricingNoLiveAgents serverAPI={props.serverAPI} />;
      case "select-new-number":
        return <SelectNewNumber />;
      case "agent-transfer":
        return <AgentTransfer />;
      case "website-url":
        return <WebsiteURL />;
      // case "plan-selection":
      //   return <PlanSelection />;
      case "signup-loa":
        return <SignUpLOA />;
      case "account-setup":
        return <AccountSetup />;
      // case "terms-of-service":
      //   return <TermsOfService />;

      default:
        return (
          <>
            {/* <Alert>Please sign up first</Alert> */}
            <AnswerNetSignUp />;
          </>
        );
    }
  };

  let progress = 0;

  const isFinalStep = JSON.parse(localStorage.getItem("final-step"));
  if (
    params.form === "signup-textable" ||
    params.form === "signup-nontextable"
  ) {
    if (!isFinalStep) {
      progress = 0.16;
    } else {
      progress = 0.62;
    }
  }
  if (params.form === "agent-transfer") {
    progress = 0.32;
  }

  if (params.form === "account-setup") {
    progress = 0.48;
  }
  if (
    params.form === "existing-with-new-copy" ||
    params.form === "select-new-number"
  ) {
    progress = 0.78;
  }
  if (params.form === "complete" || params.form === "complete-one") {
    progress = 1;
  }
  return (
    <>
      <section>
        <div className="row top-container">
          <div className="wrapper">
            <EFLeftSideWrapper />
            {/* style={{params.form === "answernet-signup"}} */}
            <div
              className="col-6 rightside"
              style={{
                paddingLeft: params.form == "answernet-signup" ? "0px" : "",
              }}
            >
              {/* <div
                className={`inner-wrapper max-width-full  ${
                  params.form === "complete" ? "" : ""
                }`}
              > */}
              <div
                className={`inner-wrapper max-width-full  ${
                  params.form === "signup-nontextable" ||
                  params.form === "signup-textable"
                    ? " pt-0"
                    : ""
                }`}
              >
                {/* {params.form !== "answernet-signup" && ( */}
                <div className="logo-wrpper">
                  <img className="logo" src={AnswerNet_logo} alt="" />
                </div>
                {/* )} */}
                {rightSideContent("/answernet-signup")}
                {/* {params.form !== "answernet-signup" && (
                  <div className="steps-wrapper">
                    <div className="steps">
                      <LinearProgress
                        progress={progress}
                        bufferingDots={false}
                      />
                    </div>
                  </div>
                )} */}
              </div>
              <EFFooter />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EFContainer;
