export const featureSelect = [
    {
        title: "",
        card: true,
        switchBtn: [
            {
                checked: true,
                value: true,
                disabled: true,
                label: "TextChat",
                help: "You're signed up to receive text notifications for customer requests."
            }
        ]
    }
];
export const featureSelectDescription = [
    {
        text: "Choose the channels you want to use TextChat with: more coming soon!",
    }
];