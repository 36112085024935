import React, { Component } from "react";
import { Card, Page, Layout, DropZone, Avatar, Button } from "@shopify/polaris";
import axios from "axios";
import RichTextEditor from "../../../General/RichTextEditor";
import BasicModal from "../../../Frame/Modal/BasicModal";
import Cropper from "../../../General/Crop/Cropper";
import getCroppedImg from "../../../General/Crop/cropImage";
import "./style.scss";
import ColorPicker from "../../../General/ColorPicker/ColorPicker";
import utility from "../../../General/utility";
import backendUtility from "../../../General/backendUtility";
import cookie from "js-cookie";
import Resizer from "react-image-file-resizer";
import EffortlessPopup from "./EffortlessPopup1";
import CustomizablePopUp from "./CustomizablePopUp1";
import ClassicPopUp from "./ClassicPopUp1";
import EffortlessChatWindow from "./EffortlessChatWindow";
import CustomizableChatWindow from "./CustomizableChatWindow";
import StepRangeSlider from "react-step-range-slider";
import TextFieldInput from "../../../General/TextFieldInput/TextFieldInput";
import { Icon } from "@shopify/polaris";
import { CircleInformationMajor } from "@shopify/polaris-icons";
import "./ChatWidget.scss";

const rangeValues = [
  { value: 15, step: 15 }, // acts as min value
  { value: 30, step: 15 },
  { value: 45, step: 15 },
  { value: 60, step: 15 },
  { value: 75, step: 15 },
  { value: 90, step: 15 },
  { value: 105, step: 15 }, // acts as max value
];

const AColorPicker = require("a-color-picker");

const defaultColors = {
  widgetColor: "#ffffff80",
  widgetTextColor: "#000000",
  agentBubbleColor: "#ffffff",
  agentTextColor: "#313131",
  clientBubbleColor: "#ffffff",
  clientTextColor: "#313131",
};

class ChatWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      popUpMessage: "",
      activeModal: false,
      imageSrc: "",
      croppedAreaPixels: null,
      nameImputFile: "",
      errorImage: "",
      updloadedFiles: {},
      aspect: false,
      shapeCrop: false,
      headerLogo:
        "https://cdn.shopify.com/s/files/1/0446/6937/files/jaded-pixel-logo-color.svg?6215648040070010999",
      iconChatbot: "",
      iconChatbotError: "",
      headerLogoError: "",
      widgetTextColor: "#000",
      clientBtnColor: "#fff",
      clientBtnColorText: "#0b24fb",
      clientBtnColorHover: "#0b24fb",
      clientBtnColorHoverText: "#fff",
      widgetColor: "#ffffff",
      agentBubbleColor: "#ffffff",
      agentTextColor: "#313131",
      clientBubbleColor: "#ffffff",
      clientTextColor: "#313131",
      loaderBubble: "#007bf5",
      accountDetail: null,
      proactive: true,
      availableMessage: "",
      busyMessage: "",
      afterHoursMessage: "",
      greetingMessages: {
        availableMessage: "",
        busyMessage: "",
        afterHoursMessage: "",
      },
    };
    this.modalAction = this.modalAction.bind(this);
    this.showCroppedImage = this.showCroppedImage.bind(this);
    this.openPicker = this.openPicker.bind(this);

    this.pickerWidgetColor = React.createRef();
    this.pickerAgentBubble = React.createRef();
    this.pickerAgentTextColor = React.createRef();
    this.pickerClientBubble = React.createRef();
    this.pickerClientTextColor = React.createRef();
    this.pickerWidgetTextColor = React.createRef();
    this.pickerClientBtn = React.createRef();
    this.pickerClientBtnText = React.createRef();
    this.pickerClientBtnHover = React.createRef();
    this.pickerClientBtnHoverText = React.createRef();
    this.pickerLoaderBubble = React.createRef();
  }

  async componentDidMount() {
    console.log({ props: this.props.widget });
    this.colorPicker();
    console.log(this.props.setIsDirty());
    if (this.props.widget && this.props.widget.colors) {
      let colorsObj = {};
      for (let color in this.props.widget.colors) {
        colorsObj[color[0].toLowerCase() + color.substr(1)] =
          this.props.widget.colors[color];
      }
      this.setState(colorsObj);
    }
    let token = await this.loadAPIToken();
    await this.getAccountInfo(token);

    await Promise.all([
      this.getImageFromApi(token),
      this.getProactive(token),
      this.getGreetingMessages(),
    ]);

    console.log({ widgetDetails: this.state.widgetDetails });
    this.setState({ isLoading: false });
  }

  loadAPIToken = () => {
    return new Promise(async (res, rej) => {
      let token = await backendUtility.getToken(
        cookie.get("shop"),
        this.props.serverAPI
      );
      await this.setState({ token }, () => {
        res(token);
      });
    });
  };

  getAccountInfo = (token) => {
    return new Promise(async (res, rej) => {
      try {
        let { data } = await axios.get(
          this.props.serverAPI + `/app/account/get?token=${token}`
        );
        let campaign = data.campaign;
        await this.setState(
          { campaignID: (campaign && campaign.id) || null, campaign },
          () => {
            res((campaign && campaign.id) || null);
          }
        );
      } catch (error) {
        this.setState(
          {
            hasError: true,
            errorText:
              "We're sorry, there was an error processing your request",
            campaignID: null,
          },
          () => {
            rej();
          }
        );
      }
    });
  };

  getProactive = async (token) => {
    let isProactive = await axios
      .get(`${this.props.serverAPI}/app/Widget/proactive`, {
        params: {
          token: token,
        },
      })
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        this.props.setError(error.response);
        return false;
      });
    if (isProactive === false || isProactive === "false") {
      this.setState({ proactive: false });
    }
    if (isProactive === true || isProactive === "true") {
      this.setState({ proactive: true });
    }
  };

  // saveProactive = async () => {
  //   let { token } = this.state;

  //   await axios
  //     .put(`${this.props.serverAPI}/app/Widget/setProactive`, {
  //       clientToken: token,
  //       isProactive: this.state.proactive,
  //     })
  //     .then((resp) => {
  //       return resp;
  //     })
  //     .catch((error) => {
  //       this.props.setError(error.response);
  //     });
  // };

  getImageFromApi = async (token) => {
    const widgetDetails = await axios
      .get(this.props.serverAPI + "/app/WidgetDetails/Get/", {
        params: {
          token: token,
        },
      })
      .then((resp) => {
        return resp.data;
      });

    if (widgetDetails) {
      if (!widgetDetails.headerLogo) {
        widgetDetails.headerLogo = {
          recordType: "LOGO_IMAGE",
          base64Data: "",
          fileName: null,
          token: token,
          s3FileName: null,
        };
      } else if (widgetDetails.headerLogo.s3FileName) {
        let headerIcon =
          this.props.bucketCDN + widgetDetails.headerLogo.s3FileName;
        this.setState({
          headerLogo: headerIcon,
        });
      }
      if (!widgetDetails.chatbotIcon) {
        widgetDetails.chatbotIcon = {
          recordType: "CHATBOT_IMAGE",
          base64Data: "",
          fileName: null,
          token: token,
          s3FileName: null,
        };
      } else if (widgetDetails.chatbotIcon.s3FileName) {
        let chatbotIcon =
          this.props.bucketCDN + widgetDetails.chatbotIcon.s3FileName;
        this.setState({
          iconChatbot: chatbotIcon,
        });
      }
    }
    // const iconChatbotImage = localStorage.getItem("iconChatbot");
    // const headerIcon = localStorage.getItem("headerLogo");

    if (widgetDetails["isProactivePopup"] !== undefined)
      delete widgetDetails["isProactivePopup"];
    if (widgetDetails["proactiveDelay"] !== undefined)
      delete widgetDetails["proactiveDelay"];
    if (widgetDetails["proactiveSecondDelay"] !== undefined)
      delete widgetDetails["proactiveSecondDelay"];

    this.setState({
      clientToken: token,
      accountDetail: widgetDetails,
      ...widgetDetails,
    });
  };

  toggleShowPopup = (value) => {
    this.props.setIsDirty(true);
    this.setState({ showPromptMessage: Boolean(value) });
  };

  inputHandler = (key, value) => {
    let updateField = {};
    updateField[key] = value;
    this.props.setIsDirty(true);
    this.setState(updateField);
  };

  saveAllGreetingMessage = async () => {
    try {
      let promises = [];
      let {
        greetingMessages,
        busyMessage,
        afterHoursMessage,
        availableMessage,
      } = this.state;
      if (availableMessage !== greetingMessages.availableMessage) {
        promises.push(this.saveGreetingMessage("Available", availableMessage));
      }
      if (busyMessage !== greetingMessages.busyMessage) {
        promises.push(this.saveGreetingMessage("Busy", busyMessage));
      }
      if (afterHoursMessage !== greetingMessages.afterHoursMessage) {
        promises.push(
          this.saveGreetingMessage("AfterHours", afterHoursMessage)
        );
      }

      await Promise.all(promises);
      this.setState({
        greetingMessages: { availableMessage, afterHoursMessage, busyMessage },
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  saveGreetingMessage = async (scheduleStatus, message) => {
    let { clientToken, campaignID } = this.state;
    await axios
      .post(this.props.serverAPI + "/app/greetingMessages/Upsert", {
        clientToken,
        campaignID,
        scheduleStatus,
        message,
      })
      .then(async (resp) => {
        this.setState({
          accountDetail: {
            ...this.state.accountDetail,
            [scheduleStatus]: message,
          },
        });
      })
      .catch((error) => {
        this.props.setError(error.response);
      });
  };

  saveAll = async () => {
    let accountDetail = this.state.accountDetail;
    accountDetail = { ...accountDetail };

    accountDetail = await this.saveImage(accountDetail);
    accountDetail = await this.saveColors(accountDetail);
    accountDetail.clientToken = cookie.get("textchatapi");

    // v2 changes
    accountDetail.holdTime = this.state.holdTime;
    accountDetail.chatBotType = this.state.chatBotType;
    accountDetail.showPromptMessage = this.state.showPromptMessage;
    accountDetail.promptMessage = this.state.promptMessage;
    accountDetail.chatbotHeaderDescription =
      this.state.chatbotHeaderDescription;
    accountDetail.chatbotHeaderSlogan = this.state.chatbotHeaderSlogan;
    accountDetail.displayName = this.state.displayName;

    await this.saveAllGreetingMessage();
    await axios
      .put(this.props.serverAPI + "/app/WidgetDetails/Upsert", accountDetail)
      .then(async (resp) => {
        console.log(accountDetail);
        this.setState({
          accountDetail: accountDetail,
        });
        if (this.props.confirmSaved) this.props.confirmSaved();
        return resp.data;
      })
      .catch((error) => {
        console.error(error);
        this.props.setError(error.response);
      });
  };
  saveColors = async (chatbotDetail) => {
    let accountDetail = chatbotDetail;
    const chatbotStyles =
      `:root {` +
      `--main-btn-color: ${this.state.clientBtnColor};` +
      `--main-btn-color-text: ${this.state.clientBtnColorText};` +
      `--main-btn-active-color: ${this.state.clientBtnColorHover};` +
      `--main-btn-active-color-text: ${this.state.clientBtnColorHoverText};` +
      `--main-bubble-color-agent: ${this.state.agentBubbleColor};` +
      `--mail-bubble-loader-agent: ${this.state.agentBubbleColor};` +
      `--main-bubble-color-text-user: ${this.state.clientTextColor};` +
      `--main-bubble-color-user: ${this.state.clientBubbleColor};` +
      `--main-ellipsis-color: ${this.state.loaderBubble};` +
      `--main-bubble-color-text-agent: ${this.state.agentTextColor};` +
      `}`;
    const widgetStyles =
      `:root {` +
      `--widget-background-color: ${this.state.widgetColor};` +
      `--widget-text-color: ${this.state.widgetTextColor};` +
      `--chat-btn-color: ${this.state.widgetColor};` +
      `}`;
    accountDetail.chatbotStyles = chatbotStyles;
    accountDetail.widgetStyles = widgetStyles;
    const objColorToSave = {
      colors: {
        widgetColor: this.state.widgetColor,
        agentBubbleColor: this.state.agentBubbleColor,
        agentTextColor: this.state.agentTextColor,
        clientBubbleColor: this.state.clientBubbleColor,
        clientTextColor: this.state.clientTextColor,
        clientBtnColor: this.state.clientBtnColor,
        clientBtnColorText: this.state.clientBtnColorText,
        clientBtnColorHover: this.state.clientBtnColorHover,
        clientBtnColorHoverText: this.state.clientBtnColorHoverText,
        loaderBubble: this.state.loaderBubble,
        widgetTextColor: this.state.widgetTextColor,
      },
      shop: this.props.shop,
    };
    console.log("colors: " + JSON.stringify(objColorToSave));
    await axios
      .post(
        this.props.serverAPI + "/app/save/chatwidget/colors",
        objColorToSave
      )
      .then((resp) => {
        console.log(resp.data);
      })
      .catch((error) => {
        this.props.setError(error.response);
      });
    return accountDetail;
  };
  colorPicker = () => {
    AColorPicker.from(".picker").on("change", (picker, color) => {
      const pickerContent = picker.element.parentNode;
      let arr = pickerContent.getAttribute("data-config");
      const newColor = AColorPicker.parseColor(color, "hex");
      if (arr) {
        this.props.setIsDirty(true);
        this.setState({
          [arr]: newColor,
        });
      }
    });
  };
  openPicker(name) {
    let picker;

    if (name === "agentBubbleColor") {
      picker = this.pickerAgentBubble.current;
    } else if (name === "clientBubbleColor") {
      picker = this.pickerClientBubble.current;
    } else if (name === "agentTextColor") {
      picker = this.pickerAgentTextColor.current;
    } else if (name === "clientTextColor") {
      picker = this.pickerClientTextColor.current;
    } else if (name === "clientBtnColor") {
      picker = this.pickerClientBtn.current;
    } else if (name === "clientBtnColorText") {
      picker = this.pickerClientBtnText.current;
    } else if (name === "clientBtnColorHover") {
      picker = this.pickerClientBtnHover.current;
    } else if (name === "clientBtnColorHoverText") {
      picker = this.pickerClientBtnHoverText.current;
    } else if (name === "loaderBubble") {
      picker = this.pickerLoaderBubble.current;
    } else if (name === "widgetTextColor") {
      picker = this.pickerWidgetTextColor.current;
    } else {
      picker = this.pickerWidgetColor.current;
    }

    if (picker) {
      if (picker.style.display === "block") {
        picker.style.display = "none";
      } else {
        picker.style.display = "block";
      }
    }
  }

  modalAction() {
    this.setState({
      activeModal: !this.state.activeModal,
    });
  }

  handleChange(e, name) {
    this.props.setIsDirty(true);
    this.setState({ [name]: e });
  }

  componentDidUpdate(prevProps) {
    const { shouldSave, discart } = this.props;
    if (shouldSave && shouldSave !== prevProps.shouldSave) {
      this.saveAll();
    }
    if (discart && discart !== prevProps.discart) {
      this.props.changesDiscarded();
      this.rollbackState();
    }
  }

  rollbackState = () => {
    let { accountDetail, greetingMessages } = this.state;
    this.setState({
      ...accountDetail,
      ...greetingMessages,
    });
  };

  resetColorSettings = () => {
    this.setState(defaultColors);
    this.props.setIsDirty(true);
  };

  resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        150,
        150,
        "PNG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file",
        50,
        50
      );
    });

  getGreetingMessages = async () => {
    let { token, campaignID } = this.state;

    let greetingMessages = await axios
      .get(`${this.props.serverAPI}/app/greetingMessages`, {
        params: {
          clientToken: token,
          campaignID: campaignID,
        },
      })
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        this.props.setError(error.response);
        return false;
      });
    // //TODO:  remove later
    // return;
    if (!greetingMessages) greetingMessages = {};
    this.setState({ greetingMessages, ...greetingMessages });
  };

  handleChatbotHeaderDescriptionChange = (value) => {
    this.inputHandler("chatbotHeaderDescription", value);
  };

  setSelectedPopUp = (value) => {
    this.props.setIsDirty(true);
    this.setState({ chatBotType: value });
  };
  render() {
    let { chatBotType } = this.state;
    return (
      <div className="page-custom-widget chatWidget">
        <Page
          separator
          title="Delegated Answering"
          subtitle={
            <b>
              Customize your chat widget and chat window to reflect your brand.
            </b>
          }
        >
          <Layout>
            <Layout.Section>
              <div>
                <div className="chatWidgetSection hidden">
                  <div className="row">
                    <div className="col-sm-6 customize-chatbot-select">
                      <label
                        className="radio-container"
                        onClick={() => {
                          this.setSelectedPopUp("EFFORTLESS");
                        }}
                      >
                        <div className="form-check-label" for="effortlessPopup">
                          <h2 className="popupWindowHeading">
                            Effortless Popup
                          </h2>
                          <p>
                            This recomended popup is eye catching and ready for
                            your website as-is! Trendy glassmorphism and your
                            agents' face make this popup apporachable ans
                            stylish.
                          </p>
                        </div>
                        <input
                          className="form-check-input"
                          type="radio"
                          value="EFFORTLESS"
                          name="customizeChatbot"
                          id="effortlessPopup"
                          checked={this.state.chatBotType === "EFFORTLESS"}
                        />
                        <span className="checkmark" />
                      </label>
                      <label
                        className="radio-container"
                        onClick={() => {
                          this.setSelectedPopUp("CUSTOMIZABLE");
                        }}
                      >
                        <div className="form-check-label" for="effortlessPopup">
                          <h2 className="popupWindowHeading">
                            Customizable Popup
                          </h2>
                          <p>
                            This popup is customizable for users that like to
                            have a hand in their branding and design. Simplicity
                            and control comibe to make this the ideal popup for
                            a user with moderate experience.
                          </p>
                        </div>
                        <input
                          className="form-check-input"
                          type="radio"
                          value="CUSTOMIZABLE"
                          name="customizeChatbot"
                          id="customizablePopup"
                          checked={this.state.chatBotType === "CUSTOMIZABLE"}
                        />
                        <span className="checkmark" />
                      </label>
                      <label
                        className="radio-container"
                        onClick={() => {
                          this.setSelectedPopUp("CLASSIC");
                        }}
                      >
                        <div className="form-check-label" for="effortlessPopup">
                          <h2 className="popupWindowHeading">Classic Widget</h2>
                          <p>
                            This minimal widget is static and unobtrusive. If
                            simplicity is your vibe, the classic widget is for
                            you! This call to action is easy to understand and
                            will seamlessly fit on any website.
                          </p>
                        </div>
                        <input
                          className="form-check-input"
                          type="radio"
                          value="CLASSIC"
                          name="customizeChatbot"
                          id="classicWidget"
                          checked={this.state.chatBotType === "CLASSIC"}
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                    <div className="col-sm-6">
                      <div
                        className={chatBotType == "EFFORTLESS" ? "" : "hidden"}
                      >
                        <Layout.Section oneThird>
                          <EffortlessPopup
                            isSelected={chatBotType === "EFFORTLESS"}
                            showPromptMessage={this.state.showPromptMessage}
                            promptMessage={this.state.promptMessage}
                            onClick={() => {
                              this.setSelectedPopUp("EFFORTLESS");
                            }}
                          />
                        </Layout.Section>
                      </div>
                      <div
                        className={
                          chatBotType == "CUSTOMIZABLE" ? "" : "hidden"
                        }
                      >
                        <Layout.Section oneThird>
                          <CustomizablePopUp
                            isSelected={chatBotType === "CUSTOMIZABLE"}
                            showPromptMessage={this.state.showPromptMessage}
                            promptMessage={this.state.promptMessage}
                            textColor={
                              ["#ffffff", "#ffffff80"].indexOf(
                                this.state.widgetColor
                              ) > -1 && this.state.widgetTextColor == "#000000"
                                ? "#ffffff"
                                : this.state.widgetTextColor
                            }
                            buttonColor={
                              ["#ffffff", "#ffffff80"].indexOf(
                                this.state.widgetColor
                              ) == -1
                                ? this.state.widgetColor
                                : "#0B1F9C"
                            }
                            onClick={() => {
                              this.setSelectedPopUp("CUSTOMIZABLE");
                            }}
                          />
                        </Layout.Section>
                      </div>
                      <div className={chatBotType == "CLASSIC" ? "" : "hidden"}>
                        <Layout.Section oneThird>
                          <ClassicPopUp
                            isSelected={chatBotType === "CLASSIC"}
                            showPromptMessage={this.state.showPromptMessage}
                            promptMessage={this.state.promptMessage}
                            textColor={
                              ["#ffffff", "#ffffff80"].indexOf(
                                this.state.widgetColor
                              ) > -1 && this.state.widgetTextColor == "#000000"
                                ? "#ffffff"
                                : this.state.widgetTextColor
                            }
                            buttonColor={
                              ["#ffffff", "#ffffff80"].indexOf(
                                this.state.widgetColor
                              ) == -1
                                ? this.state.widgetColor
                                : "#0B1F9C"
                            }
                            onClick={() => {
                              this.setSelectedPopUp("CLASSIC");
                            }}
                          />
                        </Layout.Section>
                      </div>
                      <div className="form-group color-form-group">
                        <div className="picker-content chatbot-widget-color">
                          <div
                            className={
                              chatBotType == "EFFORTLESS" ? "hidden" : ""
                            }
                          >
                            <div className="text-color-content float-left">
                              <ColorPicker
                                forwarRef={this.pickerWidgetColor}
                                label="Button Color"
                                color={this.state.widgetColor}
                                name="widgetColor"
                                openPicker={this.openPicker}
                              />
                            </div>
                            <div className="text-color-content float-left mr-0">
                              <ColorPicker
                                forwarRef={this.pickerWidgetTextColor}
                                label="Text Color"
                                color={this.state.widgetTextColor}
                                name="widgetTextColor"
                                openPicker={this.openPicker}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {/* <div className="form-group color-form-group buttons-picker col-12 col-sm-12 col-lg-6 col-xl-6">
                    <TextFieldInput
                      style={{ marginLeft: "16px" }}
                      title={"Popup Message"}
                      name="promptMessage"
                      value={this.state.promptMessage}
                      placeholder="Hi, How can we help you?"
                      showCheckbox={true}
                      isChecked={this.state.showPromptMessage}
                      onCheckHandleChange={this.toggleShowPopup}
                      onValueChange={this.inputHandler}
                    />
                  </div> */}
                </div>
              </div>

              <Card sectioned>
                <Card.Section>
                  <div className="layoutWrapper chatBoxSection hidden">
                    <Layout>
                      <Layout.Section oneThird>
                        <div
                          className="row"
                          // style={{ padding: "0 15px" }}
                        >
                          <div className="form-group tc-icon-form-group">
                            <p className="subtitle-component bold">
                              Chatbot Icon
                            </p>
                            {this.state.iconChatbotError}
                            <div
                              className="drop-zone-container"
                              style={{ width: 120, height: 120 }}
                            >
                              <DropZone
                                type="image"
                                onDropAccepted={async (file) => {
                                  await this.getImage(file, "iconChatbot");
                                }}
                                style={{ zIndex: 0 }}
                              >
                                <DropZone.FileUpload />
                                <div className="image-drop-zone">
                                  <Avatar
                                    customer
                                    size="large"
                                    source={this.state.iconChatbot}
                                  />
                                </div>
                              </DropZone>
                              <img
                                id="iconChatbot"
                                className="invisible"
                                src={this.state.iconChatbot || "about:blank"}
                                alt="Icon Chatbot"
                              />
                            </div>
                            <p className="drop-zone-description">
                              This should be your company logo,
                              <br />
                              which represents the automated part <br />
                              of the conversation.
                            </p>
                          </div>
                          <div className="form-group color-form-group ">
                            <div className="picker-content">
                              <div
                                className={
                                  chatBotType == "EFFORTLESS" ? "hidden" : ""
                                }
                              >
                                <div className="text-color-content float-left">
                                  <p className="subtitle-component p-top-space">
                                    Chatbot Message
                                  </p>
                                  <ColorPicker
                                    forwarRef={this.pickerAgentBubble}
                                    label={"Bubble Color"}
                                    color={this.state.agentBubbleColor}
                                    name="agentBubbleColor"
                                    openPicker={this.openPicker}
                                  />
                                  <ColorPicker
                                    forwarRef={this.pickerAgentTextColor}
                                    label={"Text Color"}
                                    color={this.state.agentTextColor}
                                    name="agentTextColor"
                                    openPicker={this.openPicker}
                                  />
                                </div>
                                <div className="text-color-content float-left">
                                  <p className="subtitle-component p-top-space">
                                    Visitor Message
                                  </p>
                                  <ColorPicker
                                    forwarRef={this.pickerClientBubble}
                                    label={"Bubble Color"}
                                    color={this.state.clientBubbleColor}
                                    name="clientBubbleColor"
                                    openPicker={this.openPicker}
                                  />
                                  <ColorPicker
                                    forwarRef={this.pickerClientTextColor}
                                    label={"Text color"}
                                    color={this.state.clientTextColor}
                                    name="clientTextColor"
                                    openPicker={this.openPicker}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div
                            className="form-group color-form-group"
                            style={{ marginTop: "10px" }}
                          >
                            <TextFieldInput
                              title={"Header"}
                              name="displayName"
                              value={this.state.displayName || ""}
                              placeholder="Textchat"
                              onValueChange={this.inputHandler}
                            />
                            <TextFieldInput
                              title={"Slogan"}
                              name="chatbotHeaderSlogan"
                              value={this.state.chatbotHeaderSlogan || ""}
                              placeholder="Never miss a sale."
                              onValueChange={this.inputHandler}
                            />
                            {/* {chatBotType !== "EFFORTLESS" &&  */}

                            {/* <TextFieldInput
                              title={"Header Greeting"}
                              render = {<RichTextEditor
                                value= {this.state.chatbotHeaderDescription}
                                onChange = {this.handleChatbotHeaderDescriptionChange}
                                className = "header-greeting-rich-text-editor"
                                placeholder="Never miss a sale."
                              />}
                            /> */}
                          </div>
                        </div>
                      </Layout.Section>
                      <Layout.Section oneThird>
                        {/* {chatBotType === "EFFORTLESS" ? ( */}
                        <EffortlessChatWindow
                          header={this.state.displayName || "{Company Header}"}
                          slogan={
                            this.state.chatbotHeaderSlogan || "{Company Slogan}"
                          }
                          greeting={
                            this.state.chatbotHeaderDescription ||
                            "{ Greeting Text}"
                          }
                          headerLogo={this.state.headerLogo}
                          AgentBubbleColor={this.state.agentBubbleColor}
                          AgenttextColor={this.state.agentTextColor}
                          clientBubbleColor={this.state.clientBubbleColor}
                          clientTextColor={this.state.clientTextColor}
                          widgetTextColor={
                            chatBotType === "EFFORTLESS"
                              ? null
                              : this.state.widgetTextColor
                          }
                          chatbotWidgetColor={
                            chatBotType === "EFFORTLESS"
                              ? null
                              : this.state.widgetColor
                          }
                          chatBotIcon={this.state.iconChatbot}
                        />
                        {/* ) : (
                          <CustomizableChatWindow
                            header={
                              this.state.displayName || "{Company Header}"
                            }
                            slogan={
                              this.state.chatbotHeaderSlogan ||
                              "{Company Slogan}"
                            }
                            greeting={
                              this.state.chatbotHeaderDescription ||
                              "{ Greeting Text}"
                            }
                            AgentBubbleColor={this.state.agentBubbleColor}
                            AgenttextColor={this.state.agentTextColor}
                            clientBubbleColor={this.state.clientBubbleColor}
                            clientTextColor={this.state.clientTextColor}
                            chatbotWidgetColor={this.state.widgetColor}
                          />
                        )} */}
                        <div className="btn-content">
                          <Button
                            className="btnResetColorSetting"
                            onClick={this.resetColorSettings}
                          >
                            Reset Colors
                          </Button>
                        </div>
                      </Layout.Section>
                    </Layout>
                  </div>
                </Card.Section>
                {/* <hr /> */}
                <Card.Section title="Chat Settings">
                  <div className="picker-content">
                    <div className="row">
                      {/* <div className="form-group color-form-group buttons-picker col-6 col-sm-12 col-lg-6 col-xl-6">
                        <TextFieldInput
                          title={"Greeting Message: Available"}
                          name="availableMessage"
                          value={this.state.availableMessage || ""}
                          placeholder="We are online right now!"
                          onValueChange={this.inputHandler}
                        />
                      </div> */}
                      {/* <div className="form-group color-form-group buttons-picker col-6 col-sm-12 col-lg-6 col-xl-6">
                        <TextFieldInput
                          title={"Greeting Message: Busy"}
                          name="busyMessage"
                          value={this.state.busyMessage || ""}
                          placeholder="We are busy right now!"
                          onValueChange={this.inputHandler}
                        />
                      </div> */}
                      {/* <div className="form-group color-form-group buttons-picker col-6 col-sm-12 col-lg-6 col-xl-6">
                        <TextFieldInput
                          title={"Greeting Message: After Hours"}
                          name="afterHoursMessage"
                          value={this.state.afterHoursMessage || ""}
                          placeholder="We are currently Offline!"
                          onValueChange={this.inputHandler}
                        />
                      </div> */}
                      {!this.state.isLoading && (
                        <div className="form-group color-form-group col-6 col-sm-12 col-lg-6 col-xl-6">
                          <div>
                            <div className="chatHeaderBlock holdTimeContent">
                              <Icon source={CircleInformationMajor} />
                              <span className="chatHeaderInfo">Hold Time</span>
                            </div>
                            <p className="holdTimeContent">
                              Determine how long a visitor can wait to connect
                              to an agent before receiving a busy message. We
                              recommend 30 seconds.
                            </p>
                            <div style={{ marginTop: "42px" }}>
                              <StepRangeSlider
                                value={this.state.holdTime}
                                range={rangeValues}
                                onChange={(value) =>
                                  this.inputHandler("holdTime", value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Card.Section>
              </Card>
            </Layout.Section>
          </Layout>
          <canvas id="canvasToRotate"></canvas>
          <BasicModal
            active={this.state.activeModal}
            handleChange={this.modalAction}
            actionBtnTitle="Crop"
            actionBtn={this.showCroppedImage}
            classBtn="invisible"
            body={
              <Cropper
                onCropComplete={this.onCropComplete}
                imageSrc={this.state.imageSrc}
                aspect={this.state.aspect}
                shapeCrop={this.state.shapeCrop}
              />
            }
          />
        </Page>
      </div>
    );
  }

  /*
   * Crop image method
   */
  saveImage = async (chatbotDetail) => {
    const images = this.state.updloadedFiles;
    let accountDetail = chatbotDetail;
    for (const key in images) {
      if (images.hasOwnProperty(key)) {
        let bannerImage = document.getElementById(key);
        let imgBase64 = this.getBase64Image(bannerImage);
        const random = utility.string("a", 10);
        if (key === "iconChatbot") {
          accountDetail.chatbotIcon.fileName = random + ".png";
          accountDetail.chatbotIcon.base64Data = imgBase64;
          accountDetail.chatbotIcon.s3FileName = "";

          accountDetail.headerLogo.fileName = random + ".png";
          accountDetail.headerLogo.base64Data = imgBase64;
          accountDetail.headerLogo.s3FileName = "";
        }
      }
    }
    return accountDetail;
  };
  showCroppedImage = async () => {
    const croppedImage = await getCroppedImg(
      this.state.imageSrc,
      this.state.croppedAreaPixels
    );
    let file = new File([croppedImage], this.state.nameImputFile + ".png", {
      type: "image/png",
      lastModified: Date.now(),
    });
    // this.saveImages(file, this.state.nameImputFile);
    const updloadedFiles = this.state.updloadedFiles;
    updloadedFiles[this.state.nameImputFile] = file;
    let dataURL = URL.createObjectURL(croppedImage);
    await this.setStateAsync({
      updloadedFiles: updloadedFiles,
      [this.state.nameImputFile]: dataURL,
    });
    this.props.setIsDirty(true);
    this.modalAction();
  };
  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }
  getBase64Image(img) {
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    var dataURL = canvas.toDataURL("image/png");
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }
  createImage = (url) => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      //image.setAttribute( 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
      image.src = url;
    });
  };
  getImage = async (file, name) => {
    const File = await this.resizeFile(file[0]);
    this.setState({
      [name + "Error"]: "",
    });
    let dataURL = URL.createObjectURL(File);
    const imageToValidate = await this.createImage(dataURL);

    console.log({ File, imageToValidate, dataURL });

    if (imageToValidate.width > 150 || imageToValidate.height > 150) {
      this.setState({
        [name + "Error"]: "Ensure your file max width is: 150 x 150",
      });
      return false;
    }
    if (!utility.imageAcepted(File)) {
      this.setState({
        [name + "Error"]:
          "Invalid format. Ensure your file format is: jpeg, png or svg",
      });
      return false;
    }
    if (!utility.fileSizeMax(File.size)) {
      this.setState({
        [name + "Error"]: "The image size should not exceed 70MB",
      });
      return false;
    }

    if (name === "iconChatbot") {
      this.setState({
        shapeCrop: "round",
        aspect: 3 / 3,
      });
    } else {
      this.setState({
        shapeCrop: "rect",
        aspect: 22 / 11,
      });
    }
    this.setState({
      nameImputFile: name,
    });

    const updloadedFiles = this.state.updloadedFiles;
    updloadedFiles[this.state.nameImputFile] = File;
    this.setState({
      updloadedFiles: updloadedFiles,
      imageSrc: dataURL,
    });
    this.modalAction();
  };
  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({ croppedAreaPixels });
  };
}

export default ChatWidget;
