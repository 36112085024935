import React, { Component } from 'react';
import { Input, Spinner } from 'reactstrap';
import axios from "axios";
import cookie from "js-cookie";
import ErrorRequest from '../../components/General/ErrorRequest';
import en from "@shopify/polaris/locales/en.json";
import { AppProvider } from "@shopify/polaris";

class Install extends Component {
    constructor(props) {
        super(props);
        this.state={
            shop: '',
            error: 0,
            loading: true
        }
    }
    componentDidMount() {
        const url_string = window.location.href;
        const url = new URL(url_string);
        const shop = url.searchParams.get("shop")
        if (!shop)
            this.setState({loading: false})
        else
            this.setState({shop: shop}, this.shopify)
    }
    shopify=async()=>{
        axios
            .get(this.props.serverAPI + "/shopify", {
                params: {
                    shop: this.state.shop,
                },
            })
            .then(({ data }) => {
                if (
                    data.state &&
                    data.installUrl &&
                    data.state !== "undefined" &&
                    data.installUrl !== "undefined"
                ) {
                    cookie.set("state", data.state, { expires: 30 });
                    window.location.href = data.installUrl;
                }
            })
            .catch((error) => {
                this.changeErrorStatus(error.response);
            });
    }
    changeHandler=async(e)=>{
        this.setState({[e.target.name]: e.target.value});
    }
    changeErrorStatus=async(error)=>{
        this.setState({ error: error, loading: false });
    }
    render(){
        if (this.state.loading) {
            return (<div className="text-center"><Spinner color="primary"/></div>);
        }
        else {
        if(this.state.error)
            return (
                <AppProvider i18n={en}>
                    <ErrorRequest
                        serverAPI={this.props.serverAPI}
                        shop={this.state.shop || null}
                        error={this.state.error}
                    />
                </AppProvider>
            );
        return(
            <div className="install-main">
                <h2 className="title text-center">
                    Install TextChat into your shopify store
                </h2>
                <div className="form-group m-form__group">
                    <div className="input-group">
                        <Input
                            type="text"
                            className="form-control"
                            name='shop'
                            placeholder="example.myshopify.com"
                            onChange={(e) => this.changeHandler(e)}
                            value={this.state.shop}
                        />
                        <div className="input-group-append" onClick={(e)=>this.shopify()}>
                            <span className="input-group-text btn btn-primary">Install</span>
                        </div>
                    </div>
                </div>
            </div>
        )
        }
    }
}

export default Install;
