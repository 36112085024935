import React, { useEffect, useRef, useState } from "react";
import Config from "../../config.js";
import axios from "axios";
import { Alert } from "reactstrap";
import { useHistory } from "react-router-dom";
import utility from "../../components/General/utility";
import Select from "react-select";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import ErrorModal from "./ErrorModal";

const serverAPI = Config.apiGateway.URL;
axios.defaults.headers.common["Authorization"] = Config.backendGUID;

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "23px",
    color: "#1C1D21",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "23px",
    color: "#1C1D21",
  }),
};

const formatGroupLabel = (data) => (
  <span
    style={{
      color: "#1C1D21",
      fontSize: "14px",
      fontWeight: "700",
      lineHeight: "18px",
    }}
  >
    {data.label}
  </span>
);

const SelectNewNumber = () => {
  const [phoneNumbers, setPhoneNumbers] = useState();
  const [areaCode, setAreaCode] = useState();
  const [invalidAction, setInvalidAction] = useState(false);
  const [errorText, setErrorText] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEditable, setIsEditable] = useState(true);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState();
  const [groupedOptions, setGroupedOptions] = useState([]);
  const [isAreaCodeError, setAreaCodeError] = useState(false);
  const [isPhoneNumberError, setPhoneNumberError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dropdownRef = useRef();
  const [isErrorModal, setErrorModal] = useState(false);

  const history = useHistory();

  useEffect(() => {
    setPhoneNumbers((phoneNumbers) => []);
    setAreaCode();
    setErrorText(false);
    setInvalidAction(false);
    setIsEditable(true);
    setSelectedPhoneNumber("");
    setGroupedOptions([]);

    const rightSideWrapper = document.querySelector(
      ".rightside .inner-wrapper"
    );
    rightSideWrapper.style.display = "flex";
    rightSideWrapper.style.flexDirection = "column";
    rightSideWrapper.style.alignItems = "center";
    rightSideWrapper.style.justifyContent = "start";
  }, []);

  const getPhoneNumbers = (areaCode) => {
    setPhoneNumbers((phoneNumbers) => []);
    setSelectedPhoneNumber("");
    setGroupedOptions((groupedOptions) => []);
    return axios
      .post(serverAPI + "/app/sms/phonenumbers/" + areaCode + "/US")
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        setErrorText(error.response);
        toggleErrorModal();
      });
  };

  const toggleErrorModal = () => {
    setErrorModal(!isErrorModal);
  };

  window.toggleErrorModal = toggleErrorModal;
  // useEffect(() => {
  //   setErrorText(false);
  //   setInvalidAction(false);
  //   setSelectedPhoneNumber("");
  //   setPhoneNumbers([""]);
  // }, [areaCode, phoneNumbers]);

  const validateInputs = () => {
    setInvalidAction(false);
    setErrorText(false);
    if (parseInt(areaCode) < 201 && parseInt(areaCode) > 999) {
      setAreaCodeError(true);
      setInvalidAction(true);
      setErrorText(
        "Please enter a valid area code. Valid area code ranges between 201 and 999."
      );
      return false;
    }

    // validate all other fields
    if (!parseInt(areaCode) || !selectedPhoneNumber) {
      setInvalidAction(true);
      setAreaCodeError(true);
      setPhoneNumberError(true);
      setErrorText(
        "Please enter an area code, click submit and select a number."
      );
      return false;
    }
    return true;
  };

  const onCodeSubmit = async (ev) => {
    setErrorText(false);
    setInvalidAction(false);
    setPhoneNumberError(false);
    setAreaCodeError(false);
    setSelectedPhoneNumber("");
    setPhoneNumbers((phoneNumbers) => [""]);
    setGroupedOptions((groupedOptions) => [""]);
    let phoneNumbers;
    ev.preventDefault();
    var isValidCode = validateAreaCode();

    if (!isValidCode) return false;
    if (!isValidCode && parseInt(areaCode)) {
      setInvalidAction(true);
      setErrorText(
        "Please enter a valid area code. Valid area code ranges between 201 and 999."
      );
      return false;
    }
    setLoading(true);
    phoneNumbers = await getPhoneNumbers(areaCode);

    //temp: until we have country in API response
    phoneNumbers.map((i, v) => {
      i.country = "us";
      i.value = i.phoneNumber;
      i.label = i.phoneNumber;
    });

    phoneNumbers = await utility.groupArrayBy(phoneNumbers, "country");

    setPhoneNumbers(phoneNumbers);
    setGroupedOptions((groupedOptions) => []);
    Object.keys(phoneNumbers).map((i, v) => {
      groupedOptions.push({ label: i, options: phoneNumbers[i] });
    });
    setGroupedOptions(groupedOptions);
    setLoading(false);
    dropdownRef.current.focus();
    const dropdownWrapper = document.querySelector(
      ".mobile-list > div:first-of-type"
    );
    if (dropdownWrapper) {
      dropdownWrapper.style.border = "3px solid #6CE422";
    }
    return false;
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    localStorage.setItem("isHostedPhoneNumber", false);
    let isValid = validateInputs();
    if (!isValid) return false;
    let isPhoneNumberSaved = await SaveSelectedPhoneNumber();

    if (
      isValid &&
      selectedPhoneNumber != undefined &&
      selectedPhoneNumber !== "" &&
      isPhoneNumberSaved
    ) {
      setIsLoading(false);
      history.push({
        pathname: "/enrollment-form/agent-transfer",
        // commented as of now, for new routes
        // pathname: "/enrollment-form/pricing-no-live-agents",
        state: { from: "signup-textable", isNewNumberSelected: true },
      });
    }
    setIsLoading(false);
  };

  const InfoTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  const SaveSelectedPhoneNumber = () => {
    debugger;
    var userId = JSON.parse(localStorage.getItem("UserIdCreated"));
    let tempPhoneNumber = selectedPhoneNumber.replace(/[^\w\s]/gi, "");
    tempPhoneNumber = tempPhoneNumber.replace(/\s+/g, "");
    return axios
      .post(
        serverAPI +
          "/app/sms/SaveSelectedPhoneNumber/" +
          tempPhoneNumber +
          "/" +
          userId
      )
      .then((res) => {
        // setPhoneType here
        let phoneTypeResult = axios
          .post(`${serverAPI}/app/sms/setPhoneType/${userId}/PROVISIONED`)
          .then((result) => result.data)
          .catch((err) => err);

        return res.data;
      })
      .catch((error) => {
        setErrorText(error.response);
        toggleErrorModal();
      });
  };

  const validateAreaCode = () => {
    if (isNaN(parseInt(areaCode))) {
      setErrorText("Please enter a valid area code.");
      setAreaCodeError(true);
      setInvalidAction(true);
      return false;
    }
    if (parseInt(areaCode) < 201 || parseInt(areaCode) > 999) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div className="select-new-number mt-150 font-black font-32">
      <div className="top-heading">Choose a new text-enabled number</div>
      <p className="footer-desc mt-20">
        Enter your 3-digit area code and pick from any of the available textable
        numbers below.{"  "}
        <InfoTooltip
          title={
            <p style={{ fontStyle: "normal", color: "#718096" }}>
              <em>{"* "}</em>
              {
                "You will still use your existing business landline for voice calls."
              }{" "}
            </p>
          }
        >
          <InfoOutlinedIcon
            sx={{ fontSize: 25 }}
            // className="text-light-color"
            color="#718096"
          />
        </InfoTooltip>
      </p>

      {invalidAction ? <Alert color="danger">{errorText}</Alert> : null}
      <div className="form-wrapper mt-40 width-427">
        <form>
          <div className="form-group">
            <input
              type="text"
              className={`input-control area-code ${
                isAreaCodeError ? "input-error" : ""
              }`}
              placeholder="Area code"
              value={areaCode > 0 ? areaCode : ""}
              maxLength={3}
              onChange={(e) => setAreaCode(e.target.value)}
            />
            <input
              type="button"
              value="Submit"
              className="btn-submit"
              // onClick={handleAreaCode}
              onClick={onCodeSubmit}
              style={{
                width: "80px",
                height: "48px",
                marginLeft: "10px",
              }}
            />
          </div>
          <div className="form-group">
            <Select
              placeholder={
                groupedOptions.length > 0
                  ? "Click here to pick a textable number"
                  : "Mobile Numbers"
              }
              className={`mobile-list ${
                isPhoneNumberError ? "input-error" : ""
              }`}
              styles={customStyles}
              options={groupedOptions}
              isLoading={loading}
              ref={dropdownRef}
              formatGroupLabel={formatGroupLabel}
              onChange={(selectedOption) => {
                const dropdownWrapper = document.querySelector(
                  ".mobile-list > div:first-of-type"
                );
                if (dropdownWrapper) {
                  dropdownWrapper.style.border = "1px solid #e2e8f0";
                }
                setSelectedPhoneNumber(selectedOption.value);
              }}
            />
          </div>

          <div
            className="form-group mt-250"
            style={{
              width: "80px !important",
              height: "48px",
            }}
          >
            <input
              type="button"
              value="Confirm Number"
              className="btn-submit"
              onClick={handleSubmit}
            />
          </div>
        </form>
        <div className="signin-div">
          Already have an account?{" "}
          <a href="#" className="btn-text">
            Sign In
          </a>
        </div>
      </div>
      {!isLoading && (
        <>
          <ErrorModal
            isErrorModal={isErrorModal}
            toggleErrorModal={toggleErrorModal}
          />
        </>
      )}
    </div>
  );
};

export default SelectNewNumber;
