import React from 'react';
import { Card, Layout, SkeletonBodyText, SkeletonDisplayText, TextContainer, SkeletonPage} from '@shopify/polaris';
const LoadingPage = ()=>{
    return (
        <SkeletonPage>
            <Layout>
                <Layout.Section>
                    <Card sectioned>
                        <TextContainer>
                            <SkeletonDisplayText size="small" />
                            <SkeletonBodyText lines={9} />
                        </TextContainer>
                    </Card>
                </Layout.Section>
            </Layout>
        </SkeletonPage>
    );
}
export default LoadingPage;