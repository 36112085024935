export const step2 = [
    {
        title: "User information",
        tooltip: "Please click \"Add\" to input contact info for an individual TextChat user.",
        //description: "Please enter the name, the mobile phone numbers and the email to receive forwarded text messages from chatters",
        addAgents: true,
        name: "Agents",
        mandatory: true,
        labelForm: "1st Live Chat User"
    }
];
export const step2Description = [
    {
        text: "Enter the contact details of the 'users' who will be handling the TextChat live chat on your store. These users will receive a text notification to reply to chats that come through your chat window.",
    },
    {
        text: '<b>Note:</b> There is only one account administrator, but you can add any number of live chat users to your account by clicking on the "add" button. Your users may or may not include your administrator.',
    }
];
export const step2EULA = {
    text: 'By checking "I agree", I provide my signature expressly consenting to receive automated text messages from TextChat.com or its subsidiaries, affiliates, or agents at the number I provided to answer live chats from my website or receive other information about the use of TextChat.com. I understand that message and data rates may apply, and I am not required to enter into this agreement as a condition of purchasing services. I understand that I can revoke this consent at any time.'
};
export const step2video = {
    url: "https://www.w3schools.com/html/mov_bbb.mp4",
    poster: null
}