import React from "react";
import { useHistory, Link } from "react-router-dom";

const EFFooter = () => {
  const history = useHistory();
  return (
    <div className="footer">
      <Link to="/enrollment-form/privacy-policy" className="btn-text">
        Privacy Policy
      </Link>
      <Link to="/enrollment-form/copyrights" className="btn-text">
        AnswerNet<sup>&copy;</sup> Copyright 2022
      </Link>
    </div>
  );
};

export default EFFooter;
