import React, {Component} from "react";
class ColorPicker extends Component {
    render() {
        return (
            <>
                <label>{this.props.label}</label>
                <div className="input-group" ref="picker">
                    <div className="input-group-prepend">
                        <span
                            className="input-group-text color-picker-btn"
                            style={{
                                backgroundColor: this.props.color,
                            }}
                            onClick={(e) =>
                                this.props.openPicker(this.props.name)
                            }
                        >
                            &nbsp;
                        </span>
                    </div>
                    <div
                        className="picker"
                        data-color="theme_color|background_color"
                        acp-color={this.props.color}
                        acp-show-hsl="no"
                        acp-show-rgb="no"
                        acp-palette="PALETTE_MATERIAL_CHROME"
                        data-config={this.props.name}
                        ref={this.props.forwarRef}
                    ></div>
                    <input
                        type="text"
                        className="form-control"
                        value={this.props.color}
                        readOnly={true}
                        onClick={(e) => this.props.openPicker(this.props.name)}
                    />
                </div>
            </>
        );
    }
}


export default ColorPicker;