import React, { useEffect } from "react";
import { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { Alert } from "reactstrap";

const WebsiteURL = () => {
  const [websiteURL, setWebsiteURL] = useState("");
  const [invalidAction, setInvalidAction] = useState(false);
  const [errorText, setErrorText] = useState(false);
  const [numberOfUsers, setNumberOfusers] = useState(0);

  const history = useHistory();

  useEffect(() => {
    setWebsiteURL("");
    setInvalidAction(false);
    setErrorText(false);
  }, []);

  const validateInputs = () => {
    setInvalidAction(false);
    setErrorText(false);

    // validate all other fields
    if (!websiteURL) {
      setInvalidAction(true);
      setErrorText("Please enter Website URL");    
      return false;
    }
    return true;
  };

  const handleWebsiteURL = (e) => {
    let isValid = validateInputs();

    if (!isValid) {
      console.log("Not valid");
      return;
    } else {
      history.push("/enrollment-form/completeOne");
    }
  };

  const incrementUsers = () => {
    setNumberOfusers(numberOfUsers + 1);
  };
  const decrementUsers = () => {
    setNumberOfusers(numberOfUsers - 1);
  };

  return (
    <>
      <div className="top-heading">
        Would you like to add live <br /> chat to your website as well?
      </div>

      <div>
        <div className="form-group">
          <p className="foot-text text-center">
            Please enter your website url to enable the live chat widget
          </p>
        </div>
        {invalidAction ? <Alert color="danger">{errorText}</Alert> : null}
        <div className="signUp-form mt-50">
          <div>
            <div className="form-group">
              <input
                type="text"
                className="input-control"
                placeholder="Enter your website url"
                value={websiteURL}
                onChange={(e) => setWebsiteURL(e.target.value)}
              />
            </div>

            <div className="div-table min-width-auto">
              <table className="table">
                <tbody>
                  <tr>
                    <td>
                      Live Chat{" "}
                      <span className="text-light">(includes 2 users)</span>
                    </td>
                    <td className="text-dark text-bold font-16">
                      $15.00/month
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Additional user</td>
                    <td className="text-dark text-bold font-16">
                      $15.00/month
                    </td>
                    <td>
                      <div className="counter">
                        {/* <a
                          href="javascript:void(0);"
                          className="txt-warning font-30 f-w500 d-inline-block"
                        >
                          <span className="icon-counter">-</span>
                        </a> */}
                        <button
                          className="txt-warning font-30 f-w500 d-inline-block"
                          onClick={decrementUsers}
                          disabled={numberOfUsers <= 0}
                        >
                          <span className="icon-counter">-</span>
                        </button>
                        <input
                          type="text"
                          value={numberOfUsers}
                          readOnly="readonly"
                          className="input-counter"
                        />
                        <button
                          className="txt-warning font-30 f-w500"
                          onClick={incrementUsers}
                        >
                          <span className="icon-counter">+</span>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="divider"></div>

            <div className="form-group mt-40">
              <input
                type="button"
                value="Finish Sign Up"
                className="btn-submit"
                onClick={handleWebsiteURL}
              />
            </div>

            <div className="text-center text-dark font-20 f-w800 mx_-50">
              <Link to="/enrollment-form/complete">
                I am not interested in adding live chat.
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WebsiteURL;
