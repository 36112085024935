import React, { Component } from "react";
import axios from "axios";
import {
    TextField,
    Button
} from "@shopify/polaris";
import './LiveChat.css';
import ChatBoxWindown from "./ChatBoxWindown";
import { Icon } from "@shopify/polaris";
import { CircleInformationMajor } from "@shopify/polaris-icons";

class LiveChat extends Component {
    constructor() {
        super();
        this.state = {
            
        };
    }
    componentDidMount() {
        
    }

    render() {
        const title = "Invoices"
        const chatBotType = 'EFFORTLESS'
        return (
            <div className="live-chat">
                <div className="live-chat-continer">
                    <div className="row">
                        <div className="col-sm-6 live-chat-description">
                            <p>Live chat</p>
                            <h1>A live chat tool specifically designed for small businesses!</h1>
                            <div className="live-chat-form">
                                <label className="live-chat-label">
                                    Start your 14 day free trial
                                    <Icon source={CircleInformationMajor} />
                                </label>
                                <TextField
                                    placeholder="Your website URL"
                                    autoComplete="off"
                                    className="input-trial"
                                />
                                <Button
                                    onClick={(e) => {
                                        this.submitTrial()
                                    }}
                                >
                                    Submit
                                </Button>
                            </div>
                            <div className="live-chat-option">
                                <ul>
                                    <li>
                                        <i className="fa fa-check" />
                                        Connect realtime
                                    </li>
                                    <li>
                                        <i className="fa fa-check" />
                                        Capture leads
                                    </li>
                                    <li>
                                        <i className="fa fa-check" />
                                        Cancel anytime
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-6 live-chat-chatbox">
                            <ChatBoxWindown
                                header={this.state.displayName || "TextChat"}
                                slogan={
                                    this.state.chatbotHeaderSlogan || "Never miss a sale!"
                                }
                                greeting={
                                    this.state.chatbotHeaderDescription || "{ Greeting Text}"
                                }
                                headerLogo={this.state.headerLogo}
                                AgentBubbleColor={this.state.agentBubbleColor}
                                AgenttextColor={this.state.agentTextColor}
                                clientBubbleColor={this.state.clientBubbleColor}
                                clientTextColor={this.state.clientTextColor}
                                widgetTextColor={
                                    "#FFFFFF"
                                }
                                chatbotWidgetColor={
                                    "#305973"
                                }
                                chatBotIcon={this.state.iconChatbot}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LiveChat;