import cookie from "js-cookie";
import moment from "moment";
import "./anno/anno.css";

const $ = require("jquery");
require("./anno/scrollintoview.js");
const Anno = require("./anno/anno");

let clickNavIcon = () => {
  let navIcon = $(".Polaris-TopBar__NavigationIcon");
  if (navIcon[0].offsetWidth !== 0) navIcon.click();
};

let walkthroughAnno = new Anno.Anno(
  [
    // {
    //   target: ".Polaris-TopBar",
    //   content: "Welcome to TextChat!",
    //   locked: true,
    //   beforeSwitch: clickNavIcon,
    //   centerAbsolute: true,
    // },
    {
      target: 'a[href="/#home"]',
      content:
        "Right now you're on your dashboard, where you can see analytics for your chats.",
      locked: true,
      overrideBackground: true,
    },
    {
      target: 'a[href="/chatwidget"]',
      content:
        "In Customize Chatbot, you can edit the appearance and branding of your widget.",
      locked: true,
      overrideBackground: true,
    },
    {
      target: 'a[href="/schedule"]',
      content: "In Schedule, you can manage company and agent schedules.",
      locked: true,
      overrideBackground: true,
    },
    {
      target: 'a[href="/contactinfo"]',
      content:
        "In Users, you can add more TextChat users and change your contact information.",
      locked: true,
      overrideBackground: true,
    },
    {
      target: "#AppFrameNav > .sidebar-container > nav > div > :nth-child(4)",
      content:
        "In the Chat Widget section, you can enable your chat widget when you're ready to go live, and view your widget on your Shopify store.",
      locked: true,
    },
    {
      target: 'a[href="/billingplan"]',
      content: "You can review the details of your billing plan here.",
      locked: true,
      overrideBackground: true,
    },
  ],
  {
    roots: [".Polaris-Frame__Navigation"],
    onFinish: () => {
      cookie.set("setupDate", moment().format("YYYY-MM-DD"));
      // clickNavIcon();
    },
  }
);

let walkthrough = () => {
  if (!cookie.get("setupDate")) {
    walkthroughAnno.show();
  }
};

export default walkthrough;
