import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const SignUpAgentTransfer = () => {
  const history = useHistory();

  const [pricePackage, setPricePackage] = useState("");
  const [selectedPlan, setSelectedPlan] = useState("delegated_answering");
  const [selectedPackage, setSelectedPackage] = useState(50);
  const [starterPerChat, setStarterPerChat] = useState(3.50);
  const [basicPerChat, setBasicPerChat] = useState(3.50);
  const [proPerChat, setProPerChat] = useState(5);
  const [enhancedPerChat, setEnhancedPerChat] = useState(7);
  const [selectedPerChat, setSelectedPerChat] = useState(basicPerChat);
  const [isFromTextable, setIsFromTextable] = useState();
  const [selectBorder, setSelectBorder] = useState();

  let valuesToPass = {
    selectedPlan,
    selectedPackage: parseInt(selectedPackage),
    pricePackage,
    selectedPerChat,
  };

  useEffect(() => {
    setPricePackage("");
    setSelectedPlan("delegated_answering");
    setSelectedPackage(50);
    setSelectedPerChat(basicPerChat);
    if (
      history.location.state &&
      history.location.state.from == "signup-textable"
    ) {
      setIsFromTextable(true);
    } else {
      setIsFromTextable(false);
    }
  }, []);

  const handleSubmit = () => {
    console.log("valuesToPass: ", valuesToPass);
    history.push({
      pathname: "/enrollment-form/pricing",
      state: {
        valuesToPass,
        from: isFromTextable ? "signup-textable" : "",
        isNewNumberSelected:
          history.location.state && history.location.state.isNewNumberSelected
            ? history.location.state.isNewNumberSelected
            : false,
      },
    });
  };

  const handleChangePackage = (e) => {
    if (e.target.checked) {
      setPricePackage(e.target.name);
    } else {
      setPricePackage("");
    }
  };

  // const handleClickContinue = () => {
  //   history.push('/enrollment-form/pricing');
  // }

  const InfoTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  return (
    <div className="agent-transfer-wrapper">
      <div className="top-heading text-black font-26 mt-40">
        Too busy? <br />
        Let our agents answer for you!
      </div>
      {/* <div className="top-desc">
        53% of customers will abandon their purchase if they can’t get live
        <br />
        support within 60 seconds. If you can’t be readily available, let us
        <br /> tend to your customers’ questions and concerns.
      </div>
      <div className="divider"></div> */}

      <div className="form-group mt-30 mb-0">
        <div className="delegated-answering plan-card plan-card-desc">
          <p className="plan-card-desc font-20">
            You can have AnswerNet’s live agents answer for you! <br />
            We’ll provide you with a suite of answering features.
            <br /> Watch{" "}
            <a
              href="www.google.com"
              className="font-20"
              style={{
                fontWeight: "bold",
                fontStyle: "italic",
                color: "#305973",
              }}
            >
              here
            </a>{" "}
            to learn more.
            {/* You can have AnswerNet’s live agents answer for you! <br />
            We’ll provide you with a suite of answering features <br />
            including:{" "}
            <span className="text-warning font-20">
              Scheduled Answering, Delegated Answering <br />
            </span>
            and{" "}
            <span className="text-warning font-20">On Demand Answering.</span> */}
          </p>
        </div>
      </div>
      <div className="price-package">
        {/* <div className="h-line mt-30 mb-30"></div> */}

        <div className="price-package-header text-black font-26 mb-20 mt-30">
          Select your price package below:
        </div>
        <div className="main-price-boxes">
          {/* <div className="inner-boxes">
            <div className="text-title">Starter</div>
            <div
              className={
                "inner-box " +
                (selectedPerChat == starterPerChat ||
                selectBorder == "starterPerChat"
                  ? "select"
                  : " ")
              }
              // onClick={(e) => handleChangePackage("starter")}
              onClick={() => {
                setSelectedPerChat(starterPerChat);
                setSelectedPackage(140);
              }}
              onMouseOver={() => setSelectBorder("starterPerChat")}
              onMouseOut={() => setSelectBorder()}
            >
              <div className="price-detail">
                <span>
                  $<b>140</b>
                </span>
                /month
              </div>
              <div className="text-content">
                Includes:
                <br />
                20 conversations -
                <br />
                $7/conversation
                <br />
                above 20
              </div> 

              <InfoTooltip
                title={
                  <p style={{ fontStyle: "normal", color: "#718096" }}>
                    <em>{"* "}</em>
                    {"Some text here"}{" "}
                  </p>
                }
              >
                <InfoOutlinedIcon
                  sx={{ fontSize: 25 }}
                  // className="text-light-color"
                  color="#718096"
                />
              </InfoTooltip>
            </div>
          </div>*/}

          <div className="inner-boxes">
            <div className="text-title">Basic</div>
            <div
              className={
                "inner-box " +
                (selectedPerChat == basicPerChat ||
                selectBorder == "basicPerChat"
                  ? "select"
                  : " ")
              }
              onClick={(e) => {
                setSelectedPerChat(basicPerChat);
                setSelectedPackage(50);
              }}
              onMouseOver={() => setSelectBorder("basicPerChat")}
              onMouseOut={() => setSelectBorder()}
            >
              <div className="price-detail">
                <span>
                  $<b>50</b>
                </span>
                /month
              </div>
              <div className="text-content">
                Includes:
                <br />
                15 conversations -
                <br />
                $3.50/conversation
                <br />
                above 15
              </div>
              <InfoTooltip
                title={
                  <p style={{ fontStyle: "normal", color: "#718096" }}>
                    <em>{"* "}</em>
                    {"Some text here"}{" "}
                  </p>
                }
              >
                <InfoOutlinedIcon
                  sx={{ fontSize: 25 }}
                  // className="text-light-color"
                  color="#718096"
                />
              </InfoTooltip>
            </div>
          </div>

          {/* <div className="inner-boxes">
            <div className="text-title">Pro</div>
            <div
              className={
                "inner-box " +
                (selectedPerChat == proPerChat || selectBorder == "proPerChat"
                  ? "select"
                  : " ")
              }
              onClick={(e) => {
                setSelectedPerChat(proPerChat);
                setSelectedPackage(300);
              }}
              onMouseOver={() => setSelectBorder("proPerChat")}
              onMouseOut={() => setSelectBorder()}
            >
              <div className="price-detail">
                <span>
                  $<b>300</b>
                </span>
                /month
              </div>
              <div className="text-content">
                Includes:
                <br />
                120 conversations -
                <br />
                $5/conversation
                <br />
                above 120
              </div>

              <InfoTooltip
                title={
                  <p style={{ fontStyle: "normal", color: "#718096" }}>
                    <em>{"* "}</em>
                    {"Some text here"}{" "}
                  </p>
                }
              >
                <InfoOutlinedIcon
                  sx={{ fontSize: 25 }}
                  // className="text-light-color"
                  color="#718096"
                />
              </InfoTooltip>
            </div>
          </div> */}
          <div className="inner-boxes">
            <div className="text-title">Enhanced</div>
            <div
              className={
                "inner-box " +
                (selectedPerChat == enhancedPerChat ||
                selectBorder == "enhancedPerChat"
                  ? "select"
                  : " ")
              }
              onClick={(e) => {
                setSelectedPerChat(enhancedPerChat);
                setSelectedPackage(140);
              }}
              onMouseOver={() => setSelectBorder("enhancedPerChat")}
              onMouseOut={() => setSelectBorder()}
            >
              <div className="price-detail">
                <span>
                  $<b>140</b>
                </span>
                /month
              </div>
              <div className="text-content">
                Includes:
                <br />
                20 conversations -
                <br />
                $7/conversation
                <br />
                above 20
              </div>

              <InfoTooltip
                title={
                  <p style={{ fontStyle: "normal", color: "#718096" }}>
                    <em>{"* "}</em>
                    {"Some text here"}{" "}
                  </p>
                }
              >
                <InfoOutlinedIcon
                  sx={{ fontSize: 25 }}
                  // className="text-light-color"
                  color="#718096"
                />
              </InfoTooltip>
            </div>
          </div>
        </div>
        <div className="price-package-btn mt-40 mb-0">
          <button onClick={() => handleSubmit()}>Continue</button>
        </div>
      </div>

      <div className="text-center mt-20 font-20 f-w800">
        <Link
          className="text-dark font-20"
          to={{
            pathname: "/enrollment-form/pricing-no-live-agents",
            state: {
              valuesToPass,
              from: isFromTextable ? "signup-textable" : "",
              isNewNumberSelected:
                history.location.state &&
                history.location.state.isNewNumberSelected
                  ? history.location.state.isNewNumberSelected
                  : false,
            },
          }}
        >
          No thanks, I can handle my inquiries without agent support.
        </Link>
      </div>
    </div>
  );
};

export default SignUpAgentTransfer;
