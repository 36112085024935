import axios from "axios";
import cookie from "js-cookie";
import React, { createContext, useCallback } from "react";
import { useQuery } from "react-query";
import useDispositionStore from "../store/dispositions";

const DispositionContext = createContext({});

const DispositionContextProvider = ({ children, serverAPI }) => {
  const token = cookie.get("token");

  const { addClientDepositions, addChatSpecificDepositions } =
    useDispositionStore(
      useCallback(
        (state) => ({
          addClientDepositions: state.addClientDepositions,
          addChatSpecificDepositions: state.addChatSpecificDepositions,
        }),
        []
      )
    );

  // useQuery(
  //   "GET_DISPOSITIONS",
  //   async () => axios.get(serverAPI + `/app/disposition/getAll/${token}`),
  //   {
  //     onSuccess: (data) => {
  //       addClientDepositions(data.data);
  //     },
  //   }
  // );

  // useQuery(
  //   "GET_CLIENT_DISPOSITIONS",
  //   async () => axios.get(serverAPI + `/app/chatDisposition/getAll/${token}`),
  //   {
  //     onSuccess: (data) => {
  //       addChatSpecificDepositions(data.data);
  //     },
  //   }
  // );

  return <DispositionContext.Provider>{children}</DispositionContext.Provider>;
};

export default DispositionContextProvider;
