import React, { useCallback, useRef, useState, useEffect } from "react";
import {
  ActionList,
  AppProvider,
  Card,
  ContextualSaveBar,
  FormLayout,
  Frame,
  Loading,
  Modal,
  TextField,
  Toast,
  TopBar,
} from "@shopify/polaris";
import ContactInfo from "../../components/ContactInfo/ContactInfo";
import en from "@shopify/polaris/locales/en.json";
import SearchParams from "../../components/General/SearchParams";
import ExtraInformation from "../../components/Shop/Form/MeetCustomer/ExtraInformation";
import WaitingActivation from "../../components/Shop/WaitingActivation/WaitingActivation";
// import Steps from '../../components/Shop/Form/Steps';
import LoadingPage from "../../components/LoadingPage";
import NavigationMenu from "../../components/Frame/Navigation";
//import SCA from '../../components/Shop/Feed/SCA/SCA';
import Dashboard from "../../components/Shop/Feed/Dashboard/Dashboard";
import Messages from "../../components/Messages/Messages";
import LiveChat from "../../components/Shop/Feed/LiveChat/LiveChat";

import logo from "../../assets/img/logo.png";
import logoInverted from "../../assets/img/logoInverted.png";
import cookie from "js-cookie";

import {
  CartMajor,
  SettingsMajor,
  HomeMajor,
  HomeMajorTwotone,
  LogOutMinor,
  BillingStatementDollarMajor,
} from "@shopify/polaris-icons";

import axios from "axios";

import Settings from "../../components/Shop/Feed/SCA/Settings";
import Billing from "../../components/Shop/Billing/Billing";
import ChatWidget from "../../components/Shop/Feed/Customize/ChatWidget";
import Help from "../../components/Shop/Feed/Help/Help";
import ErrorRequest from "../../components/General/ErrorRequest";
import Scheduler from "../../containers/Scheduler";

import DashboardSvg from "../../assets/img/sidebar/dashboard.svg";

export default function FrameExample({ shop, serverAPI, location, bucketCDN }) {
  const defaultState = useRef({
    emailFieldValue: shop.shop,
    nameFieldValue: "Developer Preview",
  });
  const skipToContentRef = useRef(null);

  const [toastActive, setToastActive] = useState(false);
  const [discart, setDiscartChanges] = useState(false);
  const [shouldSave, setShouldSave] = useState(false);
  const [saveBarCriticalMessage, setSaveBarCriticalMessage] = useState(null);
  const [pendingInfoExists, setPendingInfoExists] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [userMenuActive, setUserMenuActive] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [modalActive, setModalActive] = useState(false);
  const [error, setError] = useState(false);

  const [info, setInfo] = useState({});

  const [nameFieldValue, setNameFieldValue] = useState(
    defaultState.current.nameFieldValue
  );
  const [emailFieldValue, setEmailFieldValue] = useState(
    defaultState.current.emailFieldValue
  );
  const [storeName, setStoreName] = useState(
    defaultState.current.nameFieldValue
  );
  const [supportSubject, setSupportSubject] = useState("");
  const [supportMessage, setSupportMessage] = useState("");

  const handleSubjectChange = useCallback(
    (value) => setSupportSubject(value),
    []
  );
  const handleMessageChange = useCallback(
    (value) => setSupportMessage(value),
    []
  );

  const logoutUserHandler = (e) => {
    let cookies = cookie.get();
    Object.keys(cookies).forEach((cookieName) => {
      if (cookieName === "setupDate") return;
      cookie.remove(cookieName);
    });
    window.location.href = "/login";
  };

  useEffect(() => {
    getInfoBusiness();
  }, []);

  /*
   *
   *Save
   */
  const handleDiscard = useCallback(() => {
    setIsDirty(false);
    setDiscartChanges(true);
  }, []);
  const handleSave = useCallback(() => {
    setSaveBarCriticalMessage(null);
    setShouldSave(true);
  }, [emailFieldValue, nameFieldValue]);
  const confirmSaved = useCallback((confirm = true) => {
    if (confirm) {
      setToastActive(true);
      setIsDirty(false);
    }
    setShouldSave(false);
  });

  /*
   *
   *Search
   */
  const handleSearchResultsDismiss = useCallback(() => {
    setSearchActive(false);
    setSearchValue("");
  }, []);
  const handleSearchFieldChange = useCallback((value) => {
    setSearchValue(value);
    setSearchActive(value.length > 0);
  }, []);
  const toggleToastActive = useCallback(
    () => setToastActive((toastActive) => !toastActive),
    []
  );
  const changesDiscarded = useCallback(
    () => setDiscartChanges((discart) => !discart),
    []
  );
  const toggleUserMenuActive = useCallback(
    () => setUserMenuActive((userMenuActive) => !userMenuActive),
    []
  );
  const toggleMobileNavigationActive = useCallback(
    () =>
      setMobileNavigationActive(
        (mobileNavigationActive) => !mobileNavigationActive
      ),
    []
  );
  /*
   *
   *Loading change state
   */
  const toggleIsLoading = useCallback(
    () => setIsLoading((isLoading) => !isLoading),
    []
  );
  const toggleModalActive = useCallback(
    () => setModalActive((modalActive) => !modalActive),
    []
  );

  const toastMarkup = toastActive ? (
    <Toast onDismiss={toggleToastActive} content="Changes saved" />
  ) : null;

  const userMenuActions = [
    {
      items: [
        { content: " Logout", onAction: logoutUserHandler, icon: LogOutMinor },
      ],
    },
  ];

  const contextualSaveBarMarkup = isDirty
    ? [
        <ContextualSaveBar
          message={saveBarCriticalMessage || "Unsaved changes"}
          saveAction={{
            onAction: handleSave,
          }}
          discardAction={{
            onAction: handleDiscard,
          }}
        />,
        ...(saveBarCriticalMessage
          ? [
              <style type="text/css">
                {`div.Polaris-Frame-ContextualSaveBar__ActionContainer Button.Polaris-Button.Polaris-Button--primary {
                        --p-button-color: var(--p-action-critical);
                        --p-button-text: var(--p-text-on-critical);
                        --p-button-color-hover: var(--p-action-critical-hovered);
                        --p-button-color-active: var(--p-action-critical-pressed);
                        --p-button-color-depressed: var(--p-action-critical-depressed);
                        background: linear-gradient(180deg,#e6391a,#d53417);
                        border-color: #b02b13;
                        box-shadow: inset 0 1px 0 0 #e73d1f, 0 1px 0 0 rgba(22,29,37,.05), 0 0 0 0 transparent;
                        color: #fff;
                    }`}
              </style>,
            ]
          : []),
      ]
    : null;

  const userMenuMarkup = (
    <TopBar.UserMenu
      actions={userMenuActions}
      name={shop.shop}
      detail={storeName}
      initials={shop?.shop ? shop?.shop[0] : ""}
      open={userMenuActive}
      onToggle={toggleUserMenuActive}
    />
  );

  const searchResultsMarkup = (
    <Card>
      <ActionList
        items={[
          { content: "Shopify help center" },
          { content: "Community forums" },
        ]}
      />
    </Card>
  );

  const searchFieldMarkup = (
    <TopBar.SearchField
      onChange={handleSearchFieldChange}
      value={searchValue}
      placeholder="Search"
    />
  );

  const topBarMarkup = (
    <TopBar
      showNavigationToggle
      userMenu={userMenuMarkup}
      //searchResultsVisible={searchActive}
      //searchField={searchFieldMarkup}
      //searchResults={searchResultsMarkup}
      //onSearchResultsDismiss={handleSearchResultsDismiss}
      onNavigationToggle={toggleMobileNavigationActive}
    />
  );
  const navigationMarkup = () => {
    let sections = {};
    const url_string = window.location.href;
    const url = new URL(url_string);

    if (shop.active === undefined || shop.active === 0) {
      sections.default = [
        {
          label: "TextChat Setup",
          icon: HomeMajor,
        },
      ];
    }

    if (info.activationDate) {
      let thisDay = new Date();
      let actDay = new Date(info.activationDate);
      if (actDay !== "Invalid Date") {
        let diff = Math.abs(thisDay.getTime() - actDay.getTime());
        const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
        const remainDays = 15 - diffDays;
        if (remainDays > 0) {
          sections.trial = remainDays;
        }
      }
    }
    if (shop.active === 5 || shop.active === 3) {
      // sections.primary = [
      //     {
      //         label: "SCA",
      //         icon: CartMajor,
      //         url: "/cart"
      //     }
      // ];
      sections.billing = [
        {
          label: "Invoices",
          icon: BillingStatementDollarMajor,
          url: "/billing",
          selected: url.pathname === "/billing",
        },
      ];
      sections.settings = true;
      sections.preview = true;
      sections.help = true;
      sections.product = true;
    }

    return (
      <NavigationMenu
        sections={sections}
        toggleIsLoading={toggleIsLoading}
        toggleModalActive={toggleModalActive}
        shop={shop}
        path={url.pathname}
        serverAPI={serverAPI}
        setPendingExists={setPendingInfoExists}
        pendingExists={pendingInfoExists}
      />
    );
  };

  const loadingMarkup = isLoading ? <Loading /> : null;

  const skipToContentTarget = (
    <a id="SkipToContentTarget" ref={skipToContentRef} tabIndex={-1} />
  );
  const getInfoBusiness = async () => {
    return await axios
      .get(`${serverAPI}/app/get/info`, {
        params: {
          shop: shop.shop,
        },
      })
      .then((resp) => {
        const infoshop = resp.data[0] || {};
        setInfo(infoshop);
        setError(false);
        return infoshop;
      })
      .catch((error) => {
        setError(error.response);
      });
  };
  const actualPageMarkup = () => {
    let { pathname, search } = location;
    const params = SearchParams(search);
    if (shop.active === 1) {
      if (Object.keys(info).length > 0) {
        return (
          <WaitingActivation
            setError={setError}
            getInfoBusiness={info}
            serverAPI={serverAPI}
            shop={shop}
          />
        );
      } else {
        return <LoadingPage />;
      }
    } else if (shop.active === 3 || shop.active === 5) {
      if (
        pathname.includes("billingplan") ||
        pathname.includes("chatflow") ||
        pathname.includes("featureselect")
      ) {
        if (Object.keys(info).length > 0) {
          return (
            <Settings
              setError={setError}
              serverAPI={serverAPI}
              getInfoBusiness={info}
              pathname={pathname}
              setIsDirty={setIsDirty}
              setCriticalMessage={setSaveBarCriticalMessage}
              discart={discart}
              shouldSave={shouldSave}
              changesDiscarded={changesDiscarded}
              confirmSaved={confirmSaved}
              pendingExists={pendingInfoExists}
            />
          );
        } else {
          return <LoadingPage />;
        }
      } else if (pathname.includes("contactinfo")) {
        return (
          <ContactInfo
            setError={setError}
            serverAPI={serverAPI}
            getInfoBusiness={info}
            pathname={pathname}
            setIsDirty={setIsDirty}
            setCriticalMessage={setSaveBarCriticalMessage}
            discart={discart}
            shouldSave={shouldSave}
            changesDiscarded={changesDiscarded}
            confirmSaved={confirmSaved}
            pendingExists={pendingInfoExists}
          />
        );
      } else if (pathname.includes("billing")) {
        return (
          <Billing
            setError={setError}
            shop={shop}
            serverAPI={serverAPI}
            getInfoBusiness={info}
          />
        );
      } else if (pathname.includes("help")) {
        return <Help />;
      } else if (pathname.includes("schedule")) {
        return (
          <Scheduler
            setError={setError}
            bucketCDN={bucketCDN}
            serverAPI={serverAPI}
            shop={shop}
          />
        );
      } else if (pathname.includes("messages")) {
        return <Messages shop={shop} serverAPI={serverAPI} />;
      } else if (pathname.includes("livechat")) {
        return <LiveChat shop={shop} serverAPI={serverAPI} />;
      }
      return <Dashboard shop={shop} serverAPI={serverAPI} />;
    } else {
      return (
        <ExtraInformation
          setError={setError}
          setIsDirty={setIsDirty}
          setCriticalMessage={setSaveBarCriticalMessage}
          toastActive={toastActive}
          serverAPI={serverAPI}
          changesDiscarded={changesDiscarded}
          discart={discart}
          step={shop.step}
          shop={shop}
          pendingExists={pendingInfoExists}
        />
      );
    }
  };
  const pageMarkup = isLoading ? <LoadingPage /> : actualPageMarkup();

  const modalMarkup = (
    <Modal
      open={modalActive}
      onClose={toggleModalActive}
      title="Contact support"
      primaryAction={{
        content: "Send",
        onAction: toggleModalActive,
      }}
    >
      <Modal.Section>
        <FormLayout>
          <TextField
            label="Subject"
            value={supportSubject}
            onChange={handleSubjectChange}
          />
          <TextField
            label="Message"
            value={supportMessage}
            onChange={handleMessageChange}
            multiline
          />
        </FormLayout>
      </Modal.Section>
    </Modal>
  );

  const theme = {
    colors: {
      topBar: {
        background: "#0B1F9C",
      },
    },
    logo: {
      width: 124,
      topBarSource: logo,
      contextualSaveBarSource: logoInverted,
      accessibilityLabel: "TextChat",
    },
  };
  //url: ""

  return (
    <AppProvider i18n={en}>
      <div style={{ height: "500px" }}>
        {error ? (
          <ErrorRequest shop={shop.shop} serverAPI={serverAPI} error={error} />
        ) : (
          <AppProvider
            theme={theme}
            i18n={{
              Polaris: {
                Avatar: {
                  label: "Avatar",
                  labelWithInitials: "Avatar with initials {initials}",
                },
                ContextualSaveBar: {
                  save: "Save",
                  discard: "Cancel",
                },
                TextField: {
                  characterCount: "{count} characters",
                },
                TopBar: {
                  toggleMenuLabel: "Toggle menu",

                  SearchField: {
                    clearButtonLabel: "Clear",
                    search: "Search",
                  },
                },
                Modal: {
                  iFrameTitle: "body markup",
                },
                Frame: {
                  skipToContent: "Skip to content",
                  Navigation: {
                    closeMobileNavigationLabel: "Close navigation",
                  },
                },
              },
            }}
          >
            <Frame
              navigation={navigationMarkup()}
              showMobileNavigation={mobileNavigationActive}
              onNavigationDismiss={toggleMobileNavigationActive}
              skipToContentTarget={skipToContentRef.current}
            >
              {contextualSaveBarMarkup}
              {loadingMarkup}
              {pageMarkup}
              {toastMarkup}
              {modalMarkup}
            </Frame>
          </AppProvider>
        )}
      </div>
    </AppProvider>
  );
}
