import React, { useEffect, useState } from 'react';
import { Spinner } from 'reactstrap';
import Axios from 'axios';
import backendUtility from "../backendUtility";
import {
    Redirect
} from "react-router-dom";



export default function NewBillCallback(props) {
    let [shouldRedirect, setShouldRedirect] = useState(false)
    useEffect(()=>{

        let asyncEffect = async () => {
        if (props.chargeId) {
            let toSend = {
                chargeId: props.chargeId,
                shop: props.shop
            };
            let token = await backendUtility.getToken(props.shop, props.serverAPI);
            if (token)
                toSend = {...toSend, "clientToken": token}

            let billInfo = await Axios
            .post(props.serverAPI + '/app/billing/recurringActivate', toSend)
            .then(resp => {
                if (resp.data) {
                    console.log(JSON.stringify(resp.data))
                    return resp.data
                }
            })
            if (billInfo) {
                console.log("bill info")
                let confirmSaved = await Axios
                .post(props.serverAPI + '/app/billing/onCompleted', {shop: props.shop})
                .then(resp => {
                    if (resp.data) {
                        console.log(JSON.stringify(resp.data))
                        return resp.data
                    }
                })
                console.log("checking confirmSaved")
                if (confirmSaved) {
                    let dataToSave = confirmSaved

                    await Axios.post(props.serverAPI + "/appUser/confirmTempInfo", {"shop": props.shop, "toConfirm": dataToSave})
                    .then((resp) => {
                        return resp
                    })
                    .catch((error) => {
                        console.warn("confirm temp info failed")
                    })
                    
                    if (dataToSave["Agents"]) {
                        console.log(`${props.serverAPI}/app/AccountHolder/Upsert`)
                        await Axios.put(
                            `${props.serverAPI}/app/AccountHolder/Upsert`,
                            
                            { clientToken: token, "Agents" : dataToSave["Agents"] }
                        )
                            .then((resp) => {
                                return resp;
                            })
                            .catch((error) => {
                                console.warn("agents not upserted")
                            });
                    }
                    if (dataToSave["funFactOne"] && dataToSave["funFactTwo"]) {
                        console.log(`${props.serverAPI}/app/FunFacts/Upsert`)
                        await Axios.put(
                            `${props.serverAPI}/app/FunFacts/Upsert`,
                            
                            { clientToken: token, "funFactOne" : dataToSave["funFactOne"], "funFactTwo": dataToSave["funFactTwo"] }
                        )
                            .then((resp) => {
                                return resp;
                            })
                            .catch((error) => {
                                console.warn("fun facts not upserted")
                            });
                    }
                    console.log("Setting should redirect to true")
                    setShouldRedirect(true)
                }
            }
        }
        }

        asyncEffect()
        
    });
    return (
            !shouldRedirect ?
                <div className='text-center'>
                    <Spinner color="primary" />
                </div>
            :
                <Redirect to="/contactinfo"/>
    );
}
