import React, { useEffect } from "react";
import {
  Box,
  Avatar,
  Typography,
  IconButton,
  TableContainer,
  TableRow,
  TableBody,
  TableCell,
  useTheme,
  styled,
  TableHead,
  Button,
  LinearProgress,
} from "@mui/material";
import MuiTable from "@mui/material/Table";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { queryClient } from "../../../constants";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useMemo } from "react";
import { useMutation } from "react-query";
import { useTable, useExpanded } from "react-table";
import ExpandedUserProfile from "./ExpandUserProfile";
import { BootstrapInput } from "../../Input/BootstrapInput";

const StyledTableCell = styled(TableCell)({
  fontSize: "1.5rem",
});

const StyledTableCellBody = styled(TableCell)({
  fontSize: "1.5rem",
});

const StyledTableHead = styled(TableHead)({
  backgroundColor: "#FAFAFA",
});

const AgentsTable = (props) => {
  const { token, memoizedData } = props;
  const [stateAgents, setAgents] = React.useState([]);

  useEffect(() => {
    setAgents(memoizedData);
  }, [memoizedData]);

  const updateAgent = useMutation(
    async ({ data, serverAPI }) =>
      await axios.put(`${serverAPI}/app/AccountHolder/Upsert`, {
        ...data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("FETCH_AGENTS");
      },
    }
  );

  const onSaveAgent = (data, tokenId) => {
    const agents = {};
    memoizedData.forEach((agent) => {
      agents[agent.token] = agent;
    });
    agents[tokenId] = data;
    updateAgent.mutate(
      {
        data: {
          Agents: Object.values(agents),
          clientToken: token,
          deleteAgentsNotInArray: true,
        },
        serverAPI: props.serverAPI,
      },
      props.serverAPI
    );
  };

  const onDeleteAgent = (agentId) => {
    let agentsToSave = memoizedData.filter((agent) => agent.token !== agentId);
    updateAgent.mutate(
      {
        data: {
          Agents: Object.values(agentsToSave),
          clientToken: token,
          deleteAgentsNotInArray: true,
        },
        serverAPI: props.serverAPI,
      },
      props.serverAPI
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "fullname",
        Cell: (props) => {
          return (
            <Box display="flex">
              <Avatar
                sx={{
                  height: "35px",
                  width: "35px",
                }}
                src={props.row.original?.fileMetaData?.url}
              />
              <Box marginLeft={theme.spacing(1)}>
                <Typography fontSize="16px" fontWeight="500" variant="h6">
                  {props.row.original.fullName}
                </Typography>
                <Typography fontSize="14px" color="text.disabled" variant="h6">
                  {props.row.original.mobilePhoneNumber}
                </Typography>
              </Box>
            </Box>
          );
        },
      },
      {
        Header: "Email",
        accessor: "emailAddress",
      },
      {
        Header: "",
        accessor: "token",
        width: 10,
        Cell: ({ row }) => {
          return (
            <Box display="flex" justifyContent="flex-end">
              <IconButton {...row.getToggleRowExpandedProps()} color="primary">
                <EditIcon fontSize="large" />
              </IconButton>
              <IconButton
                onClick={() => {
                  onDeleteAgent(row.original.token);
                }}
                color="error"
              >
                <DeleteIcon fontSize="large" />
              </IconButton>
            </Box>
          );
        },
      },
    ],
    [memoizedData]
  );

  const { getTableBodyProps, headerGroups, rows, prepareRow, getTableProps } =
    useTable(
      {
        columns,
        data: stateAgents,
      },
      useExpanded
    );

  const theme = useTheme();

  return (
    <Box marginTop={theme.spacing(1)}>
      <Box
        marginY={theme.spacing(3)}
        width="100%"
        display="flex"
        justifyContent="space-between"
      >
        <BootstrapInput
          onChange={(e) => {
            setAgents(
              memoizedData.filter(
                (agent) =>
                  agent.fullName
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase()) ||
                  agent.emailAddress
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase().trim())
              )
            );
          }}
          placeholder="Search for Agent"
          size="small"
        />
        <Button
          onClick={() => props.setIsAddUserModalOpen(true)}
          startIcon={<AddIcon />}
          variant="contained"
        >
          Add New Agent
        </Button>
      </Box>
      <TableContainer component={Box}>
        {props.loading && <LinearProgress />}
        <MuiTable {...getTableProps()}>
          <StyledTableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <StyledTableCell {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </StyledTableCell>
                ))}
              </TableRow>
            ))}
          </StyledTableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <>
                  <TableRow {...row.getRowProps(row)}>
                    {row.cells.map((cell) => (
                      <StyledTableCellBody {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </StyledTableCellBody>
                    ))}
                  </TableRow>
                  {row.isExpanded ? (
                    <TableRow
                      sx={{
                        backgroundColor: "grey.100",
                      }}
                    >
                      <TableCell colSpan={row.cells.length}>
                        <ExpandedUserProfile
                          onSaveAgent={onSaveAgent}
                          loading={updateAgent.isLoading}
                          row={row}
                        />
                      </TableCell>
                    </TableRow>
                  ) : null}
                </>
              );
            })}
          </TableBody>
        </MuiTable>
      </TableContainer>
    </Box>
  );
};

export default AgentsTable;
