import React, { Component } from 'react';
import { Spinner } from 'reactstrap';
import cookie from "js-cookie";
import Axios from 'axios';

class Callback extends Component {
    constructor(){
        super();
        this.state={
            resp : '',
            loading: true
        };
    }
    componentDidMount(){
        const url_string = window.location.href;
        const url = new URL(url_string);
        const shop = url.searchParams.get("shop"),
            state = url.searchParams.get("state");

        const stateCookie = cookie.get('state');

        let paramsToSend = {};
        url.searchParams.forEach( (value, key) => {
            paramsToSend[key] = value;
        });

        if (state !== stateCookie)
            this.setState({ resp: 'Request origin cannot be verified', loading: false });
        else {
            Axios.get(this.props.serverAPI + '/shopify/callback', {
                params: paramsToSend
            }).then(resp => {
                if (resp.data) {
                    //this.setState({ loading: false, resp: resp.data });
                    window.location.href='/';
                    cookie.set('shop', shop, { expires: 30 });
                }
            })
        }
    }
    render() {
        return (
            <div className='text-center'>
                {this.state.loading
                    ? <Spinner color="primary" />
                    : <p>{this.state.resp}</p>
                }
            </div>
        );
    }
}

export default Callback;
