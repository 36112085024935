import React from "react";
import AnswerNet_logo from "../../images/answerNet_logo_02.png";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const ErrorModal = ({ toggleErrorModal, isErrorModal }) => (
  <Modal
    className="error-modal"
    isOpen={isErrorModal}
    toggle={() => toggleErrorModal()}
  >
    <ModalBody>
      <div className="logo-wrpper">
        <img className="logo" src={AnswerNet_logo} alt="" />
      </div>
      <div className="mt-30">
        <div>
          It appears something went wrong processing your request. Please email
          us at support@answernetchat.com for assistance. Thank you!
        </div>
      </div>
      <div className="mt-30 text-center">
        <Button className="btn-submit" onClick={() => toggleErrorModal()}>
          Close{" "}
        </Button>
      </div>
    </ModalBody>
  </Modal>
);

export default ErrorModal;
