import React, { Component } from "react";
import ScriptGenerator from "./../../../ScriptGenerator";
import {
  Card,
  Form,
  FormLayout,
  Layout,
  Stack,
  TextField,
  Checkbox,
  RadioButton,
  Select,
  Button,
  ResourceList,
  ResourceItem,
  TextStyle,
  Icon,
  Tooltip,
} from "@shopify/polaris";
import {
  CircleInformationMajor,
  EditMajor,
  DeleteMajor,
} from "@shopify/polaris-icons";
import cookie from "js-cookie";
import Axios from "axios";

import WidgetPreview from "../../../General/ProactiveWidgetPreview/ProactiveWidgetPreview";
import GreetingPreview from "./ExtraInformation/GreetingPreview/GreetingPreview";

import SwitchBtn from "../../../Layouts/SwitchBtn/SwitchBtn";
import utility from "../../../General/utility";
import "./style.css";
import Config from "../../../../config";
import { faLessThanEqual } from "@fortawesome/free-solid-svg-icons";
const bucketCDN = Config.bucketCDN.URL;

class MeetCustomer extends Component {
  constructor() {
    super();
    this.state = {
      imageSrc: null,
      editAgents: true,
      imageAgent: [],
      imageAgents: [],
      errorImage: "",
      agents: [],
      currentAgentName: "",
      currentAgentEmail: "",
      currentAgentPhone: "",
      currentAgentToken: null,
      currentAgentKey: null,
      currentEmailKey: null,
      token: null,
      currentEmail: null,
      labelAgents: null,
    };
  }
  componentDidUpdate(prevProps) {
    if (
      Object.keys(prevProps.dataToSave).length == 0 &&
      Object.keys(this.props.dataToSave).keys().length !== 0
    )
      if (
        this.props.dataToSave.Agents &&
        this.props.dataToSave.Agents.length > 0
      )
        this.setState({ editAgents: true });
    if (
      this.props.dataToSave.Agents &&
      prevProps.dataToSave.Agents !== this.props.dataToSave.Agents
    ) {
      this.setState({ agents: this.props.dataToSave.Agents });
    }
  }
  async saveAgents(callback = null) {
    let dataToSave = this.props.dataToSave["Agents"];
    let agentObj = {
      clientToken: this.state.token,
      Agents: dataToSave,
    };
    this.props.setIsDirty(true);
  }
  onEditAgentsCancel(e) {
    this.setState({
      editAgents: false,
      currentAgentEmail: "",
      currentAgentName: "",
      currentAgentPhone: "",
      currentAgentToken: null,
      currentAgentKey: null,
      currentEmailKey: null,
      currentEmail: "",
    });
  }
  onEditAgents(FullName, EmailAddress, MobilePhoneNumber, Token, key) {
    this.setState({
      editAgents: true,
      currentAgentName: FullName,
      currentAgentEmail: EmailAddress,
      currentAgentPhone: MobilePhoneNumber,
      currentAgentToken: Token,
      currentAgentKey: key,
    });
  }
  addEmailsButtonPress(name) {
    if (
      this.state.editAgents &&
      this.state.currentEmail &&
      this.state.currentEmail.trim()
    ) {
      var unverified = false;
      utility.verifyInput("email", this.state.currentEmail, () => {
        unverified = true;
      });
      if (unverified) {
        this.setState({ editAgents: true });
        this.props.setIncomplete(
          "We could not add your email",
          "Please make sure to enter a valid email for your TextChat Notification."
        );
        return;
      }
      const email = this.props.dataToSave[name] || [];
      let newEmail = this.state.currentEmail.trim();

      if (this.state.currentEmailKey === null) email.push(newEmail);
      else email[this.state.currentEmailKey] = newEmail;

      this.props.stateDataSave(name, email, () => {
        if (this.props.navSave) this.props.setIsDirty(true);
        this.onEditAgentsCancel(null);
      });
    } else if (this.state.editAgents) {
      this.props.setIncomplete(
        "We could not add your email",
        "Please make sure to enter a valid email for your TextChat Notification."
      );
      this.setState({ editAgents: true });
    } else {
      this.setState({ editAgents: true });
    }
  }
  addButtonPress() {
    if (
      this.state.agents.length >= Config.maxAgents &&
      this.state.currentAgentKey === null
    ) {
      this.props.setIncomplete(
        "No more users can be added",
        "Currently, the maximum number of users is " + Config.maxAgents + "."
      );
      this.onEditAgentsCancel(null);
      return;
    }

    /*Checks whether an agent should be saved.*/
    if (
      this.state.editAgents &&
      this.state.currentAgentName &&
      this.state.currentAgentName.trim() &&
      this.state.currentAgentEmail &&
      this.state.currentAgentEmail.trim() &&
      this.state.currentAgentPhone &&
      this.state.currentAgentPhone.trim()
    ) {
      var unverified = false;
      utility.verifyInput("email", this.state.currentAgentEmail, () => {
        unverified = true;
      });
      utility.verifyInput("phone", this.state.currentAgentPhone, () => {
        unverified = true;
      });
      if (unverified) {
        this.setState({ editAgents: true });
        this.props.setIncomplete(
          "We could not add your user",
          "Please make sure to enter a name, valid email and phone number for your TextChat User."
        );
        return;
      }
      const agent = this.props.dataToSave["Agents"] || [];
      let newAgent = {
        FullName: this.state.currentAgentName.trim(),
        EmailAddress: this.state.currentAgentEmail.trim(),
        MobilePhoneNumber: this.state.currentAgentPhone.trim(),
      };
      if (this.state.currentAgentToken)
        newAgent["Token"] = this.state.currentAgentToken;
      if (this.state.currentAgentKey === null) agent.push(newAgent);
      else agent[this.state.currentAgentKey] = newAgent;

      this.props.stateDataSave("Agents", agent, () => {
        if (this.props.navSave) this.props.setIsDirty(true);
        this.onEditAgentsCancel(null);
      });
    } else if (this.state.editAgents) {
      this.props.setIncomplete(
        "We could not add your user",
        "Please make sure to enter a name, valid email and phone number for your TextChat User."
      );
      this.setState({ editAgents: true });
    } else {
      this.setState({ editAgents: true });
    }
  }
  addAgent() {
    const newAgent = {
      FullName: "name",
      EmailAddress: "example@gmail.com",
      MobilePhoneNumber: "+12676333444",
    };
    const agent = this.props.dataToSave["Agents"] || [];

    agent.push(newAgent);

    this.setState({
      editAgents: true,
    });
    this.props.stateDataSave("Agents", agent);
  }
  deleteAgent(key) {
    const agent = this.props.dataToSave["Agents"];
    agent.splice(key, 1);
    this.props.stateDataSave("Agents", agent);
    if (this.props.navSave) this.props.setIsDirty(true);
  }
  deleteElementList(key, name) {
    const elements = this.props.dataToSave[name];
    elements.splice(key, 1);
    this.props.stateDataSave(name, elements);
    if (this.props.navSave) this.props.setIsDirty(true);
  }
  changeValAgent(e, key, name) {
    const agent = this.props.dataToSave["Agents"];
    agent[key][name] = e;
    this.props.stateDataSave("Agents", agent);
  }
  handleSetInitial(currentValue, initialValue, name) {
    if (currentValue === "") this.handleChange(initialValue, name);
  }
  handleRemoveInitial(currentValue, initialValue, name) {
    if (currentValue === initialValue) this.handleChange("", name);
  }
  render() {
    const {
      title,
      tooltip,
      description,
      labelForm,
      type,
      options,
      checkbox,
      radio,
      select,
      name,
      switchBtn,
      secondary,
      third,
      placeholder,
      cardSection,
      activateOn,
      multiline,
      dropzone,
      addAgents,
      popupMessage,
      greetingMessage,
      associatedName,
      initialValue,
      multipleEmails,
      spaceBorder,
      videoDescription,
      showHeader,
    } = this.props;
    let textField;
    let disabled = false;

    if (activateOn) {
      if (
        this.props.dataToSave[activateOn] &&
        this.props.dataToSave[activateOn].length > 0
      )
        disabled = !this.props.dataToSave[activateOn][0];
      else {
        disabled = true;
      }
    }

    if (options && options.showCharacterCount) {
      textField = (
        <TextField
          value={this.props.dataToSave[name]}
          onChange={(e) => this.handleChange(e, name)}
          onFocus={() =>
            this.handleRemoveInitial(
              this.props.dataToSave[name],
              initialValue,
              name
            )
          }
          onBlur={() =>
            this.handleSetInitial(
              this.props.dataToSave[name],
              initialValue,
              name
            )
          }
          label={labelForm || ""}
          type={type}
          multiline
          placeholder={placeholder || ""}
          maxLength={options.maxLength || 20}
          showCharacterCount
          disabled={disabled}
        />
      );
      textField =
        this.props.incomplete.filter((x) => x === name).length === 0 ? (
          textField
        ) : (
          <span className="FieldIncorrect">{textField}</span>
        );
      return this.layoutRender(
        title,
        tooltip,
        description,
        textField,
        secondary,
        third,
        true
      );
    } else if (checkbox && checkbox.length > 0) {
      textField = [];
      checkbox.map((item, index) => {
        let check = this.props.dataToSave[name]
          ? this.props.dataToSave[name].indexOf(item.value)
          : -1;
        return textField.push(
          <Checkbox
            key={index}
            label={item.label}
            checked={check > -1}
            onChange={(e) => this.handleCheckboxChange(e, name, item.value)}
            name={checkbox.name}
            disabled={disabled}
          />
        );
      });
      textField = (
        <Stack vertical={false} distribution="equalSpacing">
          {textField}
        </Stack>
      );
    } else if (radio && radio.length > 0) {
      textField = [];
      radio.map((item, index) => {
        let check = this.props.dataToSave[name];
        return textField.push(
          <RadioButton
            key={index}
            label={item.label}
            checked={check === item.value}
            onChange={(e) => this.handleChange(item.value, name)}
            name={radio.name}
            disabled={disabled}
          />
        );
      });
      textField = (
        <Stack vertical={false} distribution="equalSpacing">
          {textField}
        </Stack>
      );
    } else if (select && select.length > 0) {
      textField = (
        <Select
          label={select.label || ""}
          options={select}
          onChange={(e) => this.handleChange(e, name)}
          value={this.props.dataToSave[name] || select[0]}
          disabled={disabled}
        />
      );
    } else if (switchBtn && switchBtn.length > 0) {
      textField = [];
      switchBtn.map((item, index) => {
        let check = this.props.dataToSave[name]
          ? this.props.dataToSave[name].indexOf(item.value)
          : null;
        const val = check ? check > -1 : item.value;
        return textField.push(
          <SwitchBtn
            key={index}
            name={name}
            help={item.help}
            label={item.label || ""}
            disabled={item.disabled}
            onChange={(e) => this.handleCheckboxChange(!val, name, item.value)}
            checked={val}
          />
        );
      });
      textField = (
        <Stack vertical={false} distribution="equalSpacing">
          {textField}
        </Stack>
      );
    } else if (cardSection && cardSection.length > 0) {
      let card = [];
      cardSection.map((item, index) => {
        let extra = [];
        if (item.switchBtn) {
          let check = this.props.dataToSave[item.name]
            ? this.props.dataToSave[item.name].indexOf(item.switchBtn[0].value)
            : -1;
          let val = check > -1;
          const label = item.switchBtn[0].label;

          extra = [
            <SwitchBtn
              key={index}
              name={item.name}
              label={label || ""}
              disabled={item.disabled}
              onChange={(e) =>
                this.handleCheckboxChange(
                  !val,
                  item.name,
                  item.switchBtn[0].value
                )
              }
              checked={val}
            />,
          ];
        } else if (item.btnpressed) {
          let check = this.props.dataToSave[item.name];
          const label = item.btnpressed[0].label;
          extra = [
            <Button
              key={index}
              fullWidth
              pressed={check === item.btnpressed[0].value}
              onClick={(e) =>
                this.handleChange(item.btnpressed[0].value, item.name)
              }
              disabled={disabled}
            >
              {label}
            </Button>,
          ];
        } else if (item.switchpressed) {
          let check = this.props.dataToSave[item.name];
          const label = item.switchpressed[0].label;
          const checked = check === item.switchpressed[0].value;
          extra = [
            <SwitchBtn
              key={0}
              name={item.name}
              help={item.help}
              label={label || ""}
              disabled={item.disabled}
              onChange={(e) =>
                checked
                  ? this.handleChange(null, item.name)
                  : this.handleChange(item.switchpressed[0].value, item.name)
              }
              checked={checked}
            />,
          ];
        } else if (item.flowCreation) {
          const chatFlow =
            this.props.dataToSave[item.name] &&
            this.props.dataToSave[item.name].length > 0
              ? this.props.dataToSave[item.name]
              : [];
          const chatFlowDom =
            this.props.dataToSave["chatFlowDom"] &&
            this.props.dataToSave["chatFlowDom"].length > 0
              ? this.props.dataToSave["chatFlowDom"]
              : [];
          extra = [
            <ScriptGenerator
              key={index}
              id={item.flowCreation.id}
              showJSON={item.flowCreation.showJSON}
              height={item.flowCreation.height}
              name={item.name}
              chatFlow={chatFlow}
              chatFlowDom={chatFlowDom}
              handleChange={this.handleChange}
            />,
          ];
        }
        return card.push(
          <Card.Section key={index}>
            {extra.length > 0 ? extra : item}
          </Card.Section>
        );
      });
      textField = card;
    } else if (addAgents) {
      let agents = this.props.dataToSave["Agents"] || [];
      let notEditing = !this.state.editAgents;
      let noProps = !this.props.dataToSave.Agents;
      let propsButNoLength =
        this.props.dataToSave.Agents &&
        this.props.dataToSave.Agents.length === 0;
      let hideCancel = notEditing || noProps || propsButNoLength;
      let displayNumAgent = agents.length
        ? this.displayNumAgent(agents.length + 1)
        : labelForm;
      textField = (
        <span className="AddAgents">
          {this.state.errorImage}
          {this.state.editAgents ? (
            <>
              <TextField
                label={<b>{displayNumAgent || ""}</b>}
                value={this.state.currentAgentName}
                placeholder={"Full Name"}
                onChange={(e) => this.setState({ currentAgentName: e })}
              />
              <TextField
                value={this.state.currentAgentPhone}
                placeholder={"(000) 000 - 0000"}
                onChange={(e) => this.setState({ currentAgentPhone: e })}
              />
              <TextField
                value={this.state.currentAgentEmail}
                placeholder={"Email address"}
                onChange={(e) => this.setState({ currentAgentEmail: e })}
              />
            </>
          ) : (
            <>
              <ResourceList
                resourceName={{
                  singular: "customer",
                  plural: "customers",
                }}
                items={agents}
                renderItem={(item, key) => {
                  const {
                    id,
                    FullName,
                    MobilePhoneNumber,
                    EmailAddress,
                    Token,
                  } = item;
                  let content;
                  let keyElement = parseInt(key) + 1;
                  let displayNumAgent = this.displayNumAgent(keyElement);
                  content = (
                    <div className="List-Element-container">
                      <label className="Polaris-Label__Text User-Number">
                        <b>{displayNumAgent}</b>
                      </label>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div
                          style={{ flex: 1 }}
                          onClick={(e) => {
                            this.onEditAgents(
                              FullName,
                              EmailAddress,
                              MobilePhoneNumber,
                              Token,
                              key
                            );
                          }}
                        >
                          <h3>
                            <TextStyle variation="strong">{FullName}</TextStyle>
                          </h3>
                          <div>{MobilePhoneNumber}</div>
                          <div>{EmailAddress}</div>
                        </div>

                        <div className="AgentEditIcons">
                          <span
                            onClick={(e) => {
                              this.onEditAgents(
                                FullName,
                                EmailAddress,
                                MobilePhoneNumber,
                                Token,
                                key
                              );
                            }}
                          >
                            <Icon source={EditMajor} color={"blueDark"} />
                          </span>
                          <span onClick={(e) => this.deleteAgent(key)}>
                            <Icon source={DeleteMajor} color={"redDark"} />
                          </span>
                        </div>
                      </div>
                    </div>
                  );

                  return (
                    <ResourceItem
                      id={id}
                      accessibilityLabel={`View details for ${name}`}
                    >
                      {content}
                    </ResourceItem>
                  );
                }}
              />
            </>
          )}
          <span
            className={
              this.state.editAgents
                ? "AddBtn__Space AddButton__Editing"
                : "AddBtn__Space"
            }
          >
            <Button
              primary={!this.state.editAgents ? null : true}
              onClick={(e) => {
                this.addButtonPress();
              }}
            >
              {!this.state.editAgents ? "Add" : "Save"}
            </Button>
          </span>

          {hideCancel || (
            <Button onClick={this.onEditAgentsCancel.bind(this)}>Cancel</Button>
          )}
        </span>
      );
    } else if (multipleEmails) {
      let emails = this.props.dataToSave[name] || [];
      let notEditing = !this.state.editAgents;
      let editingMode = this.state.editAgents || emails.length === 0;
      let noProps = !this.props.dataToSave[name];
      let propsButNoLength = this.props.dataToSave[name] && emails.length === 0;
      let hideCancel = notEditing || noProps || propsButNoLength;
      textField = (
        <span className="AddEmails">
          {editingMode ? (
            <TextField
              value={emails.toString()}
              placeholder={"Email address"}
              onChange={(e) => this.setState({ currentEmail: e })}
            />
          ) : (
            <>
              <label className="Polaris-Label__Text">
                <b>{labelForm || ""}</b>
              </label>
              <ResourceList
                resourceName={{
                  singular: "customer",
                  plural: "customers",
                }}
                items={emails}
                renderItem={(item, key) => {
                  let EmailAddress;
                  if (typeof item === "string") EmailAddress = item;
                  else EmailAddress = item.EmailAddress;
                  let content;
                  content = (
                    <>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ flex: 1 }}>
                          <div>{EmailAddress}</div>
                        </div>

                        <div className="AgentEditIcons">
                          <span
                            onClick={(e) =>
                              this.setState({
                                editAgents: true,
                                currentEmail: EmailAddress,
                                currentEmailKey: key,
                              })
                            }
                          >
                            <Icon source={EditMajor} color={"blueDark"} />
                          </span>
                          <span
                            onClick={(e) => this.deleteElementList(key, name)}
                          >
                            <Icon source={DeleteMajor} color={"redDark"} />
                          </span>
                        </div>
                      </div>
                    </>
                  );

                  return (
                    <ResourceItem
                      accessibilityLabel={`View details for ${name}`}
                    >
                      {content}
                    </ResourceItem>
                  );
                }}
              />
            </>
          )}
          <span
            className={
              editingMode
                ? "AddBtn__Space AddButton__Editing"
                : "AddBtn__Space-bottom"
            }
          >
            <Button
              primary={!editingMode ? null : true}
              onClick={(e) => {
                this.addEmailsButtonPress(name);
              }}
            >
              {!editingMode ? "Add" : "Save"}
            </Button>
          </span>

          {hideCancel || (
            <Button onClick={this.onEditAgentsCancel.bind(this)}>Cancel</Button>
          )}
        </span>
      );
    } else if (popupMessage) {
      textField = (
        <WidgetPreview
          previewText={this.props.dataToSave[associatedName] || placeholder}
        />
      );
    } else if (greetingMessage) {
      textField = (
        <GreetingPreview
          placeholder={placeholder}
          message={this.props.dataToSave[associatedName]}
          header={this.props.dataToSave["greetingHeader"]}
          slogan={this.props.dataToSave["greetingSlogan"]}
          description={this.props.dataToSave["greetingDescription"]}
        />
      );
    } else if (this.props.name) {
      textField = (
        <TextField
          id={this.props.index}
          value={this.props.dataToSave[name]}
          onChange={(e) => this.handleChange(e, name)}
          onFocus={() =>
            this.handleRemoveInitial(
              this.props.dataToSave[name],
              initialValue,
              name
            )
          }
          onBlur={() =>
            this.handleSetInitial(
              this.props.dataToSave[name],
              initialValue,
              name
            )
          }
          placeholder={placeholder || labelForm || ""}
          label={""}
          type={type}
          disabled={disabled}
          multiline={multiline || false}
        />
      );
      textField =
        this.props.incomplete.filter((x) => x === name).length === 0 ? (
          textField
        ) : (
          <span className="FieldIncorrect">{textField}</span>
        );

      return this.layoutRender(
        title,
        tooltip,
        description,
        textField,
        secondary,
        third,
        true
      );
    } else if (spaceBorder) {
      return <div className="space-border">&nbsp;</div>;
    } else if (showHeader) {
      return (
        <div className="user-header">
          <h1>{this.props.title}</h1>
          <p>{this.props.description}</p>
        </div>
      );
    }
    return this.layoutRender(
      title,
      tooltip,
      description,
      textField,
      secondary,
      third
    );
  }

  layoutRender(
    title = "",
    tooltip,
    description = "",
    textField,
    secondary = false,
    third = false,
    labelHalt = false
  ) {
    let newTitle = !tooltip ? (
      title
    ) : (
      <>
        <Tooltip active={false} content={tooltip}>
          <Icon source={CircleInformationMajor} />
        </Tooltip>
        <span>{title}</span>
      </>
    );
    if (this.props.renderAsIs) {
      if (third) {
        return <Layout.Section oneThird>{textField}</Layout.Section>;
      } else if (secondary) {
        return <Layout.Section secondary>{textField}</Layout.Section>;
      } else {
        return textField;
      }
    }
    if (this.props.noContent) {
      return <h3 className="custom-title-onlayout">{newTitle}</h3>;
    }

    let card = (
      <span className="SetupCard">
        <Card title={newTitle} sectioned>
          <Form>{textField}</Form>
        </Card>
      </span>
    );
    if (third) {
      return <Layout.Section oneThird>{card}</Layout.Section>;
    } else if (secondary) {
      return <Layout.Section secondary>{card}</Layout.Section>;
    } else if (this.props.card) {
      return <Layout.Section>{card}</Layout.Section>;
    }
    description = this.props.videoDescription ? (
      <>
        <div>{description}</div>
        <video
          className="video-description"
          src={this.props.videoDescription}
          controls
        ></video>
      </>
    ) : (
      description
    );

    const layoutRenderObj = [
      <Layout.AnnotatedSection
        key={this.props.index}
        title={newTitle}
        secondary={secondary || false}
        description={description}
      >
        {textField && (
          <Card
            sectioned
            title={this.props.cardTitle ? this.props.cardTitle : ""}
          >
            {this.props.cardTitleCustom && (
              <div className="custom-title">
                {utility.objetToHtmlParse(this.props.cardTitleCustom)}
              </div>
            )}
            <Form>
              <FormLayout>{textField}</FormLayout>
            </Form>
          </Card>
        )}
      </Layout.AnnotatedSection>,
    ];

    if (labelHalt) {
      return (
        <label
          htmlFor={this.props.index}
          className={`full-size ${this.props.name}`}
        >
          {layoutRenderObj}
        </label>
      );
    }
    return layoutRenderObj;
  }

  displayNumAgent(keyElement) {
    let ordNum = "st";
    if (keyElement === 2) {
      ordNum = "nd";
    } else if (keyElement === 3) {
      ordNum = "rd";
    } else if (keyElement >= 4) {
      ordNum = "th";
    }
    return keyElement + ordNum + " Live Chat User";
  }

  handleCheckboxChange(value, nameValue, itemValue) {
    if (this.props.navSave) this.props.setIsDirty(true);
    let checkbox = this.props.dataToSave[nameValue];
    if (typeof itemValue === "boolean") {
      checkbox = [value];
    } else if (checkbox) {
      let canInsert = true;
      checkbox.map((item, index) => {
        if (item === itemValue && !value) {
          checkbox.splice(index, 1);
          return (canInsert = false);
        }
        return true;
      });
      if (canInsert) checkbox.push(itemValue);
    } else {
      checkbox = [itemValue];
    }
    this.props.stateDataSave(nameValue, checkbox);
  }
  handleChange = (value, nameValue) => {
    if (this.props.navSave) this.props.setIsDirty(true);
    this.props.stateDataSave(nameValue, value);
  };
  getImage = async (file, name, key, config = { imageId: null }) => {
    const File = file[0];
    this.setState({
      errorImage: "",
    });
    if (!utility.imageAcepted(File)) {
      this.setState({
        errorImage:
          "Invalid format. Ensure your file format is: jpeg, png or svg",
      });
      return false;
    }
    if (!utility.fileSizeMax(File.size)) {
      this.setState({
        errorImage: "The image size should not exceed 70MB",
      });
      return false;
    }
    let dataURL = URL.createObjectURL(File);
    const imageAgent = this.state.imageAgent;
    let newImageAgent = {
      FileName: File.name,
      blob: dataURL,
      imageId: config.imageId,
    };
    if (imageAgent.length >= key) {
      imageAgent[key] = newImageAgent;
    } else {
      for (let index = 0; index <= key; index++) {
        if (!imageAgent[index] && parseInt(key) !== index) {
          imageAgent.push(0);
        } else if (parseInt(key) === index) {
          imageAgent.push(newImageAgent);
        }
      }
    }
    await this.setStateAsync({
      imageAgent: imageAgent,
    });
    setTimeout(async () => {
      let bannerImage = document.getElementById(config.imageId);
      let imgData = this.getBase64Image(bannerImage);
      if (!imgData) {
        imageAgent.splice(key, 1);
        await this.setStateAsync({
          imageAgent: imageAgent,
          errorImage: "Ensure your file max width is: 100 x 100",
        });
      } else if (!this.props.navSave && imgData) {
        localStorage.setItem("numAgents", imageAgent.length);
        localStorage.setItem("agent" + key, imgData);
      }
    }, 800);
  };
  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }
  getBase64Image(img) {
    img.style.width = "auto";
    if (img.width > 100 || img.height > 100) return false;
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    var dataURL = canvas.toDataURL("image/png");
    img.style.width = "0px";
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }
}

export default MeetCustomer;
