import React, { Component } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
  Redirect,
  useParams,
} from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Spinner } from "reactstrap";
import axios from "axios";
import cookie from "js-cookie";
import Install from "./containers/Install";
import Login from "./components/Admin/Login/Login";
import ResetPassword from "./components/Admin/ResetPassword/ResetPassword";
import Signup from "./components/Admin/Signup/Signup";
import Callback from "./components/Install/Callback";
import NewBillCallback from "./components/General/NewBillCallback/NewBillCallback";
import "@shopify/polaris/styles.css";
import Frame from "./containers/Frame/Frame";
import GetInvoice from "./containers/GetInvoice";
import Messages from "./components/Messages/Messages";
import backendUtility from "./components/General/backendUtility.js";
import Config from "./config.js";
import ErrorRequest from "./components/General/ErrorRequest";
import en from "@shopify/polaris/locales/en.json";
import { AppProvider } from "@shopify/polaris";
import theme from "./theme";
import { QueryClientProvider } from "react-query";
import { queryClient } from "./constants";

import PrivacyPolicy from "./containers/EnrollmentForm/PrivacyPolicy";
import Copyrights from "./containers/EnrollmentForm/Copyrights";
import TermsOfService from "./containers/EnrollmentForm/TermsOfService";
import EFContainer from "./containers/EnrollmentForm/EFContainer";
import DispositionContextProvider from "./contexts/dispositions-context";
import SnackBarProvider from "./contexts/snackbar";
import PhoneNumberCheck from "./containers/EnrollmentForm/PhoneNumberCheck";
import AccountMode from "./containers/EnrollmentForm/AccountMode";

const serverAPI = Config.apiGateway.URL;
const bucketCDN = Config.bucketCDN.URL;
axios.defaults.headers.common["Authorization"] = Config.backendGUID;

var _shop = false;
class App extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      auth: false,
      error: false,
      messageData: [],
      currentMessageID: null,
    };
  }
  componentDidMount() {
    const shop = cookie.get("shop");
    if (shop && shop !== "undefined") this.authUser(shop);
    else this.setState({ loading: false });
  }
  /**
   * auth user to shop
   */
  authUser = async (shop) => {
    return await axios
      .get(serverAPI + "/shopify/user/access/" + shop)
      .then((res) => {
        if (res.data && res.data.length > 0) {
          _shop = res.data[0];
          this.setState({ loading: false, auth: true });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        this.setState({
          loading: false,
          error: err.response,
        });
      });
  };

  userRoutes = ({ match }) => {
    const UserRouterProps = withRouter((props) => (
      <Frame
        {...props}
        shop={_shop}
        serverAPI={serverAPI}
        bucketCDN={bucketCDN}
      />
    ));

    return (
      <Route
        render={(props) =>
          this.state.auth ? (
            <UserRouterProps />
          ) : (
            <Login {...props} path="/login" serverAPI={serverAPI} />
          )
        }
      />
    );
  };

  resetStoredShop = () => {
    cookie.remove("state");
    cookie.remove("textchatapi");
    cookie.remove("shop");
    window.location.reload();
  };

  render() {
    const url_string = window.location.href;
    const url = new URL(url_string);
    if (
      url.searchParams.get("hmac") &&
      url.searchParams.get("shop") &&
      url.searchParams.get("timestamp") &&
      cookie.get("shop")
    ) {
      if (url.searchParams.get("shop") !== cookie.get("shop"))
        this.resetStoredShop();
      else
        axios
          .get(serverAPI + "/shopify/user/access/valid/" + cookie.get("shop"))
          .then((response) => {
            if (response.data === false) this.resetStoredShop();
            else console.log("response not false: " + response.data);
          });
    } else if (
      url.searchParams.get("clientName") &&
      url.searchParams.get("token")
    ) {
      cookie.set("textchatapi", url.searchParams.get("token"));
      cookie.set("shop", url.searchParams.get("clientName"));
    }
    if (this.state.loading)
      return (
        <div className="text-center">
          <Spinner color="primary" />
        </div>
      );
    if (this.state.error)
      return (
        <AppProvider i18n={en}>
          <ErrorRequest
            shop={cookie.get("shop") || null}
            serverAPI={serverAPI}
            error={this.state.error}
          />
        </AppProvider>
      );
    return (
      <QueryClientProvider client={queryClient}>
        <DispositionContextProvider serverAPI={serverAPI}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <SnackBarProvider>
              <Router>
                <Switch>
                  <Route
                    exact
                    path="/shopify/callback"
                    render={() => <Callback serverAPI={serverAPI} />}
                  />
                  <Route
                    exact
                    path="/frame"
                    render={(props) => (
                      <Frame serverAPI={serverAPI} {...props} />
                    )}
                  />
                  <Route
                    path="/download/invoice"
                    render={(props) => <GetInvoice serverAPI={serverAPI} />}
                  />
                  <Route
                    exact
                    path="/app/shopify/application/charge/process"
                    render={(props) => {
                      const url_string = window.location.href;
                      const url = new URL(url_string);
                      const charge_id = url.searchParams.get("charge_id");
                      return (
                        <Redirect
                          to={
                            charge_id
                              ? "/billing?charge_id=" + charge_id
                              : "/billing"
                          }
                        />
                      );
                    }}
                  />
                  <Route
                    exact
                    path="/messages"
                    render={(props) => (
                      <Messages shop={_shop.shop} serverAPI={serverAPI} />
                    )}
                  />
                  <Route
                    exact
                    path="/confirmNewBill"
                    render={(props) => {
                      const url_string = window.location.href;
                      const url = new URL(url_string);
                      const charge_id = url.searchParams.get("charge_id");
                      return (
                        <NewBillCallback
                          chargeId={charge_id}
                          serverAPI={serverAPI}
                          shop={_shop.shop}
                        />
                      );
                    }}
                  />
                  <Route
                    path={[
                      "/forgot-password",
                      "/admin/reset/:clientToken/:resetToken",
                    ]}
                    render={(props) => (
                      <ResetPassword serverAPI={serverAPI} {...props} />
                    )}
                  />
                  <Route
                    path="/enrollment-form/:form"
                    render={(props) => (
                      <EFContainer serverAPI={serverAPI} {...props} />
                    )}
                  />
                  <Route
                    path="/phonenumber-check"
                    render={(props) => (
                      <PhoneNumberCheck serverAPI={serverAPI} {...props} />
                    )}
                  />
                  <Route
                    path="/accountmode"
                    render={(props) => (
                      <AccountMode serverAPI={serverAPI} {...props} />
                    )}
                  />
                  <Route
                    path="/privacy-policy"
                    render={(props) => (
                      <PrivacyPolicy serverAPI={serverAPI} {...props} />
                    )}
                  />
                  <Route
                    path="/copyrights"
                    render={(props) => (
                      <Copyrights serverAPI={serverAPI} {...props} />
                    )}
                  />
                  <Route
                    path="/signup"
                    render={(props) => (
                      <Signup serverAPI={serverAPI} {...props} />
                    )}
                  />
                  <Route
                    exact
                    component={this.userRoutes}
                    serverAPI={serverAPI}
                  />
                </Switch>
              </Router>
            </SnackBarProvider>
          </ThemeProvider>
        </DispositionContextProvider>
      </QueryClientProvider>
    );
  }
}

export default App;
