import React, { Component } from 'react';
import { jsPlumb } from 'jsplumb';
import Nodes from './Nodes';

class ChatFlow extends Component {
    /**
     * Validate whats be mount
     */
    constructor(){
        super();
        this.jsPlumbInstance = jsPlumb.getInstance();
    }
    componentDidMount() {

        this.jsPlumbInstance.reset();
        jsPlumb.reset();
        this.jsPlumbInstance.bind("ready", function () {
            this.jsPlumbInstance.Defaults.Container = document.getElementById(
                "container-plumb"
            );
            this.jsPlumbInstance.importDefaults({ Connector: ["Flowchart"] });
            this.jsPlumbInstance.reset();
            this.jsPlumbInstance.on(window, "resize", this.jsPlumbInstance.repaintEverything);
            let nodes = [...this.props.nodeDom];
            this.startNodes(nodes);
        }.bind(this));

    }

    startNodes = async (nodes) => {
        let idsArr = [];
        var currParent = null;
        var findAgent = null;
        nodes.map(function (el) {
            currParent = el.parentNode;
            idsArr[el.idNode] = el.idContainer;
            let idContainer = el.idContainer;
            findAgent = document.getElementById(idContainer);
            this.jsPlumbInstance.revalidate(findAgent);
            if (currParent) {
                this.jsPlumbInstance.connect({
                    source: idsArr[currParent],
                    target: findAgent,
                    anchor: ["Top", "Bottom"],
                    detachable: false,
                });
            }
        }.bind(this));
    };
    deleteNode = async (element) => {
        this.props.resetNodes();
        await this.props.sleep(500);
        jsPlumb.reset();
        let nodeDom = [...this.props.nodeDom];
        let nodeObj = [...this.props.nodeObj];
        nodeDom[0].left = 95;
        nodeDom[1].left = 95;
        nodeObj[1].btns.pop();
        this.props.updateNodes(nodeDom, nodeObj);
        await this.props.sleep(500);

        this.jsPlumbInstance.bind("ready", () => {
            this.jsPlumbInstance.Defaults.Container = document.getElementById(
                "container-plumb"
            );
            this.jsPlumbInstance.importDefaults({ Connector: ["Flowchart"] });
            this.jsPlumbInstance.on(window, "resize", jsPlumb.repaintEverything);
            let nodes = [...this.props.nodeDom];
            this.startNodes(nodes);
        });
    };
    render() {
        return (
            <div id="container-plumb">
                <Nodes
                    nodeDom={this.props.nodeDom}
                    nodeObj={this.props.nodeObj}
                    deleteNode={this.deleteNode}
                    selectNode={this.props.selectNode}
                    editing={this.props.editing}
                />
            </div>
        );
    }
}

export default ChatFlow;