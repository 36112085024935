import React, { useDebugValue, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
// import PinInput from "react-pin-input";
import Config from "../../config.js";
import axios from "axios";
import Stars from "../../images/stars3.png";
import { Alert } from "reactstrap";
import ErrorModal from "./ErrorModal";
import IconTel from "../../images/icon-phone.png";
import Loader from "./Loader.js";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const serverAPI = Config.apiGateway.URL;
axios.defaults.headers.common["Authorization"] = Config.backendGUID;

const SignUpExisting = () => {
  const history = useHistory();
  const [verificationCode, setVerificationCode] = useState("");
  const [phoneTree, setPhoneTree] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [sid, setSid] = useState("");
  const [isErrorModal, setErrorModal] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [isPhoneTree, setIsPhoneTree] = useState(false);
  const [eligibilityStatus, setEligibilityStatus] = useState("");
  const [ownershipverificationStatus, setOwnershipverificationStatus] =
    useState({});
  const [isLoadingLoA, setIsLoadingLoA] = useState(false);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    var temp = JSON.parse(localStorage.getItem("phoneTree"));
    setIsPhoneTree(temp);
    hostednumberorderstatus();
  }, []);

  const checkEligibility = async () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    return await axios
      .post(serverAPI + `/app/sms/hostednumberorder/${userInfo.landlineNumber}`)
      .then((res) => {
        setEligibilityStatus(res.data);
        return res.data;
      })
      .catch((error) => {
        // error.response.data contains the custom error message
        if (error.response && error.response.data) {
          setIsValid(true);
          setErrorText(error.response.data);
        } else {
          setIsValid(false);
          setErrorText(
            "We're sorry, there was an error processing your request"
          );
        }
      });
  };

  const toggleErrorModal = () => {
    setErrorModal(!isErrorModal);
  };

  const onCallLaunch = () => {
    let response = ownershipverification().catch(error => toggleErrorModal());
  };

  const onCheckEligibility = () => {
    let response = checkEligibility();
    // setEligibilityStatus("twillioProcessing");
    // setTimeout(() => {
    //   setEligibilityStatus("received");
    // }, 4000);
    // if (response.status == "twillioProcessing") {
    //   setEligibilityStatus(response.status);
    // } else {
    //   return;
    // }

    if (eligibilityStatus.status == "twilio-processing") {
    }
  };

  const handleSubmitCode = async () => {
    const result = await verifyToken();
    if (result == "approved") {
      setIsValid(true);
      history.push("/complete-one");
    } else {
      setIsValid(false);
    }
  };

  const ownershipverification = async () => {
    setIsLoading(true);
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const userSid = localStorage.getItem("userSid");
    let formValues = {
      email: userInfo.email,
      phoneNumber: userInfo.landlineNumber,
      extension: isPhoneTree ? phoneTree : "",
      sid: userSid ? userSid : "",
    };
    return await axios
      .post(serverAPI + `/app/sms/ownershipverification`, formValues)
      .then((res) => {
        console.log("RES: ", res);
        setOwnershipverificationStatus(res.data);
        setVerificationCode(res.data.verification_code);
        setIsLoading(false);
        return res.data;
      })
      .catch((error) => {
        // error.response.data contains the custom error message
        if (error.response && error.response.data) {
          throw new Error(error.response.data);
        } else {
          throw error;
        }
        setIsLoading(false);
      });
  };

  const hostednumberorder = () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const formValues = {
      email: userInfo.email,
      phoneNumber: userInfo.landlineNumber,
      extension: isPhoneTree ? phoneTree : "",
    };
    var mockRes = {
      status: "pending-verification",
      date_updated: "2018-01-21T22:31:43Z",
      verification_code: "310001",
      incoming_phone_number_sid: "PN876a18805574a952ffb73907b24205f0",
      failure_reason: "",
      verification_attempts: 1,
      verification_type: "phone-call",
      capabilities: {
        voice: false,
        mms: false,
        sms: true,
      },
      sid: "HR36f3f0c7c8d5d9210114fdcd8b6bcf19",
      phone_number: "+18444905863",
      call_delay: 0,
      signing_document_sid: null,
      verification_document_sid: "RI2ad9942e6daa7d8e87a154f6f675f3f8",
      verification_call_sids: ["CA83f7aa47a1760188e892fe8c2ba86749"],
      extension: null,
      url: "https://preview.twilio.com/HostedNumbers/HostedNumberOrders/HR36f3f0c7c8d5d9210114fdcd8b6bcf19",
      friendly_name: "My_Sms_Hosted_Number_Order",
      account_sid: "ACXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
      date_created: "2018-01-21T21:40:55Z",
    };
    setVerificationCode(mockRes.verification_code);
    setSid(mockRes.sid);
    // return mockRes;
    // return await axios
    //   .post(serverAPI + `/app/sms/hostednumberorder`, formValues)
    //   .then((res) => {
    //     return res.data;
    //   });
  };

  const hostednumberorderstatus = async () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const userSid = localStorage.getItem("userSid");
    // const formValues = {
    //   sid: sid,
    //   firstName: userInfo.firstName,
    //   lastName: userInfo.lastName,
    //   email: userInfo.email,
    //   address: userInfo.address,
    //   suite: userInfo.suite,
    //   city: userInfo.city,
    //   state: userInfo.state,
    //   zipCode: userInfo.zipCode,
    // };
    // history.push("/enrollment-form/agent-transfer");

    return await axios
      .post(serverAPI + `/app/sms/hostednumberorderstatus/${userSid}`)
      .then((res) => {
        setOwnershipverificationStatus(res.data);
        return res.data;
      });
  };

  const onContinue = async () => {
    setIsLoadingLoA(true);
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const userSid = localStorage.getItem("userSid");
    let formValues = {
      sid: userSid,
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      email: userInfo.email,
      address: userInfo.address,
      suite: userInfo.suite && userInfo.suite != "" ? userInfo.suite : "",
      city: userInfo.city,
      state: userInfo.state,
      zipCode: userInfo.zipCode,
    };
    return await axios
      .post(serverAPI + `/app/sms/createloaandsign`, formValues)
      .then((res) => {
        history.push("/enrollment-form/completeOne");
        setIsLoadingLoA(false);
        return res.data;
      })
      .catch((error) => {
        toggleErrorModal();
      });
  };

  const initiateVerificationCall = async () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    return await axios
      .post(
        serverAPI +
          `/app/sms/initiateVerificationCall/${userInfo.landlineNumber}`
      )
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        // error.response.data contains the custom error message
        if (error.response && error.response.data) {
          setIsValid(true);
          setErrorText(error.response.data);
        } else {
          setIsValid(false);
          setErrorText(
            "We're sorry, there was an error processing your request"
          );
        }
      });
  };

  const verifyToken = async () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    return await axios
      .post(
        serverAPI +
          `/app/sms/verifyToken/${userInfo.landlineNumber}/${verificationCode}`
      )
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        // error.response.data contains the custom error message
        if (error.response && error.response.data) {
          setIsValid(true);
          setErrorText(error.response.data);
        } else {
          setIsValid(false);
          setErrorText(
            "We're sorry, there was an error processing your request"
          );
        }
      });
  };

  const toggle = () => {
    setModal(!modal);
  };

  return (
    <div className="existing-with-new-copy mt-80">
      <div className="check-you-out w-100">
        {/* <img src={Stars} alt="stars" className="img-star img-center" /> */}
        <div className="check-you-out-text text-black">
          {isPhoneTree
            ? "Let’s make sure your number works!"
            : "Let’s make sure your number is working!"}
        </div>
      </div>
      {!isValid && (
        <div>
          <Alert
            color="danger"
            className="font-20"
            style={{ margin: "0 5px 15px 5px" }}
          >
            {errorText}
          </Alert>
        </div>
      )}
      <div className="steps-header">
        {isPhoneTree
          ? "Step1:  Please enter the phone tree option number that your customers press to speak to someone.  For example, if it says, “press 3 to speak with a representative,” enter 3 in the box below, then press launch call."
          : "Step1:  Click below to launch your confirmation call "}
      </div>
      {isPhoneTree && (
        <div className="phone-tree-input">
          <input
            type="text"
            id="phonetree"
            maxLength={4}
            value={phoneTree}
            onChange={(e) => {
              setPhoneTree(e.target.value);
              setIsValid(true);
            }}
            placeholder="Type here"
            style={{
              background: "transparent",
              border: "none",
              borderBottom: "3px solid #EEAA11",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              marginRight: "16px",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "35px",
              lineHeight: "125%",
              textAlign: "center",
              letterSpacing: "0.2px",
              color: "#305973",
            }}
            onFocus={() => {
              document
                .getElementById("phonetree")
                .removeAttribute("placeholder");
            }}
          />
        </div>
      )}

      <div
        className={`verification-call-btn justify-content-center text-center ${
          !isValid && eligibilityStatus != "received" ? "less-bottom" : ""
        }`}
      >
        {isLoading ? (
          <Loader />
        ) : (
          // <button onClick={() => onCallLaunch()}>
          <button onClick={() => toggle()}>Launch call</button>
        )}
      </div>
      <div className="steps-header mt-30">
        Step 2: When you launch the call, your 6-digit code will appear here.
      </div>
      <div className="verification-code-input">
        <h2
          style={{
            background: "#F1F3F6",
            border: "2px solid #EEAA11",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            borderRadius: "10px",
            // marginRight: "16px",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "35px",
            lineHeight: "125%",
            textAlign: "center",
            letterSpacing: "0.2px",
            color: "#305973",
          }}
        >
          {verificationCode && isValid ? verificationCode : "XXXXXX"}
        </h2>
      </div>
      <div className="steps-header mt-30">
        Step 3: When prompted, enter the above code in your landline phone. Once
        complete, press next.
      </div>

      {/* {!isPhoneTree && (
        <div className="icon-telephone mt-30 text-center">
          <img src={IconTel} style={{ width: "120px" }} />
        </div>
      )} */}
      <div className="submit-code-btn mt-30 justify-content-center text-center">
        {isLoadingLoA ? (
          <Loader />
        ) : (
          <button
            disabled={!verificationCode || verificationCode.length < 6}
            onClick={() => onContinue()}
          >
            Next
          </button>
        )}
      </div>
      {!isLoading && (
        <>
          <ErrorModal
            isErrorModal={isErrorModal}
            toggleErrorModal={toggleErrorModal}
          />
        </>
      )}
      {!verificationCode && !isLoading && !isLoadingLoA && (
        <>
          <Modal
            isOpen={modal}
            toggle={() => toggle()}
            className="existing-copy"
          >
            <ModalBody>
              <div className="check-you-out-text text-black logo-wrpper font-28">
                Please Note:
              </div>
              <div className="mt-20">
                <div className="text-center light-text font-20">
                  Before clicking continue, please be sure that you have
                  <br />
                  your business landline phone with you and ready to <br />{" "}
                  answer the call to verify!
                </div>
              </div>

              <div className="row mt-20 p-0">
                <div className="col-6">
                  <button
                    className="btn-submit btn-cancel"
                    onClick={() => toggle()}
                  >
                    Cancel{" "}
                  </button>
                </div>
                <div className="col-6 text-end">
                  <button
                    className="btn-submit"
                    onClick={() => {
                      onCallLaunch();
                      toggle();
                    }}
                  >
                    Continue{" "}
                  </button>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </>
      )}
    </div>
  );
};

export default SignUpExisting;
