import React, { Component } from 'react';
import ChatFlow from './ChatFlow';
import Editor from './Editor';
import nodeDom from './ChatFlow/json/nodes.json';
import nodeObj from './ChatFlow/json/elements.json';
import './style.css';

class ScriptGenerator extends Component {
    constructor() {
        super();

        this.sleep = this.sleep.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.setStateAsync = this.setStateAsync.bind(this);
        this.state = {
            numNodes: nodeObj.length,
            nodeType: 0,
            nodeObj: [...nodeObj],
            nodeDom: [...nodeDom],
            countId: nodeObj.length,
            newNodes: [],
            editing: 1,
            editingIndex: nodeDom[0],
            editingExtraNodes: 0,
            firstUpdate: false
        }
    }
    componentDidUpdate(prevProps) {
        const { chatFlow } = this.props;
        const update = typeof chatFlow !== "undefined";
        if (
            update &&
            !this.state.firstUpdate &&
            chatFlow &&
            chatFlow.length &&
            chatFlow !== prevProps.chatFlow
        ) {
            this.setState({
                firstUpdate: true,
                nodeObj: chatFlow,
            });
        }
    }
    selectNode = (e, node, index, extraNodes) => {
        this.setState({
            editing: node,
            editingIndex: [...this.state.nodeDom][index],
            editingExtraNodes: extraNodes,
        });
    }
    sleep(delay) {
        var start = new Date().getTime();
        while (new Date().getTime() < start + delay);
    }
    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve)
        });
    }
    changeHandler(e) {
        this.setState({ [e.target.name]: e.target.value });
    }
    changeNodeText = (e) => {
        let nodeObjAux = [...this.state.nodeObj];
        if (this.state.editingIndex.isNode) {
            if (!this.state.editingIndex.msgInput) {
                nodeObjAux[this.state.editingIndex.parentNode - 1].btns[
                    this.state.editingIndex.indexBtn
                ].msg = e.target.value;
            }
            else {
                nodeObjAux[
                    this.state.editingIndex.parentNode - 1
                ].input.placeholder = e.target.value;
            }
        }
        else {
            nodeObjAux[
                this.state.editingIndex.id - this.state.editingExtraNodes
            ].msg = e.target.value;
        }

        this.setState({ nodeObj: nodeObjAux });

        const { chatFlow, name, handleChange } = this.props;

        if (chatFlow && name && handleChange) {
            let chatFlowSave = nodeObjAux;
            handleChange(chatFlowSave, name);
        }

    }
    resetNodes = async (chatFlow = [] ,chatFlowDom = []) => {

        this.setState({
            numNodes: chatFlow.length || nodeObj.length,
            nodeType: 0,
            nodeObj: chatFlow || [...nodeObj],
            nodeDom: chatFlowDom || [...nodeDom],
            countId: chatFlow.length || nodeObj.length,
            newNodes: [],
            editing: 1,
            editingIndex: chatFlowDom[0] || nodeDom[0],
            editingExtraNodes: 0
        })
    }
    updateNodes = async (nodeDom, nodeObj) => {
        this.setState({ nodeDom: nodeDom, nodeObj: nodeObj });

    }
    render() {
        if(!this.state.nodeDom.length || !this.state.nodeObj.length) return null;
        return (
            <div>
                <Editor
                    messageEditing={
                        this.state.editingIndex.isNode
                            ? !this.state.editingIndex.msgInput
                                ? this.state.nodeObj[
                                      this.state.editingIndex.parentNode - 1
                                  ].btns[this.state.editingIndex.indexBtn].msg
                                : this.state.nodeObj[
                                      this.state.editingIndex.parentNode - 1
                                  ].input.placeholder
                            : this.state.nodeObj[
                                  this.state.editingIndex.id -
                                      this.state.editingExtraNodes
                              ].msg
                    }
                    changeNodeText={this.changeNodeText}
                />
                <ChatFlow
                    sleep={this.sleep}
                    setStateAsync={this.setStateAsync}
                    changeHandler={this.changeHandler}
                    numNodes={this.state.numNodes}
                    nodeType={this.state.nodeType}
                    nodeObj={this.state.nodeObj}
                    nodeDom={this.state.nodeDom}
                    countId={this.state.countId}
                    newNodes={this.state.newNodes}
                    editing={this.state.editing}
                    selectNode={this.selectNode}
                    updateNodes={this.updateNodes}
                    resetNodes={this.resetNodes}
                />
            </div>
        );
    }
}
export default ScriptGenerator;