import Axios from 'axios';
import cookie from "js-cookie";

let backendUtility = {};

backendUtility.getToken = async (shop, serverAPI) => {
    let cookieToken = cookie.get("textchatapi")
    if (cookieToken)
        return cookieToken
    else {
        var token = await Axios
        .get(serverAPI + "/app/textchat/get/token", {
            params: {
               shop: shop,
             },
        })
        .catch( (error) => {
            console.warn("get token failed: " + JSON.stringify(error))
            return null
        })
        .then( (response) => {
            cookie.set("textchatapi", response.data.clientToken)
            return response.data.clientToken
        })
        return token
    }
}

export default backendUtility;