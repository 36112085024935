import React, { Component } from "react";
import ModalVideo from "react-modal-video";
import { PlayMajor } from "@shopify/polaris-icons";
import { Icon } from "@shopify/polaris";

import "./Help.scss";
import PlayButtonIcon from "../../../../assets/img/play-button.svg";
import HeaderCard from "src/components/HeaderCard";
import { Box } from "@mui/material";
// import YoutubePlayer from "../../../General/YoutubePlayer/YoutubePlayer";

const videoItems = [0, 1, 2, 3, 4, 5, 6, 7, 8];

export default class Help extends Component {
  constructor(props) {
    super(props);
    let thumb1 = require("../../../../assets/img/YoutubeThumbs/thumb1.jpg");
    let thumb2 = require("../../../../assets/img/YoutubeThumbs/thumb2.jpg");
    this.state = {
      helpData: [
        {
          slug: "4SXLytqoUsA",
          img: thumb1,
        },
        {
          slug: "aooJFrkC4iE",
          img: thumb2,
        },
      ],
      current: 0,
      toDisplay: "videos",
      openModal: false,
      currentSlug: null,
    };
  }
  componentDidMount() {
    this.onMount();
  }

  onMount = async () => {};

  handleOpenVideoModal = (slug) => {
    this.setState({
      openModal: true,
      currentSlug: slug,
    });
  };

  handleCloseVideoModal = () => {
    this.setState({
      openModal: false,
    });
  };

  render() {
    const { currentSlug, helpData, current, openModal } = this.state;

    return (
      <div className="help-center">
        <Box display="flex" justifyContent="center" marginBottom={"20px"}>
          <HeaderCard
            title="AnswerNet Learning center"
            description="Take a look below and learn all about the awesome communication tools
          we have! You can also see how to use our platform"
          />
        </Box>

        <div className="help-center-videos">
          {videoItems.map((item, index) => (
            <div className="help-center-video" key={index}>
              {/* <YoutubePlayer
                vidSlug={this.state.helpData[this.state.current]["slug"]}
              /> */}
              <div
                className="help-center-video-play-panel"
                onClick={() =>
                  this.handleOpenVideoModal(helpData[current]["slug"])
                }
              >
                <img
                  className="help-center-video-play-icon"
                  src={PlayButtonIcon}
                  alt=""
                />
              </div>
              <div className="help-center-video-panel">
                <div className="help-center-video-panel-header">
                  <div className="help-center-video-panel-header-text">
                    How to customize chatbot
                  </div>
                  <div className="help-center-video-panel-header-duration">
                    10:05
                  </div>
                </div>
                <div className="help-center-video-panel-desc">
                  Lorem ipsum descriptions
                </div>
              </div>
            </div>
          ))}
        </div>
        <ModalVideo
          channel="youtube"
          autoplay
          isOpen={openModal}
          videoId={currentSlug}
          onClose={() => this.handleCloseVideoModal()}
        />
      </div>
    );
  }
}
