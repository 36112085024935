import React, { useState } from "react";
import {
  Button,
  ButtonBase,
  ButtonGroup,
  FormControl,
  IconButton,
  InputLabel,
  styled,
  Typography,
} from "@mui/material";
import cookie from "js-cookie";
import { yupResolver } from "@hookform/resolvers/yup";
import answernetLogo from "../../../assets/img/answernet-logo.png";
import Box from "@mui/material/Box";
import * as Yup from "yup";
import { Paper, useTheme } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";
import { useSnackBarContext } from "../../../contexts/snackbar";
import { useMutation } from "react-query";
import { LoadingButton } from "@mui/lab";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import InputWithIcon from "src/components/Input/InputWithIcon";
import { NormalInput } from "src/components/Input/NormalInput";

const loginSchema = Yup.object({
  email: Yup.string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const StyledInputLabel = styled(InputLabel)({
  fontSize: "18px",
});

const StyledImage = styled("img")({});

const Login = ({ serverAPI, history }) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const [loggedInAccounts, setLoggedInAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState<any>();

  const theme = useTheme();

  const { openSnackbarContext } = useSnackBarContext();

  const { control, formState, handleSubmit } = useForm({
    resolver: yupResolver(loginSchema),
    mode: "all",
  });

  const loginMutation = useMutation((data: any) =>
    axios.post(serverAPI + "/app/login", data)
  );

  const onLogin = async (data) => {
    try {
      const loginResponse = await loginMutation.mutateAsync({
        pw: data.password,
        email: data.email,
      });
      const loginData = loginResponse.data;
      if (loginData.length === 1) {
        let client = loginData[0];

        handleAdminLogin({
          token: client?.clientToken,
          shop: client.clientName,
        });
      } else if (loginData.length > 1) {
        setLoggedInAccounts(loginData);
      } else {
        openSnackbarContext("Login failed, please try again", "error");
      }
    } catch (error) {}
  };

  const handleAdminLogin = ({ token, shop }) => {
    cookie.set("token", token);
    cookie.set("shop", shop);
    openSnackbarContext("You have been logged in successfully", "success");
    window.location.reload();
  };

  return (
    <Box
      sx={
        {
          // backgroundColor: theme.palette.background.darker,
        }
      }
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box>
        <Paper
          elevation={0}
          sx={{
            width: "500px",
            padding: theme.spacing(3),
          }}
        >
          <>
            {loggedInAccounts.length > 0 ? (
              <Box width="100%">
                <Typography textAlign="center" variant="h4" fontWeight="bold">
                  Multiple Accounts
                </Typography>
                <Typography
                  marginBottom={theme.spacing(2)}
                  fontWeight="400"
                  textAlign="center"
                  variant="h5"
                >
                  You have multiple accounts, select one to continue
                </Typography>
                <ButtonGroup
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                  fullWidth
                >
                  {loggedInAccounts.map((account: any) => (
                    <Button
                      variant={
                        account?.clientToken === selectedAccount?.clientToken
                          ? "contained"
                          : "outlined"
                      }
                      color="secondary"
                      sx={{
                        marginBottom: theme.spacing(1),
                      }}
                      onClick={() => {
                        setSelectedAccount(account);
                      }}
                    >
                      {account.clientName}
                    </Button>
                  ))}
                </ButtonGroup>
                <Button
                  fullWidth
                  onClick={() =>
                    handleAdminLogin({
                      token: selectedAccount?.clientToken,
                      shop: selectedAccount.clientName,
                    })
                  }
                  disabled={!selectedAccount}
                  sx={{
                    marginTop: theme.spacing(2),
                  }}
                  variant="contained"
                >
                  Continue
                </Button>
              </Box>
            ) : (
              <>
                <Box
                  display="flex"
                  justifyContent="center"
                  paddingY={theme.spacing(2)}
                  flexDirection="column"
                  alignItems="center"
                >
                  <Box marginBottom={theme.spacing(3)}>
                    <StyledImage sx={{ height: "100px" }}src={answernetLogo} />
                  </Box>
                  <Typography variant="h4" fontWeight="bold">
                    Sign In
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column">
                  <form onSubmit={handleSubmit(onLogin)}>
                    {/* <Box marginY={theme.spacing(2)}>
                      <InputLabel
                        sx={{
                          fontSize: "14px",
                        }}
                      >
                        Choose Access
                      </InputLabel>
                      <Controller
                        control={control}
                        name="access"
                        render={({ field, fieldState }) => (
                          <FormControl variant="standard" fullWidth>
                            <Select
                              labelId="choose-access-select"
                              displayEmpty
                              id="access-select"
                              {...field}
                              input={<NormalInput />}
                              sx={{
                                "#access-select": {
                                  paddingY: theme.spacing(2),
                                  minHeight: "0px",
                                },
                              }}
                            >
                              <StyledMenuItem value={undefined}>
                                Choose Access
                              </StyledMenuItem>
                              <StyledMenuItem value="admin">
                                Administrator
                              </StyledMenuItem>
                              <StyledMenuItem value="user">User</StyledMenuItem>
                            </Select>
                          </FormControl>
                        )}
                      />
                    </Box> */}
                    <Controller
                      name="email"
                      control={control}
                      render={({ field, fieldState }) => (
                        <FormControl fullWidth variant="standard">
                          <StyledInputLabel shrink htmlFor="email">
                            Email
                          </StyledInputLabel>
                          <NormalInput
                            {...field}
                            {...fieldState}
                            sx={{
                              borderRight: "none",
                            }}
                            error={!!formState.errors.email}
                            fullWidth
                            placeholder="Enter email"
                            id="email"
                          />
                          {formState.errors.email && (
                            <Typography
                              color="error"
                              fontSize="12px"
                              marginTop="4px"
                            >
                              {(formState as any).errors.email.message}
                            </Typography>
                          )}
                        </FormControl>
                      )}
                    />
                    <Box marginTop={theme.spacing(3)}>
                      <Controller
                        name="password"
                        control={control}
                        render={({ field, fieldState }) => (
                          <FormControl fullWidth variant="standard">
                            <StyledInputLabel shrink htmlFor="password">
                              Password
                            </StyledInputLabel>
                            <Box marginTop={theme.spacing(3)}>
                              <InputWithIcon
                                inputProps={
                                  {
                                    ...field,
                                    ...fieldState,
                                    type: passwordShown ? "text" : "password",
                                    error: !!formState.errors.password,
                                  } as any
                                }
                                IconComponent={
                                  <IconButton
                                    onClick={() =>
                                      setPasswordShown(!passwordShown)
                                    }
                                  >
                                    {!passwordShown ? (
                                      <VisibilityOff
                                        sx={{
                                          fontSize: "20px",
                                        }}
                                      />
                                    ) : (
                                      <Visibility
                                        sx={{
                                          fontSize: "20px",
                                        }}
                                      />
                                    )}
                                  </IconButton>
                                }
                              />
                            </Box>

                            {formState.errors.password && (
                              <Typography
                                color="error"
                                fontSize="12px"
                                marginTop="4px"
                              >
                                {(formState as any).errors.password.message}
                              </Typography>
                            )}
                          </FormControl>
                        )}
                      />
                    </Box>
                    <Box marginY={theme.spacing(3)} display="flex">
                      <Typography fontSize={theme.typography.fontSize * 0.9}>
                        Forgot Passcode?
                      </Typography>
                      <ButtonBase
                        onClick={() => history.push("/forgot-password")}
                        disableRipple
                      >
                        <Typography
                          color="primary"
                          fontWeight="600"
                          marginLeft={theme.spacing(1)}
                          fontSize={theme.typography.fontSize * 0.9}
                        >
                          Click Here
                        </Typography>
                      </ButtonBase>
                    </Box>
                    <LoadingButton
                      loading={loginMutation.isLoading}
                      sx={{
                        fontWeight: "400",
                      }}
                      disabled={!formState.isValid}
                      type="submit"
                      size="medium"
                      variant="contained"
                      fullWidth
                    >
                      Sign In
                    </LoadingButton>
                  </form>
                </Box>
              </>
            )}
          </>
        </Paper>
      </Box>
    </Box>
  );
};

export default Login;
