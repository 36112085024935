let DEV_GUID = process.env.REACT_APP_DEV_GUID;
let PROD_GUID = process.env.REACT_APP_PROD_GUID;

const local = {
  apiGateway: {
    // URL: "https://jetsense-shopify-api.ngrok.io",
    URL: "https://backend-an.greendash.qa.textchat.ai",
  },
  bucketCDN: {
    URL: "https://d2fzjtk430nuyq.cloudfront.net/",
  },
  backendGUID: DEV_GUID,
};

const dev = {
  apiGateway: {
    //REGION: "us-east-2",
    URL: "https://shopifybackend-an.dev.local",
    //URL: "http://localhost:3002"
  },
  // this isnt being used but i think we need to or the app will crash
  bucketCDN: {
    URL: "https://d2fzjtk430nuyq.cloudfront.net/",
  },
  backendGUID: DEV_GUID,
  // apiGateway: {
  //   //REGION: "us-east-2",
  //   URL: "https://backend.greendash.qa.textchat.ai",
  //   //URL: "http://localhost:3002"
  // },
  // // this isnt being used but i think we need to or the app will crash
  // bucketCDN: {
  //   URL: "https://d2fzjtk430nuyq.cloudfront.net/",
  // },
  // backendGUID: DEV_GUID,
};

const qa = {
  apiGateway: {
    //REGION: "us-east-2",
    URL: "https://backend-an.greendash.qa.textchat.ai",
  },
  // this isnt being used but i think we need to or the app will crash
  bucketCDN: {
    URL: "https://d2fzjtk430nuyq.cloudfront.net/",
  },
  backendGUID: DEV_GUID,
};

const prod = {
  apiGateway: {
    URL: "https://backend.textchat.ai",
  },
  // this isnt being used but i think we need to or the app will crash
  bucketCDN: {
    URL: "https://d2fzjtk430nuyq.cloudfront.net/",
  },
  backendGUID: PROD_GUID,
};

// Default to dev if not set

const config =
  process.env.REACT_APP_STAGE === "prod"
    ? prod
    : process.env.REACT_APP_STAGE === "local"
    ? local
    : process.env.REACT_APP_STAGE === "qa"
    ? qa
    : dev;

export default {
  // Add common config values here
  ...config,
  maxAgents: 20,
};
