import { styled } from "@mui/material";

export const NameLabel = styled("div")({
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "125%",
  letterSpacing: "0.5px",
  color: "black",
});

export const ResultsWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
});

export const ResultWrapper = styled("div")({
  background: "#EBEAED",
  borderRadius: "31px",
  marginRight: "16px",
  padding: "8px 12px",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "125%",
  letterSpacing: "0.5px",
  color: "black",
  "&:last-child": {
    marginRight: "0px",
  },
});

export const OptionsWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  "& svg": {
    width: "16px",
    height: "16px",
  },
});
