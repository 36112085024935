import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { StyledEngineProvider } from "@mui/material/styles";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { CSVLink } from "react-csv";

import "./DataTable.scss";
import Messages from "../../Messages/Messages";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function DataTable({
  title,
  data,
  columns,
  isForDetail = false,
  headers,
  serverAPI,
  shop,
  isClickable = true,
}) {
  const [messagesDialogOpen, setMessagesDialogOpen] = React.useState(false);
  const [messageId, setMessageId, isSMSMessage, setIsSMSMessage] = useState(false);
  // const navigate = useNavigate();
  const handleGoToDetail = (id) => {
    if (!isForDetail) {
      window.open(`/messages?id=${id}`);
    }
  };

  const onClose = () => {
    setMessagesDialogOpen(false);
    setMessageId(null);
    setIsSMSMessage(false);
  };

  return (
    <StyledEngineProvider injectFirst>
      <div className="dashboard-content">
        <div className="dashboard-header">{title}</div>
        <div className="btn-container">
          {data && (
            <button>
              <CSVLink
                data={data}
                headers={headers}
                filename={"chat_log_detail.csv"}
              >
                <LocalPrintshopIcon />
                Export
              </CSVLink>
            </button>
          )}
        </div>
        {data && (
          <div style={{ height: 650, width: "100%" }}>
            <DataGrid
              rows={data}
              columns={columns}
              pageSize={10}
              disableColumnMenu
              disableColumnSelector
              onRowClick={(params, e) => {
                if (isClickable) {
                  setMessagesDialogOpen(true);
                  setMessageId(params.row.interactionId);
                }
              }}
            />
          </div>
        )}
      </div>
      <Dialog
        fullScreen
        open={messagesDialogOpen}
        onClose={onClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h4" component="div">
              Chat Log Detail
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
              size="large"
              sx={{
                fontSize: "30px",
              }}
            >
              <CloseIcon
                sx={{
                  fontSize: "25px",
                }}
              />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Messages shop={shop} serverAPI={serverAPI} message_id={messageId} is_sms_message={isSMSMessage} />
      </Dialog>
    </StyledEngineProvider>
  );
}

export default DataTable;
