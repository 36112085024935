import React from "react";
import "./EffortlessChatWindow.scss";
import AgentOliver from "../../../../assets/img/Chatbot-agent-oliver.jpg";
import AgentIan from "../../../../assets/img/Chatbot-agent-Ian1.jpg";

function EffortlessChatWindow(props) {
  return (
    <div className="effortlessWindow">
      <div
        className="jetsense-chatbot glass-design body-proactive active"
        style={{ background: props.chatbotWidgetColor }}
      >
        <div
          className="jetsense-chatbot-title header-proactive"
          style={{
            background: props.chatbotWidgetColor,
            color: props.widgetTextColor,
          }}
        >
          <div className="tc-agent-container js-agent-box ">
            <div className="tc-agent" style={{ zIndex: "3" }}>
              <div className="tc-agent-img-container">
                <img className="tc-agent-img" src={AgentIan} />
                <span className="badge-online">
                  <span></span>
                </span>
              </div>
              <div className="tc-agent-name">Ian</div>
            </div>
            <div className="tc-agent" style={{ zIndex: "2" }}>
              <div className="tc-agent-img-container">
                <img
                  className="tc-agent-img"
                  src="https://d2fzjtk430nuyq.cloudfront.net/f82d51e5-abe0-4cd0-a015-5362b40ee74a/5000cb05-cf57-4405-b8b3-56e40e583248.jpeg"
                />
                <span className="badge-online">
                  <span></span>
                </span>
              </div>
              <div className="tc-agent-name">Eric</div>
            </div>
            <div className="tc-agent" style={{ zIndex: "1" }}>
              <div className="tc-agent-img-container">
                <img className="tc-agent-img" src={AgentOliver} />
                <span className="badge-online">
                  <span></span>
                </span>
              </div>
              <div className="tc-agent-name">Oliver</div>
            </div>{" "}
          </div>
          <div className="company-header">
            <div className="jetsense-chatbot-companyTitle">
              <div
                className="company-title"
                style={{
                  color: props.widgetTextColor,
                }}
              >
                {props.header}
              </div>
              <div className="slogan" style={{ color: props.widgetTextColor }}>
                {props.slogan}
              </div>
            </div>
            {/* <div className="jetsense-chatbot-respondTime">
              <p className="respond-time">Our usual reply time</p>
              <i className="fa fa-clock-o" />
              <span>Online now</span>
            </div> */}
            {/* <div className="window-action btns">
              <div className="jetsense-chatbot-minimize">
                <i
                  className="textchat-fa textchat-fa-times"
                  aria-hidden="true"
                />
              </div>
            </div> */}
          </div>
          {/* <div
            className="header-greating"
            dangerouslySetInnerHTML={{ __html: props.greeting }}
          ></div> */}
          <div className="gradient-transfer-animation hidden" />
        </div>
        <div className="jetsense-chatbot-body">
          <div className="theme-new-design uhctheme--shopify keep-msg-bottom js-chatbot-top">
            <section className="page">
              <div className="chat-frame">
                <div className="chat-body">
                  <div className="row chatbot-msg type-agent" data-nodeid={1}>
                    <div className="agent-message-box">
                      <div className="tc-agent">
                        <div className="tc-agent-img-container">
                          <img
                            className="tc-agent-img"
                            src={props.chatBotIcon || "about:blank"}
                          />
                          <span className="badge-online">
                            <span></span>
                          </span>
                        </div>
                        {/* <p className="header-name">{props.header}</p> */}
                      </div>
                    </div>
                    <div className="bubble-container">
                      <div
                        className="bubble initial"
                        style={{ backgroundColor: props.AgentBubbleColor }}
                      >
                        <div className="text">
                          <div style={{ color: props.AgenttextColor }}>
                            This is what a message from your chatbot looks like.
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="time" style={{ display: "block" }}>
                      23 h
                    </div> */}
                  </div>
                  <div className="row type-visitor first">
                    <div className="visitor">
                      <div className="bubble-container">
                        <div
                          className="bubble initial"
                          style={{ backgroundColor: props.clientBubbleColor }}
                        >
                          <div
                            className="text"
                            style={{ color: props.clientTextColor }}
                          >
                            <div className="nowrap">
                              This is what a message from a visitor looks like.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="meta">
                        <img
                          src={
                            "https://pngimage.net/wp-content/uploads/2018/05/avatar-profile-png-8.png"
                          }
                          width={50}
                        />
                        {/* <div className="time" style={{ display: "none" }}>
                          Now
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <section className="msg-container">
                <div className="err">Test</div>
                <img
                  src="https://assets.jetsense.ai/textchat/smiley.png"
                  alt="smiley"
                  className="emoticons no-pointer"
                />
                <textarea
                  type="text"
                  className="msg input-enter-message"
                  placeholder="Type message here..."
                  data-emojiable="true"
                  disabled
                  defaultValue={"            "}
                />
                <div className="icon-send">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={21}
                    height={18}
                    viewBox="0 0 21 18"
                  >
                    <g fill="none" fillRule="evenodd">
                      <path
                        d="M0 0L24 0 24 24 0 24z"
                        transform="translate(-2 -3)"
                      />
                      <path
                        fill="#4285F4"
                        d="M2.01 21L23 12 2.01 3 2 10 17 12 2 14z"
                        transform="translate(-2 -3)"
                      />
                    </g>
                  </svg>
                </div>
              </section>
              <div className="chat-footer">
                <span>
                  Powered by{" "}
                  <a target="_blank" href="https://www.textchat.com">
                    TextChat
                  </a>
                </span>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EffortlessChatWindow;
