import {
  Box,
  Button,
  FormControl,
  InputLabel,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import axios from "axios";
import * as Yup from "yup";
import React, { useState } from "react";
import cookie from "js-cookie";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { BootstrapInput } from "../../Input/BootstrapInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { queryClient } from "../../../constants";
import { LoadingButton } from "@mui/lab";

const StyledInputLabel = styled(InputLabel)({
  fontSize: "18px",
  fontWeight: 500,
});

const validationSchema = Yup.object().shape({
  salesEmail: Yup.array()
    .transform(function (value, originalValue) {
      if (this.isType(value) && value !== null) {
        return value;
      }
      return originalValue ? originalValue.split(/[\s,]+/) : [];
    })
    .of(Yup.string().email(({ value }) => `${value} is not a valid email`)),
});

const EmailNotifications = (props) => {
  const [editMode, setEditMode] = useState(false);
  const [stepData, setStepData] = useState();
  const { control, formState, handleSubmit, reset, setValue, getValues } =
    useForm({
      mode: "all",
      resolver: yupResolver(validationSchema),
    });

  useQuery(
    "FETCH_INFO",
    async () =>
      await axios.get(`${props?.serverAPI}/app/get/info`, {
        params: {
          shop: cookie.get("shop"),
        },
      }),
    {
      onSuccess: (data) => {
        const step1Data = data?.data[0]["step4"];
        setStepData(step1Data);
        reset({ ...step1Data });
      },
    }
  );

  const saveInfo = useMutation(
    async (savedData) =>
      await axios.post(
        `${props?.serverAPI}/app/save/info`,
        {
          info: {
            ...stepData,
            ...savedData,
          },
          shop: cookie.get("shop"),
        },
        {
          params: {
            shop: cookie.get("shop"),
          },
        }
      ),
    {
      onSuccess: (data) => {
        setEditMode(false);
        reset(data);
        queryClient.invalidateQueries("FETCH_INFO");
      },
    }
  );

  const theme = useTheme();

  const onSubmitForm = (data) => {
    saveInfo.mutate(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <Controller
        name="salesEmail"
        control={control}
        render={({ field, fieldState }) => (
          <FormControl fullWidth variant="standard">
            <StyledInputLabel shrink htmlFor="salesEmail">
              Email Notifications
            </StyledInputLabel>
            <BootstrapInput
              {...field}
              {...fieldState}
              error={!!formState.errors.salesEmail}
              fullWidth
              id="salesEmail"
            />
            {formState.errors.salesEmail && (
              <Typography color="error" fontSize="12px" marginTop="4px">
                {formState.errors.salesEmail
                  ?.map((err) => err.message)
                  .join(" ")}
              </Typography>
            )}
          </FormControl>
        )}
      />
      {formState?.isDirty && (
        <Box
          display="flex"
          justifyContent="space-between"
          marginTop={theme.spacing(1)}
        >
          <Button
            onClick={() => {
              reset(stepData);
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            loading={saveInfo.isLoading}
            variant="contained"
          >
            Save
          </LoadingButton>
        </Box>
      )}
    </form>
  );
};

export default EmailNotifications;
