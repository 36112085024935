import { createTheme } from "@mui/material";

export const themeOptions = {
  overrides: {},
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#305973",
    },
    secondary: {
      main: "#feb031",
      light: "#F5F7FD",
      contrastText: "#ffffff",
    },
    info: {
      main: "#007fff",
    },
    success: {
      main: "#1db954",
    },
    error: {
      main: "#e32636",
    },
    warning: {
      main: "#ffd700",
    },
    background: {
      default: "#FBFBFE",
      darker: "#F1F2F6",
    },
    extra: {
      darker: "#1F3586",
      lightestBlue: "#F5F7FD",
      subtle: "#D6DEF9",
      azureLightest: "#F2F9FF",
      azureSubtle: "#CCE5FF",
      azureDarker: "#004C99",
    },
  },
  typography: {
    fontFamily: "Lato",
    button: {
      fontSize: "1.2rem",
      fontWeight: "500",
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          "&:-webkit-autofill": {
            "-webkit-box-shadow": "0 0 0 100px #ffff inset",
            "-webkit-text-fill-color": "inherit",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        disableElevation: false,
        contained: {
          boxShadow: "none",
        },
        text: {
          backgroundColor: "#F2F2F2",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontSize: "20px",
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 42,
          height: 26,
          padding: 0,
          margin: 8,
        },
        switchBase: {
          padding: 1,
          "&$checked, &$colorPrimary$checked, &$colorSecondary$checked": {
            transform: "translateX(16px)",
            color: "#fff",
            "& + $track": {
              opacity: 1,
              border: "none",
            },
          },
        },
        thumb: {
          width: 24,
          height: 24,
        },
        track: {
          borderRadius: 13,
          border: "1px solid #bdbdbd",
          backgroundColor: "#fafafa",
          opacity: 1,
          transition:
            "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
      },
    },
  },
});

export default theme;
