import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  useTheme,
  Typography,
  TextField,
  styled,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import "react-image-crop/dist/ReactCrop.css";
import cookie from "js-cookie";
import axios from "axios";
import backendUtility from "../General/backendUtility";
import "./style.css";
import { useMutation, useQuery } from "react-query";
import { queryClient } from "../../constants";
import utility from "../General/utility";
import ImageCrop from "../ImageCrop";
import GeneralInfoForm from "./components/GeneralInfo";
import AgentsTable from "./components/AgentTable";
import AddUserForm from "./components/AddUserForm";
import EmailNotifications from "./components/EmailNotifications";
import CustomImageCrop from "../Crop";
import HeaderCard from "../HeaderCard";

const StyledSpan = styled("span")(({ theme }) => ({
  fontWeight: "medium",
  color: theme.palette.primary.main,
}));

const StyledTextField = styled(TextField)({
  fontSize: "20px",
  ".MuiInputBase-root": {
    fontSize: "15px",
  },
  ".MuiInputLabel-root": {
    fontSize: "15px",
  },
});

const StyledInputLabel = styled(InputLabel)({
  fontSize: "18px",
  fontWeight: 600,
  color: "black",
});

const ContactInfo = (props) => {
  const [token, setToken] = useState();
  const [isAgentMessageShown, setIsAgentMessageShown] = useState(false);
  const [isCropDialogShown, setIsCropDialogShown] = useState(false);
  const [fileBase64, setFileBase64] = useState();
  const [fileImage, setFileImage] = useState(null);
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [agentsMap, setAgentsMap] = useState({});

  useEffect(() => {
    const asyncActions = async () => {
      let tokenData = await backendUtility.getToken(
        cookie.get("shop"),
        props.serverAPI
      );
      setToken(tokenData);
    };

    asyncActions();
  }, []);

  const { data, isLoading, isFetching } = useQuery(
    "FETCH_AGENTS",
    async () =>
      await axios.get(`${props.serverAPI}/app/agents`, {
        params: {
          token: token,
        },
      }),
    {
      onSuccess: (data) => {
        // map agents using their agentToken
        let aMap = {};
        let answerNetAgent = data.data.find(a => a.appRoleID == 4);
        if(answerNetAgent) {
          answerNetAgent.fileMetaData = { url: "https://chatbot-an.qa.textchat.ai/client/shopify/img/answerneticon.png" };
        }
        data.data.forEach((agent) => {
          aMap[agent.token] = agent;
        });
        setAgentsMap(aMap);
      },
      onError: (err) => {},
      enabled: !!token,
    }
  );

  const addUser = useMutation(
    async (data) =>
      await axios.put(`${props.serverAPI}/app/AccountHolder/Upsert`, {
        ...data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("FETCH_AGENTS");
        setIsAddUserModalOpen(false);
        setFileBase64(null);
        setFileImage(null);
      },
    }
  );

  const memoizedData = useMemo(() => data?.data || [], [data]);

  const onAddAgent = (data) => {
    let agent = {
      ...data,
      hasAdded: true,
    };
    if (fileBase64) {
      const random = utility.string("a", 10);
      agent["fileMetaData"] = {
        fileName: random + ".png",
        recordType: "AGENT_IMAGE",
        s3FileName: "",
        base64Data: fileBase64,
        tempUrl: URL.createObjectURL(fileImage),
      };
    }
    const formattedData = memoizedData.map((agent) => ({
      ...agent,
      EmailAddress: agent.emailAddress,
      FullName: agent.fullName,
      MobilePhoneNumber: agent.mobilePhoneNumber,
      Token: agent.token,
    }));

    const payloadData = {
      Agents: [agent, ...formattedData],
      clientToken: token,
    };
    addUser.mutate(payloadData);
  };

  const theme = useTheme();
  return (
    <div className="page-wrapper">
      <Box display="flex" justifyContent="center" marginBottom={"20px"}>
        <HeaderCard
          title="User Settings"
          description=" Here is where you will be able to designate your adminstrator,
              add/delete/modify users, and set business details"
        />
      </Box>
      <Box
        marginY={theme.spacing(2)}
        sx={{
          backgroundColor: "white",
          paddingX: theme.spacing(3),
          paddingY: theme.spacing(3),
        }}
        borderRadius={theme.spacing(2)}
        marginBottom={theme.spacing(3)}
      >
        <Box marginBottom={theme.spacing(3)}>
          <Typography variant="h4" fontWeight="medium">
            General Info
          </Typography>
          <Typography marginTop={theme.spacing(1)} variant="h5">
            General info about your account
          </Typography>
        </Box>
        <GeneralInfoForm serverAPI={props.serverAPI} />
      </Box>

      <Box
        sx={{
          backgroundColor: "white",
        }}
        paddingY={theme.spacing(3)}
        paddingX={theme.spacing(3)}
        borderRadius={theme.spacing(2)}
        marginTop={theme.spacing(3)}
      >
        <Typography variant="h4" fontWeight="medium">
          Agent Manager
        </Typography>
        <Typography marginTop={theme.spacing(1)} variant="h5">
          Add or manage your agents from here, we recommend adding your own
          headshots for your agent profiles
        </Typography>
        <Box marginTop={theme.spacing(3)}>
          <AgentsTable
            serverAPI={props.serverAPI}
            loading={isLoading || isFetching}
            memoizedData={memoizedData || []}
            token={token}
            setIsAddUserModalOpen={setIsAddUserModalOpen}
          />
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: "white",
        }}
        paddingY={theme.spacing(3)}
        paddingX={theme.spacing(3)}
        borderRadius={theme.spacing(2)}
        marginTop={theme.spacing(3)}
      >
        <Typography variant="h4" fontWeight="medium">
          Email Settings
        </Typography>
        <Typography
          marginBottom={theme.spacing(3)}
          marginTop={theme.spacing(1)}
          variant="h5"
        >
          Add multiple emails separated by a comma
        </Typography>
        <EmailNotifications serverAPI={props.serverAPI} />
      </Box>
      <Dialog
        open={isAddUserModalOpen}
        onClose={() => {
          setIsAddUserModalOpen(false);
          setIsAgentMessageShown(false);
        }}
        maxWidth={!isAgentMessageShown ? "sm" : "xs"}
        fullWidth
      >
        <DialogTitle
          sx={{
            fontSize: "18px",
            fontWeight: "medium",
          }}
        >
          {!isAgentMessageShown ? "Add User - Confirmation" : "   Add User"}
        </DialogTitle>
        <DialogContent>
          {!isAgentMessageShown ? (
            <Box>
              <Typography
                sx={{
                  fontSize: "18px",
                  color: "#808191",
                }}
              >
                Additional users (above 2 users) is{" "}
                <StyledSpan>$15/month/user</StyledSpan>. By clicking confirm
                below, you agree to the additional monthly charge.
              </Typography>
              <Typography
                sx={{
                  fontSize: "18px",
                  color: "#808191",
                }}
                marginTop={theme.spacing(2)}
              >
                The additional amount will be added at the beginning of your
                next billing cycle.
              </Typography>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                marginTop={theme.spacing(2)}
              >
                <Button
                  onClick={() => {
                    setIsAddUserModalOpen(false);
                  }}
                  size="large"
                  sx={{ fontSize: "12px" }}
                >
                  CANCEL
                </Button>
                <Button
                  size="large"
                  sx={{ fontSize: "12px" }}
                  variant="contained"
                  onClick={() => {
                    setIsAgentMessageShown(true);
                  }}
                >
                  CONFIRM
                </Button>
              </Box>
            </Box>
          ) : (
            <AddUserForm
              token={token}
              agents={memoizedData}
              onAddAgent={onAddAgent}
              serverAPI={props.serverAPI}
              onCloseModal={() => {
                setFileImage(null);
                setFileBase64(null);
                setIsAddUserModalOpen(false);
              }}
              setFileImage={setFileImage}
              fileImage={fileImage}
              loading={addUser.isLoading}
              setIsCropDialogShown={setIsCropDialogShown}
            />
          )}
        </DialogContent>
      </Dialog>
      <Dialog fullWidth open={isCropDialogShown}>
        <CustomImageCrop
          src={fileImage?.preview}
          onCropComplete={async (image) => {
            const bs64 = await getBase64Image(image);
            setFileBase64(bs64);
            setIsCropDialogShown(false);
          }}
          onCropAborted={() => {
            setFileImage(null);
            setIsCropDialogShown(false);
          }}
        />
      </Dialog>
    </div>
  );
};

const getBase64Image = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
      if (encoded.length % 4 > 0) {
        encoded += "=".repeat(4 - (encoded.length % 4));
      }
      resolve(encoded);
    };
    reader.onerror = (error) => reject(error);
  });
};

export default ContactInfo;
