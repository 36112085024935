import * as React from "react";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import { Visibility } from "@mui/icons-material";

export const BootstrapInput = styled(InputBase)(({ theme, error, size }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: `1px solid ${error ? "red" : "#ced4da"}`,
    fontSize:
      size === "small"
        ? theme.typography.h5.fontSize
        : theme.typography.fontSize * 1.2,
    width: "100%",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:filled": {
      backgroundColor: "yellow",
      background: "none",
    },
    "&:focus": {
      boxShadow: `${alpha(
        error ? theme.palette.error.main : theme.palette.primary.main,
        0.25
      )} 0 0 0 0.2rem`,
      borderColor: error
        ? theme.palette.error.main
        : theme.palette.primary.main,
    },
  },
}));

export const InputWithIcon = (props) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundColor: "#fcfcfb",
        borderRadius: 2,
        border: "1px solid #EBEAED",
        padding: "4px 12px",
      }}
      display="flex"
      alignItems="center"
    >
      <InputBase
        sx={{
          flexGrow: 1,
          fontSize:
            props.size === "small"
              ? theme.typography.h5.fontSize
              : theme.typography.fontSize,
        }}
        {...props.inputProps}
      />
      {props?.endIcon && props.endIcon}
    </Box>
  );
};
