import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Grid,
  FormControl,
  Typography,
  IconButton,
  useTheme,
  InputLabel,
  styled,
  Button,
} from "@mui/material";
import cookie from "js-cookie";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { BootstrapInput } from "../../../components/Input/BootstrapInput";
import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import * as yup from "yup";
import { queryClient } from "../../../constants";
import { LoadingButton } from "@mui/lab";

const StyledInputLabel = styled(InputLabel)({
  fontSize: "18px",
  fontWeight: 500,
  color: "black",
});

const generalInfoFormValidationSchema = yup.object().shape({
  businessName: yup.string().required("Business Name is required"),
});

const GeneralInfoForm = (props) => {
  const [editMode, setEditMode] = useState(false);
  const [step4Data, setStep4Data] = useState();
  const { control, formState, handleSubmit, reset } = useForm({
    mode: "all",
    resolver: yupResolver(generalInfoFormValidationSchema),
  });

  const saveInfo = useMutation(
    async (savedData) =>
      await axios.post(
        `${props?.serverAPI}/app/save/info`,
        {
          info: {
            ...step4Data,
            ...savedData,
          },
          shop: cookie.get("shop"),
        },
        {
          params: {
            shop: cookie.get("shop"),
          },
        }
      ),
    {
      onSuccess: (data) => {
        setEditMode(false);
        queryClient.invalidateQueries("FETCH_INFO");
      },
    }
  );

  useQuery(
    "FETCH_INFO",
    async () =>
      await axios.get(`${props?.serverAPI}/app/get/info`, {
        params: {
          shop: cookie.get("shop"),
        },
      }),
    {
      onSuccess: (data) => {
        const step1Data = data?.data[0]["step4"];
        setStep4Data(step1Data);
        reset(step1Data);
      },
    }
  );

  const onSubmit = (data) => {
    saveInfo.mutate(data);
  };

  const theme = useTheme();

  return (
    <Box width="100%" display="flex" flexDirection="column">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item md={6}>
            <Controller
              name="businessName"
              control={control}
              render={({ field, fieldState }) => (
                <FormControl fullWidth variant="standard">
                  <StyledInputLabel shrink htmlFor="businessName">
                    Business Name
                  </StyledInputLabel>
                  <BootstrapInput
                    {...field}
                    {...fieldState}
                    error={!!formState.errors.businessName}
                    disabled={!editMode}
                    fullWidth
                    id="businessName"
                  />
                  {formState.errors.businessName && (
                    <Typography color="error" fontSize="12px" marginTop="4px">
                      {formState.errors.businessName.message}
                    </Typography>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item md={6}>
            <Controller
              name="mobileNumbers"
              control={control}
              render={({ field, fieldState }) => (
                <FormControl fullWidth variant="standard">
                  <StyledInputLabel shrink htmlFor="mobileNumbers">
                    Email Address
                  </StyledInputLabel>
                  <BootstrapInput
                    disabled={!editMode}
                    {...field}
                    {...fieldState}
                    fullWidth
                    error={!!formState.errors.mobileNumbers}
                    id="mobileNumbers"
                  />
                  {formState.errors.mobileNumbers && (
                    <Typography color="error" fontSize="12px" marginTop="4px">
                      {formState.errors.mobileNumbers.message}
                    </Typography>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item md={6}>
            <Controller
              name="contactName"
              control={control}
              render={({ field, fieldState }) => (
                <FormControl fullWidth variant="standard">
                  <StyledInputLabel shrink htmlFor="contactName">
                    Adminstrator Name
                  </StyledInputLabel>
                  <BootstrapInput
                    disabled={!editMode}
                    {...field}
                    {...fieldState}
                    fullWidth
                    error={!!formState.errors.contactName}
                    id="contactName"
                  />
                  {formState.errors.contactName && (
                    <Typography color="error" fontSize="12px" marginTop="4px">
                      {formState.errors.contactName.message}
                    </Typography>
                  )}
                </FormControl>
              )}
            />
          </Grid>

          <Grid item md={6}>
            <Controller
              name="personalEmail"
              control={control}
              render={({ field, fieldState }) => (
                <FormControl fullWidth variant="standard">
                  <StyledInputLabel shrink htmlFor="personalEmail">
                    Adminstrator Email
                  </StyledInputLabel>
                  <BootstrapInput
                    disabled={!editMode}
                    {...field}
                    {...fieldState}
                    fullWidth
                    error={!!formState.errors.personalEmail}
                    id="personalEmail"
                  />
                  {formState.errors.personalEmail && (
                    <Typography color="error" fontSize="12px" marginTop="4px">
                      {formState.errors.personalEmail.message}
                    </Typography>
                  )}
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
        {!editMode ? (
          <Box
            marginTop={theme.spacing(2)}
            display="flex"
            justifyContent="flex-end"
          >
            <IconButton onClick={() => setEditMode(true)}>
              <EditIcon
                sx={{
                  fontSize: theme.spacing(4),
                }}
                fontSize="large"
              />
            </IconButton>
          </Box>
        ) : (
          <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            marginTop={theme.spacing(3)}
          >
            <Button variant="outlined" onClick={() => setEditMode(false)}>
              Cancel
            </Button>
            <LoadingButton
              loading={saveInfo.isLoading}
              startIcon={<SaveIcon />}
              type="submit"
              variant="contained"
            >
              Save
            </LoadingButton>
          </Box>
        )}
      </form>
    </Box>
  );
};

export default GeneralInfoForm;
