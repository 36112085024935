import React from "react";
import Stars2 from "../../images/stars2.png";
import axios from "axios";
import Config from "../../config.js";
import { useHistory } from "react-router-dom";
import IconEmail from "../../images/icon-email.png";

axios.defaults.headers.common["Authorization"] = Config.backendGUID;
const CompleteOne = () => {
  const history = useHistory();
  const handleGotoDashboard = () => {
    window.location.href = "/login";
    // history.push("/#home");
    // const userInfo = localStorage.getItem("userInfo");

    // if (!isValid) return;

    // try {
    //   if (userInfo) userInfo = JSON.parse(userInfo);
    //   let accountCreationResult = await createNewAccount({
    //     name: `${userInfo.firstName} ${userInfo.lastName}`,
    //     email: userInfo.email,
    //     password: userInfo.password,
    //     phoneNumber: userInfo.landlineNumber,
    //     clientName: uuid(),
    //     websiteUrl: "",
    //   }).then((resp) => {
    //     return resp.data;
    //   });

    //   if (accountCreationResult.adminDash)
    //     setAdminDashUrl(accountCreationResult.adminDash);
    // } catch (ex) {
    //   setInvalidAction(true);
    //   setErrorText("Something went wrong when submitting your data");
    //   return;
    // }
  };

  return (
    <div className="complete-one-page">
      <div className="top-heading mt-100 font-26">
        Almost there... Last Step...
      </div>

      <div className="text-center mt-30 mb-20">
        <img src={IconEmail} />
      </div>
      <div className="top-desc mt-20 mb-40">
        Our partner, Twilio, requires an electronic signature on a Letter of
        Authorization (LoA).
      </div>
      <div className="top-desc">
        Keep an eye out for an email from noreply@mail.hellosign.com, and please
        e-sign.
      </div>

      <div className="complete-one-page-action">
        <button onClick={() => handleGotoDashboard()}>Continue To Admin</button>
      </div>
    </div>
  );
};

export default CompleteOne;
