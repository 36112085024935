import React, { Component } from 'react';

class Editor extends Component {
    /**
     * Validate whats be mount
     */
    render() {
        return (
            <div id='editor'>
                <label htmlFor="">Node Content</label>
                <textarea onChange={(e) => this.props.changeNodeText(e)} value={this.props.messageEditing} ></textarea>

            </div>
        )
    }

}

export default Editor;