import { Box, Typography, useTheme } from "@mui/material";
import React from "react";

const HeaderCard = ({
  description,
  title,
}: {
  title: string;
  description: string;
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundColor: "white",
        paddingX: "16px",
        paddingY: "16px",
        border: `3px solid ${theme.palette.primary.main}`,
        borderRadius: "10px",
      }}
    >
      <Typography fontWeight="medium" textAlign="center" variant="h3">
        {title}
      </Typography>
      <Typography
        sx={{
          fontSize: "14px",
        }}
        marginTop={theme.spacing(1)}
      >
        {description}
      </Typography>
    </Box>
  );
};

export default HeaderCard;
