import React from "react";

const PrivacyPolicy = () => {
  return (
    <>
      <h2>Privacy Policy</h2>
    </>
  );
};

export default PrivacyPolicy;
