import React from "react";
import AgentOliver from "../../../../assets/img/Chatbot-agent-oliver.jpg";
import AgentIan from "../../../../assets/img/Chatbot-agent-Ian1.jpg";

function EffortlessChatWindow(props) {
  return (
    <div className="effortlessWindow">
      <div
        className="jetsense-chatbot glass-design body-proactive active"
        style={{ background: props.chatbotWidgetColor }}
      >
        <div
          className="jetsense-chatbot-title header-proactive"
          style={{
            background: props.chatbotWidgetColor,
            color: props.widgetTextColor,
          }}
        >
          <div className="tc-agent-container js-agent-box ">
            <div className="tc-agent" style={{ zIndex: "3" }}>
              <div className="tc-agent-img-container">
                <img className="tc-agent-img" src={AgentIan} />
                <span className="badge-online">
                  <span></span>
                </span>
              </div>
              <div className="tc-agent-name">Ian</div>
            </div>
            <div className="tc-agent" style={{ zIndex: "2" }}>
              <div className="tc-agent-img-container">
                <img
                  className="tc-agent-img"
                  src="https://d2fzjtk430nuyq.cloudfront.net/f82d51e5-abe0-4cd0-a015-5362b40ee74a/5000cb05-cf57-4405-b8b3-56e40e583248.jpeg"
                />
                <span className="badge-online">
                  <span></span>
                </span>
              </div>
              <div className="tc-agent-name">Eric</div>
            </div>
            <div className="tc-agent" style={{ zIndex: "1" }}>
              <div className="tc-agent-img-container">
                <img className="tc-agent-img" src={AgentOliver} />
                <span className="badge-online">
                  <span></span>
                </span>
              </div>
              <div className="tc-agent-name">Oliver</div>
            </div>{" "}
          </div>
          <div className="company-header">
            <div className="jetsense-chatbot-companyTitle">
              <div
                className="company-title"
                style={{
                  color: props.widgetTextColor,
                }}
              >
                {props.header}
              </div>
              <div className="slogan" style={{ color: props.widgetTextColor }}>
                {props.slogan}
              </div>
            </div>
          </div>
          <div className="gradient-transfer-animation hidden" />
        </div>
        <div className="jetsense-chatbot-body">
          <div className="theme-new-design uhctheme--shopify keep-msg-bottom js-chatbot-top">
            <section className="page">
              <div className="chat-frame">
                <div className="chat-body">
                  <div className="row chatbot-msg type-agent" data-nodeid={1}>
                    <div className="agent-message-box">
                      <div className="tc-agent">
                        <div className="tc-agent-img-container">
                          <div className="tc-agent-border">
                            <img
                              src={
                                "https://d2fzjtk430nuyq.cloudfront.net/f82d51e5-abe0-4cd0-a015-5362b40ee74a/5000cb05-cf57-4405-b8b3-56e40e583248.jpeg"
                              }
                              width={36}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bubble-container">
                      <div
                        className="bubble initial"
                        style={{ backgroundColor: props.AgentBubbleColor }}
                      >
                        <div className="text">
                          <div style={{ color: props.AgenttextColor }}>
                            This is what a message from your chatbot looks like.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row type-visitor first">
                    <div className="visitor">
                      <div className="bubble-container">
                        <div
                          className="bubble initial"
                          style={{ backgroundColor: props.clientBubbleColor }}
                        >
                          <div
                            className="text"
                            style={{ color: props.clientTextColor }}
                          >
                            <div className="nowrap">
                              This is what a message from a visitor looks like.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="meta">
                        <img
                          src={
                            "https://pngimage.net/wp-content/uploads/2018/05/avatar-profile-png-8.png"
                          }
                          width={50}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <section className="msg-container">
                <div className="err">Test</div>
                <img
                  src="https://assets.jetsense.ai/textchat/smiley.png"
                  alt="smiley"
                  className="emoticons no-pointer"
                />
                <textarea
                  type="text"
                  className="msg input-enter-message"
                  data-emojiable="true"
                  defaultValue={""}
                />
              </section>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EffortlessChatWindow;
