const introData =
    {
        title: "Welcome to TextChat!",
        paragraphs: [
            "Setting up your live chat will only take a couple of minutes",
            "The information you provide during the setup can be updated later on the TextChat dashboard if you have any questions, contact <a href='mailto:support@textchat.com'>support@textchat.com</a>.",
        ],
        buttonTitle: "Let's Go",
        video:{
            url: "https://www.w3schools.com/html/mov_bbb.mp4",
            poster: null
        }
    }


export default introData;
