import React  from 'react';
import {
    Scrollable
} from '@shopify/polaris';
export const step4 = [
    [
        {
            card: true,
            title: "Billing Plan Details",
            cardSection: [
                <p>Sign up now and get a 14 day FREE trial!</p>,
                <p>Flat Rate per 30 days per agent $19.00.</p>,
                <p>100 Chats per agent.</p>,
                <p>Overage Per Chat Per Agent Per Billing Period is $0.04</p>,
                {
                    switchpressed: [
                      {
                          value: "bronze"
                       }
                    ],
                    name: "subscription",
                    mandatory: true
                }
            ]
        }
    ],
    [
        {
            title: 'Terms of Service',
            card: true,
            cardSection: [
                <Scrollable shadow style={{ height: "200px" }}>

                    <p style={{textAlign:'center'}}><b><span style={{fontSize:18}}>TERMS OF SERVICE</span></b></p>

	                <p>&nbsp;</p>

    <p>The following terms and conditions, as well as any future
	modifications (the “Terms”) are a contract between you or your company (“you”
	or “your”) and JetSpring LLC., a Pennsylvania corporation d/b/a TextChat.ai
	(“TextChat,” “we,” “us,” or “our”) governing your use, and TextChat’s
	provision, of the Services (as defined below). By using TextChat’s software
	applications, application programming interfaces, TextChat Webhooks,
	communication services, networks, and websites, including, but not limited to
	www.TextChat.com, (collectively, the “Services”), you acknowledge that you have
	read, understood, accept and agree to be bound by the most recent version of
	these Terms.</p>

	<p >&nbsp;</p>

	<p >You may review the most current version of the Terms at any
	time on the TextChat website at https://TextChat.com/terms-and-conditions (“Term Page”). TextChat
	reserves the right to update and change the Terms from time to time without
	notice, effective immediately, by posting the updated Terms on the Term Page.
	Your continued use of the Services after the revision date of any such changes
	shall constitute consent to such changes. You agree to review the Terms
	periodically to be aware of any such revisions. Any new features that augment
	or enhance the current Services, including the release of new tools and
	resources, shall be subject to the Terms.</p>

	<p >&nbsp;</p>

	<p >If you elect to enable additional functionality or features
	made available through the Services (“Additional Features”), you may be
	presented with additional terms related to the use of such Additional Features
	(the “Additional Terms”). By using the Additional Features, you agree and
	accept the Additional Terms. In the event of a conflict between these Terms and
	the Additional </p>

	<p >Terms, the Additional Terms shall govern.</p>

	<p >&nbsp;</p>

	<p >By using TextChat and entering your mobile phone number or
	the mobile phone number(s) of your employees, you are agreeing to receive text
	messages from TextChat with notifications of users wanting to webchat with you
	on your associated website. Furthermore, you agree to receive support and
	marketing messages at the mobile phone number(s) provided. Consent is a
	condition of the purchase. Message and data rates my apply. Message frequency
	varies. Reply STOP to Cancel. </p>

	<p ><i>&nbsp;</i></p>

	<p ><b>IF YOU DO NOT AGREE TO THESE TERMS, DO NOT USE, OR CEASE
	YOUR USE OF, THE SERVICES.</b></p>

	<p >&nbsp;</p>

	<p ><b>Grant of Limited License</b></p>

	<p >&nbsp;</p>

	<p >Subject to your compliance with the Terms including, without
	limitation, maintaining your registration with current and accurate information
	and the prompt payment of applicable fees, TextChat hereby grants you a
	non-exclusive, non-transferable, revocable, worldwide license to access and use
	the Services to communicate with your end users (“End Users”). All rights not
	expressly granted to you are reserved by TextChat and its licensors.</p>

	<p >&nbsp;</p>

	<p ><b>Without explicit permission from TextChat, you may not:</b></p>

	<p >&nbsp;</p>

	<p >use TextChat’s trademarks or service marks;</p>

	<p >alter, modify, obscure, or remove TextChat’s trademarks from
	the Services;</p>

	<p >alter, modify, or obscure the Services; either explicitly,
	implicitly, or through acts of omission, represent yourself as acting on behalf
	of TextChat; or either explicitly, implicitly, or through acts of omission,
	represent yourself as a partner, affiliate, reseller, employee, contractor,
	vendor, advisor, or counsel of TextChat.</p>

	<p >&nbsp;</p>

	<p >&nbsp;</p>

	<p ><b>End Users</b></p>

	<p >&nbsp;</p>

	<p >Access to the Services by an End User shall be governed by
	your End User terms of service (“Your Terms”), provided that you shall be
	responsible for ensuring that, as between an End User, you and TextChat: (i) TextChat
	will at all times retain ownership of all of its intellectual property (as
	further described in the Section entitled “TextChat’s Intellectual Property
	Rights” below) and End Users are granted no rights to such intellectual
	property, (ii) TextChat makes no direct or implied warranties to End Users,
	(iii) you shall not make any representation or warranties to End Users with
	respect to the Service, other than the representation that you have the
	necessary rights to allow End Users to use the Service, (iv) TextChat is the
	direct and intended beneficiary of Your Terms, and (v) TextChat will not be
	liable in any way to End Users, either directly or indirectly. As between TextChat
	and you, you are responsible for ensuring that End Users do not communicate
	information in violation of law using the Service, and for advising them
	against transmitting sensitive information using the Service, including but not
	limited to health/medical information or personally identifiable information of
	minors.</p>

	<p >&nbsp;</p>

	<p ><b>Fees</b></p>

	<p >&nbsp;</p>

	<p >TextChat reserves the right to charge fees for your use of
	the Services, as described during the account registration process or when
	making changes to your account plan (“Fees”). You agree to pay such Fees in
	accordance with the terms set forth in such account registration or change to
	account plan.</p>

	<p >&nbsp;</p>

	<p >All Fees are exclusive of all taxes, levies, or duties
	imposed by taxing authorities, and you shall be responsible for payment of all
	such taxes, levies, or duties associated with your use of the Services, other
	than on TextChat’s income. You agree to pay for any such taxes that might be
	applicable to your use of the Services and payments made by you herein.</p>

	<p >&nbsp;</p>

	<p >TextChat reserves the right, in TextChat’s sole discretion,
	to change published prices without notice. Furthermore, we reserve the right to
	change the Fees for the Services tied to your account upon thirty (30) days
	advance notice of the applicability of such change to the Fees and shall not
	apply to Fees already charged to your account.</p>

	<p >&nbsp;</p>

	<p ><b>Modifications to the Services</b></p>

	<p >&nbsp;</p>

	<p >TextChat reserves the right at any time to modify or
	discontinue, temporarily or permanently, the Services (or any part thereof)
	with or without notice. TextChat shall not be liable to you or to any third
	party for any modification, price change, suspension or discontinuance of the
	Services.</p>

	<p >&nbsp;</p>

	<p ><b>Account Restrictions</b></p>

	<p >&nbsp;</p>

	<p >You must be legally able to enter into these Terms in your
	jurisdiction, such as being older than the minimum age of consent to enter into
	contracts.</p>

	<p >You must be a natural person or a natural person
	representing a company, association or other organization. Accounts registered
	by “bots” or other automated methods are in violation of these Terms and will
	be immediately terminated.</p>

	<p >&nbsp;</p>

	<p >You must provide a valid email address and truthfully
	provide any other information requested in order to complete the signup process
	(“Registration Data”) and provide prompt updates to the Registration Data in
	the event of any changes.</p>

	<p >You must not impersonate nor misrepresent your identity or
	affiliations with other persons or entities.</p>

	<p >&nbsp;</p>

	<p >You are responsible for maintaining the security of your
	account and password. TextChat shall not be liable for any loss or damage from
	your failure to comply with this security obligation. You are liable for any
	acts or omissions made using your login and password.</p>

	<p >You must not intentionally and maliciously disrupt the
	reasonable enjoyment of others using the Service.</p>

	<p >&nbsp;</p>

	<p >If your use of the Services causes undue stress on the
	Services and the systems running the Services, TextChat reserves the right to
	limit or disable your access to the Services without notice.</p>

	<p >&nbsp;</p>

	<p >Verbal, physical, written or other abuse (including threats
	of abuse or retribution) of any customer, employee, member, or officer of TextChat
	will result in immediate account termination.</p>

	<p >&nbsp;</p>

	<p >You may not forge or manipulate headers, data, or network
	protocols to disguise the origin of Content or otherwise disrupt the intended
	use of the Services.</p>

	<p >Content Restrictions</p>

	<p >&nbsp;</p>

	<p >You are responsible for all materials, including without limitation,
	information, data, text, software, music, sound, photographs, graphics, video,
	and email messages or other kinds of messages posted, received or sent through
	the Services (collectively “Content”) and for all activity that occurs under
	your account, whether done so by you or any End User of the Service who is
	chatting with you.</p>

	<p >&nbsp;</p>

	<p >We have the right, but not the obligation, to remove Content
	that we determine in our sole discretion to be “Offensive Content” as defined
	below. We also have the right, but not the obligation, to limit or revoke the
	use privileges of anyone who posts such Content or engages in unacceptable
	behavior on or through the Services.</p>

	<p >&nbsp;</p>

	<p >TextChat does not warrant that the Content will be
	accessible or transferable on the Services either for short term or long term
	use. To the extent any Content is available for use through the Services, you
	agree to only use such Content for internal purposes only to address the End
	User’s needs.</p>

	<p >&nbsp;</p>

	<p >We cannot control all Content posted by third parties to the
	Services. You agree to use the Services at your own risk. You understand that
	by using the Services you may be exposed to Content that you may find
	offensive, indecent, incorrect or objectionable, and you agree that under no
	circumstances will TextChat be liable in any way for any Content, including any
	errors or omissions in any Content, or any loss or damage of any kind incurred
	as a result of your use of any Content. You understand that you must evaluate
	and bear all risks associated with the use of any Content, including any
	reliance on the Content, integrity, and accuracy of such Content. If you would
	like to report objectionable materials, you may contact us at info@TextChat.com.</p>

	<p >&nbsp;</p>

	<p >You may not post any unlawful, offensive, threatening,
	libelous, defamatory, obscene or otherwise objectionable Content, or Content
	that violates any of these the Terms (“Offensive Content”). Examples of such
	Offensive Content include, but are not limited to:</p>

	<p >&nbsp;</p>

	<p ><span>·<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
	</span></span>abuse, harassment, threats, flaming or intimidation of any person
	or organization</p>

	<p >engaging in or contributing to any
	illegal activity or activity that violates others’ rights</p>

	<p ><span>·<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
	</span></span>use of derogatory, discriminatory or excessively graphic language</p>

	<p ><span>·<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
	</span></span>providing information that is false, misleading or inaccurate</p>

	<p ><span
	>·<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
	</span></span>hacking or modifying the Services to falsely imply an association
	with TextChat</p>

	<p ><span
	>·<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
	</span></span>engaging in or contributing to any activity that maliciously
	degrades the Services</p>

	<p ><span
	>·<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
	</span></span>implying or pretending to be affiliated with a company or
	organization with which you are not affiliated, or misrepresenting the extent
	of your affiliation or role with an affiliated company or organization disclosing
	personal login information, including, but not limited to passwords without the
	express permission of the individual or organization who provided it to you</p>

	<p ><span
	>·<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
	</span></span>transmitting worms, viruses or harmful software sending spam,
	unsolicited mail, junk mail or chain letters disclosing personal or proprietary
	information of another user, End User, person or organization altering the
	Service’s user interface in a way that removes access to any part of the
	Service’s functionality and/or inhibits access to or the display of
	advertisements</p>

	<p ><span
	>·<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
	</span></span>collecting the content or information belonging to another user,
	an End User or TextChat, or otherwise accessing the Services, using automated
	means (such as harvesting bots, robots, spiders or scrapers) without our
	permission soliciting log in information or accessing the account of another
	user or End User of the Services promoting, condoning, endorsing, distributing,
	publishing, or posting any material that solicits money, funds or people on websites
	or other properties that belong to TextChat aiding in the use of the Services
	for purposes of advertisement or solicitation for commercial goods or services
	on websites or other properties that belong to TextChat harassing, annoying,
	intimidating or threatening users or End Users of the Services or employees or
	agents of TextChat aiding or engaging in deceptive or predatory practices
	towards users or End Users of the Service removing or altering, visually or
	not, any copyrights, trademarks, or proprietary marks and rights owned by us</p>

	<p ><span
	>·<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
	</span></span>forging, manipulating or plagiarizing messages, communications,
	headers or identifiers to imply an association with us or the Services posting,
	publishing, displaying or transmitting in any way proprietary information,
	copyrighted material and trademarks not belonging to you, without the prior
	written consent of the owner of such rights</p>

	<p ><span
	>·<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
	</span></span>posting content that advocates, endorses, condones or promotes
	harassment of others, or content that actively or indirectly harasses others posting
	content that is offensive and harmful, including but not limited to, content
	that advocates, endorses, condones or promotes racism, bigotry, hatred or
	physical harm of any kind against any individual or any group of individuals</p>

	<p ><span
	>·<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
	</span></span>posting content that provides materials or access to materials
	that exploits people under the age of 18 in an abusive, violent or sexual
	manner posting content that solicits personal information or any information
	from anyone under the age of 18 posting content that uses, promotes or links to
	an illegal or unauthorized copy of another person’s copyrighted work or
	intellectual property.</p>

	<p >&nbsp;</p>

	<p ><b>Grant of License.</b> You represent that you have the
	authority to and do hereby grant to TextChat a non-exclusive, irrevocable
	license to use the Content made available through the Services to the extent
	necessary to provide the Services (the “Content License”). Such Content License
	includes, without limitation, the right to reproduce, compress, transfer, and
	distribute the Content internally and with third party service providers to the
	extent necessary to enable transmission of and access to the Content through
	the Service.</p>

	<p >&nbsp;</p>

	<p ><b>TextChat’s Intellectual Property Rights</b></p>

	<p >&nbsp;</p>

	<p >The Services are protected by copyright, trademark, and
	other laws of the United States and foreign countries. Except as expressly
	provided in the Terms, TextChat, its suppliers and licensors own all right,
	title and interest in and to the Services, including all associated
	intellectual property rights. You will not remove, alter or obscure any
	copyright, trademark, service mark or other proprietary rights notices
	incorporated in or accompanying the Services.</p>

	<p >&nbsp;</p>

	<p >TextChat always appreciates your feedback or other
	suggestions about TextChat, but you understand that we may use such feedback
	without any obligation to compensate you for them (just as you have no
	obligation to offer them). You grant us a non-exclusive, worldwide,
	royalty-free, sublicensable, transferable, irrevocable, perpetual license to
	use, modify, adapt, publish, perform, or derive new works from your feedback as
	we choose.</p>

	<p >&nbsp;</p>

	<p >You may not duplicate, copy, reuse, or reverse engineer any
	portion of the Services, including, but not limited to, the HTML, CSS,
	Javascript, visual design elements, features, functions, or graphics without
	express written permission from us.</p>

	<p >&nbsp;</p>

	<p >All trademarks, service marks, logos, trade names and any
	other proprietary designations of TextChat used herein are trademarks or registered
	trademarks of TextChat. Any other trademarks, service marks, logos, trade names
	and any other proprietary designations are the trademarks or registered
	trademarks of their respective parties.</p>

	<p >&nbsp;</p>

	<p ><b>Digital Millennium Copyright Act Notice</b></p>

	<p >&nbsp;</p>

	<p >If you believe that your copyrighted work has been copied in
	a way that constitutes copyright infringement and is accessible through our
	Services, please notify our copyright agent, as set forth in the Digital
	Millennium Copyright Act of 1998 (“DMCA”). For your complaint to be valid under
	the DMCA, you must provide the following information in writing:</p>

	<p >&nbsp;</p>

	<p ><span
	>·<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
	</span></span>An electronic or physical signature of a person authorized to act
	on behalf of the copyright owner;</p>

	<p ><span
	>·<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
	</span></span>Identification of the copyrighted work that you claim is being
	infringed;</p>

	<p ><span
	>·<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
	</span></span>Identification of the material that is claimed to be infringing
	and where it is located;</p>

	<p ><span
	>·<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
	</span></span>Information reasonably sufficient to permit us to contact you,
	such as your address, telephone number, and e-mail address;</p>

	<p ><span
	>·<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
	</span></span>A statement that you have a good faith belief that use of the
	material in the manner complained of is not authorized by the copyright owner,
	its agent, or law; and</p>

	<p ><span
	>·<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
	</span></span>A statement, made under penalty of perjury, that the above
	information is accurate, and that you are the copyright owner or are authorized
	to act on behalf of the owner.</p>

	<p >&nbsp;</p>

	<p >Our designated copyright agent to receive notifications of
	claimed infringement can be reached as follows</p>

	<p >&nbsp;</p>

	<p >info@TextChat.com</p>

	<p >OR</p>

	<p >Copyright Agent</p>

	<p >JetSpring LLC (dba TextChat)</p>

	<p >2 Radnor Corporate Center</p>

	<p >100 Matsonford Road, Suite 101</p>

	<p >Radnor, PA 19087</p>

	<p >&nbsp;</p>

	<p >As part of your enjoyment of the Services, subject to these
	Terms, you may grant a third party access to your account to provide additional
	products, services or labor provided you agree to be responsible for the actions
	of the third party and to bind the third party to these Terms as your agent.</p>

	<p >&nbsp;</p>

	<p >From time to time, separate applications or services that
	integrate or interoperate with the TextChat Services (collectively, “Add-ons”)
	may become available, either directly from TextChat or independently. If you
	choose to install, access or enable an Add-on, you agree that the third-party
	Add-on provider may acquire access to your account data and information as
	required for the interoperation or integration of such Add-on and you grant TextChat
	permission to give such Add-on access to your account data and information.
	Accordingly, such applications are governed by their own terms and conditions
	and are not considered Services under these Terms.</p>

	<p >&nbsp;</p>

	<p ><b>Privacy Policy</b></p>

	<p >&nbsp;</p>

	<p >We take your privacy seriously. You can view our full
	explanation of how we handle your privacy on our dedicated privacy policy page
	(the “Privacy Policy”).</p>

	<p >&nbsp;</p>

	<p ><b>100% Service Guarantee</b></p>

	<p >&nbsp;</p>

	<p >We will respond to your support inquiries within one
	business day and we will ensure the Services have no unexpected downtime. If
	you do not believe we met this service level on any day during the period of
	your paid subscription, notify us by emailing support@TextChat.com within 30
	days of the issue and we will credit your subscription back for that day.</p>

	<p >&nbsp;</p>

	<p ><b>Cancellation</b></p>

	<p >&nbsp;</p>

	<p >You are solely responsible for properly cancelling your paid
	subscription. An email, phone, chat, or written request to cancel your paid
	subscription is not considered cancellation. You may cancel your paid
	subscription at any time by clicking on the “billing” link on the dashboard
	when logged in. The billing page provides a link to cancel your account. The
	cancellation of your paid subscription will occur at the end of your current
	paid up period. Upon cancellation or otherwise, you may request that your data
	be destroyed. The destruction of your data will follow the the terms of our
	Privacy Policy.</p>

	<p >&nbsp;</p>

	<p ><b>Termination</b></p>

	<p >&nbsp;</p>

	<p >TextChat may terminate your account without notice in the
	event that you breach any of these Terms. Your cancellation will take effect
	immediately and you will not be charged again. In the event of any termination,
	TextChat will not refund any of the prepaid Fees. Such termination of the
	Services will result in the deactivation or deletion of your account or your access
	to your account, and the forfeiture and relinquishment of all content in your
	account. TextChat reserves the right to refuse service to anyone for any reason
	at any time.</p>

	<p >&nbsp;</p>

	<p ><b>Refunds</b></p>

	<p >&nbsp;</p>

	<p >TextChat is not obligated to provide refunds or credits for
	partial months or years of the Services, upgrades or downgrades, or unused time
	during your subscription. If TextChat materially breaches these Terms and fails
	to cure this breach within thirty (30) days of receiving written notice from
	you of such breach, TextChat will refund the proportion of the pre-paid fees
	attributable to the period after the breach. If you terminate your account in
	the event of a suspension for more than 5 business days or discontinuance of
	the Services, you will be entitled to a refund of the proportion of pre-paid
	fees attributable to the period after termination.</p>

	<p >&nbsp;</p>

	<p >&nbsp;</p>

	<p >&nbsp;</p>

	<p ><b>Warranty Disclaimers</b></p>

	<p >&nbsp;</p>

	<p >You expressly understand and agree that:</p>

	<p >&nbsp;</p>

	<p >TextChat warrants that your data will be treated in
	accordance with its Privacy Policy and protected as described in the Privacy
	Policy. Except as expressly warranted in the terms, all Services are provided
	“as is” and TextChat hereby disclaims all warranties and conditions with regard
	to the Services, whether express, implied or statutory, including all
	warranties and conditions of merchantability, fitness for a particular purpose,
	title and non-infringement.</p>

	<p >&nbsp;</p>

	<p >Other than as expressly set in these Terms, TextChat makes
	no warranty that (i) the Services will meet your requirements or expectations,
	(ii) that your access to or use of the Services will be uninterrupted, timely,
	secure or error free, (iii) that any defects in the Services will be corrected,
	or (iv) that the Services or any server through which you access the Services
	are free of viruses or other harmful components.</p>

	<p >&nbsp;</p>

	<p >Any material downloaded or otherwise obtained through the
	use of the Services are accessed at your own discretion and risk, and you will
	be solely responsible for any damage to your computer system or loss of data
	that results from the download of any such material.</p>

	<p >&nbsp;</p>

	<p >No advice or information, whether oral or written, obtained
	by you from TextChat or through or from the Services shall create any warranty
	not expressly stated in these terms.</p>

	<p >&nbsp;</p>

	<p ><b>Limitation of Liability</b></p>

	<p >&nbsp;</p>

	<p >You expressly understand and agree that TextChat and its
	subsidiaries, affiliates, officers, employees, agents, partners and licensors
	shall not be liable to you for any indirect, incidental, special, consequential
	or exemplary damages, including, but not limited to, damages for loss of
	profits, goodwill, use, data or other intangible losses (even if TextChat has
	been advised of the possibility of such damages) in any way relating to or
	arising from this agreement. In no event shall TextChat or its respective
	suppliers’ aggregate liability for direct damages under this agreement exceed
	the fees paid for the immediately preceding month before the claim. Some
	jurisdictions do not allow the exclusion of certain warranties or the
	limitation or exclusion of liability for incidental or consequential damages.
	Accordingly, some of the above limitations may not apply to you.</p>

	<p >&nbsp;</p>

	<p >Please note that downgrading your account may result in the
	loss of content, features, or capacity of your account. TextChat does not
	accept any liability for any such losses.</p>

	<p >&nbsp;</p>

	<p ><b>Indemnification</b></p>

	<p >&nbsp;</p>

	<p >You agree to defend, indemnify and hold harmless TextChat,
	its officers, directors, employees, and agents, harmless for any losses, costs,
	liabilities and expenses (including reasonable attorneys’ fees) relating to or
	arising out of your connection to or use of the Services, including but not
	limited to any breach by you of these Terms, any claims arising from the
	content you submit, post, transmit or make available through the Services, and
	any misuse of the Services or claims against TextChat by an End User.</p>

	<p >&nbsp;</p>

	<p ><b>Non-solicitation</b></p>

	<p >&nbsp;</p>

	<p >You may not use the Services to solicit, interfere with, or
	endeavour to entice away from TextChat any TextChat customer, user, or
	subscriber.</p>

	<p >&nbsp;</p>

	<p >The Services are for your own use only. You may not resell,
	lease or provide them in any other way to anyone else, except as expressly
	permitted through the Services or by written permission of TextChat or by
	acting as an agent contracted by a third party to act on its behalf.</p>

	<p >&nbsp;</p>

	<p >If you are interested in becoming an TextChat partner,
	reseller, or affiliate, we are happy to talk with you. Affiliates, please look
	at the TextChat Partners program. If you have something else in mind, please
	email sales@TextChat.com or talk to us on live chat.</p>

	<p >&nbsp;</p>

	<p >Governing Law</p>

	<p >&nbsp;</p>

	<p >These Terms, your rights and obligations, and all actions
	contemplated by these Terms shall be governed by the laws of the state of Pennsylvania.
	Any disputes arising under this Agreement shall be heard in the state or
	federal courts in Montgomery County, Pennsylvania. You also agree that
	regardless of any statute or law to the contrary, any claim or cause of action
	arising from or related to the use of the Services must be filed within one (1)
	year after such claim or cause of action arose or be forever barred. The
	failure of either party to exercise in any respect any right provided for
	herein shall not be deemed a waiver of any further rights hereunder.</p>

	<p >&nbsp;</p>

	<p ><b>Force Majeure</b></p>

	<p >&nbsp;</p>

	<p >TextChat shall not be responsible for any failure to perform
	due to unforeseen, non-commercial circumstances beyond its reasonable control,
	including but not limited to acts of God, war, riot, crime, terrorist attacks,
	embargoes, acts of civil or military authorities, widespread Internet outages,
	fire, floods, earthquakes, accidents, strikes, fuel or energy. In the event of
	any such delay, any applicable period of time for action by TextChat may be
	deferred for a period equal to the time of such delay.</p>

	<p >&nbsp;</p>

	<p ><b>Integration and Severability</b></p>

	<p >&nbsp;</p>

	<p >These Terms are the entire agreement between you and TextChat
	with respect to the Services and supersede all prior or contemporaneous
	communications and proposals (whether oral, written or electronic) between you
	and TextChat with respect to the Services. If any provision of the Terms is
	found to be unenforceable or invalid, that provision will be limited or
	eliminated to the minimum extent necessary so that the Terms will otherwise
	remain in full force and effect and enforceable. The failure of either party to
	exercise in any respect any right provided for herein shall not be deemed a
	waiver of any further rights hereunder.</p>

	<p >&nbsp;</p>

	<p ><b>Assignment of Terms</b></p>

	<p >&nbsp;</p>

	<p >TextChat may assign or transfer the Terms, in its sole
	discretion, without restriction. Subject to the foregoing, the Terms will bind
	and inure to the benefit of the parties, their successors and permitted
	assigns.</p>

	<p >&nbsp;</p>

	<p ><b>Notices</b></p>

	<p >&nbsp;</p>

	<p >Any notices or other communications required hereunder,
	including those regarding modifications to the Terms, will be in writing and
	given by posting to our website. For notices made by e-mail, the date of
	receipt will be deemed the date on which such notice is transmitted.</p>

	<p >&nbsp;</p>

	<p >&nbsp;</p>

	<p ><b>Headings for Convenience Only</b></p>

	<p >&nbsp;</p>

	<p >Headings are for convenience only and have no legal or
	contractual effect.</p>

	<p >&nbsp;</p>

	<p ><b>Contact</b></p>

	<p >&nbsp;</p>

	<p >If you have any questions about the Terms of Service, please
	contact us at info@TextChat.com. Copyright © 2020 JetSpring LLC. </p>

	<p >&nbsp;</p>


            </Scrollable>,
                {
                    switchBtn: [
                      {
                          label:
                              "I agree to the Terms of Service",
                          value: "accepted"
                       }
                    ],
                    name: "agreeEULA",
                    mandatory: true
                }
            ]
        }
    ]
];
export const step4Description = [
    [
        {
            text: "Confirm your plan."
        },
        {
            text: "You will be automatically billed through the default payment method on your Shopify account once the 14-day free trial ends."
        },
    ],
    [
        {
            text: "To complete your registration, read and accept our Terms of Service and click \"Finish Setup\".",
		},
		{
			text: "When you end the setup process, you will be asked to agree to recurring charges of $%amount%",
			toReplace: [["%amount%", "billingAmount"]]
		},
        {
            text: "You can easily manage your TextChat subscriptions on the dashboard."
		}
    ]
];

export const step4video = {
	url: "https://www.w3schools.com/html/mov_bbb.mp4",
	poster: null
}
export const step4Substeps = 2