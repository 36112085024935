import React, { Component, useEffect, useMemo, useState } from "react";
import { Page } from "@shopify/polaris";
import Highcharts from "highcharts";
import {
  getDates,
  makeDistinct,
  getMinDate,
  getMaxDate,
  getDateCategories,
  getSeriesData,
} from "./Charts";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import answernetLogo from "../../../../assets/img/answernet-textchat.png";
import "./Dashboard.scss";
import moment from "moment";
import axios from "axios";
import cookie from "js-cookie";
import DashboardTable from "./DashboardTable";
import { Spinner } from "reactstrap";
import showWalkthrough from "../Walkthrough/Walkthrough";
import InfoIcon from "@mui/icons-material/Info";
import { DataGrid } from "@mui/x-data-grid";
import CloseIcon from "@mui/icons-material/Close";
import AddResultsModal from "./AddResultsModal";
import DispositionTable from "./DispositionTable";
import { Controller, useForm, useFormState } from "react-hook-form";
import { BootstrapInput } from "../../../Input/BootstrapInput";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  Modal,
  styled,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import MuiTable from "@mui/material/Table";
import { Add, Delete } from "@mui/icons-material";
import { useMutation, useQuery } from "react-query";
import { useExpanded, useTable } from "react-table";
import { queryClient } from "../../../../constants";
import HeaderCard from "src/components/HeaderCard";

const StyledForm = styled("form")({
  width: "100%",
  display: "flex",
});

const StyledInputLabel = styled(InputLabel)({
  fontSize: "18px",
  fontWeight: 400,
});

const StyledTableCell = styled(TableCell)({
  fontSize: "1.5rem",
});

const StyledTableCellBody = styled(TableCell)({
  fontSize: "1.5rem",
});

const StyledTableHead = styled(TableHead)({
  backgroundColor: "#FAFAFA",
});
let parser = require("ua-parser-js");

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Dashboard = ({ serverAPI }) => {
  const [info, setInfo] = useState({
    tableData: null,
    daysSelected: 30,
    token: null,
    payloadData: null,
    dispositionName: "",
  });
  const [welcomeModalOpen, setWelcomeModalOpen] = useState(false);
  const [addResultsOpen, setAddResultsOpen] = useState(false);
  const [dispositions, setDispositions] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [targetDisposition, setTargetDisposition] = useState(null);

  const handleAddResultsClose = () => {
    setAddResultsOpen(false);
  };

  useEffect(() => {
    const isModalOpen = localStorage.getItem("WELCOME_MODAL_OPEN");
    setWelcomeModalOpen(!!isModalOpen);
  }, []);

  const onClickContinueOnWelcomeModal = () => {
    localStorage.setItem("WELCOME_MODAL_OPEN", true);
    setWelcomeModalOpen(true);
  };

  const handleAddDisposition = (newDisposition, editMode = false) => {
    const customDispositions = dispositions.slice();
    if (editMode) {
      customDispositions[newDisposition.id] = {
        ...newDisposition,
      };
    } else {
      customDispositions.push({
        ...newDisposition,
        id: dispositions.length,
      });
      setInfo({
        ...info,
        dispositionName: "",
      });
    }
    setDispositions(customDispositions);
  };

  const deleteDispositionMutation = useMutation(
    (data) => axios.post(serverAPI + "/app/disposition/deactivate/" + data.dispositionId),
    {
      onSuccess: (result) => {
        queryClient.invalidateQueries("GET_DISPOSITIONS");
      }
    }
  )

  const deleteDisposition = (data) => {
    deleteDispositionMutation.mutate(data);
  }

  const handleEditDisposition = (row) => {
    setTargetDisposition(row);
    setEditMode(true);
    setAddResultsOpen(true);
  };

  const handleRemoveDisposition = (row) => {
    const customDispositions = dispositions.slice();
    const targetIndex = customDispositions.findIndex(
      (item) => item.id === row.id
    );
    if (targetIndex > -1) {
      customDispositions.splice(targetIndex, 1);
    }
    setDispositions(customDispositions);
  };

  useEffect(() => {
    onMount();
    showWalkthrough();
  }, []);

  const loadTableData = async (token) => {
    let endDate = moment().format("YYYY-MM-DD");
    let startDate = moment(endDate).subtract(1000, "days").format("YYYY-MM-DD");

    /*let testStart = "2020-07-01"
        let testEnd = "2020-08-31"
        let testToken = "2047959D-7E60-46C6-B679-96DAC9C2DD8B"*/

    return await axios
      .post(serverAPI + "/app/GetChatActivity", {
        start: startDate,
        end: endDate,
        ClientToken: token,
      })
      .then((response) => {
        const customTableData = [];
        response.data.forEach((item, index) => {
          let parseObj = new parser(item.userAgentRaw);
          window.parseTest = parseObj;
          let device = "";
          if (parseObj.getDevice().vendor) {
            device = parseObj.getDevice().vendor;
          } else if (parseObj.getOS().name) {
            device = parseObj.getOS().name;
          }

          customTableData.push({
            id: index,
            date: moment(item.dataCreated).format("MM/DD/YYYY"),
            time: moment(item.dateCreated).format("h:mm a"),
            visitorName: item.clientName,
            location: item.geoInfoRaw,
            device,
            messages: item.totalMessages,
            interactionId: item.globalInteractionID,
            chatID: item.chatID,
          });
        });
        setInfo({ ...info, tableData: customTableData });
        return response.data;
      });
  };

  const loadAPIToken = async (knowMounted = false) => {
    return await axios
      .get(serverAPI + "/app/textchat/get/token", {
        params: {
          shop: cookie.get("shop"),
        },
      })
      .then((response) => {
        return response.data.clientToken;
      })
      .catch((error) => {
        console.warn(error.response);
      });
  };

  const updateReport = async (
    clientToken,
    startDate = null,
    endDate = null
  ) => {
    let initialD = startDate;
    let finalD = endDate;
    if (!finalD) {
      finalD = moment().format("YYYY-MM-DD");
    }
    if (!initialD) {
      initialD = moment(finalD)
        .subtract(info.daysSelected, "days")
        .format("YYYY-MM-DD");
    }

    /*************************************
        API Call/Result goes here
        *************************************/
    let resultPayload = await getReport(clientToken, initialD, finalD);

    setInfo({ ...info, payloadData: resultPayload });
    if (resultPayload !== "") createChart(resultPayload, initialD, finalD);
  };

  const createInitialChart = () => {
    let finalD = moment().format("MM-DD-YYYY");
    let initialD = moment(finalD)
      .subtract(info.daysSelected, "days")
      .format("MM-DD-YYYY");

    let initialData = [];
    createChart(initialData, initialD, finalD);
  };

  const formatChartData = (chartData) => {
    for (let g = 0; g < chartData.length; g++) {
      let groupName = chartData[g]["name"];
      if (groupName === "Sales") chartData[g]["color"] = "#0c25fb";
      if (groupName === "Support") chartData[g]["color"] = "#fd3939";
    }
    return chartData;
  };

  const onMount = async () => {
    // this.createInitialChart();
    var token = await loadAPIToken();
    setInfo({ ...info, token });
    // this.updateReport(token);
    loadTableData(token);
  };

  const handleDispositionChange = (event) => {
    setInfo({
      ...info,
      dispositionName: event.target.value,
    });
  };

  const createChart = (chartData, startDate, endDate) => {
    /*************************************
        Transformation Process here
        *************************************/
    // Step 1: Get Min and Max Date ranges
    let minDate = startDate;
    let maxDate = endDate;
    let minDateFormatted = moment(minDate).format("MM-DD-YYYY");
    let maxDateFormatted = moment(maxDate).format("MM-DD-YYYY");

    // Step 2: Create full dates between two date ranges
    let dateRange = getDates(minDate, maxDate);

    // Step 3: Get series names (to be used for line series)
    let series = ["Sales", "Support"];

    // suffix for the title (can remove you want)
    let strDateRange = minDateFormatted + " - " + maxDateFormatted;

    /*************************************
        DO IT!
        *************************************/

    // "Driver" for the HighCharts engine
    let Chart = {
      title: series.join(" vs ") + ", " + strDateRange,
      subTitle: null,
      yTitle: "Total Chats",
      series: formatChartData(
        getSeriesData(series, minDate, maxDate, chartData)
      ),
      categories: getDateCategories(minDate, maxDate),
      maxWidth: 800,
    };

    // Don't change unless you like pain
    Highcharts.chart("container", {
      title: { text: Chart.title },
      subtitle: { text: Chart.subTitle },
      yAxis: { title: { text: Chart.yTitle } },
      xAxis: {
        categories: Chart.categories,
        labels: {
          rotation: -90,
          step: 5,
        },
      },

      legend: {
        layout: "vertical",
        align: "right",
        verticalAlign: "middle",
        style: { fontFamily: '"Gibson Light"' },
      },

      plotOptions: {
        series: {
          label: {
            connectorAllowed: false,
          },
          //pointStart: Chart.categories[0]
        },
      },

      series: Chart.series,

      responsive: {
        rules: [
          {
            condition: {
              maxWidth: Chart.maxWidth,
            },
            chartOptions: {
              legend: {
                layout: "horizontal",
                align: "center",
                verticalAlign: "bottom",
              },
            },
          },
        ],
      },
      credits: { enabled: false },
      chart: {
        style: { fontFamily: '"Gibson Light"', fontSize: "12px" },
        backgroundColor: "#ecedf7",
        plotBackgroundColor: "#ffffff",
      },
    });
  };

  const getReport = async (clientToken, startDate, endDate) => {
    return await axios
      .post(serverAPI + "/app/FlowReport", {
        start: startDate,
        end: endDate,
        ClientToken: clientToken,
      })
      .then((response) => {
        return response.data;
      });
  };

  const didLoad = info.tableData !== null;
  const loadedWithData = info.tableData !== null && info.tableData.length > 0;

  const theme = useTheme();

  return (
    <div className="dashboard-custom">
      <Page
        separator
        // title="Dashboard"
        // subtitle={
        //   <div>
        //     Please review all conversations from your business communication
        //   </div>
        // }
      >
        <div
          className="text-center"
          style={didLoad ? { display: "none" } : undefined}
        >
          <Spinner color="primary" />
        </div>
        <Box display="flex" justifyContent="center" marginBottom={"20px"}>
          <HeaderCard
            title="Dashboard"
            description="Please review all conversations from your business communication"
          />
        </Box>
        <DashboardTable serverAPI={serverAPI} />
        <Despositions serverAPI={serverAPI} deleteDisposition={deleteDisposition} />
        <WelcomeModal
          onClickContinueOnWelcomeModal={onClickContinueOnWelcomeModal}
          open={!welcomeModalOpen}
        />
      </Page>
      <AddResultsModal
        modalOpen={addResultsOpen}
        modalClose={handleAddResultsClose}
        title={info.dispositionName}
        initialData={targetDisposition}
        editMode={editMode}
        saveDisposition={handleAddDisposition}
      />
    </div>
  );
};

const Despositions = ({ serverAPI, deleteDisposition }) => {
  const [despositionResultModalOpen, setDespositionResultModalOpen] =
    useState(false);
  const {
    register,
    control,
    handleSubmit,
    getValues,
    resetField,
    formState: { isValid },
  } = useForm({
    mode: "all",
    resolver: yupResolver(
      yup.object({
        dispositionName: yup
          .string()
          .required("Please enter a disposition name"),
      })
    ),
  });

  const onSubmit = (data) => {
    setDespositionResultModalOpen(true);
  };

  const theme = useTheme();

  return (
    <div className="dashboard-custom-conversation">
      <div className="dashboard-custom-conversation-header">
        Conversation Dispositions (disposition = conversation outcomes)
      </div>
      <div className="dashboard-custom-conversation-desc">
        Here is where you can set conversation outcomes, allowing your team to
        track your results. You can create your first disposition in the field
        below.
      </div>
      <div className="dashboard-custom-conversation-title">
        <div className="dashboard-custom-conversation-title-text">
          Disposition Name
        </div>
        <InfoIcon />
      </div>

      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="dispositionName"
          render={({ field, fieldState }) => (
            <BootstrapInput
              {...field}
              {...fieldState}
              {...register("dispositionName")}
              sx={{
                flexGrow: 1,
                marginRight: theme.spacing(3),
              }}
              type="text"
            />
          )}
        />
        <Button
          onClick={() => {
            // setEditMode(false);
            // setTargetDisposition(null);
            // setAddResultsOpen(true);
          }}
          variant="contained"
          type="submit"
          disabled={!isValid}
        >
          create new
        </Button>
      </StyledForm>
      <DispositionResultModal
        open={despositionResultModalOpen}
        onClose={() => {
          setDespositionResultModalOpen(false);
        }}
        onSubmitSuccess={() => {
          resetField("dispositionName")
        }}
        serverAPI={serverAPI}
        {...getValues()}
      />

      {/* {dispositions.length > 0 && (
    <DispositionTable
      rows={dispositions}
      editRow={handleEditDisposition}
      removeRow={handleRemoveDisposition}
    />
  )} */}
      <DispositionsTable serverAPI={serverAPI} deleteDisposition={deleteDisposition} />
    </div>
  );
};

const StyledImage = styled("img")``;

const WelcomeModal = ({ open, onClickContinueOnWelcomeModal }) => {
  const theme = useTheme();
  return (
    <BootstrapDialog maxWidth="xs" fullWidth open={open} onClose={() => {}}>
      <Box paddingY={theme.spacing(3)} paddingX={theme.spacing(4)} width="100%">
        <Box
          display="flex"
          justifyContent="center"
          marginBottom={theme.spacing(2)}
        >
          <StyledImage
            src={answernetLogo}
            sx={{
              width: "245px",
              height: "70px",
            }}
          />
        </Box>
        <Typography fontWeight="bold" textAlign="center" fontSize="20px">
          Welcome, Let's get started
        </Typography>
        <Typography
          marginTop={theme.spacing(2.5)}
          color="#305973"
          fontWeight="500"
          marginBottom={theme.spacing(2)}
          fontSize="16px"
        >
          This is your admin dashboard where you'll be able to:
        </Typography>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box marginLeft="10px">
            <ul>
              <li>
                <Typography color="#305973" fontWeight="500" fontSize="16px">
                  Review all conversations for your account
                </Typography>
              </li>
              <li>
                <Typography color="#305973" fontWeight="500" fontSize="16px">
                  Set up your conversation dispositions
                </Typography>
              </li>
              <li>
                <Typography color="#305973" fontWeight="500" fontSize="16px">
                  Add Users
                </Typography>
              </li>
              <li>
                <Typography color="#305973" fontWeight="500" fontSize="16px">
                  Adjust live agent support settings
                </Typography>
              </li>
            </ul>
          </Box>
        </Box>
        <Typography
          marginTop={theme.spacing(2.5)}
          color="#305973"
          fontWeight="500"
          marginBottom={theme.spacing(2)}
          fontSize="16px"
        >
          If you have questions, feel free to contact us at
          support@answermytexts.com
        </Typography>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Button
            sx={{
              marginTop: theme.spacing(2),
            }}
            variant="contained"
            onClick={() => {
              onClickContinueOnWelcomeModal();
            }}
          >
            Continue
          </Button>
        </Box>
      </Box>
    </BootstrapDialog>
  );
};

const DispositionResultModal = ({
  dispositionName,
  open,
  onClose: onCloseModal,
  serverAPI,
  onSubmitSuccess
}) => {
  const [numberOfResults, setNumberOfResults] = useState(2);

  const { control, formState, setValue, handleSubmit, getValues, reset } =
    useForm({
      defaultValues: {
        dispositionName,
      },
    });

  const onClose = () => {
    onCloseModal();
    reset();
  };

  useEffect(() => {
    setValue("dispositionName", dispositionName);
  }, [dispositionName]);

  const createDispositionMutation = useMutation(
    (data) => axios.post(serverAPI + "/app/disposition/create", data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("GET_DISPOSITIONS");
        onClose();
        onSubmitSuccess();
      },
    }
  );

  const onSubmit = (data) => {
    // find all that have string result from {result1:'', result2:'', result3:''}
    const resultsArr = [];
    Object.keys(data).filter((key) => {
      if (key.includes("result") && data[key] !== "") {
        resultsArr.push(data[key]);
        return true;
      }
    });

    const { dispositionName } = getValues();
    const token = cookie.get("token");

    const dataToSend = {
      name: dispositionName,
      clientToken: token,
      dispositionResults: resultsArr,
    };

    createDispositionMutation.mutate(dataToSend);
  };

  return (
    <BootstrapDialog maxWidth="xs" fullWidth open={open} onClose={onClose}>
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          fontSize: "1.5rem",

          h2: {
            fontSize: "1.6rem",
          },
        }}
      >
        Create Desposition
        {onClose ? (
          <IconButton
            aria-label="close"
            size="large"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              fontSize: "5rem",
              color: (theme) => theme.palette.grey[500],
              ".MuiSvgIcon-fontSizeMedium": {
                fontSize: "2.2rem",
              },
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="dispositionName"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl
                sx={{
                  marginBottom: "2.5rem",
                }}
                fullWidth
                variant="standard"
              >
                <StyledInputLabel shrink htmlFor="dispositionName">
                  Desposition Name
                </StyledInputLabel>
                <BootstrapInput
                  {...field}
                  {...fieldState}
                  disabled
                  fullWidth
                  error={!!formState.errors.dispositionName}
                  id="dispositionName"
                />
                {formState.errors.dispositionName && (
                  <Typography color="error" fontSize="12px" marginTop="4px">
                    {formState.errors.dispositionName.message}
                  </Typography>
                )}
              </FormControl>
            )}
          />
          {Array.from({ length: numberOfResults }).map((_, index) => {
            const keyAndName = `result${index}`;
            return (
              <Controller
                name={keyAndName}
                control={control}
                key={index}
                render={({ field, fieldState }) => (
                  <FormControl
                    sx={{
                      marginBottom: "2.5rem",
                    }}
                    fullWidth
                    variant="standard"
                  >
                    <StyledInputLabel shrink htmlFor={keyAndName}>
                      Desposition Result {index + 1}
                    </StyledInputLabel>
                    <BootstrapInput
                      {...field}
                      {...fieldState}
                      fullWidth
                      error={!!formState.errors.dispositionName}
                      id={keyAndName}
                    />
                    {formState.errors[keyAndName] && (
                      <Typography color="error" fontSize="12px" marginTop="4px">
                        {formState.errors[keyAndName].message}
                      </Typography>
                    )}
                  </FormControl>
                )}
              />
            );
          })}
          <Button
            sx={{
              marginRight: "1rem",
            }}
            variant="outlined"
            color="secondary"
            onClick={() => setNumberOfResults(numberOfResults + 1)}
            startIcon={<Add />}
          >
            Add More Results
          </Button>
          <Box display="flex" justifyContent="flex-end">
            <Button
              sx={{
                marginRight: "1rem",
              }}
              variant="outlined"
              color="secondary"
              onClick={() => onClose()}
            >
              Cancel
            </Button>
            <Button type="submit" variant="contained">
              Save
            </Button>
          </Box>
        </form>
      </DialogContent>
    </BootstrapDialog>
  );
};

const DispositionsTable = ({ serverAPI, deleteDisposition }) => {
  const token = cookie.get("token");

  const columns = [
    {
      Header: "Disposition Name",
      accessor: "name",
    },
    {
      Header: "Disposition Results",
      accessor: "dateCreated",
      Cell: ({ row }) => {
        const results = row.original.dispositionResults;
        return (
          <Box>
            {results.map((result, indx) => (
              <Chip
                key={result.name + indx}
                sx={{
                  marginRight: "1rem",
                }}
                label={result.name}
              />
            ))}
          </Box>
        );
      },
    },
    {
      Header: "Actions",
      accessor: "id",
      // TODO: implement this once backend is ready
      Cell: ({ row }) => { 
        const disposition = row.original;
        return (
        <Box
        >
          <IconButton>
            <Delete
              color="error"
              sx={{
                fontSize: "2.2rem",
              }}
              onClick={() => {
                deleteDisposition({dispositionId: row.original.id})
              }}
            />
          </IconButton>
        </Box>
      )
    },
    },
  ];

  const { data, isLoading } = useQuery("GET_DISPOSITIONS", async () =>
    axios.get(serverAPI + `/app/disposition/getAll/${token}?isDisplayNotInUse=false`)
  );

  const despositions = useMemo(() => data?.data || [], [data]);

  const cols = useMemo(() => columns, []);

  const { getTableBodyProps, headerGroups, rows, prepareRow, getTableProps } =
    useTable(
      {
        columns: cols,
        data: despositions,
      },
      useExpanded
    );

  return (
    <TableContainer
      sx={{
        marginTop: "2rem",
      }}
      component={Box}
    >
      {isLoading && <LinearProgress />}
      <MuiTable {...getTableProps()}>
        <StyledTableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <StyledTableCell {...column.getHeaderProps()}>
                  {column.render("Header")}
                </StyledTableCell>
              ))}
            </TableRow>
          ))}
        </StyledTableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps(row)}>
                {row.cells.map((cell) => (
                  <StyledTableCellBody {...cell.getCellProps()}>
                    {cell.render("Cell")}
                  </StyledTableCellBody>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
};

export default Dashboard;
