import { AlertColor, Snackbar, Alert, SnackbarOrigin } from "@mui/material";
import React, { useMemo, useState } from "react";

type SnackBarContextType = {
  openSnackbar: boolean;
  setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  snackBarMessage: string;
  setSnackBarMessage: React.Dispatch<React.SetStateAction<string>>;
  snackBarSeverity: AlertColor | undefined;
  setSnackBarSeverity: React.Dispatch<
    React.SetStateAction<AlertColor | undefined>
  >;
  openSnackbarContext: (
    message: string,
    severity: AlertColor,
    origin?: SnackbarOrigin
  ) => void;
};

const SnackBarContext = React.createContext<SnackBarContextType>({
  openSnackbar: false,
  setOpenSnackbar: () => {},
  snackBarMessage: "",
  setSnackBarMessage: () => {},
  snackBarSeverity: "info",
  setSnackBarSeverity: () => {},
  openSnackbarContext: () => {},
});

const SnackBarProvider = ({ children }: { children: React.ReactNode }) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarSeverity, setSnackBarSeverity] = useState<AlertColor>();
  const [anchorOrigin, setAnchorOrigin] = useState<SnackbarOrigin>({
    vertical: "top",
    horizontal: "right",
  });

  const openSnackbarContext = (
    message: string,
    severity: AlertColor,
    origin?: SnackbarOrigin
  ) => {
    if (origin) {
      setAnchorOrigin(origin);
    }
    setSnackBarMessage(message);
    setSnackBarSeverity(severity);
    setOpenSnackbar(true);
  };

  const memoizedObj = useMemo(
    () => ({
      openSnackbar,
      setOpenSnackbar,
      snackBarMessage,
      setSnackBarMessage,
      snackBarSeverity,
      setSnackBarSeverity,
      openSnackbarContext,
    }),
    []
  );

  return (
    <SnackBarContext.Provider value={memoizedObj}>
      {children}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={anchorOrigin}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={snackBarSeverity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
    </SnackBarContext.Provider>
  );
};

export const useSnackBarContext = () => {
  const context = React.useContext(SnackBarContext);
  if (!context) {
    throw new Error(
      "useSnackBarContext must be used within a SnackBarProvider"
    );
  }
  return context;
};

export default SnackBarProvider;
