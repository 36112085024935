import React, { useState } from "react";
import axios from "axios";
import backendUtility from "../General/backendUtility";
import MessageTable from "./MessageTable";
import "./Messages.scss";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Spinner } from "reactstrap";
import moment from "moment";

export default function Messages({ shop, serverAPI, message_id, is_sms_message }) {
  const [loaded, setLoaded] = useState(false);
  const [messageID, setMessageID] = useState(null);
  const [messageData, setMessageData] = useState([]);
  const url_string = window.location.href;
  const url = new URL(url_string);

  if (messageID !== message_id) {    
    backendUtility.getToken(serverAPI, shop).then((token) => {
      
      // if not sms look up regular transcript from Chat table
      if(!is_sms_message) {
        axios
        .post(serverAPI + "/app/GetChatMessages", {
          chatId: message_id,
          ClientToken: token,
        })
        .then((response) => {
          setMessageID(message_id);
          console.log(response.data);
          const customMessageData = [];
          response.data.forEach((item, index) => {
            let author = "";
            if (item.author === "C") {
              author = "Chatbot";
            } else if (item.author === "V") {
              author = "Visitor";
            } else if (item.author === "A") {
              author = item.agentName;
            }
            customMessageData.push({
              id: index,
              author,
              messageRaw: item.messageRaw,
              dateSent: moment(item.dateSent).format("h:mm a"),
            });
          });
          setMessageData(customMessageData);
          setLoaded(true);
        })
        .catch((error) => console.log(error));
      }
      else {
        // look up SMS transcript
        // /app/smsChat/getTranscript/:sessionToken/:clientToken
        axios.get(`${serverAPI}/app/smsChat/getTranscript/${message_id}/${token}`)
          .then((response) => {            
            setMessageID(message_id);
            const customMessageData = [];
            response.data.forEach((item, index) => {
              customMessageData.push({
                id: index,
                author: item.author,
                messageRaw: item.messageText,
                dateSent: moment(item.dateSent).format("h:mm a")
              });              
            });

            setMessageData(customMessageData);
            setLoaded(true);
          })
          .catch((error) => console.log(error));
      }
    });
  }

  return (
    <div className="messages">
      <div
        className="text-center"
        style={loaded ? { display: "none" } : undefined}
      >
        <Spinner color="primary" />
      </div>
      <div style={loaded ? undefined : { display: "none" }}>
        <MessageTable setLoaded={setLoaded} data={messageData} />;
      </div>
    </div>
  );
}
