import React from "react";
import { Button, IconButton, styled } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

export const ModalHeader = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  height: "65px",
  borderBottom: "1px solid #EBEAED",
});

export const ModalHeaderText = styled("div")({
  fontWeight: "500",
  fontSize: "20px",
  lineHeight: "125%",
  letterSpacing: "0.15px",
  color: "#305973",
});

export const ModalHeaderCloseBtn = ({ children, ...restProps }) => (
  <IconButton
    {...restProps}
    sx={{
      "& svg": {
        fill: "#305973",
        width: "14px",
        height: "14px",
      },
    }}
  >
    {children}
  </IconButton>
);

export const NameLabel = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  marginBottom: "10px",
  marginTop: "16px",
  "& svg": {
    width: "12px",
    height: "12px",
    fill: "#BDBDBD",
  },
});

export const NameLabelText = styled("div")({
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "125%",
  letterSpacing: "0.5px",
  color: "#1B1D21",
  marginRight: "8px",
});

export const NameLabelValue = styled("div")({
  fontWeight: "600",
  fontSize: "17px",
  lineHeight: "125%",
  letterSpacing: "0.5px",
  color: "#262626",
  marginBottom: "19px",
});

export const ResultsWrapper = styled("div")({
  position: "relative",
  marginTop: "6px",
});

export const ResultInput = ({ ...restProps }) => (
  <input
    {...restProps}
    placeholder="Enter result here..."
    style={{
      width: "100%",
      height: "48px",
      border: "1px solid #EBEAED",
      borderRadius: "10px",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "125%",
      letterSpacing: "0.5px",
      color: "#262626",
      marginBottom: "16px",
      paddingLeft: "16px",
      "&:focus-visible": {
        outline: "none",
      },
    }}
  />
);

export const AddMoreResultBtn = ({ ...restProps }) => (
  <Button
    {...restProps}
    variant="outlined"
    startIcon={
      <AddIcon sx={{ fill: "#305973", width: "16px", height: "16px" }} />
    }
    sx={{
      borderRadius: "10px",
      borderColor: "#305973",
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "125%",
      letterSpacing: "0.15px",
      color: "#305973",
      height: "48px",
      marginBottom: "16px",
    }}
  >
    Add More results
  </Button>
);

export const ActionWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});
