import React from "react";
import { useHistory } from "react-router-dom";
import LoaCheckIcon from "../../images/loa-check.png";

const SignUpLOA = () => {
  const history = useHistory();
  const handleContinue = () => {
    history.push("/enrollment-form/agent-transfer");
  };
  return (
    <div className="signup-loa">
      <div className="steps-header">We successfully verified your number!</div>
      <div className="checkbox-wrapper">
        <img src={LoaCheckIcon} alt="" />
      </div>
      <div className="loa-desc">
        Check your email! You’ll need to electronically sign the Letter of
        Authorization (LoA) from our partner, Twilio.
        <br />
        <br />
        The email will come from noreply@mail.hellosign.com. If you don’t
        receive it after a few minutes, please check your spam folder.
        <br />
        <br />
        Don’t worry, all information will be prefilled for you. We just need
        your John Hancock!
      </div>
      <div className="continue-btn">
        <button onClick={() => handleContinue()}>Continue</button>
      </div>
    </div>
  );
};

export default SignUpLOA;
