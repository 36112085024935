import React from 'react';
import './StepHeadBorder.css';

const StepHeadBorder = ({step, substep, substeps}) => {
    let subSections;
    if (substeps) {
        var indices = [...Array(substeps).keys()];
        subSections = indices.map(i => {
            let marginClass;
            if (i === 0)
                marginClass = " first-sub"
            else if (i === substeps - 1)
                marginClass = " last-sub"
            else
                marginClass = ""
            return(
                <div className={"Substep" + (substep === i ? " js-active" : "") + marginClass}/>
            );
        })
    }
    return(
        <div className="StepHeadBorder">
                        <div className={"Step" + (step === 1 && !substeps ? " js-active" : "")}>
                            {step === 1 && substeps ? subSections : null}
                        </div>
                        <div className={"Step" + (step === 2 && !substeps ? " js-active" : "")}>
                            {step === 2 && substeps ? subSections : null}
                        </div>
                        <div className={"Step" + (step === 3 && !substeps ? " js-active" : "")}>
                            {step === 3 && substeps ? subSections : null}
                        </div>
                        <div className={"Step" + (step === 4 && !substeps ? " js-active" : "")}>
                            {step === 4 && substeps ? subSections : null}
                        </div>
        </div>
    )
}

export default StepHeadBorder;