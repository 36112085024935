import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import SelectUSState from "react-select-us-states";
import "../../css/stylesheet.scss?v";
import utility from "../../components/General/utility";
import { Alert } from "reactstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Config from "../../config.js";
import axios from "axios";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import InputMask from "react-input-mask";
import ErrorModal from "./ErrorModal";
import Loader from "./Loader";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Button, Modal, ModalBody } from "reactstrap";
import AnswerNet_logo from "../../images/answerNet_logo_02.png";

const serverAPI = Config.apiGateway.URL;
axios.defaults.headers.common["Authorization"] = Config.backendGUID;

const AnwerNetSignUp = (props) => {
  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    companyName: "",
    websiteUrl: "",
    cellNumber: "",
    cellNumberCode: "1",
    busiLandNumber: "",
    busiLandNumberCode: "1",
    address: "",
    suite: "",
    city: "",
    state: "",
    zipCode: "",
    accountNumber: "",
    didNumber: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [modal, setModal] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [isNumberTextable, setIsNumberTextable] = useState();

  useEffect(() => {
    // localStorage.clear();
    const rightSideWrapper = document.querySelector(
      ".rightside .inner-wrapper"
    );
    rightSideWrapper.style.display = "flex";
    rightSideWrapper.style.flexDirection = "column";
    rightSideWrapper.style.alignItems = "center";
    rightSideWrapper.style.justifyContent = "start";

    setUserInfo({
      ...userInfo,
      state: "AL",
    });
  }, []);

  const [busiLandNumberCode, setBusiLandNumberCode] = useState("1");
  const [isErrorModal, setErrorModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [invalidAction, setInvalidAction] = useState(false);
  const [errorText, setErrorText] = useState(false);
  const [eligibilityStatus, setEligibilityStatus] = useState("");
  const [isFirstNameError, setFirstNameError] = useState(false);
  const [isLastNameError, setLastNameError] = useState(false);
  const [isEmailAddressError, setEmailAddressError] = useState(false);
  const [isPasswordError, setPasswordError] = useState(false);
  const [isCompanyNameError, setCompanyNameError] = useState(false);
  const [isWebsiteURLError, setWebsiteURLError] = useState(false);
  const [isBusinessLandNumberError, setBusinessLandNumberError] =
    useState(false);
  const [isCellphoneNumberError, setCellphoneNumberError] = useState(false);
  const [isAddressError, setAddressError] = useState(false);
  const [isCityError, setCityError] = useState(false);
  const [isZipCodeError, setZipCodeError] = useState(false);
  const [isConfirmPasswordError, setConfirmPasswordError] = useState(false);
  const [isAccountNumberError, setAccountNumberError] = useState(false);
  const [isDidNumberError, setDidNumberError] = useState(false);

  const history = useHistory();

  const toggle = () => {
    setModal(!modal);
  };

  const handleChange = (ev) => {
    if (ev.target.name == "cellNumber" || ev.target.name == "busiLandNumber") {
      let tempPhoneNumber = ev.target.value.replace(/[^\w\s]/gi, "");
      tempPhoneNumber = tempPhoneNumber.replace(/\s+/g, "");
      setUserInfo({
        ...userInfo,
        [ev.target.name]: tempPhoneNumber,
      });
    } else {
      setUserInfo({
        ...userInfo,
        [ev.target.name]: ev.target.value,
      });
    }
  };

  const toggleErrorModal = () => {
    setErrorModal(!isErrorModal);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const passwordValidator = (password = "") => {
    var passwordvalid =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
    if (password.match(passwordvalid)) return true;
    else return false;
  };

  const resetInputError = () => {
    setFirstNameError(false);
    setLastNameError(false);
    setEmailAddressError(false);
    setPasswordError(false);
    setCompanyNameError(false);
    // setWebsiteURLError(false);
    setBusinessLandNumberError(false);
    setCellphoneNumberError(false);
    setAddressError(false);
    setCityError(false);
    setZipCodeError(false);
    setConfirmPasswordError(false);
  };

  const validateInputs = () => {
    let hasError = false;
    setErrorText(false);
    let isEmailWrongError = false;

    utility.verifyInput("email", userInfo.email, () => {
      // setInvalidAction(true);
      hasError = true;
      if (userInfo.email) {
        setErrorText("Email Address is not valid.");
      } else {
        setErrorText("Please include email in order to proceed");
      }
      // setEmailAddressError(true);
      isEmailWrongError = true;
      // console.log("Email Address is not valid.");
      return false;
    });

    if (isEmailWrongError) {
      setInvalidAction(true);
      setEmailAddressError(true);
      return false;
    }

    if (!passwordValidator(userInfo.password)) {
      // setInvalidAction(true);
      hasError = true;
      setInvalidAction(true);
      setPasswordError(true);
      setErrorText(
        "Password should be minimum 8 characters, at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 symbol"
      );
      return false;
    }

    if (userInfo.password != userInfo.confirmPassword) {
      // setInvalidAction(true);
      hasError = true;
      setPasswordError(true);
      setInvalidAction(true);
      setConfirmPasswordError(true);
      setErrorText("Password and confirm password should match.");
      return false;
    }

    const {
      firstName,
      lastName,
      password,
      confirmPassword,
      companyName,
      websiteUrl,
      busiLandNumber,
      cellNumber,
      address,
      city,
      state,
      zipCode,
    } = userInfo;
    let errorField = "";
    if (!firstName) {
      // setInvalidAction(true);
      hasError = true;
      errorField = "First Name";
      setFirstNameError(true);
    }
    if (!lastName) {
      // setInvalidAction(true);
      hasError = true;
      errorField = "Last Name";
      setLastNameError(true);
    }
    if (!companyName) {
      // setInvalidAction(true);
      hasError = true;
      setCompanyNameError(true);
      errorField = "Company Name";
    }
    // if (!websiteUrl) {
    //   hasError = true;
    //   // setInvalidAction(true);
    //   setWebsiteURLError(true);
    //   errorField = "Website URL";
    // }
    if (!password) {
      // setInvalidAction(true);
      hasError = true;
      setPasswordError(true);
      errorField = "Password";
    }
    if (!busiLandNumber) {
      setInvalidAction(true);
      hasError = true;
      setBusinessLandNumberError(true);
      errorField = "Business Landline Number";
    }
    if (!address) {
      // setInvalidAction(true);
      hasError = true;
      setAddressError(true);
      errorField = "Address";
    }
    if (!city) {
      // setInvalidAction(true);
      hasError = true;
      setCityError(true);
      errorField = "City";
    }
    if (!state) {
      // setInvalidAction(true);
      hasError = true;
      errorField = "State";
    }
    if (!zipCode) {
      // setInvalidAction(true);
      hasError = true;
      setZipCodeError(true);
      errorField = "Zip Code";
    }
    if (!cellNumber) {
      // setInvalidAction(true);
      hasError = true;
      setCellphoneNumberError(true);
      errorField = "Cell Number";
    }

    if (hasError) {
      setInvalidAction(true);
      const errorText = `Please include "${errorField}" in order to proceed.`;
      setErrorText(errorText);
      return false;
    }
    return true;
  };

  const onSignUp = async () => {
    resetInputError();
    setInvalidAction(false);
    setIsLoading(true);
    setIsModalLoading(true);
    let isValid = validateInputs();
    const formValues = {
      ...userInfo,
      cellNumber: `${userInfo.cellNumberCode}${userInfo.cellNumber}`,
      landlineNumber: `${userInfo.busiLandNumberCode}${userInfo.busiLandNumber}`,
      address: userInfo.address,
    };
    if (!isValid) {
      console.log("Not valid");
      setIsModalLoading(false);
      setIsLoading(false);
      return;
    }
    localStorage.setItem("userInfo", JSON.stringify(formValues));

    try {
      /* Creating chatbot steps + buying phone number */
      let isTextable = await isPhoneNumberTextable(userInfo.busiLandNumber);
      setIsNumberTextable(isTextable);
      let areCredentialsSubmitted = await submissionStep1(formValues);
      let createOrder = await createTwillioOrder();
      localStorage.setItem("UserIdCreated", areCredentialsSubmitted);
      if (
        areCredentialsSubmitted > 0 &&
        areCredentialsSubmitted !== undefined &&
        createOrder !== undefined
      ) {
        localStorage.setItem("accountInfo", formValues);
        toggle();
        // if (isNumberTextable) {
        //   history.push("/enrollment-form/signup-textable");
        // } else {
        //   history.push("/enrollment-form/signup-nontextable");
        // }
      } else {
        throw new Error("Credentials or the order weren't created correctly");
      }
    } catch (ex) {
      toggleErrorModal();
      setIsModalLoading(false);
    }
    setIsLoading(false);
  };

  const createTwillioOrder = async () => {
    // const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    return await axios
      .post(
        serverAPI +
          `/app/sms/hostednumberorder/${userInfo.busiLandNumberCode}${userInfo.busiLandNumber}`
      )
      .then((res) => {
        setEligibilityStatus(res.data);
        localStorage.setItem("userSid", res.data.sid);
        return res.data;
      })
      .catch((error) => {
        // error.response.data contains the custom error message
        if (error.response && error.response.data) {
          setInvalidAction(true);
          setErrorText(error.response.data);
        } else {
          setInvalidAction(true);
          setErrorText(
            "We're sorry, there was an error processing your request"
          );
        }
      });
  };

  const isPhoneNumberTextable = async (busiLandNumber) => {
    return await axios
      .post(serverAPI + "/app/sms/canEnable/" + busiLandNumber)
      .then((res) => {
        return res.data;
      });
  };

  const submissionStep1 = async (formValues) => {
    return await axios
      .post(serverAPI + "/app/sms/submission", formValues)
      .then(async (resp) => {
        return resp.data;
      })
      .catch((error) => {
        // error.response.data contains the custom error message
        if (error.response && error.response.data) {
          setInvalidAction(true);
          setErrorText(error.response.data);
        } else {
          setInvalidAction(true);
          setErrorText(
            "We're sorry, there was an error processing your request"
          );
        }
      });
  };

  const PswdTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  const OnContinue = () => {
    let extraInfo = {
      billingId: userInfo.accountNumber,
      didNumber: userInfo.didNumber
    };
    localStorage.setItem("extraInfo", JSON.stringify(extraInfo));
    history.push({
      pathname: isNumberTextable
        ? "/enrollment-form/signup-textable"
        : "/enrollment-form/signup-nontextable",
    });
  };

  return (
    <div className="answernet-signup">
      <div className="sub-heading text-black mt-20">
        To get started, please provide the below information:
      </div>
      <div className="signUp-form">
        <form>
          {invalidAction ? (
            <Alert
              color="danger"
              className="font-20"
              style={{ margin: "0 5px 15px 5px" }}
            >
              {errorText}
            </Alert>
          ) : null}
          <div className="form-group">
            {/* <InputField
                type={"text"}
                // name="firstName"
                // className="input-control"
                // placeholder={"First name"}
                  value={firstName || ""}
                // onChange={handleChange}
                // onValueChange={handleChange}
              /> */}
            <input
              type="text"
              name="firstName"
              className={`input-control ${
                isFirstNameError ? "input-error" : ""
              }`}
              placeholder="First name"
              value={userInfo.firstName}
              onChange={handleChange}
            />
            <input
              type="text"
              name="lastName"
              className={`input-control ${
                isLastNameError ? "input-error" : ""
              }`}
              placeholder="Last name"
              value={userInfo.lastName}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <input
              type="email"
              name="email"
              className={`input-control ${
                isEmailAddressError ? "input-error" : ""
              }`}
              placeholder="Email"
              value={userInfo.email}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <Input
              className={`input-control input-password ${
                isPasswordError ? "input-error" : ""
              }`}
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              name="password"
              value={userInfo.password}
              placeholder="Password"
              onChange={handleChange}
              disableUnderline={true}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />

            <Input
              className={`input-control input-password ${
                isConfirmPasswordError ? "input-error" : ""
              }`}
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              name="confirmPassword"
              value={userInfo.confirmPassword}
              placeholder="Confirm Password"
              onChange={handleChange}
              disableUnderline={true}
            />
            <div style={{ paddingTop: "10px", marginRight: "-25px" }}>
              <PswdTooltip
                title={
                  <p style={{ fontStyle: "normal", color: "#718096" }}>
                    <em>{"* "}</em>
                    {
                      "Password must include: lowercase, uppercase, number, symbol and special character (symbol)"
                    }{" "}
                  </p>
                }
              >
                <InfoOutlinedIcon
                  sx={{ fontSize: 25 }}
                  // className="text-light-color"
                  color="#718096"
                />
              </PswdTooltip>
            </div>
          </div>
          <div className="form-group">
            <input
              type="text"
              name="companyName"
              className={`input-control ${
                isCompanyNameError ? "input-error" : ""
              }`}
              placeholder="Company Name"
              value={userInfo.companyName}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <input
              type="text"
              name="websiteUrl"
              className={`input-control`}
              placeholder="Website URL"
              value={userInfo.websiteUrl}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <PhoneInput
              specialLabel={""}
              country={"us"}
              onlyCountries={["us", "ca"]}
              defaultCountry="us"
              value={userInfo.busiLandNumberCode}
              onChange={(code) =>
                setUserInfo({
                  ...userInfo,
                  busiLandNumberCode: code,
                })
              }
              className="input-control phone-code getPhoneCode"
            />
            <InputMask
              mask="(999) 999-9999"
              value={userInfo.busiLandNumber}
              disabled={false}
              maskChar=" "
              onChange={handleChange}
            >
              {() => (
                <input
                  type="text"
                  name="busiLandNumber"
                  className={`input-control ${
                    isBusinessLandNumberError ? "input-error" : ""
                  }`}
                  placeholder="Business Landline Number"
                  onKeyPress={() =>
                    "if(isNaN( String.fromCharCode(event.keyCode) )) return false;"
                  }
                  // value={userInfo.busiLandNumber}
                  // onChange={handleChange}
                />
              )}
            </InputMask>
          </div>

          <div className="form-group">
            <PhoneInput
              specialLabel={""}
              country={"us"}
              onlyCountries={["us", "ca"]}
              defaultCountry="us"
              value={userInfo.cellNumberCode}
              onChange={(code) =>
                setUserInfo({
                  ...userInfo,
                  cellNumberCode: code,
                })
              }
              className="input-control phone-code getPhoneCode"
            />
            <InputMask
              mask="(999) 999-9999"
              value={userInfo.cellNumber}
              disabled={false}
              maskChar=" "
              onChange={handleChange}
            >
              {() => (
                <input
                  type="text"
                  name="cellNumber"
                  className={`input-control ${
                    isCellphoneNumberError ? "input-error" : ""
                  }`}
                  placeholder="Mobile Number"
                  onKeyPress={() =>
                    "if(isNaN( String.fromCharCode(event.keyCode) )) return false;"
                  }
                  // value={userInfo.cellNumber}
                  // onChange={handleChange}
                />
              )}
            </InputMask>
            <div style={{ paddingTop: "10px", marginRight: "-25px" }}>
              <PswdTooltip
                title={
                  <p style={{ fontStyle: "normal", color: "#718096" }}>
                    <em>{"* "}</em>
                    {
                      "Your mobile number is required in order to receive SMS messages on your phone"
                    }{" "}
                  </p>
                }
              >
                <InfoOutlinedIcon
                  sx={{ fontSize: 25 }}
                  // className="text-light-color"
                  color="#718096"
                />
              </PswdTooltip>
            </div>
          </div>

          <div className="form-group">
            <input
              type="text"
              name="address"
              className={`input-control ${isAddressError ? "input-error" : ""}`}
              placeholder="Street Address"
              value={userInfo.address}
              onChange={handleChange}
            />
            <input
              type="text"
              name="suite"
              className="input-control suite"
              placeholder="Suite"
              value={userInfo.suite}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <input
              type="text"
              name="city"
              className={`input-control city ${
                isCityError ? "input-error" : ""
              }`}
              placeholder="City"
              value={userInfo.city}
              onChange={handleChange}
            />
            <SelectUSState
              className="input-control states"
              onChange={(stateValue) => {
                setUserInfo({
                  ...userInfo,
                  state: stateValue,
                });
              }}
              placeholder="State"
            />
            <input
              type="text"
              name="zipCode"
              placeholder="Zip Code"
              value={userInfo.zipCode}
              onChange={handleChange}
              className={`input-control zip-code getZipCode ${
                isZipCodeError ? "input-error" : ""
              }`}
            />
          </div>

          <div className="form-group">
            <p className="terms mt-10 mb-10">
              By creating an account you are agreeing to our{" "}
              <a href="#" className="btn-text text-dark-black">
                terms and conditions.
              </a>
            </p>
          </div>
          <div className="form-group justify-content-center">
            {isLoading ? (
              <Loader />
            ) : (
              <input
                type="button"
                value="Sign Up"
                className="btn-submit"
                onClick={async () => {  await onSignUp(); }}
              />
            )}
            {/* {"abc" + isLoading} */}
          </div>
        </form>
        <div className="signin-div">
          Already have an account?{" "}
          <a href="#" className="btn-text">
            Sign In
          </a>
        </div>
      </div>
      {!isLoading && (
        <>
          <ErrorModal
            isErrorModal={isErrorModal}
            toggleErrorModal={toggleErrorModal}
          />
        </>
      )}
      <>
        <Modal
          isOpen={modal}
          toggle={() => toggle()}
          className="pricing-modal signup-page-modal"
        >
          <ModalBody>
            <div className="logo-wrpper">
              <img className="logo" src={AnswerNet_logo} alt="" />
            </div>
            <div className="text-left mt-10 font-16 mb-20 text-bold">
              Please insert the client’s SA Billing ID and Xcally Inbound DID
              Number in the below fields:
            </div>
            <div className="mb-20 text-center mt-10">
              <div>
                <p className="text-bold font-16">SA Billing ID: </p>
              </div>
              <div>
                <input
                  type="text"
                  name="accountNumber"
                  className={`input-control ${
                    isAccountNumberError ? "input-error" : ""
                  }`}
                  placeholder="Account Number"
                  value={userInfo.accountNumber}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="mb-40 text-center text-bold">
              <div>
                <p className="text-bold font-16">Xcally Inbound DID Number:</p>
              </div>
              <div>
                <input
                  type="text"
                  name="didNumber"
                  className={`input-control ${
                    isDidNumberError ? "input-error" : ""
                  }`}
                  placeholder="DID Number"
                  value={userInfo.didNumber}
                  onChange={handleChange}
                />
              </div>
            </div>
            {/* <div className="text-center mt-10 font-20 mb-20 text-bold">
              AnswerNet TextChat has been added your account!
            </div>
            <div className="text-center font-17 light-text text-bold">
              Next, we need to confirm your ownership of the landline. Please
              click button below to continue.
            </div>
            <div className="mt-30 text-center">
              {isModalLoading ? (
                <Loader />
              ) : (
                <Button
                  className="btn-submit"
                  // onClick={() => onConfirmNumber()}
                  onClick={() => OnContinue()}
                >
                  Continue{" "}
                </Button>
              )}
            </div> */}
            <Button className="btn-submit" onClick={() => OnContinue()}>
              Continue{" "}
            </Button>
          </ModalBody>
        </Modal>
      </>
    </div>
  );
};

export default AnwerNetSignUp;
