import { InfoOutlined, InfoRounded } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Modal,
  Slider,
  Stack,
  styled,
  Switch,
  Typography,
  useTheme,
  Button,
  Popover,
  RadioGroup,
  Radio,
  IconButton,
  FormControlLabel
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { Box } from "@mui/system";
import axios from "axios";
import cookie from "js-cookie";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useSnackBarContext } from "src/contexts/snackbar";
import backendUtility from "../General/backendUtility";

const LargeRadioButton = styled(Radio)({
    "& svg": {
        width: "2em",
        height: "2em"
    }
});

const RadioLabel = styled(Typography)({
  fontSize: "14px",
  fontWeight: "500"
});


const AnsweringServiceText = styled(Typography)({
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "14px",
  // lineHeight: "125%",
  letterspacing: "0.25px",
  color: "#808191",
  margin: "0",
  marginY: "8px",
  textTransform: "uppercase",
});

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.secondary.main,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const SERVICE_TYPES = ["SCHEDULED", "DELAY", "ONDEMAND"];

const DELAY_INTERVALS = [
  {
    value: 30
  },
  {
    value: 60
  },
  {
    value: 90
  }
]

const MAPPED_SERVICE_TYPES = [
  {
    title: "Scheduled Answering",
    value: "SCHEDULE",
  },
  {
    title: "Overflow Answering",
    value: "DELAY",
  },
  {
    title: "OnDemand Answering",
    value: "ONDEMAND",
  },
];

const AccountToggle = ({ serverAPI }) => {
  const [isDelegatedAnsweringModalOpen, setIsDelegatedAnsweringModalOpen] =
    useState(false);

  const [widgetDetails, setWidgetDetails] = useState<any>();
  const [showServiceType, setShownServiceType] = useState("SELF");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [transferPreference, setTransferPreference] = React.useState("DEFAULT");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [showTransferType, setShownTransferType] = useState("");
  const [campaignId, setCampaignId] = useState(null);
  const token = cookie.get("token");

  const { openSnackbarContext } = useSnackBarContext();

   const {} = useQuery(
    "FETCH_WIDGET_DETAILS",
    () => axios.get(`${serverAPI}/app/WidgetDetails/Get/?token=${token}`),
    {
      onSuccess: (data) => {
        setWidgetDetails(data.data);
        setCampaignId(data?.data?.campaignID);
        axios.get(`${serverAPI}/app/sms/GetTransferPreference?clientToken=${token}&campaignId=${data?.data?.campaignID}`).then(result => {
          setShownTransferType(result.data.preference);
        })
      },
    }
  );

  const { refetch } = useQuery(
    "FETCH_SERVICE_TYPE",
    () =>
      axios.post(`${serverAPI}/app/sms/GetServiceType`, {
        ClientToken: token,
      }),
    {
      onSuccess: (data) => {
        if (data?.data?.serviceType) {
          setShownServiceType(data?.data?.serviceType);
        }
      },
    }
  ); 

  const upsertServiceType = useMutation(
    (serviceType: string) =>
      axios.post(`${serverAPI}/app/sms/UpsertServiceType`, {
        ClientToken: token,
        ServiceType: serviceType
      }),
    {
      onSuccess: (data, variables) => {
        refetch();
        if (variables === "DELAY") {
          setIsDelegatedAnsweringModalOpen(true);
        }
      },
    }
  );

  const upsertTransferType = useMutation(
    (serviceType: string) =>
      axios.post(`${serverAPI}/app/sms/UpsertTransferPreference`, {
        ClientToken: token,
        Preference: serviceType,
        DelayTime: widgetDetails?.holdTime,
        CampaignId: campaignId
      }),
    {
      onSuccess: (data, variables) => {
        refetch();
        setShownTransferType(variables);
        // if (variables === "DELAY") {
        //   setIsDelegatedAnsweringModalOpen(true);
        // }
      },
    }
  );

  const upsertService = useMutation(
    async (data: any) => {
      let clientToken = await backendUtility.getToken(
        cookie.get("shop"),
        serverAPI
      );
      return await axios.put(
        `${serverAPI}/app/WidgetDetails/Upsert`,
        {
          ...data,
          clientToken,
        }
      );
    },
    {
      onSuccess: (data: any, variables) => {
        openSnackbarContext("Hold Time updated successfully", "success");
        setIsDelegatedAnsweringModalOpen(false);
      },
      onError: () => {
        openSnackbarContext("Something went wrong", "error");
      },
    }
  );

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const theme = useTheme();
  return (
    <React.Fragment>
      {showServiceType !== "SELF" && (
        <Box>
          <Box
            sx={{
              backgroundColor: theme.palette.primary.main,
              border: `2px solid ${theme.palette.secondary.main}`,
            }}
            paddingY={theme.spacing(2)}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              sx={{
                fontSize: "16px",
                lineHeight: "125%",
                fontWeight: 400,
                textTransform: "uppercase",
              }}
              color="white"
            >
              Answering Service: On/Off
            </Typography>
            <IconButton onMouseEnter={handleClick} onMouseLeave={handleClose}>
              <InfoOutlined
                sx={{
                  color: "white",
                  fontSize: "18px",
                  marginLeft: theme.spacing(1),
                }}
              />
            </IconButton>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              elevation={0}
              style={{ pointerEvents: 'none' }}
            >
              <Box
                sx={{
                  width: "350px",
                  paddingX: theme.spacing(1),
                  paddingY: theme.spacing(1),
                  backgroundColor: "#D9D9D9",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "14px",
                  }}
                  textTransform="uppercase"
                >
                  Your answering service control panel allows you to turn on/off
                  the answering service feature you prefer.
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "14px",
                  }}
                  textTransform="uppercase"
                  marginTop={theme.spacing(2)}
                >
                  *for scheduled answering, please remember to set your company
                  schedule on the above schedule tab
                </Typography>
              </Box>
            </Popover>
          </Box>

          <Box marginTop={theme.spacing(1)}>
            <Box
              marginLeft={theme.spacing(0.5)}
              paddingX={theme.spacing(4)}
              marginY={theme.spacing(2)}
            >
              {MAPPED_SERVICE_TYPES.map((serviceType, index) => (
                <Stack
                  direction="row"
                  width="100%"
                  spacing={1}
                  justifyContent="space-between"
                  alignItems="center"
                  marginY={theme.spacing(1)}
                  sx={{
                    borderBottom:
                      index < MAPPED_SERVICE_TYPES.length - 1
                        ? `1px solid ${grey[300]}`
                        : "",
                    paddingY: theme.spacing(0.8),
                  }}
                >
                  <AnsweringServiceText>
                    {serviceType.title}
                  </AnsweringServiceText>
                  <AntSwitch
                    defaultChecked
                    onChange={(event) => {
                      let isDefault = !event.target.checked;
                      let targetTransferPreference = isDefault ? "DEFAULT" : event.target.value as string;
                      setTransferPreference(targetTransferPreference);
                      upsertTransferType.mutate(targetTransferPreference);
                      if (targetTransferPreference === "DELAY") {
                        setIsDelegatedAnsweringModalOpen(true);
                      }
                    }}
                    value={serviceType.value}
                    checked={serviceType.value === showTransferType}
                  />
                </Stack>
              ))}
            </Box>
          </Box>
          <Box
            sx={{
              backgroundColor: "black",
            }}
            marginTop={theme.spacing(2)}
          />
        </Box>
      )}
      <Dialog
        onClose={() => setIsDelegatedAnsweringModalOpen(false)}
        open={isDelegatedAnsweringModalOpen}
        fullWidth
        maxWidth="sm"
      >
        <Box marginX={theme.spacing(1)} marginY={theme.spacing(2)}>
          <DialogTitle
            sx={{
              fontSize: "20px",
            }}
          >
            Chat Settings
          </DialogTitle>
          {widgetDetails && (
            <DialogContent>
              <Box>
                <Box display="flex" alignItems="center">
                  <InfoRounded color="disabled" fontSize="large" />
                  <Typography
                    marginLeft={theme.spacing(0.5)}
                    sx={{
                      fontSize: "18px",
                      fontWeight: "medium",
                    }}
                  >
                    Hold time
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    marginTop: theme.spacing(1),
                    fontSize: "14px",
                  }}
                >
                  Please select your preferred hold time to answer customer text messages prior to transferring to an AnswerNet Agent.
                </Typography>
                <Box marginTop={theme.spacing(5)}>
                  <RadioGroup
                    row
                    aria-labelledby="delay-interval-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    sx={{
                      display: "flex",
                      justifyContent: "space-evenly"
                    }}
                  >
                    {DELAY_INTERVALS.map((delayInterval, index) => (
                        <FormControlLabel
                        value={delayInterval.value}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          fontSize: "14px !important"
                        }}
                        control={<LargeRadioButton checked={widgetDetails?.holdTime === delayInterval.value} />} 
                        onChange={(event: any) => {
                          setWidgetDetails({
                            ...widgetDetails,
                            holdTime: Number(event?.target?.value),
                          });
                        }}
                        label={<RadioLabel>{delayInterval.value} Seconds</RadioLabel>} />
                    ))}
                  </RadioGroup>
                </Box>
                <Box
                  marginTop={theme.spacing(2)}
                  display="flex"
                  justifyContent="flex-end"
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                      marginRight: theme.spacing(2),
                    }}
                    onClick={() => {
                      setIsDelegatedAnsweringModalOpen(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    onClick={() => {
                      upsertTransferType.mutate(transferPreference);
                      upsertService.mutate(widgetDetails);
                      setIsDelegatedAnsweringModalOpen(false);
                    }}
                    color="primary"
                    variant="contained"
                    loading={upsertService.isLoading}
                  >
                    Save
                  </LoadingButton>
                </Box>
              </Box>
            </DialogContent>
          )}
        </Box>
      </Dialog>
    </React.Fragment>
  );
};

export default AccountToggle;
