import React from "react";
let utility = {};

utility.formatMoney = (n, c, d, t) => {
  c = isNaN((c = Math.abs(c))) ? 2 : c;
  d = d === undefined ? "." : d;
  t = t === undefined ? "," : t;
  if (isNaN(n)) {
    return n;
  }
  let s = n < 0 ? "-" : "";
  let i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c))));
  let k = i.length;
  let j = k > 3 ? k % 3 : 0;
  return (
    s +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : "")
  );
};

const fileTypes = ["image/jpeg", "image/png", "image/svg"];

utility.imageAcepted = (file) => {
  return fileTypes.includes(file.type);
};
utility.fileSizeMax = (number) => {
  if (number <= 7340032) {
    return true;
  }
  return false;
};

/**
 *
 * @param {string} type mix of this a, A, 1, a1, A, aA, aA1
 * a = 'abcdefghijklmnopqrstuvwxyz'; a
 * A = 'ABCDEFGHIJKLMNOPQSTUVWXYZ';  A
 * 1 = '0123456789';
 * @param {*} size size of string
 */
const lettersglobl = "abcdefghijklmnopqrstuvwxyz";
const lettersUpper = "ABCDEFGHIJKLMNOPQSTUVWXYZ";
const numbers = "0123456789";

utility.string = (type = "a", size = 10) => {
  var letters = "";

  switch (type) {
    case "a":
      letters = lettersglobl;
      break;

    case "A":
      letters = lettersUpper;
      break;

    case "1":
      letters = this.numbers;
      break;

    case "aA":
      letters = lettersglobl + lettersUpper;
      break;

    case "aA1":
      letters = lettersglobl + lettersUpper + numbers;
      break;

    default:
      letters = lettersglobl;
      break;
  }

  let result = "";
  for (let i = 0; i < size; i++)
    result += letters[Math.floor(Math.random() * letters.length)];

  return result;
};

utility.verifyEmail = (value) => {
  var lowerValue = value.toLowerCase();
  var emailVal =
    /^[a-z][a-zA-Z0-9_]*(\.[a-zA-Z][a-zA-Z0-9_]*)?@[a-z][a-zA-Z-0-9]*\.[a-z]+(\.[a-z]+)?$/;
  var result = lowerValue.match(emailVal);
  return !!result;
};

utility.verifyPhone = (value) => {
  var phoneNumber = value;

  if (phoneNumber.length == 10) {
    phoneNumber = "1" + phoneNumber;
  }

  if (phoneNumber.substr(0, 1) != "+") {
    phoneNumber = "+" + phoneNumber;
  }

  var phoneno = /^\+1(\d{10})$/;
  var result = phoneNumber.match(phoneno);
  return result && result.length > 0;
};

utility.verifyOnlyNumbers = (value) => {
  const re = /^[0-9\b]+$/;

  // if value is not blank, then test the regex
  var result = re.test(value);
  return result;
};

utility.verifyInput = (type, value, onUnverified = () => {}) => {
  if (!type || !value) {
    onUnverified();
    return;
  }
  if (type === "email") {
    if (Array.isArray(value)) {
      let verified = 0;
      value.map((element) => {
        for (let email in element) {
          if (utility.verifyEmail(element[email])) {
            verified++;
          }
        }
      });
      if (verified < value.length) onUnverified(type);
    } else {
      var isVerified = utility.verifyEmail(value);
      if (!isVerified) { 
        onUnverified(type);
        return;
      } else {
        return isVerified;
      }      
    }
  } else if (type === "phone") {
    var isVerified = utility.verifyPhone(value);
    if (!isVerified) onUnverified(type);
  } else if (type === "number") {
    var isVerified = utility.verifyOnlyNumbers(value);
    if (!isVerified) onUnverified(type);
  } else onUnverified();
};

utility.isObject = (a) => {
  return !!a && a.constructor === Object;
};

utility.reduceStringArr = (accumulator, currentValue) =>
  accumulator + "," + currentValue;

utility.objetToHtmlParse = (noFormatHtml) => {
  let outPutHtml;
  switch (typeof noFormatHtml) {
    case "object":
      outPutHtml = [];
      if (Array.isArray(noFormatHtml)) {
        noFormatHtml.forEach((item, key) => {
          if (item.p) {
            outPutHtml.push(
              <p key={key} className={item.class || ""}>
                {item.p}
              </p>
            );
          }
        });
      } else {
        for (const key in noFormatHtml) {
          if (key === "p") {
            outPutHtml.push(<p>{noFormatHtml[key]}</p>);
          } else if (key === "div") {
            outPutHtml.push(<div>{noFormatHtml[key]}</div>);
          }
        }
      }
      break;
    default:
      outPutHtml = noFormatHtml;
      break;
  }
  return outPutHtml;
};

utility.groupArrayBy = (array, key) => {
  return array.reduce((result, obj) => {
    (result[obj[key]] = result[obj[key]] || []).push(obj);
    return result;
  }, {});
};

export default utility;
