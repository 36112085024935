import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import SelectUSState from "react-select-us-states";
import { Alert } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import utility from "../../components/General/utility";
import axios from "axios";
import moment from "moment";
import InputMask from "react-input-mask";
import AnswerNet_logo from "../../images/answerNet_logo_02.png";
import CopyIcon from "../../images/Copy.png";
import { Button, Modal, ModalBody } from "reactstrap";
import Config from "../../config.js";
import Loader from "./Loader";
import ErrorModal from "./ErrorModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";

const serverAPI = Config.apiGateway.URL;
axios.defaults.headers.common["Authorization"] = Config.backendGUID;

const SignUpPricing = (props) => {
  const history = useHistory();
  const [invalidAction, setInvalidAction] = useState(false);
  const [errorText, setErrorText] = useState(false);
  const [name, setName] = useState();
  const [cardNumber, setCardNumber] = useState();
  const [code, setCode] = useState();
  const [expiry, setExpiry] = useState();
  const [term, setTerm] = useState(false);
  const [numberOfUsers, setNumberOfusers] = useState(0);
  const [txtSrvc, setTxtSrvc] = useState(49);
  const [cstMsg, setCstmsg] = useState(0.04);
  const [agntSrvc, setAgntSrvc] = useState(140);
  const [cstConv, setCstConv] = useState(7.0);
  const [totalCst, setTotalCst] = useState(0);
  const [adminDashUrl, setAdminDashUrl] = useState();
  const [address, setAddress] = useState("");
  const [suite, setSuite] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [selectedPlan, setSelectedPlan] = useState("");
  const [isHostedPhoneNumber, setIsHostedPhoneNumber] = useState(false);
  const [selectedPricePerChat, setSelectedPricePerChat] = useState();
  const [additionalUsers, setAdditionalUsers] = useState(0);
  const [isFromTextable, setIsFromTextable] = useState();
  const [pStartDate, setPStartDate] = useState();
  const [pEndDate, setPEndDate] = useState();
  const [partialMonthCost, setPartialMonthCost] = useState(0);
  const [modal, setModal] = useState(false);
  const [copyModal, setCopyModal] = useState(false);
  const [paymentResponse, setPaymentResponse] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [isNewNumberSelected, setIsNewNumberSelected] = useState(false);
  const [answernetAPIResponse, setAnswernetAPIResponse] = useState({});
  const [isErrorModal, setErrorModal] = useState(false);
  const [clientToken, setClientToken] = useState();

  const today = moment();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  useEffect(() => {
    if (userInfo) {
      setAddress(userInfo.address);
      setSuite(userInfo.suite);
      setCity(userInfo.city);
      setState(userInfo.state);
      const stateElement = document.querySelector(".state-input");
      if (stateElement) {
        stateElement.value = userInfo.state;
        stateElement.dispatchEvent(new Event("change"));
      }
      setZipCode(userInfo.zipCode);
    }
    if (
      history.location.state &&
      history.location.state.from == "signup-textable"
    ) {
      setIsFromTextable(true);
    } else {
      setIsFromTextable(false);
    }
    setPStartDate(today.format("MM/DD/YYYY"));
    setPEndDate(today.endOf("month").format("MM/DD/YYYY"));
  }, []);

  useEffect(() => {
    var diff = moment(pEndDate).diff(moment(pStartDate), "days");
    var partialMonth = (totalCst / today.daysInMonth()) * diff;
    // console.log("First Payment: ", totalCst, today.daysInMonth(), diff);
    setPartialMonthCost((Math.round(partialMonth * 100) / 100).toFixed(2));
  }, [pStartDate, pEndDate, totalCst]);

  // useEffect(() => {
  //   if (adminDashUrl) {
  //     history.push({
  //       pathname: isHostedPhoneNumber
  //         ? "/enrollment-form/signup-textable"
  //         : "/enrollment-form/complete",
  //       state: { adminDashUrl },
  //     });
  //   }
  // }, [adminDashUrl]);

  useEffect(() => {
    if (history.location.state) {
      setAgntSrvc(
        parseInt(history.location.state.valuesToPass.selectedPackage)
      );
      setSelectedPricePerChat(
        parseInt(history.location.state.valuesToPass.selectedPerChat)
      );
      setSelectedPlan(history.location.state.valuesToPass.selectedPlan);
    } else setAgntSrvc(0);

    console.log("isNewNumberSelected: ", history.location.state);
    if (history.location.state.isNewNumberSelected) {
      setIsNewNumberSelected(true);
    }

    setName("");
    setCardNumber();
    setCode();
    setExpiry("");
    setInvalidAction(false);
    setErrorText(false);
    setTotalCst(txtSrvc + agntSrvc);
    // setSelectedPricePerChat(parseInt(history.location.state.selectedPerChat));
    setIsHostedPhoneNumber(
      localStorage.getItem("isHostedPhoneNumber") == "true"
    );

    const rightSideWrapper = document.querySelector(
      ".rightside .inner-wrapper"
    );
    rightSideWrapper.style.display = "flex";
    rightSideWrapper.style.flexDirection = "column";
    rightSideWrapper.style.alignItems = "center";
    rightSideWrapper.style.justifyContent = "center";
  }, []);

  useEffect(() => {
    if (isHostedPhoneNumber) {
      localStorage.setItem("final-step", true);
    }
  }, [isHostedPhoneNumber]);

  useEffect(() => {
    setTotalCst(txtSrvc + agntSrvc);
  }, [agntSrvc]);

  const handleChangeTerm = (e) => {
    setTerm(e.target.checked);
  };

  const handleUpdateAdditionalUsers = (mode) => {
    if (mode === "minus" && additionalUsers > 0) {
      setAdditionalUsers(additionalUsers - 1);
    } else if (mode === "plus") {
      setAdditionalUsers(additionalUsers + 1);
    }
  };

  useEffect(() => {
    if (additionalUsers > 0) {
      setTotalCst(txtSrvc + additionalUsers * 15 + agntSrvc);
    } else {
      setTotalCst(txtSrvc + agntSrvc);
    }
  }, [additionalUsers]);

  const validateInputs = () => {
    setInvalidAction(false);
    setErrorText(false);

    utility.verifyInput("number", cardNumber, () => {
      setInvalidAction(true);
      setErrorText("Card Number should only include numbers.");
      return false;
    });

    // validate all other fields
    if (!name || !cardNumber || !expiry) {
      setInvalidAction(true);
      setErrorText("All fields are required.");
      return false;
    }
    return true;
  };

  const buyPhoneNumber = async (submissionId, clientToken) => {
    return axios.post(props.serverAPI + "/app/sms/buyphonenumber", {
      submissionId,
      clientToken,
    });
  };

  const saveHostedNumber = async (submissionId, clientToken) => {
    return axios.post(props.serverAPI + "/app/sms/saveHostedNumber", {
      submissionId,
      clientToken,
    });
  };

  const submitExtraInfo = async (payload) => {
    return axios.post(props.serverAPI + "/app/signup/upsertExtraSignUpInfo", {
      "clientToken": payload.clientToken,
      "billingId": payload.billingId,
      "inboundNumber": payload.didNumber
    });
  }

  const submitSignUpInfo = async (payload) => {
    return axios.post(props.serverAPI + "/app/signup/upsertAnswerNetSignUpInfo", {
      "accountName": payload.accountName, 
	    "clientToken": payload.clientToken, 
	    "adminEmail": payload.adminEmail,
	    "companyAddress": payload.companyAddress,
	    "servicePackageID": payload.servicePackageID,
	    "totalCost": payload.totalCost
    });
  }

  const createNewAccount = async (newAccount) => {
    const {
      name,
      email,
      password,
      phoneNumber,
      clientName,
      websiteUrl,
      transferPreference,
      serviceType,
    } = newAccount;

    return axios.post(props.serverAPI + "/app/signup/submit", {
      name,
      email,
      password,
      phoneNumber,
      clientName,
      websiteUrl,
      transferPreference,
      serviceType,
      isCreateAnswerNetAgent: true,
      isSendSignUpEmail: false,
    });
  };

  const sendSignUpEmail = async (clientToken) => {
    return axios.post(props.serverAPI + "/app/signup/sendSignUpEmail", {
      clientToken,
    });
  };

  const processPayment = async () => {
    // let paymentBody = { cardNumber, expirationDate: expiry, cardCode: code };
    setIsLoading(true);
    let tempExpiry = moment(expiry, "MM/YYYY");
    let paymentBody = {
      cardNumber,
      expirationDate: expiry ? tempExpiry.format("YYYY-MM") : "",
      cardCode: code,
      // amount: partialMonthCost,
      // for testing purpose
      amount: 1.0,
      email: userInfo.email,
      // company: userInfo.companyName,
      // address,
      // city,
      // state,
      // zip: zipCode,
      // country: "USA",
      // firstName: firstName,
      // lastName: lastName,
      billTo: {
        firstName: name,
        // lastName: name,
        company: userInfo.companyName,
        address,
        city,
        state,
        zip: zipCode,
        country: "USA",
      },
      shipTo: {
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        company: userInfo.companyName,
        address: userInfo.address,
        city: userInfo.city,
        state: userInfo.state,
        zip: userInfo.zipCode,
        country: "USA",
      },
    };
    console.log("paymentBody: ", paymentBody);
    return await axios
      .post(serverAPI + `/app/sms/payment`, paymentBody)
      .then((res) => {
        console.log("---> ", res);
        setPaymentResponse(res.data);
        // localStorage.setItem("userSid", res.data.sid);
        setIsLoading(false);
        return res.data;
      })
      .catch((error) => {
        // error.response.data contains the custom error message
        if (error.response && error.response.data) {
          setInvalidAction(true);
          setErrorText(error.response.data);
        } else {
          setModal(false);
          setInvalidAction(true);
          setErrorText(
            "We're sorry, there was an error processing your request"
          );
        }
        setIsLoading(false);
      });
  };

  const saveCustomerTransactionDB = async (clientToken) => {
    let saveToDB = {
      clientToken,
      CustomerProfileId:
        paymentResponse.responseProfileCreate.customerProfileId,
      PaymentProfileId: paymentResponse.responseProfileCreate.paymentProfileId
        ? paymentResponse.responseProfileCreate.paymentProfileId
            .numericString[0]
        : null,
      TransactionId: paymentResponse.responseTransaction.transactionId,
      // TransactionStatus: paymentResponse.responseTransaction.description,
      TransactionStatus: 1,
    };
    console.log("paymentBody: ", saveToDB);
    return axios.post(
      props.serverAPI + "/app/sms/SaveCustomerTransaction",
      saveToDB
    );
  };

  const OnContinue = (modalType) => {
    console.log("copyModal: ", copyModal);
    if (modalType == "confirmation") {
      toggle();
      setCopyModal(!copyModal);
    }
    if (modalType == "copyModal") {
      history.push({
        pathname: isHostedPhoneNumber
          ? "/enrollment-form/signup-textable"
          : "/enrollment-form/complete",
        state: { adminDashUrl },
      });
    }
  };

  const onConfirmNumber = async () => {
    setIsModalLoading(true);
    let isValid = validateInputs(),
      userId = JSON.parse(localStorage.getItem("UserIdCreated")),
      extraInfo = JSON.parse(localStorage.getItem("extraInfo")),
      transferPreference = "DEFAULT",
      serviceType = "LIVE";

    // if (!isValid) {
    //   setIsModalLoading(false);
    //   toggleErrorModal();
    //   return;
    // }

    try {
      // if (userInfo) userInfo = JSON.parse(userInfo);
      let accountCreationResult = await createNewAccount({
        name: `${userInfo.firstName} ${userInfo.lastName}`,
        email: userInfo.email,
        password: userInfo.password,
        phoneNumber: userInfo.cellNumber,
        clientName: userInfo.companyName,
        websiteUrl: userInfo.websiteUrl,
        transferPreference,
        serviceType,
      }).then((resp) => {
        return resp.data;
      });

      setClientToken(accountCreationResult.clientToken);

      console.log("setPaymentResponse: ", paymentResponse);
      // for testing purpose
      // await saveCustomerTransactionDB("D12187D5-B565-4543-87E5-4DBC6BB8633B");
      // await saveCustomerTransactionDB(accountCreationResult.clientToken);

      if (!isHostedPhoneNumber) {
        await buyPhoneNumber(userId, accountCreationResult.clientToken);
      } else {
        await saveHostedNumber(userId, accountCreationResult.clientToken);
      }

      await submitSignUpInfo({
        "accountName": userInfo.companyName, 
	      "clientToken": accountCreationResult.clientToken, 
	      "adminEmail": userInfo.email,
	      "companyAddress": userInfo.address,
	      "servicePackageID": agntSrvc === 50 ? 1 : 2,
	      "totalCost": totalCst
      })

      if(extraInfo) await submitExtraInfo({
        clientToken: accountCreationResult.clientToken,
        billingId: extraInfo.billingId,
        didNumber: extraInfo.didNumber
      })

      if (accountCreationResult.adminDash) setAdminDashUrl(accountCreationResult.adminDash);

      // if (accountCreationResult.clientToken)
      // sendAnswernetDetails(accountCreationResult.clientToken);
      // sendAnswernetDetails("098E588E-9C74-4B4F-AD18-988C1B2BED79");

      await sendSignUpEmail(accountCreationResult.clientToken);

      return;
      // history.push({
      //   pathname: isHostedPhoneNumber ? "/enrollment-form/account-setup" : "/enrollment-form/complete",
      // });
    } catch (ex) {
      throw ex;
      // setInvalidAction(true);
      // setErrorText("Something went wrong when submitting your data");
      // setIsModalLoading(false);
      // toggleErrorModal();
      // return;
    }
  };

  const toggleErrorModal = () => {
    setErrorModal(!isErrorModal);
  };

  const toggle = () => {
    setModal(!modal);
  };

  const toggleCopyModal = () => {
    setModal(false);
    setCopyModal(!copyModal);
  };

  const sendAnswernetDetails = async (clientToken) => {
    let tempExpiry = moment(expiry, "YYYYMMddhhmmss");
    console.log("TempExipry: ", tempExpiry);
    let testData = {
      token: "098E588E-9C74-4B4F-AD18-988C1B2BED79",
      Invoice: "TextChat20220823215800",
      cardType: "Visa",
      cardNum: "XXXX1234",
      cardExp: "0823",
      cardHolder: "John Doe",
      transAmount: "25.00",
      status: "Approved",
      transCode: "DEF123",
      authCode: "12345B",
    };
    let requestBody = {
      token: clientToken,
      Invoice: "TextChat" + moment().format("YYYYMMDDHHms"),
      cardType: paymentResponse
        ? paymentResponse.responseTransaction.cardType
        : "",
      cardNum: paymentResponse
        ? paymentResponse.responseTransaction.cardNum
        : "",
      cardExp: expiry ? tempExpiry.format("mmYY") : "",
      cardHolder: name,
      transAmount: partialMonthCost,
      status:
        paymentResponse.responseTransaction.responseCode === "1"
          ? "Approved"
          : "",
      transCode: paymentResponse.responseTransaction.transactionId,
      authCode: paymentResponse.responseTransaction.authCode,
    };
    console.log("calleddd.....", paymentResponse, requestBody);
    return axios
      .post(
        props.serverAPI + "/app/sms/sendDetailsToAnswernet",
        // testData
        requestBody
      )
      .then((res) => {
        console.log("RES---> ", res);
        setAnswernetAPIResponse(res.Result);
        return res.data;
      })
      .catch((error) => {
        // error.response.data contains the custom error message
        console.log("error: ", error);
        if (error.response && error.response.data) {
          setIsModalLoading(false);
          setErrorText(error.response.data);
        } else {
          setModal(false);
          setIsModalLoading(false);
          setInvalidAction(true);
          setErrorText(
            "We're sorry, there was an error processing your request"
          );
        }
        setIsLoading(false);
      });
  };

  // const onChangeCredit = (e) => {
  //   setCardNumber(e.target.value);
  //   cardNumber;
  //   setTimeout(() => setCardNumberMasked(temp), 3000);
  // };

  return (
    <div className="pricing-wrapper">
      <div className="box-bdr md-30 mt-20 mb-30">
        <div className="top-heading text-black font-32">
          Awesome! You’re almost set!
        </div>
        <p className="pricing-info-header">
          Here is your monthly pricing info:
        </p>
        <div className="div-table">
          <table className="table">
            <tbody>
              <tr>
                <td className="font-16">
                  SMS Text Service (includes 2 users, 1,000 texts)
                </td>
                <td className="price">${txtSrvc}.00</td>
              </tr>
              <tr>
                <td>
                  <div className="additional-users">
                    <div className="additional-users-text">
                      Additional users ($15.00 each)
                    </div>
                    <div className="additional-users-actions">
                      <button
                        onClick={() => handleUpdateAdditionalUsers("minus")}
                      >
                        <div className="button-bar" />
                      </button>
                      <input readOnly type="text" value={additionalUsers} />
                      <button
                        onClick={() => handleUpdateAdditionalUsers("plus")}
                      >
                        +
                      </button>
                    </div>
                  </div>
                </td>
                <td className="price">${15 * additionalUsers}</td>
              </tr>
              <tr>
                <td className="font-16">
                  Live Agent Service:
                  {agntSrvc == 140 ? " Enhanced " : " Basic "}
                  Pack ({agntSrvc == 140 ? "20 " : "15 "} conversation limit)
                </td>
                <td className="price">${agntSrvc}</td>
              </tr>
              <tr>
                <td colSpan="2">
                  <div className="divider"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Total Cost: </strong>
                </td>
                <td className="price total">
                  ${Math.round(totalCst * 100) / 100}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>
                    First Payment: {pStartDate} - {pEndDate}{" "}
                  </strong>
                </td>
                <td className="price total">${partialMonthCost}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="pricing-bottom-info">
          *Your first payment will be pro-rata based on when signing up, then
          you will will be billed monthly on the 1st of the month
          <br />
          **Texts that go above the limit of 1,000 are an addition $.04/text
          <br />
          ***Cost for additional live agent conversations above the limit will
          be ${selectedPricePerChat}/conversation
        </div>
      </div>
      {/* <div className="payment-information-wrapper">
        <div className="form-group privete-policy-wrap">
          <div className="policy-text">
            By checking here, I consent that any data I provide can be accessed,
            per the
            <a href="#" className="text-dark">
              Privacy Policy
            </a>
          </div>
          <label className="container starter">
            <input
              type="radio"
              checked={term}
              name="starter"
              onChange={(e) => handleChangeTerm(e)}
            />
            <span className="checkmark"></span>
          </label>
        </div>
      </div> */}
      {/* <div className="top-desc text-black mt-20 font-24">
        Billing Information:
      </div> */}
      {/* {invalidAction ? <Alert color="danger">{errorText}</Alert> : null} */}
      {/* <div className="payment-information-wrapper">
        <div className="form-group">
          <input
            type="text"
            className="input-control"
            placeholder="Cardholder Name"
            value={name}
            maxLength={25}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="form-group">
          <InputMask
            mask="9999 9999 9999 9999"
            disabled={false}
            maskChar=" "
            value={cardNumber}
            onChange={(e) => {
              setCardNumber(e.target.value);
            }}
            onBlur={() => {
              let tempcardNumber = cardNumber.replace(/\s+/g, "");
              setCardNumber(tempcardNumber);
            }}
          >
            {() => (
              <input
                type="text"
                className="input-control"
                style={{ marginRight: "6px" }}
                placeholder="Card Number"
                // maxLength={16}
                // value={cardNumber}
                // onChange={(e) => setCardNumber(e.target.value)}
              />
            )}
          </InputMask>
          <InputMask
            mask="99/9999"
            disabled={false}
            maskChar=" "
            value={expiry}
            onChange={(date) => setExpiry(date)}
          >
            {() => ( 
          <InputMask
            mask="99/9999"
            disabled={false}
            maskChar=" "
            value={expiry}
            onChange={(e) => setExpiry(e.target.value)}
          >
            {() => (
              <input
                type="text"
                className="input-control"
                style={{ width: "108.33px" }}
                placeholder="MM/YYYY"
                // maxLength={16}
                // value={cardNumber}
                // onChange={(e) => setCardNumber(e.target.value)}
              />
            )}
          </InputMask>
           <DatePicker
            className="input-control"
            dateFormat="MM/yyyy"
            selected={expiry}
            onChange={(date) => setExpiry(date)}
            placeholderText="MM/YYYY"
          /> 
          )}
          </InputMask> 
        </div>

        <div className="form-group">
          <input
            type="text"
            name="address"
            className="input-control"
            placeholder="Street Address"
            value={address}
            style={{ marginRight: "6px " }}
            onChange={(e) => setAddress(e.target.value)}
          />
          <input
            type="text"
            name="suite"
            className="input-control suite"
            placeholder="Suite"
            value={suite}
            onChange={(e) => setSuite(e.target.value)}
          />
        </div>

        <div className="form-group">
          <input
            type="text"
            name="city"
            className="input-control city"
            placeholder="City"
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
          <SelectUSState
            className="input-control state-input"
            onChange={(stateValue) => {
              setState(stateValue);
            }}
            value={state ? state : ""}
            selected={state}
            style={{ marginRight: "6px" }}
          />
          <input
            type="text"
            name="zipCode"
            placeholder="Zip Code"
            value={zipCode}
            onChange={(e) => setZipCode(e.target.value)}
            className="input-control zip-code getZipCode"
          />
        </div>
        <div className="form-group privete-policy-wrap">
          {/* <input type="text" />
          <div className="policy-text">
            By checking here, I consent that any data I provide can be accessed,
            per the{" "}
            <Link to="/enrollment-form/privacy-policy" className="btn-text">
              Privacy Policy
            </Link>
          </div>
          <label className="container starter">
            <input
              type="radio"
              checked={term}
              name="starter"
              onChange={(e) => handleChangeTerm(e)}
            />
            <span className="checkmark"></span>
          </label>
        </div>
      </div> */}
      <div className="form-group mb-20 submit-btn-wrapper justify-content-center">
        {isLoading ? (
          <Loader />
        ) : (
          <input
            type="button"
            value="Add to Your Account"
            style={{ width: "60%", height: "48px" }}
            className="btn-submit"
            // without modal implementation
            // onClick={onConfirmNumber}
            onClick={async () => {
              // processPayment();
              setIsLoading(true);
              await onConfirmNumber()
                .then(() => {
                  setIsLoading(false);
                  setIsModalLoading(false);
                  toggle();
                })
                .catch((ex) => {
                  setIsLoading(false);
                  setInvalidAction(true);
                  setErrorText(
                    "Something went wrong when submitting your data"
                  );
                  setIsModalLoading(false);
                  toggleErrorModal();
                });
            }}
          />
        )}
      </div>
      {!isLoading && (
        <>
          <ErrorModal
            isErrorModal={isErrorModal}
            toggleErrorModal={toggleErrorModal}
          />
        </>
      )}
      {!isLoading && (
        <>
          <Modal
            isOpen={modal}
            toggle={() => toggle()}
            className="pricing-modal"
          >
            <ModalBody>
              <div className="logo-wrpper">
                <img className="logo" src={AnswerNet_logo} alt="" />
              </div>
              <div className="text-center mt-10 font-20 mb-20 text-bold">
                AnswerNet TextChat has been added your account!
              </div>
              <div className="text-center font-17 light-text text-bold">
                {isHostedPhoneNumber
                  ? " Next, we need to confirm your ownership of the landline. Please click button below to continue. "
                  : " Click next to go to your admin dashboard. "}
                {/* Next, we need to confirm your ownership of the landline. Please
                click button below to continue. */}
              </div>
              {/* <div className="mt-20">
                <div className="div-table bdr-both">
                  <table className="table ">
                    <tbody>
                      <tr>
                        <td className="light-text">Transaction ID: </td>
                        <td className="text-right fw-600">
                          {paymentResponse
                            ? paymentResponse.responseTransaction.transactionId
                            : "some error occurred"}
                        </td>
                      </tr>
                      <tr>
                        <td className="light-text">Card has been charged</td>
                        <td className="text-right fw-600">
                          ${partialMonthCost}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div> */}
              {/* <div className="mt-40 text-dark text-center font-16 text-bold">
                {isNewNumberSelected
                  ? "You're all set! Please click continue to finish sign-up!"
                  : "Now we’ll need to call you on your landline to confirm ownership of your number. Please click continue below to verify:"}
              </div> */}
              <div className="mt-30 text-center">
                {isModalLoading ? (
                  <Loader />
                ) : (
                  <Button
                    className="btn-submit"
                    // onClick={() => onConfirmNumber()}
                    onClick={() => OnContinue("confirmation")}
                  >
                    Continue{" "}
                  </Button>
                )}
              </div>
            </ModalBody>
          </Modal>

          <Modal
            isOpen={copyModal}
            toggle={() => toggleCopyModal()}
            className="pricing-modal"
          >
            <ModalBody>
              <div className="logo-wrpper">
                <img className="logo" src={AnswerNet_logo} alt="" />
              </div>
              <div className="text-center mt-10 font-20 mb-20 text-bold">
                Here is your Account ID:
              </div>
              <div className="text-center font-17 light-text text-bold">
                {clientToken !== undefined && clientToken !== null
                  ? clientToken
                  : "Some error occured"}
                <Button
                  className="no-bg border-0"
                  onClick={() => {
                    navigator.clipboard.writeText(clientToken);
                  }}
                >
                  <img src={CopyIcon} />
                  {/* <FontAwesomeIcon icon={faClipboard} /> */}
                </Button>
              </div>
              <div className="mt-30 text-center">
                <Button
                  className="btn-submit d-inline-block"
                  onClick={() => OnContinue("copyModal")}
                >
                  Continue{" "}
                </Button>
              </div>
            </ModalBody>
          </Modal>
        </>
      )}
    </div>
  );
};

export default SignUpPricing;
