import React, { useState } from "react";
import moment from "moment";
import DataTable from "../General/DataTable/DataTable";

const headers = [
  { label: "Author", key: "author" },
  { label: "Message", key: "messageRaw" },
  { label: "Time sent", key: "dateSent" },
];

const columns = [
  {
    field: "author",
    headerName: "Author",
    width: 200,
    sortable: true,
  },
  {
    field: "messageRaw",
    headerName: "Message",
    width: 697,
    sortable: true,
  },
  {
    field: "dateSent",
    headerName: "Time sent",
    width: 200,
    sortable: true,

    // render: (data, type, row) => {
    //   if (type === "display" || type === "filter")
    //     return moment(data).format("h:mm a");
    //   return moment(data).toISOString();
  },
];

export default function MessageTable(props) {
  let [tableTitle, setTitle] = useState("Messages");

  let updateFunc = (nextProps, thisTable) => {
    if (nextProps.data && nextProps.data[0] && props.setLoaded) {
      props.setLoaded(true);
    }
    if (nextProps.data && nextProps.data[0] && nextProps.data[0]["chatDate"]) {
      let newTitle =
        "Messages" +
        " on " +
        moment(nextProps.data[0]["chatDate"]).format("MM-DD-YYYY");

      setTitle(newTitle);
    }
  };

  return (
    <DataTable
      {...props}
      isClickable={false}
      columns={columns}
      content={tableTitle}
      headers={headers}
      isForDetail
    />
  );
}
