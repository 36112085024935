import { alpha, InputBase, styled } from "@mui/material";
import React from "react";

export const NormalInput = styled(InputBase)(({ theme, error, size }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#fcfcfb",
    border: `1px solid ${error ? "red" : "#EBEAED"}`,
    fontSize:
      size === "small"
        ? theme.typography.h5.fontSize
        : theme.typography.fontSize * 1.2,
    width: "100%",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:filled": {
      backgroundColor: "yellow",
      background: "none",
    },
    "&:focus": {
      boxShadow: `${alpha(
        error ? theme.palette.error.main : theme.palette.primary.main,
        0.25
      )} 0 0 0 0.2rem`,
      borderColor: error
        ? theme.palette.error.main
        : theme.palette.primary.main,
    },
  },
}));
