import React from 'react';
import {
    Button,
    TextContainer
} from "@shopify/polaris";
import ReactHtmlParser from 'react-html-parser';

import introData from './introData';
import './introStyle.css';

function Intro({ paragraphs, title, buttonTitle, onNext, video}) {

    function renderParagraphs(paragraphs) {
        return (
            paragraphs.map(paragraph => (<p>{ReactHtmlParser(paragraph)}</p>))
        );
    }
    return(
        <div className="intro">

            <h1 className="Polaris-DisplayText Polaris-DisplayText--sizeLarge">{title}</h1>
            {/*video && <video className='video-intro-data' src={video.url} poster={video.poster} controls></video>*/}
            <TextContainer>
                {renderParagraphs(paragraphs)}
            </TextContainer>
            <Button primary size="large" onClick={onNext}>
                {buttonTitle}
            </Button>
        </div>
    )
}

export default function IntroWrapped({onNext}) {
    return <Intro
                paragraphs={introData.paragraphs}
                title={introData.title}
                buttonTitle={introData.buttonTitle}
                video={introData.video}
                onNext={onNext}
                />
}
