import React from "react";
import "./style.css";
import { Icon, Checkbox } from "@shopify/polaris";
import { CircleInformationMajor } from "@shopify/polaris-icons";

function TextFieldInput(props) {
  let {
    title = "",
    value = "",
    isChecked = false,
    onCheckHandleChange,
    showCheckbox = false,
    noTitle = false,
    onValueChange,
    name,
    placeholder = "",
    isDisabled = false,
    render = null,
    ...restProps
  } = props;

  return (
    <div {...restProps}>
      <div className="chatHeaderBlock">
        <Icon source={CircleInformationMajor} />
        {!noTitle && <span className="chatHeaderInfo">{title}</span>}
        {showCheckbox && (
          <Checkbox checked={isChecked} onChange={onCheckHandleChange} />
        )}
      </div>
      <div className="inputTextBox-tc">
        {render ? (
          render
        ) : (
          <textarea
            placeholder={placeholder}
            onChange={(e) =>
              onValueChange && onValueChange(name, e.target.value)
            }
            value={showCheckbox && !isChecked ? "" : value}
            disabled={isDisabled || (showCheckbox && !isChecked)}
          ></textarea>
        )}
      </div>
    </div>
  );
}

export default TextFieldInput;
