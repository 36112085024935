import React, { Component } from "react";
import ExtraInformation from "../../Form/MeetCustomer/ExtraInformation";
import SearchParams from "../../../General/SearchParams";
import { contactInfo, contactInfoDescription } from "./contactInfo";
import { featureSelect, featureSelectDescription } from "./featureSelect";
import { chatFlow } from "./chatFlow";
import { billingPlan, billingPlanDescription } from "./billingPlan";
import "./Settings.css";

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      stepInfo: {},
      loading: true,
      inputsToPass: [],
      title: "TextChat",
    };
  }
  componentDidMount() {
    if (this.props.pathname) {
      const pathname = this.props.pathname;
      const info = this.props.getInfoBusiness;

      var allAppFields = {};
      for (var i = 0; i < 5; i++) {
        var stepInfo = info["step" + String(i)];
        if (stepInfo) allAppFields = { ...allAppFields, ...stepInfo };
      }

      let settingsData;
      let description = [];
      let title;
      let subTitle;
      if (pathname.includes("contactinfo")) {
        settingsData = contactInfo;
        description = contactInfoDescription;
        title = "User Settings";
        subTitle = "Lorem ipsum dolor sit amet, consectetur adipiscing elit";
      } else if (pathname.includes("featureselect")) {
        settingsData = featureSelect;
        description = featureSelectDescription;
        title = "Feature Selection";
      } else if (pathname.includes("chatflow")) {
        settingsData = chatFlow;
        // description = chatFlowDescription
        title = "Customize Chatbot Messaging";
      } else if (pathname.includes("billingplan")) {
        settingsData = billingPlan;
        description = billingPlanDescription;
        title = "Plan Details";
      }

      this.setState({
        pathname: pathname,
        info: allAppFields,
        loading: false,
        inputsToPass: settingsData,
        description: description,
        title: title,
        subTitle: subTitle,
      });
    }
  }

  render() {
    if (this.state.loading && !this.state.step) return null;
    return (
      <div className="settings-forms user-settings">
        <ExtraInformation
          serverAPI={this.props.serverAPI}
          setIsDirty={this.props.setIsDirty}
          setCriticalMessage={this.props.setCriticalMessage}
          discart={this.props.discart}
          shouldSave={this.props.shouldSave}
          inputs={this.state.inputsToPass}
          info={this.state.info}
          changesDiscarded={this.props.changesDiscarded}
          setError={this.props.setError}
          title={this.state.title}
          subTitle={this.state.subTitle}
          descriptions={this.state.description}
          confirmSaved={this.props.confirmSaved}
          pendingExists={this.props.pendingExists}
        />
      </div>
    );
  }
}

export default Settings;
