import {
  Box,
  Paper,
  useTheme,
  styled,
  Typography,
  FormControl,
  InputLabel,
  Button,
} from "@mui/material";
import * as yup from "yup";
import answernetLogo from "../../../assets/img/answernet-logo.png";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { BootstrapInput } from "../../Input/BootstrapInput";
import { useMutation } from "react-query";
import axios from "axios";
import { useSnackBarContext } from "src/contexts/snackbar";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";

const StyledImage = styled("img")({});

const StyledInputLabel = styled(InputLabel)({
  fontSize: "18px",
});

const ResetPassword = (props) => {
  const { clientToken, resetToken } = props.match.params;
  let isForgotPassRoute = !Boolean(clientToken && resetToken);
  const { control, formState, reset, handleSubmit } = useForm({
    mode: "all",
    resolver: yupResolver(
      yup.object({
        email: yup
          .string()
          .email("Enter a valid email")
          .required("Email is required"),
      })
    ),
  });

  const { openSnackbarContext } = useSnackBarContext();

  const forgotPasscodeMutation = useMutation(
    (email) =>
      axios.post(props.serverAPI + "/app/forgot-password", {
        email,
        appUrl: window.location.origin,
      }),
    {
      onSuccess: () => {
        openSnackbarContext(
          "Password reset link has been sent to your email",
          "success"
        );
        reset();
      },
      onError: () => {
        openSnackbarContext(
          "We're sorry, there was an error processing your request",
          "error"
        );
      },
    }
  );

  const handleSubmitEmail = (data) => {
    forgotPasscodeMutation.mutate(data.email);
  };

  const theme: any = useTheme();
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.darker,
      }}
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box>
        <Paper
          elevation={0}
          sx={{
            width: "500px",
            padding: theme.spacing(3),
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            paddingY={theme.spacing(2)}
            flexDirection="column"
            alignItems="center"
          >
            <Box marginBottom={theme.spacing(3)}>
              <StyledImage sx={{ height: "100px"}}src={answernetLogo} />
            </Box>
            <Typography variant="h4" fontWeight="bold">
              {isForgotPassRoute ? "Forgot Password" : "Set New Password"}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column">
            {isForgotPassRoute ? (
              <form onSubmit={handleSubmit(handleSubmitEmail)}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl fullWidth variant="standard">
                      <StyledInputLabel shrink htmlFor="email">
                        Email
                      </StyledInputLabel>
                      <BootstrapInput
                        {...field}
                        {...fieldState}
                        error={!!formState.errors.email}
                        fullWidth
                        placeholder="Enter email"
                        id="email"
                      />
                      {formState.errors.email && (
                        <Typography
                          color="error"
                          fontSize="12px"
                          marginTop="4px"
                        >
                          {(formState as any).errors.email.message}
                        </Typography>
                      )}
                    </FormControl>
                  )}
                />
                <LoadingButton
                  sx={{
                    marginTop: theme.spacing(4),
                  }}
                  type="submit"
                  fullWidth
                  loading={forgotPasscodeMutation.isLoading}
                  variant="contained"
                  disabled={!formState.isValid}
                >
                  Request Forgot password email
                </LoadingButton>
              </form>
            ) : (
              <SetNewPassword {...props} />
            )}
            <Button
              sx={{
                marginTop: theme.spacing(2),
              }}
              fullWidth
              onClick={() => props.history.push("/login")}
            >
              back to login
            </Button>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

const SetNewPassword = (props) => {
  const { openSnackbarContext } = useSnackBarContext();

  const { control, formState, reset, handleSubmit } = useForm({
    mode: "all",
    resolver: yupResolver(
      yup.object({
        password: yup
          .string()
          .required("Password is required")
          .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/, "Password should be minimum 8 characters, at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 symbol"),
        // confirm password be same as password
        confirmPassword: yup
          .string()
          .required("Confirm password is required")
          .oneOf([yup.ref("password"), null], "Passwords must match")
          .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/, "Password should be minimum 8 characters, at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 symbol")
      })
    ),
  });

  const resetPasswordMutation = useMutation(
    (data: any) => axios.post(props.serverAPI + "/app/reset-password", data),
    {
      onSuccess: () => {
        openSnackbarContext("Password has been reset", "success");
        props.history.push({
          pathname: "/login",
          state: {
            msgText:
              "Password reset successfully. You may now login with the new password.",
            msgType: "success",
          },
        });
        reset();
      },
      onError: () => {
        openSnackbarContext(
          "We're sorry, there was an error processing your request",
          "error"
        );
      },
    }
  );

  const resetPassword = async (data: any) => {
    // reset password logic goes here
    const { clientToken, resetToken } = props.match.params;

    resetPasswordMutation.mutate({
      clientToken,
      resetToken,
      rawNewPassword: data.password,
    });
  };

  const theme = useTheme();

  return (
    <form onSubmit={handleSubmit(resetPassword)}>
      <Controller
        name="password"
        control={control}
        render={({ field, fieldState }) => (
          <FormControl fullWidth variant="standard">
            <StyledInputLabel shrink htmlFor="password">
              Password
            </StyledInputLabel>
            <BootstrapInput
              {...field}
              {...fieldState}
              error={!!formState.errors.password}
              fullWidth
              placeholder="Enter password"
              id="password"
              type="password"
            />
            {formState.errors.password && (
              <Typography color="error" fontSize="12px" marginTop="4px">
                {(formState as any).errors.password.message}
              </Typography>
            )}
          </FormControl>
        )}
      />
      <Controller
        name="confirmPassword"
        control={control}
        render={({ field, fieldState }) => (
          <FormControl
            sx={{
              marginTop: "16px",
            }}
            fullWidth
            variant="standard"
          >
            <StyledInputLabel shrink htmlFor="confirmPassword">
              Confirm Password
            </StyledInputLabel>
            <BootstrapInput
              {...field}
              {...fieldState}
              error={!!formState.errors.confirmPassword}
              fullWidth
              type="password"
              placeholder="Enter Confirm Password"
              id="confirmPassword"
            />
            {formState.errors.confirmPassword && (
              <Typography color="error" fontSize="12px" marginTop="4px">
                {(formState as any).errors.confirmPassword.message}
              </Typography>
            )}
          </FormControl>
        )}
      />
      <LoadingButton
        sx={{
          marginTop: theme.spacing(4),
        }}
        type="submit"
        fullWidth
        loading={resetPasswordMutation.isLoading}
        variant="contained"
        disabled={!formState.isValid}
      >
        Reset Password
      </LoadingButton>
    </form>
  );
};

export default ResetPassword;
