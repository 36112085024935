import axios from "axios";
import React, { useMemo, useState } from "react";
import MuiTable from "@mui/material/Table";
import cookie from "js-cookie";
import parser from "ua-parser-js";
import CloseIcon from "@mui/icons-material/Close";
import { useMutation, useQuery } from "react-query";
import DataTable from "../../../General/DataTable/DataTable";
import { useExpanded, useTable } from "react-table";
import Lottie from "lottie-react";
import TextField from "@mui/material/TextField";
import chatAnimation from "../../../../assets/lottie/no-chats.json";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  AppBar,
  Box,
  Chip,
  Dialog,
  IconButton,
  LinearProgress,
  Slide,
  styled,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import useDispositionStore from "../../../../store/dispositions";
import moment from "moment";
import Messages from "src/components/Messages/Messages";
import { queryClient } from "src/constants";

const StyledTextField = styled(TextField)({
  fontSize: "20px",
  ".MuiInputBase-root": {
    fontSize: "15px",
  },
  ".MuiInputLabel-root": {
    fontSize: "15px",
  },
});

const Slider = Slide as any;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slider direction="up" ref={ref} {...props} />;
});

const StyledDatePicker = styled(DatePicker)({
  // locate button
  ".MuiCalendarPicker-root": {
    color: "red",
  },
});

const StyledTableCell = styled(TableCell)({
  fontSize: "1.5rem",
});

const StyledTableCellBody = styled(TableCell)({
  fontSize: "1.5rem",
});

const StyledTableHead = styled(TableHead)({
  backgroundColor: "#FAFAFA",
});

export default function DashboardTable({ serverAPI }) {
  const [smsConversations, setSMSConversations] = useState<any[]>([]);

  // start date 3 months ago
  const [startDate, setStartDate] = React.useState(
    moment().subtract(3, "months")
  );
  const [endDate, setEndDate] = React.useState(moment());

  const token = cookie.get("token");

  const clientChatDepositions = useDispositionStore(
    (state: any) => state.clientChatDepositions
  );
  const chatSpecificDepositions = useDispositionStore(
    (state: any) => state.chatSpecificDepositions
  );
  const dispositionResults = useDispositionStore(
    (state: any) => state.dispositionResults
  );

  const { data: fetchData, isLoading } = useQuery(
    ["FETCH ACTIVITY", startDate, endDate],
    () =>
      axios.post(serverAPI + "/app/GetChatActivity", {
        start: moment(startDate).format("YYYY-MM-DD"),
        end: moment(endDate).format("YYYY-MM-DD"),
        ClientToken: token,
      }),
      {
        enabled: moment(startDate).isValid() && moment(endDate).isValid(),
      }
  );

  const { data: fetchedSMSData, isLoading: isFetchSMSLoading } = useQuery(
    ["FETCH SMS ACTIVITY", startDate, endDate],
    () =>
      
      axios.post(serverAPI + "/app/smsChats/getAll", {
        start: moment(startDate).format("YYYY-MM-DD"),
        end: moment(endDate).format("YYYY-MM-DD"),
        clientToken: token,
      }),
      {
        enabled: moment(startDate).isValid() && moment(endDate).isValid(),
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
          const smsConversations = data.data as any[];          
          let conversations = smsConversations?.map((item, index) => {
            let parseObj = new parser(item.userAgentRaw);
            (window as any).parseTest = parseObj;
            let device = "";
            if (parseObj.getDevice().vendor) {
              device = parseObj.getDevice().vendor;
            } else if (parseObj.getOS().name) {
              device = parseObj.getOS().name;
            }

            return {
              id: index,
              // date: moment(item.dataCreated).format("MM/DD/YYYY"),
              // time: moment(item.dateCreated).format("h:mm a"),
              date: item.date,
              time: item.time,
              visitorName: item.visitorName,
              location: "",
              device: "Mobile",
              messages: item.messages,
              interactionId: item.sessionToken,
              chatID: item.chatID,
              isSMS: true,
              dispositions: item.dispositions
            };
          }) || [];

          setSMSConversations(conversations);
        },
        onError: (data) => {
          console.log("error getting SMS Chats")
        },
      }
  );

  const memoizedChats = useMemo(
    () =>
      fetchData?.data?.map((item, index) => {
        let parseObj = new parser(item.userAgentRaw);
        (window as any).parseTest = parseObj;
        let device = "";
        if (parseObj.getDevice().vendor) {
          device = parseObj.getDevice().vendor;
        } else if (parseObj.getOS().name) {
          device = parseObj.getOS().name;
        }

        return {
          id: index,
          date: moment(item.dateCreated).format("MM/DD/YYYY"),
          time: moment(item.dateCreated).format("h:mm a"),
          visitorName: item.clientName,
          location: item.geoInfoRaw,
          device,
          messages: item.totalMessages,
          interactionId: item.globalInteractionID,
          chatID: item.chatID,
          isSMS: false
        };
      }) || [],
    [fetchData]
  );

  const columns = [
    { Header: "Date", accessor: "date" },
    { Header: "Time", accessor: "time" },
    { Header: "Phone Number", accessor: "visitorName" },
    { Header: "Location", accessor: "location" },
    { Header: "Device", accessor: "device" },
    { Header: "Messages", accessor: "messages" },
    {
      Header: "Dispositions",
      id: "dispositions",
      width: 250,
      Cell: ({ row: { original } }) => {
        const chatId = original.chatID;
        let dispositions: any = [];
        if(!original.isSMS) {
          dispositions = chatSpecificDepositions
          .filter((item) => item.chatID === chatId)
          .map((dep) => ({
            ...dep,
            deposition: clientChatDepositions[dep.dispositionID],
            result: dispositionResults[dep.dispositionResultID],
          }));
        }
        else {          
          dispositions = original.dispositions.map((dep, index) => ({
            //...dep,
            deposition: dep.disposition,
            result: dep.dispositionResult,
            disposition: dep.disposition,
            id: index
          }));          
        }

        const chatDispositions = dispositions;        
        return (
          <Box>
            {chatDispositions.length > 0 ? (
              <>
                {chatDispositions.map((item) => {
                  return (
                    <Chip
                      size="small"
                      sx={{
                        marginRight: "4px",
                        marginBottom: "4px",
                      }}
                      key={item.id}
                      label={`${item.deposition.name || item.deposition}:${item.result.name || item.result}`}
                    />
                  );
                })}
              </>
            ) : (
              <>-</>
            )}
          </Box>
        );
      },
    },
  ];

  const cols = useMemo(() => columns, []);
  const [messagesDialogOpen, setMessagesDialogOpen] = React.useState(false);
  const [messageId, setMessageId] = useState(false);
  const [isSMSMessage, setIsSMSMessage] = useState(false);

  const memoizedData = useMemo(
    () => smsConversations.concat(memoizedChats),
    [smsConversations, memoizedChats]
  );

  const { getTableBodyProps, headerGroups, rows, prepareRow, getTableProps } =
    useTable({
      columns: cols,
      data: memoizedData,
    });

  const onClose = () => {
    setMessagesDialogOpen(false);
    setIsSMSMessage(false);
    setMessageId(null as any);
  };

  const theme = useTheme();

  return (
    <Box>
      {isLoading && <LinearProgress />}
      <TableContainer
        sx={{
          backgroundColor: "white",
          borderRadius: theme.spacing(1),
          paddingY: theme.spacing(3),
          paddingX: theme.spacing(3),
        }}
        component={Box}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Box
            marginY={theme.spacing(2)}
            justifyContent="space-between"
            display="flex"
            alignItems="center"
          >
            <Typography fontWeight="500" fontStyle="normal" variant="h3">
              SMS Conversations
            </Typography>
            <Box display="flex">
              <Box marginRight={theme.spacing(1)}>
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={(newValue) => {
                    setStartDate(newValue as any);
                  }}
                  DialogProps={{
                    sx: {
                      ".MuiCalendarPicker-root": {
                        color: "red",
                      },
                    },
                  }}
                  PaperProps={{
                    sx: {
                      button: {
                        fontSize: theme.spacing(1.3),
                      },
                      ".MuiTypography-caption": {
                        fontSize: theme.spacing(1.3),
                      },
                    },
                  }}
                  renderInput={(params) => (
                    <StyledTextField
                      sx={{
                        fontSize: theme.spacing(2),
                      }}
                      {...params}
                    />
                  )}
                />
              </Box>
              <StyledDatePicker
                label="End Date"
                value={endDate}
                onChange={(newValue) => {
                  setEndDate(newValue as any);
                }}
                DialogProps={{
                  sx: {
                    ".MuiCalendarPicker-root": {
                      color: "red",
                    },
                  },
                }}
                PaperProps={{
                  sx: {
                    button: {
                      fontSize: theme.spacing(1.3),
                    },
                    ".MuiTypography-caption": {
                      fontSize: theme.spacing(1.3),
                    },
                  },
                }}
                renderInput={(params) => (
                  <StyledTextField
                    sx={{
                      fontSize: theme.spacing(2),
                    }}
                    {...params}
                  />
                )}
              />
            </Box>
          </Box>
        </LocalizationProvider>

        {memoizedData.length > 0 ? (
          <MuiTable
            sx={{
              border: "1px solid #e0e0e0",
              borderRadius: theme.spacing(3),
            }}
            {...getTableProps()}
          >
            <StyledTableHead>
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <StyledTableCell
                      {...column.getHeaderProps()}
                      sx={{
                        width: column.width,
                      }}
                    >
                      {column.render("Header")}
                    </StyledTableCell>
                  ))}
                </TableRow>
              ))}
            </StyledTableHead>
            <TableBody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <>
                    <TableRow {...row.getRowProps(row)}>
                      {row.cells.map((cell) => (
                        <StyledTableCellBody
                          sx={{
                            width: row.width,
                            cursor: "pointer",
                          }}
                          onClick={() => {                            
                            setMessagesDialogOpen(true);
                            setMessageId(row.original.interactionId);
                            setIsSMSMessage(row.original.isSMS);
                          }}
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </StyledTableCellBody>
                      ))}
                    </TableRow>
                  </>
                );
              })}
            </TableBody>
          </MuiTable>
        ) : (
          <Box>
            {!isLoading ? (
              <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
              >
                <Typography
                  fontSize="24px"
                  marginY={theme.spacing(2)}
                  fontWeight="600"
                  color="primary"
                >
                  You have no chats ... yet!
                </Typography>
                <Box height="450px" position="relative">
                  <Lottie animationData={chatAnimation} loop={true} />
                </Box>
                <Typography
                  sx={{
                    width: "40%",
                  }}
                  textAlign="center"
                  fontSize="18px"
                  fontWeight="400"
                  color="primary"
                >
                  Once you have chats they will appear here, you can also select
                  another date range above to show previous conversations
                </Typography>
              </Box>
            ) : null}
          </Box>
        )}
      </TableContainer>
      <Dialog
        fullScreen
        open={messagesDialogOpen}
        onClose={onClose}
        TransitionComponent={Transition as any}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h4" component="div">
              Chat Log Detail
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
              size="large"
              sx={{
                fontSize: "30px",
              }}
            >
              <CloseIcon
                sx={{
                  fontSize: "25px",
                }}
              />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Messages
          shop={cookie.get("shop")}
          serverAPI={serverAPI}
          message_id={messageId}
          is_sms_message={isSMSMessage}
        />
      </Dialog>
    </Box>
  );
}