const arrayToMapOfKey = (array, key) =>
  array.reduce(
    (resultMap, item) => ({
      ...resultMap,
      [item[key]]: item,
    }),
    {}
  );

export default arrayToMapOfKey;
