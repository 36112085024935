import { Card } from "@shopify/polaris";
import React from "react";
import "./ClassicPopUp1.scss";
import { CloseIcon } from "../../../SVG/CustomIcons";

function ClassicPopUp(props) {
  return (
    <Card>
      <Card.Section>
        <div className="classicPopUp">
          <div className="classic-main-container">
            <div className="chat-popup-text-container">
              <div className="classic-chatbot-bubble">
                <p>{"Ask us anything, we're online now!"}</p>
              </div>

              <div className="btn-container">
                <div
                  className="classic-chat-btn"
                  style={{
                    backgroundColor: props.buttonColor,
                    color: props.textColor,
                  }}
                >
                  Chat Now
                </div>
              </div>
            </div>
            <div className="message-cross-container">
              <a href={null}>
                <CloseIcon
                  height="12px"
                  width="14px"
                  color={props.buttonColor}
                />
              </a>
            </div>
          </div>
        </div>
      </Card.Section>
      {/* <Card.Section>
        <h2 className="popupWindowHeading">Classic Widget</h2>
        <p style={{ textAlign: "center" }}>
          This minimal widget is static and unobtrusive. If simplicity is your
          vibe, the classic widget is for you! This call to action is easy to
          understand and will seamlessly fit on any website.
        </p>
      </Card.Section> */}
    </Card>
  );
}

export default ClassicPopUp;
