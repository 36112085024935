export const contactInfo = [
    {
        title: 'General info',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
        showHeader: true
    },
    {
        title: 'Business Name',
        labelForm: 'Enter Business Name',
        name: 'businessName',
        mandatory: true,
    },
    {
        title: "Phone Number",
        labelForm: "Enter Administrator Phone Number",
        name: "mobileNumbers",
        multiline: true,
        mandatory: true,
        type: "phone",
    },
    {
        title: "Administrator Name",
        labelForm: "Enter Administrator Name",
        name: "contactName",
        mandatory: true,
    },
    {
        title: "Administrator email",
        labelForm: "Enter Administrator Email Address",
        name: "personalEmail",
        mandatory: true,
        type: "email",
    },
    {
        spaceBorder: true
    },
    {
        title: "User informations",
        tooltip: "Please click \"Add\" to input contact info for an individual TextChat user.",
        description:
            "Add or manage your agents from here. We recommend adding your own headshots for your agent profiles.",
        addAgents: true,
        name: "Agents",
        mandatory: true,
    },
    {
        spaceBorder: true
    },
    {
        title: 'Email Settings',
        description: 'Add multiple email separated by comma',
        showHeader: true
    },
    {
        title: "Email Notifications",
        name: "salesEmail",
        placeholder: "Enter Email Address",
        tooltip: "We will send all transcripts and chat-related questions to the email address provided here.",
        mandatory: true,
        multipleEmails: true
    },
    // {
    //     spaceBorder: true
    // },
    // {
    //     title: "Support Notifications",
    //     name: "supportEmail",
    //     placeholder: "Enter Sales Email Address",
    //     tooltip: "Whenever you get a support-related chat request on your website, we'll forward that information to this email address for your records",
    //     mandatory: true,
    //     multipleEmails: true
    // }
];
export const contactInfoDescription = [
];