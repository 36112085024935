import React, { Component } from "react";
import { Spinner } from "reactstrap";
import axios from "axios";
import cookie from "js-cookie";
import ErrorRequest from "../../components/General/ErrorRequest";
import en from "@shopify/polaris/locales/en.json";
import backendUtility from "../../components/General/backendUtility";
import FilterList from "../../components/General/FilterList/FilterList";
import {
  AppProvider,
  Tabs,
  ActionList,
  Popover,
  Button,
  Avatar,
  TextStyle,
} from "@shopify/polaris";
import SchedulerComponent from "../../components/Scheduler";
import SchedulerModal from "../../components/Scheduler/SchedulerModal.js";
import AgentSchedulerModal from "../../components/Scheduler/AgentSchedulerModal";
import "./style.scss";
import moment from "moment";
import { Alert } from "reactstrap";
import LoadingPage from "../../components/LoadingPage";

import CalendarIcon from "../../assets/img/calendar.svg";
import CalendarActiveIcon from "../../assets/img/calendar-active.svg";
import GroupIcon from "../../assets/img/group.svg";
import GroupActiveIcon from "../../assets/img/group-active.svg";
import InfoIcon from "../../assets/img/info.svg";
import { Box } from "@mui/material";
import HeaderCard from "src/components/HeaderCard";

const agentColors = [
  "#F2C063",
  "#2F80ED",
  "#9B51E0",
  "#305973",
  "#7EA1BF",
  "#262626",
];
class Scheduler extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
      shop: "",
      loading: true,
      timeZonePopOverActive: false,
      selectedTimezone: null,
      timeZones: [],
      errorText: null,
      formModalOpen: false,
      agentFormModalOpen: false,
      formData: {
        selectedDay: 0,
      },
      selectedAgent: null,
      companySchedules: [],
      agentSchedules: [],
      agentScheduleData: [],
      campaignScheduleData: {},
      campaignID: null,
      token: null,
      agents: [],
      modalErrorText: null,
      agentModalErrorText: null,
    };

    this.tabs = [
      {
        id: "company-schedule-fitted-2",
        content: "Company Schedule",
        accessibilityLabel: "Company Schedule",
        panelID: "company-schedule-fitted-content-2",
      },
      {
        id: "agent-schedule-fitted-2",
        content: "Agent Schedule",
        panelID: "agent-schedule-fitted-Ccontent-2",
      },
    ];
  }
  dates = [
    "12-27-2020",
    "12-28-2020",
    "12-29-2020",
    "12-30-2020",
    "12-31-2020",
    "01-01-2021",
    "01-02-2021",
  ];

  onAgentScheduleChangeHandler = (event, key, value, index) => {
    let { formData } = this.state;
    formData = { ...formData };
    if (key === "start" || key === "end") {
      formData[key] = (value && value.toDate()) || null;
      this.setState({ formData });
    } else if (key === "selectedDay") {
      formData[key] = value;
    }
    event && event.preventDefault();
    this.setState({ formData });
  };

  // used to managing schedule formData change handler
  onScheduleChangeHandler = (event, key, value, index) => {
    let { formData } = this.state;
    formData = { ...formData };
    if (key === "addTime" && value) {
      let lastTiming = { ...formData.timings[formData.timings.length - 1] };
      lastTiming.start = lastTiming.end;
      formData.timings = [...formData.timings, lastTiming];
    } else if (key === "busy") {
      let isBusy = key === "busy" && value;
      formData.title = isBusy ? "Busy" : "Available";
      formData[key] = value;
      formData.statusID = formData.title;
      formData.available = !isBusy;
      formData.busy = isBusy;
      formData.timings = this.getTimingBlocks(
        formData.title,
        formData.selectedDay,
        formData.selectedDate
      );
    } else if (key === "selectedDay") {
      formData[key] = value;
    } else if (key === "start" || key === "end") {
      let { timings } = formData;
      formData.timings = timings.map((t, i) => {
        if (i === index) {
          t[key] = (value && value.toDate()) || null;
        }
        return t;
      });
    }
    event && event.preventDefault();
    // debugger;

    this.setState({ formData });
  };

  onDeleteItemHandler = (event, value, index) => {
    let { formData } = this.state;

    let { timings } = formData;
    timings = [...timings];
    timings.splice(index, 1);
    this.setState({ formData: { ...formData, timings } });
  };

  loadAPIToken = () => {
    return new Promise(async (res, rej) => {
      let token = await backendUtility.getToken(
        cookie.get("shop"),
        this.props.serverAPI
      );
      await this.setState({ token }, () => {
        res(token);
      });
    });
  };

  getAgents = async () => {
    let token;
    if (!this.state.token) token = await this.loadAPIToken();
    else token = this.state.token;
    //This should have no reason to influence anything at all, but for some reason the code breaks in local without this line
    //If problem diagnosed, please remove
    this.setState({ _dontUseThis: { block: 1 } });
    let agents = await axios
      .get(`${this.props.serverAPI}/app/agents`, {
        params: {
          token: token,
        },
      })
      .then((resp) => {
        return resp.data.filter(agent=>agent.appRoleID != 4);
      })
      .catch((error) => {
        this.setState({ error: error.response });
        return null;
      });

    let newAgents = [];
    if (agents.length) {
      newAgents = agents.map((agent) => {
        let parsedAgent = {
          FullName: agent.fullName,
          EmailAddress: agent.emailAddress,
          MobilePhoneNumber: agent.mobilePhoneNumber,
          Token: agent.token,
        };

        if (agent.fileMetaData) {
          parsedAgent.FileMetaData = {
            RecordType: "AGENT_IMAGE",
            FileName: agent.fileMetaData.fileName,
            s3FileName: agent.fileMetaData.s3FileName,
            url: agent.fileMetaData.url,
          };
        }
        return parsedAgent;
      });
      this.setState({ agents: newAgents });
    }
  };

  async componentDidMount() {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const shop = url.searchParams.get("shop");
    if (shop) this.setState({ shop: shop }, this.shopify);

    await this.loadAPIToken();

    await Promise.all([
      this.getTimeZones(),
      this.getAgents(),
      this.getAccountInfo(),
    ]);

    if (this.state.selectedTab == 0) {
      await this.getCampaignSchedules();
    } else {
      await this.getAllAgentSchedules();
    }
    this.setState({ loading: false });
  }

  // to get campegain id
  getAccountInfo = () => {
    return new Promise(async (res, rej) => {
      try {
        let { token } = this.state;
        let { data } = await axios.get(
          this.props.serverAPI + `/app/account/get?token=${token}`
        );
        let campaign = data.campaign;
        await this.setState(
          { campaignID: (campaign && campaign.id) || null },
          () => {
            res((campaign && campaign.id) || null);
          }
        );
      } catch (error) {
        this.setState(
          {
            hasError: true,
            errorText:
              "We're sorry, there was an error processing your request",
            campaignID: null,
          },
          () => {
            rej();
          }
        );
      }
    });
  };

  // get timezone
  getTimeZones = async () => {
    try {
      let { data: timeZones } = await axios.get(
        this.props.serverAPI + "/app/Schedule/TimeZones"
      );
      await this.setState({
        timeZones,
        selectedTimezone: timeZones && timeZones[0],
      });
    } catch (error) {
      this.setState({
        hasError: true,
        errorText: "We're sorry, there was an error processing your request",
        timeZones: [],
        selectedTimezone: null,
      });
    }
  };

  setTimeZones = async (timeZone) => {
    try {
      let { token: clientToken, campaignScheduleData } = this.state;
      let { scheduleBlocks, ...payload } = campaignScheduleData;
      this.setState({ timeZonePopOverActive: false });

      payload.timeZone = timeZone;
      payload.scheduleID = payload.id;
      delete payload.id;

      // debugger;
      await axios.post(
        `${this.props.serverAPI}/app/Schedule/Campaign/Set/Metadata/${clientToken}`,
        payload
      );

      this.setState({
        selectedTimezone: timeZone,
      });
    } catch (error) {
      this.setState({
        hasError: true,
        errorText: "We're sorry, there was an error updating Time zone.",
      });
    }
  };

  // Get the CampaignSchedule for a clientToken + campaignID.
  getCampaignSchedules = async () => {
    try {
      let { campaignID, token: clientToken } = this.state;
      let { data: campaignScheduleData } = await axios.get(
        `${this.props.serverAPI}/app/Schedule/Campaign/${campaignID}/${clientToken}`
      );

      this.setState({
        campaignScheduleData,
        errorText: null,
        loading: false,
        selectedTimezone: campaignScheduleData.timeZone,
      });
    } catch (error) {
      this.setState({
        hasError: true,
        errorText: "We're sorry, there was an error processing your request",
      });
    }
  };

  // set the Campaign Schedule for a clientToken;
  setCampaignSchedule = async (payload) => {
    try {
      let { token: clientToken } = this.state;

      let { data } = await axios.post(
        `${this.props.serverAPI}/app/Schedule/Campaign/Set/${clientToken}`,
        payload
      );
      // this.setState({ campaignScheduleData });
    } catch (error) {
      this.setState({
        hasError: true,
        errorText: "We're sorry, there was an error processing your request",
      });
    }
  };

  // set the agent Schedule for a clientToken;
  setAgentSchedule = async (payload) => {
    try {
      let { token: clientToken } = this.state;

      let { data } = await axios.post(
        `${this.props.serverAPI}/app/Schedule/Agent/Set/${clientToken}`,
        payload
      );
    } catch (error) {
      throw error;
    }
  };

  // Clears the CampaignSchedule for the provided campaignID + scheduleID.
  deleteCampaignSchedule = async (scheduleID, dayOfWeek) => {
    try {
      let { campaignId } = this.state;
      let url = `${this.props.serverAPI}/app/Schedule/Campaign/${campaignId}/${scheduleID}`;
      if (dayOfWeek) url = `${url}?dayOfWeek=${dayOfWeek}`;
      let { data: campaignScheduleData } = await axios.delete(url);
      await this.getCampaignSchedules();
    } catch (error) {
      this.setState({
        hasError: true,
        errorText: "We're sorry, there was an error processing your request",
      });
    }
  };

  // Get the CampaignScheduleStatus for a clientToken + campaignID.
  getCampaignSchedulesStatus = async (scheduleID) => {
    try {
      let { campaignId } = this.state;

      let { data: scheduleStatus } = await axios.get(
        `${this.props.serverAPI}/app/Schedule/Campaign/${campaignId}/${scheduleID}/Status`
      );

      //   this.setState({ campaignScheduleData });
    } catch (error) {
      this.setState({
        hasError: true,
        errorText: "We're sorry, there was an error processing your request",
      });
    }
  };

  // Deactivates a campaign schedule
  deactivateCampaignSchedule = async (scheduleID) => {
    try {
      let url = `${this.props.serverAPI}/app/Schedule/Campaign/Deactivate/${scheduleID}`;
      let { data: response } = await axios.delete(url);
    } catch (error) {
      this.setState({
        hasError: true,
        errorText: "We're sorry, there was an error processing your request",
      });
    }
  };

  // Get the CampaignSchedule for a clientToken + campaignID.
  getAgentSchedule = async (agentToken) => {
    try {
      let { campaignId, clientToken } = this.props;

      let { data: agentSchedule } = await axios.get(
        `${this.props.serverAPI}/app/Schedule/Agent/${agentToken}/${campaignId}/${clientToken}`
      );

      this.setState({ agentSchedule });
    } catch (error) {
      this.setState({
        hasError: true,
        errorText: "We're sorry, there was an error processing your request",
      });
    }
  };

  getAllAgentSchedules = async () => {
    try {
      let { campaignID, token: clientToken } = this.state;

      let { data: agentScheduleData } = await axios.get(
        `${this.props.serverAPI}/app/Schedule/Agent/All/${campaignID}/${clientToken}`
      );
      this.setState({ agentScheduleData, errorText: null, loading: false });
    } catch (error) {
      this.setState({
        hasError: true,
        errorText: "We're sorry, there was an error processing your request",
        agentScheduleData: [],
      });
    }
  };

  handleTabChange = async (selectedTabIndex) => {
    let { agentScheduleData, campaignScheduleData } = this.state;
    this.setState({ selectedTab: selectedTabIndex });
    if (selectedTabIndex == 0 && !Object.keys(campaignScheduleData).length) {
      this.setState({ loading: true }, async () => {
        await this.getCampaignSchedules();
      });
    } else if (
      selectedTabIndex == 1 &&
      !Object.keys(agentScheduleData).length
    ) {
      this.setState({ loading: true }, async () => {
        await this.getAllAgentSchedules();
      });
    }
  };

  handleFormModalToggle = () => {
    let toUpdate = {};
    if (this.state.selectedTab == 1) {
      toUpdate.agentFormModalOpen = !this.state.agentFormModalOpen;
      toUpdate.agentModalInfoText = null;
    } else if (this.state.selectedTab === 0) {
      toUpdate.formModalOpen = !this.state.formModalOpen;
    }
    if (this.state.formModalOpen) {
      toUpdate.formData = {};
    }
    this.setState(toUpdate);
  };

  handleTimeZonePopOverActive = (timezone) =>
    this.setState({ timeZonePopOverActive: !this.state.timeZonePopOverActive });

  // ui events

  getTimingBlocks = (statusID = "Available", dayOfWeek, selectedDate) => {
    let { campaignScheduleData } = this.state;
    let dates = this.dates;

    let timings = [];
    campaignScheduleData.scheduleBlocks &&
      campaignScheduleData.scheduleBlocks.forEach((sblock) => {
        if (sblock.dayOfWeek === dayOfWeek && sblock.statusID === statusID) {
          timings.push({
            start: moment(
              `${dates[dayOfWeek]} ${sblock.startTimeLocal}`,
              "MM-DD-YYYY HH:mm"
            ),
            end: moment(
              `${dates[dayOfWeek]} ${sblock.endTimeLocal}`,
              "MM-DD-YYYY HH:mm"
            ),
            status: sblock.statusID,
          });
        }
      });
    if (!timings.length) {
      timings = [
        {
          start: moment(selectedDate).startOf("day"),
          end: moment(selectedDate).endOf("day"),
          status: "Available",
        },
      ];
    }
    return timings;
  };

  onSelectEvent = (event) => {
    let { selectedTab } = this.state;
    if (selectedTab === 0) {
      this.companySelectEvent(event);
    } else if (selectedTab === 1) {
      this.agentSelectEvent(event);
    }
  };

  agentSelectEvent = (event) => {
    let { selectedAgent, agents } = this.state;

    if (!selectedAgent) {
      this.setState({
        errorText:
          "Kindly select one agent from agent directory to add/update schedule",
      });
      return;
    }
    let dayOfWeek = moment(event.start).day();
    // debugger;

    let formData = {
      selectedDay: dayOfWeek,
      selectedDayActual: dayOfWeek,
      ...event,
      title: "Set an availability",
    };

    agents.forEach((agent) => {
      if (agent.Token === selectedAgent) {
        formData.agentLogoUrl = agent.FileMetaData
          ? agent.FileMetaData.url
          : "";
      }
    });
    if (!formData.agentTokens || !formData.agentNames) {
      formData.agentTokens = new Set(selectedAgent);
      formData.agentNames = [];
      agents.forEach((agent) => {
        if (agent.Token === selectedAgent) {
          formData.agentNames.push(agent.FullName);
        }
      });
    }
    formData.selectedDate = moment(event.start);
    this.setState({ formData, agentFormModalOpen: true });
  };

  companySelectEvent = (event) => {
    let dayOfWeek = moment(event.start).day();
    let block = event.block;
    let statusID = (block && block.statusID) || "Available";
    let formData = {
      timings: this.getTimingBlocks(statusID, dayOfWeek, event.start),
      selectedDay: dayOfWeek,
      selectedDayActual: dayOfWeek,
      ...event,
      busy: statusID === "Busy" ? true : false,
      available: statusID === "Busy" ? false : true,
      statusID: statusID,
      title: "Set an availability",
    };

    if (event.afterHours) {
      delete formData.afterHours;
      formData.available = true;
      formData.busy = false;
    }
    delete formData.start;
    delete formData.end;

    formData.selectedDate = moment(event.start);
    this.setState({ formData, formModalOpen: true });
  };

  onSaveScheduleHandler = async (e) => {
    e.preventDefault();
    let { formData, selectedTimezone, campaignScheduleData } = this.state;
    let { scheduleBlocks } = campaignScheduleData;

    let payload = {
      timeZone: selectedTimezone,
      scheduleName: campaignScheduleData.scheduleName,
      campaignID: campaignScheduleData.campaignID,
      scheduleID: campaignScheduleData.id,
      days:
        formData.selectedDay == 7
          ? [1, 2, 3, 4, 5]
          : formData.selectedDay == 8
          ? [0, 6]
          : [formData.selectedDay],
    };
    let OtherSameDaySchedules = [];

    if (scheduleBlocks) {
      scheduleBlocks.forEach((sb) => {
        if (
          sb.statusID !== formData.statusID &&
          sb.dayOfWeek === formData.selectedDay
        ) {
          OtherSameDaySchedules.push({
            startTime: sb.startTimeLocal,
            endTime: sb.endTimeLocal,
            status: sb.statusID == "Available" ? 1 : 0,
          });
        }
      });
    }
    let currentSchedules = formData.timings.map((timing) => {
      let scheduleBlock = {
        startTime: moment(timing.start).format("HH:mm"),
        endTime: moment(timing.end).format("HH:mm"),
        status: formData.available ? 1 : 0,
      };
      return scheduleBlock;
    });

    payload.schedule = [...OtherSameDaySchedules, ...currentSchedules];

    let response = await this.setCampaignSchedule(payload);
    await this.getCampaignSchedules();
    this.setState({ formModalOpen: false });
  };

  onDeleteAgentTimingHandler = async (e) => {
    e && e.preventDefault();
    let { formData, selectedTimezone, agentScheduleData, selectedAgent } =
      this.state;

    this.setState({
      formData: {
        ...formData,
        end: undefined,
        start: undefined,
      },
      agentModalInfoText: "Save changes to delete schedule",
    });
  };

  // Agent Schedule save handler
  onSaveAgentScheduleHandler = async (e) => {
    e.preventDefault();
    try {
      let {
        formData,
        selectedTimezone,
        agentScheduleData,
        selectedAgent,
        campaignID,
      } = this.state;
      // debugger;
      let errorMessage = null;

      if (formData.selectedDay < 7) {
        if (formData.start) {
          formData.start = moment(
            `${this.dates[formData.selectedDay]} ${moment(
              formData.start
            ).format("HH:mm")}`,
            "MM-DD-YYYY HH:mm"
          );
        }
        if (formData.end) {
          formData.end = moment(
            `${this.dates[formData.selectedDay]} ${moment(formData.end).format(
              "HH:mm"
            )}`,
            "MM-DD-YYYY HH:mm"
          );
        }
      } else {
        if (formData.start) {
          formData.start = moment(
            `${this.dates[0]} ${moment(formData.start).format("HH:mm")}`,
            "MM-DD-YYYY HH:mm"
          );
        }
        if (formData.end) {
          formData.end = moment(
            `${this.dates[0]} ${moment(formData.end).format("HH:mm")}`,
            "MM-DD-YYYY HH:mm"
          );
        }
      }

      // error handling
      if (formData.end || formData.start) {
        if (!formData.start) {
          errorMessage = "Start time should be valid";
        } else if (!formData.end) {
          errorMessage = "End time should be valid";
        } else if (formData.end < formData.start) {
          errorMessage = "End time should be greater than start time";
        }
        this.setState({ agentModalErrorText: errorMessage });
        if (errorMessage) return;
      }

      let selectedAgentSchedule = agentScheduleData.find(
        (agent) => agent.agentToken == selectedAgent
      );
      let payload = {};

      payload = {
        agentToken: selectedAgent,
        campaignID: campaignID,
        timeZone: selectedTimezone,
        days:
          formData.selectedDay == 7
            ? [1, 2, 3, 4, 5]
            : formData.selectedDay == 8
            ? [0, 6]
            : [formData.selectedDay],
        schedule: [],
        scheduleID: selectedAgentSchedule ? selectedAgentSchedule.id : null,
      };

      if (formData.end && formData.start) {
        payload.schedule.push({
          startTime: formData.start.format("HH:mm"),
          endTime: formData.end.format("HH:mm"),
          status: 1,
        });
      }
      let response = await this.setAgentSchedule(payload);
      await this.getAllAgentSchedules();
      this.setState({ agentFormModalOpen: false, formData: {} });
    } catch (error) {
      console.log(error);
      this.setState({
        agentModalErrorText:
          (error && error.response && error.response.data) ||
          "Something went wrong",
      });
    }
  };

  onSelectSlot = (event) => {
    let { selectedTab } = this.state;
    if (selectedTab === 1) {
      this.agentSelectEvent(event);
    }
  };

  eventParser = (scheduleData) => {
    let dates = this.dates;
    let allEvents = [];
    let { scheduleBlocks = [] } = scheduleData;
    let dateObjects = {};
    scheduleBlocks.forEach((block) => {
      let startTime = moment(
        `${dates[block.dayOfWeek]} ${this.pad(block.startTimeLocal)}`,
        "MM-DD-YYYY HHmm"
      );
      let endTime = moment(
        `${dates[block.dayOfWeek]} ${this.pad(block.endTimeLocal)}`,
        "MM-DD-YYYY HHmm"
      );
      let eventObject = {
        blockId: block.id,
        hasEvent: true,
        start: startTime.toDate(),
        end: endTime.toDate(),
        block, //TODO: Remove block
      };
      if (block.statusID == "Available") {
        eventObject.title = "Available";
        eventObject.available = true;
      } else {
        eventObject.title = "Busy";
        eventObject.busy = true;
      }
      dateObjects[block.dayOfWeek]
        ? dateObjects[block.dayOfWeek].push(eventObject)
        : (dateObjects[block.dayOfWeek] = [eventObject]);
    });

    // sort subgroups by time
    dates.forEach((date, index) => {
      let sortedEvents = dateObjects[index]
        ? dateObjects[index].sort((a, b) => a.start - b.start)
        : [];

      let allDayEvents = this.getFullDayEvents(sortedEvents, date);
      allEvents = [...allEvents, ...allDayEvents];
    });

    return allEvents;
  };

  // get free blocks for the day
  getFullDayEvents = (events = [], date) => {
    let fullDayEvents = [];
    let dateMoment = moment(date, "MM-DD-YYYY");
    if (!events.length) {
      fullDayEvents.push({
        start: dateMoment.startOf("day").toDate(),
        end: dateMoment.endOf("day").toDate(),
        title: "After hours",
        afterHours: true,
      });

      return fullDayEvents;
    }
    events.forEach((event, index) => {
      // debugger;
      let start = moment(event.start);
      let end = moment(event.end);
      if (index == 0) {
        fullDayEvents.push({
          start: start.startOf("day").toDate(),
          end: moment(event.start).startOf("minute").toDate(),
          title: "After hours",
          afterHours: true,
        });
      }
      fullDayEvents.push(event);
      let previousHour = fullDayEvents[fullDayEvents.length - 1].end;
      let nextHours = events[index + 1]
        ? events[index + 1].start
        : end.endOf("day").toDate();
      if (
        moment(previousHour).format("MM-DD-YYYY HHmm") !==
        moment(nextHours).format("MM-DD-YYYY HHmm")
      ) {
        fullDayEvents.push({
          start: moment(previousHour).endOf("minute").toDate(),
          end: moment(nextHours)
            .subtract("1 minute")
            .startOf("minute")
            .toDate(),
          title: "After hours",
          afterHours: true,
        });
      }
    });
    return fullDayEvents;
  };

  // pad numbers
  pad(d) {
    return d < 10 ? "0" + d.toString() : d.toString();
  }

  agentEventparser = (scheduleData) => {
    let { selectedAgent, agents } = this.state;
    let agentNames = {};
    agents.forEach((agent) => {
      agentNames[agent.Token] = agent.FullName;
    });

    let dates = this.dates;
    let agentBlocks = scheduleData.filter((block) => {
      if (!selectedAgent) return true;
      return block.agentToken === selectedAgent;
    });
    let allEvents = [];

    agentBlocks.forEach((agentBlock) => {
      let { scheduleBlocks = [] } = agentBlock;

      // if(scheduleBlocks.length)debugger;
      let dateObjects = {};
      scheduleBlocks.forEach((block) => {
        let startTime = moment(
          `${dates[block.dayOfWeek]} ${this.pad(block.startTimeLocal)}`,
          "MM-DD-YYYY HHmm"
        );
        let endTime = moment(
          `${dates[block.dayOfWeek]} ${this.pad(block.endTimeLocal)}`,
          "MM-DD-YYYY HHmm"
        );

        let eventObject = allEvents.find((event) => {
          if (
            moment(event.start).format("MM-DD-YYYY HHmm") ===
              startTime.format("MM-DD-YYYY HHmm") &&
            moment(event.end).format("MM-DD-YYYY HHmm") ===
              endTime.format("MM-DD-YYYY HHmm")
          )
            return true;
          return false;
        });

        let needToPush = false;
        if (!eventObject) {
          needToPush = true;
        }

        if (!eventObject) {
          eventObject = {
            isAgent: true,
            blockId: block.id,
            hasEvent: true,
            start: startTime.toDate(),
            end: endTime.toDate(),
            block, //TODO: Remove block
            agentNames: new Set(),
            agentTokens: new Set(),
          };
        }

        eventObject.isActive = block.isActive;
        eventObject.agentTokens.add(agentBlock.agentToken);
        eventObject.agentNames.add(agentNames[agentBlock.agentToken]);
        eventObject.title = [...eventObject.agentNames];

        if (needToPush) {
          dateObjects[block.dayOfWeek]
            ? dateObjects[block.dayOfWeek].push(eventObject)
            : (dateObjects[block.dayOfWeek] = [eventObject]);
        }
      });

      // sort subgroups by time
      dates.forEach((date, index) => {
        let sortedEvents = dateObjects[index]
          ? dateObjects[index].sort((a, b) => a.start - b.start)
          : [];
        // let allDayEvents = this.getFullDayEvents(sortedEvents, date);
        allEvents = [...allEvents, ...sortedEvents];
      });
      // debugger;
    });

    // merge events
    allEvents = this.breakAndMergeEvents(allEvents);

    return allEvents;
  };

  breakAndMergeEvents = (scheduleEvents) => {
    for (let i = 0; i < scheduleEvents.length; i++) {
      let event = scheduleEvents[i];

      let collapsingEvent = scheduleEvents.find((e) => {
        if (e.start >= event.start && e.end < event.end) {
          return true;
        }
        return false;
      });

      if (collapsingEvent) {
        // break first event
        let eventEndTime = event.end;
        event.end = collapsingEvent.start;
        event.agentTokens.forEach((item) =>
          collapsingEvent.agentTokens.add(item)
        );
        event.agentNames.forEach((item) =>
          collapsingEvent.agentNames.add(item)
        );
        collapsingEvent.title = Array.from(collapsingEvent.agentNames);

        if (event.start.getTime() === collapsingEvent.start.getTime()) {
          event.start = collapsingEvent.end;
          event.end = eventEndTime;
        } else {
          let cloned;
          if (eventEndTime >= collapsingEvent.end) {
            cloned = Object.assign({}, event);
            cloned.start = collapsingEvent.end;
            cloned.end = eventEndTime;
          } else {
            cloned = Object.assign({}, collapsingEvent);
            cloned.start = eventEndTime;
          }
          scheduleEvents.push(cloned);
        }
        i = 0;
      }
    }
    return scheduleEvents;
  };

  onClear = () => {
    this.setState({ selectedAgent: null });
  };

  render() {
    let {
      selectedTab,
      timeZones,
      selectedAgent,
      formData,
      formModalOpen,
      agents,
      campaignScheduleData,
      agentScheduleData,
      errorText,
      agentFormModalOpen,
    } = this.state;

    if (this.state.loading) {
      return (
        <div className="text-center">
          <Spinner color="primary" />
        </div>
      );
    }

    if (this.state.error)
      return (
        <AppProvider i18n={en}>
          <ErrorRequest
            serverAPI={this.props.serverAPI}
            shop={this.state.shop || null}
            error={this.state.error}
          />
        </AppProvider>
      );

    let filterAgentList =
      agents && agents.length
        ? agents.map((agent, index) => ({
            id: agent.Token,
            name: agent.FullName,
            color: agentColors[index],
          }))
        : [];

    const activator = (
      <Button onClick={this.handleTimeZonePopOverActive} disclosure>
        {this.state.selectedTimezone}
      </Button>
    );
    let scheduleEvents =
      selectedTab == 0
        ? this.eventParser(campaignScheduleData)
        : [
            ...this.eventParser(campaignScheduleData),
            ...this.agentEventparser(agentScheduleData),
          ]; //agentScheduleData;

    return (
      <Box>
        <AppProvider i18n={en}>
          <div className="scheduler-container">
            <Box display="flex" justifyContent="center" marginBottom={"20px"}>
              <HeaderCard
                title="Schedule"
                description="Set your Company and Agent Schedule for the week. Click anywhere in
              the calendar to schedule your availability!"
              />
            </Box>
            <div className="scheduler-container-wrapper">
              <div className="scheduler-container-wrapper-top">
                <div className="scheduler-tabs">
                  <div
                    className={`scheduler-tab ${
                      selectedTab === 0 ? "active" : ""
                    }`}
                    onClick={() => this.handleTabChange(0)}
                  >
                    <img
                      src={
                        selectedTab === 0 ? CalendarActiveIcon : CalendarIcon
                      }
                      alt=""
                    />
                    <div className="scheduler-tab-text">company schedule</div>
                  </div>
                  <div
                    className={`scheduler-tab ${
                      selectedTab === 1 ? "active" : ""
                    }`}
                    onClick={() => this.handleTabChange(1)}
                  >
                    <img
                      src={selectedTab === 1 ? GroupActiveIcon : GroupIcon}
                      alt=""
                    />
                    <div className="scheduler-tab-text">agent schedule</div>
                  </div>
                </div>
                {selectedTab === 0 && (
                  <div className="timezone-box">
                    <Popover
                      active={this.state.timeZonePopOverActive}
                      activator={activator}
                      onClose={this.handleTimeZonePopOverActive}
                    >
                      <ActionList
                        items={timeZones.map((timeZone) => ({
                          content: timeZone,
                          onAction: () => {
                            this.setTimeZones(timeZone);
                          },
                        }))}
                      />
                    </Popover>
                  </div>
                )}
              </div>
              {this.state.loading ? (
                <LoadingPage />
              ) : (
                <div className="scheduler-container-scheduler">
                  {selectedTab === 1 && (
                    <div className="agent-filter-wrapper">
                      <div className="agent-filter-wrapper-header">
                        <div className="agent-filter-wrapper-header-text">
                          Agent Directory
                        </div>
                        <img src={InfoIcon} alt="" />
                      </div>
                      <FilterList
                        title={
                          selectedAgent
                            ? filterAgentList.find(
                                (item) => item.id === selectedAgent
                              ).name
                            : "All"
                        }
                        filterList={filterAgentList}
                        selectedId={selectedAgent}
                        onClear={this.onClear}
                        renderItem={(item) => {
                          const { id, name } = item;
                          return (
                            <div
                              className={`listItemRow ${
                                id === selectedAgent ? "selected-filter" : ""
                              } `}
                              id={id}
                              key={id}
                              onClick={() => {
                                this.setState({ selectedAgent: id });
                              }}
                            >
                              {name}
                            </div>
                          );
                        }}
                      />
                    </div>
                  )}
                  {errorText && (
                    <Alert color="danger" className="alert-error">
                      {errorText}
                    </Alert>
                  )}
                  <SchedulerComponent
                    startDate="12-27-2020"
                    events={scheduleEvents}
                    onSelectEvent={this.onSelectEvent}
                    onSelectSlot={this.onSelectSlot}
                    agents={filterAgentList}
                    isAgent={selectedTab === 1}
                  />
                  {selectedTab === 0 ? (
                    <div className="scheduler-colors-info">
                      <div className="scheduler-colors-info-item">
                        <div className="scheduler-colors-info-item-circle available" />
                        <div className="scheduler-colors-info-item-text">
                          Available time
                        </div>
                      </div>
                      <div className="scheduler-colors-info-item">
                        <div className="scheduler-colors-info-item-circle busy" />
                        <div className="scheduler-colors-info-item-text">
                          Busy time
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="scheduler-colors-info">
                      {filterAgentList.map((agent, index) => (
                        <div className="scheduler-colors-info-item" key={index}>
                          <div
                            className="scheduler-colors-info-item-circle"
                            style={{ background: agent.color }}
                          />
                          <div className="scheduler-colors-info-item-text">
                            {agent.name.split(/(\s+)/)[0]}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </AppProvider>
        <SchedulerModal
          isOpen={formModalOpen}
          toggleModal={this.handleFormModalToggle}
          error={this.state.modalErrorText}
          onValueChange={this.onScheduleChangeHandler}
          onSave={this.onSaveScheduleHandler}
          title={formData.title}
          onDeleteItem={this.onDeleteItemHandler}
          isBusy={formData.busy}
          formData={formData}
          className="schedulerModal"
        />

        <AgentSchedulerModal
          isOpen={agentFormModalOpen}
          toggleModal={this.handleFormModalToggle}
          error={this.state.agentModalErrorText}
          info={this.state.agentModalInfoText}
          onValueChange={this.onAgentScheduleChangeHandler}
          onSave={this.onSaveAgentScheduleHandler}
          title={formData.title}
          onDeleteItem={this.onDeleteAgentTimingHandler}
          formData={formData}
          className="schedulerModal"
        />
      </Box>
    );
  }
}

export default Scheduler;
