import React from "react";
import { Modal, Button } from "@shopify/polaris";
const BasicModal = (props) => {
  return (
    <div style={{ height: props.float ? "unset" : "500px" }}>
      <div className={props.classBtn}>
        <Button onClick={(e) => props.handleChange()}>Open</Button>
      </div>
      <Modal
        open={props.active}
        onClose={(e) => props.handleChange()}
        title="Adjust your chatbot image"
        primaryAction={{
          content: props.actionBtnTitle || "Save",
          onAction: (e) => props.actionBtn() || props.handleChange(),
        }}
        secondaryActions={[
          {
            content: "Close",
            onAction: (e) => props.handleChange(),
          },
        ]}
      >
        <Modal.Section>{props.body || ""}</Modal.Section>
      </Modal>
    </div>
  );
};

export default BasicModal;
