import React from "react";
import { PDFViewer } from "@react-pdf/renderer";
import { Modal } from "@shopify/polaris";
import PDF from "./PDF/PDF";
import "./style.css";
import GetInvoice from "../../../../containers/GetInvoice/GetInvoice";

const Invoice = ({ toggleActive, showModalInvoice, pdfContent, shop, id, serverAPI }) => {

    //const PDFDoc = <PDF pdfContent={pdfContent} shopInfo={shopInfo} />;
    /*
                <GetInvoice kbill={this.props.kbill} shop={this.props.shop}/>
    */    
   /*<PDFViewer className="pdf-viewer">{PDFDoc}</PDFViewer> */

    if (!Object.keys(pdfContent).length) return null;
    return (
        <Modal
            large
            open={showModalInvoice}
            onClose={toggleActive}
            title="Invoice"
            secondaryActions={[
                {
                    content: "Cancel",
                    onAction: toggleActive,
                },
            ]}
        >
            <div className="content-pdf-invoice">
                <GetInvoice serverAPI={serverAPI} kbill={id} shop={shop}/>
            </div>
        </Modal>
    );
};

export default Invoice;
