import React, {Component} from 'react';
import './GreetingPreview.css';

export default class GreetingPreview extends Component {
    render() {
        const {message, placeholder, header = null, slogan=null, description=null} = this.props;
        return(
            <div className="GreetingPreview">
                <div className="TopBar">
                    <div className="HeaderContainer flex">
                        <div className="agents-example-window">
                            <img className="agent-widget" src="https://assets.jetsense.ai/TextChat/stock_1.png" title="John Smith" alt="John Smith" />
                            <img className="agent-widget" src="https://assets.jetsense.ai/TextChat/stock_2.png" title="John Doe" alt="John Doe" />
                            <span className="agent-online-dot"></span>
                        </div>
                        <div className='HeaderElements'>
                            <div className="Header">
                                <span>{header}</span>
                            </div>
                            <div className="Slogan">
                                <span>{slogan}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="Description" style={ description ? {} : {display: "none"}}>
                    <p>{description}</p>
                </div>
                <div className="FirstNameContainer">
                    <div className="Speaker-Container">
                        <div className="Speaker">
                            <img src={require("../../../../../../assets/img/speaker_logo.png")}/>
                        </div>
                    </div>
                    <div className="ChatBubble-Container">
                        <div className="ChatBubble">
                            <p>{message || placeholder || ""}</p>
                        </div>
                    </div>
                </div>
                <div className="ChatButton-Container">
                    <div className="ChatButton"><p>Sales</p></div>
                    <div className="ChatButton"><p>Support</p></div>
                </div>
                <div className="input-window">
                    <img src="https://chatbot.textchat.ai/assets/img/smiley.png" alt="smiley"/>
                </div>
                <p className="endingText">Powered by <span className='powered-by-footer'>TextChat</span></p>
            </div>
        );
    }
}