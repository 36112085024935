import React, { useState } from "react";
import AnswerNet_logo from "../../images/answerNet_logo_02.png";
import Success from "../../images/Success.png";
import Fail from "../../images/Fail.png";
import axios from "axios";
import Config from "../../config.js";
import Loader from "./Loader";

const serverAPI = Config.apiGateway.URL;
axios.defaults.headers.common["Authorization"] = Config.backendGUID;

const PhoneNumberCheck = () => {
  const [isLandlineNumberError, setIsLandlineNumberError] = useState(false);
  const [landLineNumber, setLandlineNumber] = useState();
  const [errorText, setErrorText] = useState(false);
  const [invalidAction, setInvalidAction] = useState(false);
  const [isSuccess, setIsSuccess] = useState(true);
  const [isNumberTextable, setIsNumberTextable] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const validateInputs = () => {
    let hasError = false;
    setErrorText(false);

    let errorField = "";
    if (!landLineNumber) {
      // setInvalidAction(true);
      hasError = true;
      errorField = "Landline Number";
      setIsLandlineNumberError(true);
    }
    if (hasError) {
      setInvalidAction(true);
      const errorText = `Please include "${errorField}" in order to proceed.`;
      setErrorText(errorText);
      return false;
    }
    return true;
  };

  const isPhoneNumberTextable = async (landLineNumber) => {
    console.log("isPhoneNumberTextable: ", landLineNumber);
    return await axios
      .post(serverAPI + "/app/sms/canEnable/" + landLineNumber)
      .then((res) => {
        return res.data;
      });
  };

  const onSubmit = async () => {
    setIsLoading(true);
    console.log("onSubmit: ", landLineNumber);
    let isTextable = await isPhoneNumberTextable(landLineNumber);
    setIsNumberTextable(isTextable);
    setIsLoading(false);
  };

  return (
    <>
      <div className="phonenumber-box ">
        <div className="logo-wrpper">
          <img className="logo" src={AnswerNet_logo} alt="" />
        </div>
        <div className="text-center mt-10 font-16 mb-20 text-bold">
          Submit business number below to check if you can text-enable:
        </div>
        <div className="form-group">
          <input
            type="text"
            name="landlineNumber"
            className={`input-control ${
              isLandlineNumberError ? "input-error" : ""
            }`}
            placeholder="Input Landline Number"
            value={landLineNumber}
            onChange={(e) => setLandlineNumber(e.target.value)}
          />
        </div>
        <div className="form-group justify-content-center">
          {isLoading ? (
            <Loader />
          ) : (
            <input
              type="button"
              value="Submit"
              className="btn-submit"
              onClick={onSubmit}
            />
          )}
        </div>
        {(isNumberTextable === true || isNumberTextable === false) && (
          <>
            <div className="text-center mt-10 font-20 mb-20 text-bold">
              Result
            </div>
            <div className="text-center">
              {console.log("isNumberTextable:::: ", isNumberTextable)}
              {isNumberTextable === true && (
                <img
                  className="logo"
                  src={Success}
                  alt=""
                  style={{ width: "180px" }}
                />
              )}
              {isNumberTextable === false && (
                <img
                  className="logo"
                  src={Fail}
                  alt=""
                  style={{ width: "150px" }}
                />
              )}
            </div>
            <div className="text-center mt-10 font-16 mb-20 text-bold">
              {isNumberTextable === true &&
                "This business landline can be text-enabled!"}
              {isNumberTextable === false &&
                "Unfortunately this business landline cannot be text-enabled. Please have your client pick from a new number, just for texting!"}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default PhoneNumberCheck;
