import React  from 'react';
export const billingPlan = [
        {
            card: true,
            title: "Plan Details",
            cardSection: [
                <p>First 14 days FREE!</p>,
                <p>Flat Rate per 30 days per agent $19.00.</p>,
                <p>100 Chats per agent.</p>,
                <p>Overage Per Chat Per Agent Per Billing Period is $0.04</p>,
                {
                    switchpressed: [
                      {
                          value: "bronze"
                       }
                    ],
                    name: "subscription",
                    mandatory: true
                }
            ]
        },
];
export const billingPlanDescription = [
        {
            text: "Review your plan details.",
            bold: true
        },
];