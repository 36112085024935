import React, { Component } from "react";
import { Page, PageActions, Layout, Banner } from "@shopify/polaris";
import Axios from "axios";
import cookie from "js-cookie";
import ReactHtmlParser from "react-html-parser";
import utility from "../../../../General/utility";
import backendUtility from "../../../../General/backendUtility";
import MeetCustomer from "..";
import StepHead from "./StepHead/StepHead";
import StepHeadBorder from "./StepHead/StepHeadBorder/StepHeadBorder";
import WaitingActivation from "../../../WaitingActivation/WaitingActivation";
import data from "./formData";
import { step1, step1Description, step1video, EULA } from "./step1";
import { step2, step2Description, step2video, step2EULA } from "./step2";
import { step3, step3Description, step3Substeps, step3video } from "./step3";
import { step4, step4Description, step4Substeps, step4video } from "./step4";
import { CircleTickMajor } from "../../../../SVG/CustomIcons";
import AgentManager from "../../AgentManager/AgentManager";

import Intro from "./Intro";

class ExtraInformation extends Component {
  constructor() {
    super();
    this.stateDataSave = this.stateDataSave.bind(this);
    this.getAgents = this.getAgents.bind(this);
    this.state = {
      inputs: [],
      descriptions: [],
      dataToSave: {},
      auxDataToSave: {},
      mandatory: [],
      complete: false,
      url: "",
      substeps: null,
      substep: 0,
      step: 0,
      active: 0,
      haltIncomplete: false,
      incompleteTitle: null,
      incompleteDescription: null,
      loading: true,
      token: null,
      incompleteInputNames: [],
      agentNum: null,
      _dataToSaveString: "{}",

      video: {},
      EULA: null,
      acceptedEULA: false,
      videoMaxWith: 768, //to update this you need to take a look on ../styles.css too
    };
  }
  componentDidMount() {
    this.switchView();
    if (this.props.inputs) {
      this.prepSetup();
    }
  }

  switchView = async () => {
    let stateData;
    this.setState({
      substeps: null,
      complete: false,
      incompleteInputNames: [],
    });

    if (this.props.inputs) {
      stateData = {
        inputs: this.props.inputs,
        url: "app",
        mandatory: this.getMandatory(this.props.inputs),
      };
      if (this.props.descriptions)
        stateData = { ...stateData, descriptions: this.props.descriptions };
    } else {
      let step = this.state.step || this.props.step;
      switch (parseInt(step)) {
        case 1:
          stateData = {
            inputs: step1,
            descriptions: step1Description,
            url: "app",
            EULA,
            video: step1video,
          };
          break;
        case 2:
          stateData = {
            inputs: step2,
            descriptions: step2Description,
            url: "app",
            video: step2video,
            EULA: step2EULA,
          };
          break;
        case 3:
          stateData = {
            inputs: step3,
            substeps: step3Substeps,
            substep: 0,
            descriptions: step3Description,
            url: "app",
            video: step3video,
            EULA: null,
          };
          break;
        case 4:
          stateData = {
            inputs: step4,
            substeps: step4Substeps,
            substep: 0,
            descriptions: step4Description,
            url: "app",
            video: step4video,
            EULA: null,
          };
          break;
        default:
          stateData = { inputs: [], url: "shopify", EULA: null };
          break;
      }
    }
    var initialData = this.getInitialData(
      stateData.inputs,
      !!stateData.substeps
    );
    this.setStateAsync(stateData).then(() => {
      if (this.props.info) {
        this.processEmails(this.props.info);
        this.setState({
          dataToSave: Object.assign(initialData, this.props.info),
          auxDataToSave: JSON.parse(
            JSON.stringify(Object.assign(initialData, this.props.info))
          ),
          url: "app",
          loading: false,
        });
      } else this.getInfoBusiness(initialData);
    });
  };
  prepSetup = async () => {
    console.log("this.props.inputs: ", this.props.inputs);
    if (this.props.inputs.filter((x) => x.name === "Agents").length > 0) {
      this.hasAgents = true;
      await this.getAgents();
    }
    if (this.props.inputs.filter((x) => x.name === "funFactOne").length > 0) {
      await this.getFunFacts();
    }
    if (this.props.inputs.filter((x) => x.name === "greetingHeader")) {
      await this.getHeader();
    }
  };
  loadAPIToken = async () => {
    let token = await backendUtility.getToken(
      cookie.get("shop"),
      this.props.serverAPI
    );
    this.setState({ token });
    return token;
  };
  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }
  getInfoBusiness = async (initialData = {}) => {
    const url = this.state.url;
    let info = await Axios.get(`${this.props.serverAPI}/${url}/get/info`, {
      params: {
        shop: cookie.get("shop"),
      },
    })
      .then((resp) => {
        console.log("-0---------", resp);
        if (resp.data && utility.isObject(resp.data)) return resp.data;
        else return {};
      })
      .catch((error) => {
        this.props.setError(error.response);
      });
    const inputs = [...this.state.inputs];
    let itemsMandatory = this.getMandatory(inputs);
    //Note: remove permanently unless a bug is found in connection with this change.
    //this.continue(info, itemsMandatory);
    var infoToSet = info;
    this.processEmails(infoToSet);
    Object.keys(initialData).forEach((k) => {
      if (!infoToSet[k]) infoToSet[k] = initialData[k];
    });
    await this.setStateAsync({
      dataToSave: infoToSet,
      auxDataToSave: JSON.parse(JSON.stringify(infoToSet)),
      mandatory: itemsMandatory,
      loading: false,
    });
  };
  processEmails(info) {
    if (typeof info["supportEmail"] === "string")
      info["supportEmail"] = [info["supportEmail"]];
    if (typeof info["salesEmail"] === "string")
      info["salesEmail"] = [info["salesEmail"]];
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (!this.state.active) {
      return true;
    }
    if (this.state.agentNum !== nextState.agentNum) {
      return true;
    }
    return false;
  }

  componentDidUpdate(prevProps) {
    const { shouldSave, discart } = this.props;
    if (shouldSave && shouldSave !== prevProps.shouldSave) {
      this.save(true, false);
    }
    if (discart && discart !== prevProps.discart) {
      this.setState({
        dataToSave: JSON.parse(JSON.stringify(this.state.auxDataToSave)),
      });
      this.props.changesDiscarded();
    }
    if (!!this.props.info && this.state.dataToSave.Agents) {
      let prevAgents = JSON.parse(this.state._dataToSaveString).Agents;
      if (
        prevAgents &&
        this.state.dataToSave.Agents.length !== prevAgents.length
      ) {
        this.props.setCriticalMessage(
          "You will be asked to accept modified charges"
        );
      }
    }
    if (
      !!this.props.info &&
      this.state.dataToSave &&
      this.props.pendingExists
    ) {
      let prevData = this.state._dataToSaveString;
      if (JSON.stringify(this.state.dataToSave) !== prevData) {
        this.props.setCriticalMessage(
          "Warning: you will overwrite pending information"
        );
      }
    }

    let stateString = JSON.stringify(this.state.dataToSave);
    if (stateString !== this.state._dataToSaveString) {
      this.setState({ _dataToSaveString: stateString });
    }
  }
  setIncomplete = (title = null, description = null) => {
    let titleToSet = title;
    let descriptionToSet = description;
    if (
      !title &&
      !description &&
      this.state.inputs &&
      Object.keys(this.state.inputs).indexOf("Agent") !== -1
    ) {
      titleToSet = "Please add a TextChat user";
      descriptionToSet =
        "Fill in the details of at least one TextChat user to continue the setup process.";
    }
    this.setState({
      haltIncomplete: true,
      incompleteTitle: titleToSet,
      incompleteDescription: descriptionToSet,
    });
  };
  resetIncomplete = () => {
    this.setState({
      haltIncomplete: false,
      incompleteTitle: null,
      incompleteDescription: null,
      incompleteInputNames: [],
    });
  };
  getTrimmedData = () => {
    var dataToSave = this.state.dataToSave;
    const inputNames = this.state.inputs.map((x) => x.name);
    Object.keys(dataToSave).forEach((x) => {
      if (inputNames.indexOf(x) !== -1) {
        if (typeof dataToSave[x] === "string")
          dataToSave[x] = dataToSave[x].trim();
      }
    });
    return dataToSave;
  };
  getMandatory = (inputs) => {
    let itemsMandatory = [];
    inputs.forEach((item) => {
      if (item.cardSection) {
        item.cardSection.forEach((cardItem) => {
          if (cardItem.mandatory) itemsMandatory.push(cardItem.name);
        });
      }
      if (item.mandatory) itemsMandatory.push(item.name);
    });
    return itemsMandatory;
  };
  getInitialData = (inputs, hasSubsteps) => {
    var initialData = {};
    let inputsToExamine = [];
    if (hasSubsteps)
      inputs.forEach((x) => {
        inputsToExamine = inputsToExamine.concat(x);
      });
    else inputsToExamine = inputs;
    inputsToExamine.forEach((item) => {
      if (item.initialValue !== undefined)
        initialData[item.name] = item.initialValue;
    });
    return initialData;
  };
  save = async (shouldContinue = true, saveThenContinue = true) => {
    const dataToSave = this.getTrimmedData();
    if (shouldContinue && !saveThenContinue) {
      let validChange = await this.continue(dataToSave, this.state.mandatory);
      if (!validChange) {
        if (this.props.confirmSaved) this.props.confirmSaved(false);
        return false;
      }
    }

    const url = this.state.url;

    let objUpdateInfo = {
      info: dataToSave,
      shop: cookie.get("shop"),
    };
    if (this.props.info && this.props.step)
      objUpdateInfo.step = this.props.step;

    const dataSaved = await Axios.post(
      `${this.props.serverAPI}/${url}/save/info`,
      objUpdateInfo
    )
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        this.props.setError(error.response);
      });
    if (dataSaved.billingUrl) {
      window.open(dataSaved.billingUrl, "_self");
      return;
    }

    if (!!this.props.info && dataToSave["Agents"]) {
      Axios.put(
        `${this.props.serverAPI}/app/AccountHolder/Upsert`,

        { clientToken: this.state.token, Agents: dataToSave["Agents"] }
      )
        .then((resp) => {
          return resp;
        })
        .catch((error) => {
          this.props.setError(error.response);
        });
    }
    if (
      !!this.props.info &&
      dataToSave["funFactOne"] &&
      dataToSave["funFactTwo"]
    ) {
      Axios.put(
        `${this.props.serverAPI}/app/FunFacts/Upsert`,

        {
          clientToken: this.state.token,
          funFactOne: dataToSave["funFactOne"],
          funFactTwo: dataToSave["funFactTwo"],
        }
      )
        .then((resp) => {
          return resp;
        })
        .catch((error) => {
          this.props.setError(error.response);
        });
    }
    if (!!this.props.info) {
      Axios.put(`${this.props.serverAPI}/app/header/Upsert`, {
        clientToken: this.state.token,
        displayName: dataToSave["greetingHeader"] || "",
        chatbotHeaderDescription: dataToSave["greetingDescription"] || "",
        chatbotHeaderSlogan: dataToSave["greetingSlogan"] || "",
      })
        .then((resp) => {
          return resp;
        })
        .catch((error) => {
          this.props.setError(error.response);
        });
    }

    this.setState({
      auxDataToSave: JSON.parse(JSON.stringify(this.state.dataToSave)),
    });

    if (this.props.confirmSaved) this.props.confirmSaved();

    if (shouldContinue && saveThenContinue)
      return await this.continue(dataSaved, this.state.mandatory);
  };
  continue = async (dataSaved, mandatory) => {
    /*Count stuff filled in*/
    let complete = true;
    const countMandatory = mandatory.length;
    let emptyInputs = [];

    if (countMandatory !== 0) {
      if (dataSaved.agreeEULA && dataSaved.agreeEULA[0] !== "accepted") {
        //Not complete, Eula needs to be filled out
        complete = false;
      }
      const dataSavedElements = Object.keys(dataSaved);
      let countSaved = 0;
      mandatory.forEach((item) => {
        if (dataSavedElements.indexOf(item) > -1 && dataSaved[item]) {
          if (item === "Agents" && dataSaved["Agents"].length > 0) countSaved++;
          else if (item !== "Agents" && dataSaved[item]) countSaved++;
        } else {
          //If the item isn't present, this means it needs to be highlighted

          emptyInputs.push(item);
        }
      });
      if (countSaved !== countMandatory) {
        //Not everything filled in, highlight stuff
        complete = false;
      }
    }

    //Count not filled in
    let toVerify = this.state.inputs.filter((x) => x.type);
    let unverified = false;
    let unverifiedInputs = [];
    toVerify.forEach((x) => {
      if (dataSaved[x.name]) {
        utility.verifyInput(x.type, dataSaved[x.name], () => {
          unverifiedInputs.push(x.name);
          unverified = true;
        });
      }
    });
    if (unverified) {
      complete = false;
    }
    this.setState({
      incompleteInputNames: [...unverifiedInputs, ...emptyInputs],
      complete: complete,
    });
    if (!complete) this.setIncomplete();
    else this.resetIncomplete();

    return complete;
  };
  stateDataSave = (name, value, callback = null) => {
    const dataToSave = this.state.dataToSave;
    dataToSave[[name]] = value;
    this.setState(
      {
        dataToSave: JSON.parse(JSON.stringify(dataToSave)),
      },
      () => {
        if (callback) callback();
      }
    );
  };
  renderLayouts() {
    const { inputs, substeps, substep } = this.state;
    const { setIsDirty } = this.props;
    if (!inputs.length) return null;
    const inputsToRender = substeps ? inputs[substep] : inputs;
    return inputsToRender.map((item, index) => {
      /*let placeholder = item.placeholder;
            let initialValue = item.initialValue;

            if (item.toReplace && item.toReplace.length > 0) {
                let shopUrl = this.props.shop.shop;
                let shopName = shopUrl.substring(0, shopUrl.indexOf("."));
                item.toReplace.forEach(replacePair => {
                    if (placeholder.includes(replacePair[0])) {
                        if (replacePair[1] === "storeName") {
                            placeholder = placeholder.replace(replacePair[0], shopName);
                        }
                    }
                    if (initialValue.includes(replacePair[0])) {
                        if (replacePair[1] === "storeName")
                            initialValue = initialValue.replace(replacePair[0], shopName);
                    }
                });
            }*/

      // agent listing container
      if (item.addAgents) {
        return (
          <AgentManager
            key={index}
            index={index}
            activateOn={item.activateOn}
            title={item.title}
            tooltip={item.tooltip}
            description={item.description}
            type={item.type}
            labelForm={item.labelForm}
            options={item.options}
            checkbox={item.checkbox}
            radio={item.radio}
            select={item.select}
            cardSection={item.cardSection}
            setIsDirty={setIsDirty}
            navSave={!!this.props.info}
            dataToSave={this.state.dataToSave}
            stateDataSave={this.stateDataSave}
            name={item.name}
            switchBtn={item.switchBtn}
            secondary={item.secondary}
            third={item.third}
            placeholder={item.placeholder}
            card={item.card}
            mandatory={item.mandatory}
            multiline={item.multiline}
            dropzone={item.dropzone}
            addAgents={item.addAgents}
            popupMessage={item.popupMessage}
            greetingMessage={item.greetingMessage}
            renderAsIs={item.renderAsIs}
            associatedName={item.associatedName}
            initialValue={item.initialValue}
            setIncomplete={this.setIncomplete}
            serverAPI={this.props.serverAPI}
            getAgents={this.getAgents}
            setError={this.props.setError}
            incomplete={this.state.incompleteInputNames}
            multipleEmails={item.multipleEmails}
            spaceBorder={item.spaceBorder}
            noContent={item.noContent}
            cardTitleCustom={item.cardTitleCustom}
            videoDescription={item.videoDescription}
          />
        );
      }

      return (
        <MeetCustomer
          key={index}
          index={index}
          activateOn={item.activateOn}
          title={item.title}
          tooltip={item.tooltip}
          description={item.description}
          type={item.type}
          labelForm={item.labelForm}
          options={item.options}
          checkbox={item.checkbox}
          radio={item.radio}
          select={item.select}
          cardSection={item.cardSection}
          setIsDirty={setIsDirty}
          navSave={!!this.props.info}
          dataToSave={this.state.dataToSave}
          stateDataSave={this.stateDataSave}
          name={item.name}
          switchBtn={item.switchBtn}
          secondary={item.secondary}
          third={item.third}
          placeholder={item.placeholder}
          card={item.card}
          mandatory={item.mandatory}
          multiline={item.multiline}
          dropzone={item.dropzone}
          addAgents={item.addAgents}
          popupMessage={item.popupMessage}
          greetingMessage={item.greetingMessage}
          renderAsIs={item.renderAsIs}
          associatedName={item.associatedName}
          initialValue={item.initialValue}
          setIncomplete={this.setIncomplete}
          serverAPI={this.props.serverAPI}
          getAgents={this.getAgents}
          setError={this.props.setError}
          incomplete={this.state.incompleteInputNames}
          multipleEmails={item.multipleEmails}
          spaceBorder={item.spaceBorder}
          noContent={item.noContent}
          cardTitleCustom={item.cardTitleCustom}
          videoDescription={item.videoDescription}
          showHeader={item.showHeader}
        />
      );
    });
  }
  renderDescriptions() {
    const { descriptions, substeps, substep } = this.state;
    const originalDescriptions = substeps
      ? descriptions[substep]
      : descriptions;

    let descriptionsToRender = JSON.parse(JSON.stringify(originalDescriptions));

    //Replace descriptions
    originalDescriptions.forEach((item, index) => {
      let toReplace = item["toReplace"];
      if (toReplace && toReplace.length)
        toReplace.forEach((replacePair) => {
          //Make sure description includes item to replace
          if (item.text.includes(replacePair[0])) {
            if (replacePair[1] === "billingAmount") {
              //Handle case where need to replace with billing amount
              if (this.state.agentNum === null) {
                descriptionsToRender[index].text = item.text.replace(
                  replacePair[0],
                  "19/month per user."
                );
                Axios.get(this.props.serverAPI + "/app/get/agentNum", {
                  params: { shop: cookie.get("shop") },
                }).then((response) => {
                  let responseString = String(response.data);
                  let newLength =
                    3 > responseString.length ? responseString.length : 3;
                  let newAgentNum = Number(
                    responseString.substring(0, newLength)
                  );
                  this.setState({ agentNum: newAgentNum });
                });
              } else {
                descriptionsToRender[index].text = item.text.replace(
                  replacePair[0],
                  String(this.state.agentNum * 19) +
                    " per month total ($19 for each of your " +
                    String(this.state.agentNum) +
                    " users)."
                );
              }
            } else {
              descriptionsToRender[index].text = item.text.replace(
                replacePair[0],
                replacePair[1]
              );
            }
          }
        });
    });
    return descriptionsToRender.map((item, key) => (
      <p key={key}>
        {item.bold ? <strong>{item.text}</strong> : ReactHtmlParser(item.text)}
      </p>
    ));
  }

  renderEULA() {
    const { EULA } = this.state;

    if (EULA && EULA.text)
      return (
        <Layout>
          <Layout.AnnotatedSection>
            <div className="pointer-event" onClick={(e) => this.acceptEULA(e)}>
              <div className="wizard-eula Polaris-Layout__AnnotationContent">
                <p>{EULA.text}</p>
              </div>
              <div className="wizard-eula Polaris-Layout__AnnotationContent">
                <p className="wizard-eula">
                  <span className="agree-eula-text">I agree</span>{" "}
                  <span>
                    <CircleTickMajor
                      color={this.state.acceptedEULA ? "#ff042a" : "#dfdfdf"}
                    />
                  </span>
                </p>
              </div>
            </div>
          </Layout.AnnotatedSection>
        </Layout>
      );

    return null;
  }

  acceptEULA(e) {
    e.preventDefault();
    let { acceptedEULA } = this.state;
    this.setState({ acceptedEULA: !acceptedEULA });
  }

  renderVideo(showVideo) {
    const { video } = this.state;
    if (video && video.url && showVideo) {
      /*return(
                <div className='video-content'>
                    <video className='video-steps' src={video.url} poster={video.poster} controls />
                </div>
            )*/
      return null;
    }
  }

  handleSave = async (e, options = {}) => {
    e.preventDefault();
    let action = "plus";

    if (options.action) {
      action = options.action;
    }

    await this.save();
    if (action === "plus" && !this.state.complete) {
      this.setIncomplete();
      return null;
    }
    const tryStep = this.state.step || this.props.step;
    let step = (tryStep || 0) + 1;
    if (options.step) {
      step = options.step;
    }
    return await this.stepRequest(step, action);
  };
  changeSubstep = async (e, options = {}) => {
    e.preventDefault();

    let action = "plus";
    if (options.action) {
      action = options.action;
    }

    if (action === "plus" && this.state.substep < this.state.substeps - 1) {
      this.resetIncomplete();
      this.confirmSubstepIncrement(() =>
        this.setState({ substep: this.state.substep + 1, complete: false })
      );
    } else if (
      action === "plus" &&
      this.state.substep === this.state.substeps - 1
    ) {
      this.resetIncomplete();
      this.confirmSubstepIncrement(async () => {
        await this.save(false);
        const tryStep = this.state.step || this.props.step;
        let step = (tryStep || 0) + 1;
        if (options.step) {
          step = options.step;
        }
        return await this.stepRequest(step, action);
      });
    } else {
      this.resetIncomplete();
      this.setState({ substep: this.state.substep - 1, complete: false });
    }
  };
  confirmSubstepIncrement = (increment) => {
    var toVerify = this.state.inputs[this.state.substep].filter((x) => x.type);
    var unverified = false;
    toVerify.forEach((x) => {
      utility.verifyInput(x.type, this.state.dataToSave[x.name], () => {
        unverified = true;
      });
    });
    if (unverified) {
      this.setIncomplete();
      return;
    }

    const mandatory = this.getMandatory([
      ...this.state.inputs[this.state.substep],
    ]);
    const countMandatory = mandatory.length;

    if (countMandatory === 0) {
      increment();
      return;
    } else {
      if (
        this.state.dataToSave.agreeEULA &&
        this.state.dataToSave.agreeEULA[0] !== "accepted"
      ) {
        this.setIncomplete();
        return;
      }
      const dataSavedElements = Object.keys(this.state.dataToSave);
      let countSaved = 0;
      //Note: assuming here that no substep contains an agent add component
      mandatory.forEach((item) => {
        if (
          dataSavedElements.indexOf(item) > -1 &&
          this.state.dataToSave[item]
        ) {
          countSaved++;
        }
      });
      if (countSaved === countMandatory) {
        increment();
        return;
      } else {
        this.setIncomplete();
        return;
      }
    }
  };
  stepRequest = async (nextStep, action) => {
    const shop = cookie.get("shop");
    return await Axios.post(`${this.props.serverAPI}/app/save/step`, {
      step: nextStep,
      shop: shop,
      action: action,
    })
      .then(async (resp) => {
        const stepResp = resp.data;
        if (stepResp.billingUrl) {
          window.open(stepResp.billingUrl, "_self");
          return;
        }

        if (stepResp.step || stepResp.active) {
          stepResp.dataToSave = {};
          await this.setStateAsync(stepResp);
          this.resetIncomplete();
        }
        if (!stepResp.active) this.switchView();
        return resp.data;
      })
      .catch((error) => {
        this.props.setError(error.response);
      });
  };
  getAgents = async () => {
    let token;
    if (!this.state.token) token = await this.loadAPIToken();
    else token = this.state.token;
    //This should have no reason to influence anything at all, but for some reason the code breaks in local without this line
    //If problem diagnosed, please remove
    this.setState({ _dontUseThis: { block: 1 } });
    let agents = await Axios.get(`${this.props.serverAPI}/app/agents`, {
      params: {
        token: token,
      },
    })
      .then((resp) => {
        console.log("Agents gotten: " + JSON.stringify(resp.data));
        return resp.data;
      })
      .catch((error) => {
        console.log("Agents get error: " + JSON.stringify(error.response));
        this.props.setError(error.response);
        return false;
      });

    let loadStorage = true;

    let newAgents = [];
    if (agents.length) {
      newAgents = agents.map((agent) => {
        console.log("mapping agent: " + JSON.stringify(agent));
        let parsedAgent = {
          FullName: agent.fullName,
          EmailAddress: agent.emailAddress,
          MobilePhoneNumber: agent.mobilePhoneNumber,
          Token: agent.token,
        };

        if (agent.fileMetaData) {
          parsedAgent.fileMetaData = { ...agent.fileMetaData };
          delete parsedAgent.mimeType;
        }
        console.log("parsed agent: " + JSON.stringify(parsedAgent));
        return parsedAgent;
      });
      // console.log("new agents: " + JSON.stringify(newAgents))
      // console.log("first new agent === old: " + String(newAgents[0] === agents[0]))

      // console.log("old agents at end: " + JSON.stringify(agents))
      loadStorage = false;
      this.stateDataSave("Agents", newAgents);
    }
  };
  getFunFacts = async () => {
    console.log("getting fun facts");
    let token;
    if (!this.state.token) token = await this.loadAPIToken();
    else token = this.state.token;
    let funFacts = await Axios.get(`${this.props.serverAPI}/app/funFacts`, {
      params: {
        token: token,
      },
    })
      .then((resp) => {
        console.log("fun fact resp: " + JSON.stringify(resp));
        return resp.data;
      })
      .catch((error) => {
        this.props.setError(error.response);
        return false;
      });
    if (funFacts.length) {
      let newFacts = funFacts.map((fact) => fact.funFact);
      this.stateDataSave("funFactOne", newFacts[0]);
      this.stateDataSave("funFactTwo", newFacts[1]);
    }
  };
  getHeader = async () => {
    console.log("getting header");
    let token;
    if (!this.state.token) token = await this.loadAPIToken();
    else token = this.state.token;
    let header = await Axios.get(`${this.props.serverAPI}/app/header`, {
      params: {
        token: token,
      },
    })
      .then((resp) => {
        console.log("header resp: " + JSON.stringify(resp));
        return resp.data;
      })
      .catch((error) => {
        this.props.setError(error.response);
        return false;
      });
    if (header.displayName) {
      this.stateDataSave("greetingHeader", header.displayName);
    }
    if (header.chatbotHeaderDescription) {
      this.stateDataSave(
        "greetingDescription",
        header.chatbotHeaderDescription
      );
    }
    if (header.chatbotHeaderSlogan) {
      this.stateDataSave("greetingSlogan", header.chatbotHeaderSlogan);
    }
  };
  actions = () => {
    const step = this.state.step || this.props.step;
    const { substep, substeps } = this.state;
    if (this.props.info) {
      return null;
    }
    if (step && step > 1) {
      let canContinue = true;
      if (step === 2)
        canContinue =
          this.state.dataToSave["Agents"] &&
          this.state.dataToSave["Agents"].length &&
          this.state.acceptedEULA;

      return (
        <PageActions
          primaryAction={{
            content:
              step === 4 && substeps && substep === substeps - 1
                ? "Finish Setup"
                : "Next",
            disabled: !canContinue,
            onAction: (e) => {
              if (substeps && substep < substeps) this.changeSubstep(e);
              else this.handleSave(e);
            },
          }}
          secondaryActions={[
            {
              content: "Back",
              onAction: (e) => {
                if (substeps && substep > 0)
                  this.changeSubstep(e, {
                    action: "subtract",
                  });
                else
                  this.handleSave(e, {
                    step: 1,
                    action: "subtract",
                  });
              },
            },
          ]}
        />
      );
    }
    return (
      <PageActions
        primaryAction={{
          content: "Next",
          disabled: !this.state.acceptedEULA,
          onAction: (e) => {
            if (substeps && substep < substeps) this.changeSubstep(e);
            else this.handleSave(e);
          },
        }}
      />
    );
  };
  render() {
    const steps = this.state.step || this.props.step;
    return !this.state.loading && this.state.active === 1 ? (
      <WaitingActivation
        serverAPI={this.props.serverAPI}
        shop={this.props.shop}
        setError={this.props.setError}
        textchatAPI={this.props.textchatAPI}
      />
    ) : (
      <div className="Wizard-Content">
        {steps > 0 || this.props.inputs ? (
          <Page
            title={this.props.title || "TextChat Wizard"}
            subtitle={<b>{this.props.subTitle || ""}</b>}
          >
            {this.props.info ? (
              ""
            ) : (
              <>
                <StepHead step={steps} />
                <StepHeadBorder
                  step={steps}
                  substeps={this.state.substeps}
                  substep={this.state.substep}
                />
              </>
            )}
            {this.renderDescriptions()}
            {this.renderVideo(true)}
            <Layout>{this.renderLayouts()}</Layout>
            {this.state.haltIncomplete ? (
              <div className="banner-layout">
                <Banner
                  title={
                    this.state.incompleteTitle ||
                    "Complete this step before continuing"
                  }
                  status="warning"
                >
                  <p>
                    {this.state.incompleteDescription ||
                      "All fields are required. Please check to make sure that you have entered email addresses and phone numbers correctly."}
                  </p>
                </Banner>
              </div>
            ) : (
              ""
            )}
            {this.renderEULA()}
            {this.actions()}
          </Page>
        ) : (
          <div>
            <Intro onNext={() => this.stepRequest(1, "plus")} />
          </div>
        )}
      </div>
    );
  }
}
export default ExtraInformation;
